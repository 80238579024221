import React, { useState } from "react";
import { SliderData } from "./Slider";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import styles from "./EmployeeHandbook.module.css";
const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = SliderData.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? FaArrowAltCircleLeft : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? current : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className={styles.slider}>
      {current === 0 ? (
        <></>
      ) : (
        <FaArrowAltCircleLeft
          className={styles.left_arrow}
          onClick={prevSlide}
        />
      )}
      {current === length - 1 ? (
        <></>
      ) : (
        <FaArrowAltCircleRight
          className={styles.right_arrow}
          onClick={nextSlide}
        />
      )}
      {SliderData.map((slide, index) => {
        return (
          <div
            className={
              index === current
                ? `${styles.slide} ${styles.active}`
                : styles.slide
            }
            key={index}
          >
            {index === current && (
              <img
                src={slide.image}
                alt="EmployeeHandbook"
                className={styles.image1}
              />
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ImageSlider;
