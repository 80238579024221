import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";

export const fetchReceiverGroups = createAsyncThunk(
  "poll/receiver-groups",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_RECEIVER_GROUPS}`);

      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeActivePolls = createAsyncThunk(
  "poll/active-polls",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.ACTIVE_POLLS}`);

      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  receiverGroups: [],
  employeeActivePolls: [],
  loading: false,
  error: null,
  success: false,
};

export const pollSlice = createSlice({
  name: "pollSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceiverGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReceiverGroups.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.receiverGroups = payload?.receiverGroups;
        state.error = null;
      })
      .addCase(fetchReceiverGroups.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.receiverGroups = [];
        state.success = false;
      })
      .addCase(fetchEmployeeActivePolls.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeActivePolls.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeActivePolls = payload?.employeeActivePolls;
        state.error = null;
      })
      .addCase(fetchEmployeeActivePolls.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeActivePolls = [];
        state.success = false;
      });
  },
});

export default pollSlice.reducer;
