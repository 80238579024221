import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMappedConfigData } from "../../../../store/slices/leaveConfigSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";

const LeaveConfiguration = ({ id, handleUpdateConfig }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMappedConfigData(id));
  }, [dispatch, id]);
  const leaveConfigData = useSelector(
    (state) => state?.leaveConfig?.getMappedConfigDataList?.response?.data
  );
  console.log("leaveConfigData:", leaveConfigData);
  return (
    <div className="leavePlanTable overflow-x-auto rounded-t-md">
      {leaveConfigData ? (
        <table className="w-full whitespace-nowrap">
          <thead className="text-sm bg-gray-50 uppercase">
            <tr className="border-b border-gray-100">
              <th className="p-3">Leave Type</th>
              <th className="p-3">Quota</th>
              <th className="p-3">Accural</th>
              <th className="p-3">End Of Year</th>
              <th className="p-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {leaveConfigData &&
              leaveConfigData?.map((list) => {
                return (
                  <tr key={list?.id}>
                    <td className="text-sm py-2 px-3 border-b border-gray-100">
                      {list?.leaveType?.leaveTypeLabel}
                    </td>
                    <td className="text-sm py-2 px-3 border-b border-gray-100">
                      {list?.quota ?? 0}
                    </td>
                    <td className="text-sm py-2 px-3 border-b border-gray-100">
                      {list?.accural}
                    </td>
                    <td className="text-sm py-2 px-3 border-b border-gray-100">
                      {list?.end_of_year}
                    </td>
                    <td className="text-sm py-2 px-3 border-b border-gray-100">
                      <span
                        id={list?.id}
                        onClick={(event) => handleUpdateConfig(event, list)}
                        className="py-1 px-3 border-1 border-blue-300 bg-blue-50 rounded-md mr-2 cursor-pointer"
                      >
                        SetUp
                      </span>
                      <span className="py-1 px-3 border-1 border-red-300 bg-red-50 rounded-md mr-2 cursor-pointer">
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="noLeaveTypeAssign">
          No Leave Types are assigned to this leave plan.
        </div>
      )}
    </div>
  );
};

export default LeaveConfiguration;
