import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWfhRequestData } from "../../../store/slices/ReportsSlice";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../Loader/Loader";
import { ClickAwayListener, Pagination } from "@mui/material";
import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faEye,
  faFilter,
  faHouseLaptop,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import FilterWFHComponent from "./FilterWFHComponent";
import ApplyWFHDialog from "./ApplyWFHDialog";
import WFHConfirmationDialog from "./WFHConfirmationDialog";
import UpdateWFHDialog from "./UpdateWFHDialog";
import { leaveUpdateStatusV1 } from "../../../store/slices/leaveSlice";
// import AlertsMessage from "../../AlertsMessage/AlertsMessage";
import { useContext } from "react";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import {
  componentDisplayAccess,
  getEmployeesAccess,
} from "../../../utils/userPermission";

const WorkFromHome = () => {
  const { displayPopup } = useContext(PopupContext);

  const [confirmStatus, setConfirmStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(
    JSON.parse(localStorage.getItem("wfhFilter"))?.status?.value ?? null
  );
  const [selectedEmpData, setSelectedEmpData] = useState([]);
  const [updateData, setUpdateData] = useState({});
  // const [selectEmpId, setSelectEmpId] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [applyFilter, setApplyFilter] = useState(false);
  // const [alert, setAlert] = useState(false);
  const [action, setAction] = useState();
  const [alertClass, setAlertClass] = useState("alert-danger");
  const [filterValues, setFilterValues] = useState(
    localStorage?.getItem("wfhFilter")
      ? JSON.parse(localStorage.getItem("wfhFilter"))
      : null
  );
  const [sorting, setSorting] = useState("posting_date");
  const [order, setOrder] = useState("DESC");
  const workFromHomeAccess = componentDisplayAccess({
    component: "Work_From_Home_V1",
  });
  const dispatch = useDispatch();

  const getData = useCallback(
    async (params) => {
      let response = JSON.parse(localStorage.getItem("wfhFilter"));
      await dispatch(
        fetchWfhRequestData({
          filterValue: "Work From Home",
          search: response?.employee?.value,
          status: response?.status?.value,
          fromDate: response?.date?.from_date,
          toDate: response?.date?.to_date,
          limit,
          page,
          sort: sorting,
          order
        })
      );
      setSelectedEmpData([]);
      setSelectedStatus(
        JSON.parse(localStorage.getItem("wfhFilter"))?.status?.value ?? null
      );
    },
    [dispatch, limit, page, sorting, order]
  );

  const data = useSelector(
    (state) => state?.reports?.getWfhRequestData?.response?.data
  );
  useEffect(() => {
    if (localStorage?.getItem("wfhFilter")) {
      // const { date, status, employee, leave_type } = JSON.parse(
      //   localStorage?.getItem("wfhFilter")
      // );
      // if (!date?.from_date && !status && !employee && !leave_type) {
      //   localStorage?.setItem(
      //     "wfhFilter",
      //     JSON.stringify({
      //       status: { label: "Open", value: "Open" },
      //     })
      //   );
      const initialData = JSON.parse(localStorage?.getItem("wfhFilter"));
      setFilterValues(initialData);
      // }
    } else {
      localStorage?.setItem(
        "wfhFilter",
        JSON.stringify({
          status: { label: "Open", value: "Open" },
        })
      );
      const initialData = JSON.parse(localStorage?.getItem("wfhFilter"));
      setFilterValues(initialData);
    }
  }, []);

  useEffect(() => {
    // setAlert(false);
    getData();
  }, [page, limit, getData, filterValues]);

  const isFetching = useSelector(
    (state) => state?.reports?.getWfhRequestData?.isFetching
  );

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleUpdate = async (status) => {
    const res = await dispatch(
      leaveUpdateStatusV1({
        status: status,
        leavelist: selectedEmpData,
      })
    );
    // setAlert(true);
    setSelectedEmpData([]);
    setAlertClass(selectedStatus);
    setSelectedStatus(
      JSON.parse(localStorage.getItem("wfhFilter"))?.status?.value ?? null
    );
    if (res?.payload?.status)
      displayPopup(
        "Successfully Updated Work From Home Status",
        "alert-success"
      );
    else displayPopup(res?.payload?.message, "alert-danger");
    getData();
    setConfirmStatus(false);
  };

  const handleChangeLimit = () => {
    setToggle(!toggle);
  };

  const employeeList = useSelector(
    (state) => state?.employee?.employeeNameList
  );

  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const WorkFromHomeId = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Work_From_Home_V1",
  });

  const wfhStatus = ["Open", "Dismissed", "Approved", "Rejected"];

  const handleFilterClose = (e, dataId) => {
    const data = filterValues;
    data[dataId] = null;
    localStorage.setItem("wfhFilter", JSON.stringify(data));
    const result = JSON.parse(localStorage.getItem("wfhFilter"));

    setFilterValues(result);
    setPage(1);
  };

  const handleSelectAll = (e) => {
    if (e?.target?.checked) {
      const selectAllData = [];
      data?.item?.forEach((data) => {
        if (
          data?.status === selectedStatus &&
          WorkFromHomeId?.[data?.masterleave?.EmployeeId]?.updateAccess
        )
          selectAllData.push(data?.leaveApplicationId);
      });
      setSelectedEmpData(selectAllData);
    } else {
      setSelectedEmpData([]);
      setSelectedStatus(
        JSON.parse(localStorage.getItem("wfhFilter"))?.status?.value ?? null
      );
    }
  };

  const handleCheckboxInput = (e, data) => {
    if (e?.target?.checked) {
      setSelectedStatus(data?.status);
      selectedEmpData.push(data?.leaveApplicationId);
    } else {
      Number(selectedEmpData.length) === 1 && setSelectedStatus();
      setSelectedEmpData(
        selectedEmpData?.filter((item) => item !== data?.leaveApplicationId)
      );
      Number(selectedEmpData.length) === 1 && setSelectedStatus();
    }
    // setSelectEmpId(data?.EmployeeId);
    setToggle(!toggle);
  };

  let headers = [
    { label: "Employee ID", name: "EmployeeId" },
    { label: "Employee Name", name: "employee_name" },
    { label: "Total Days", name: "total_leave_days" },
    { label: "From Date", name: "from_date" },
    { label: "To Date", name: "to_date" },
    { label: "Reporting Manager", name: "reporting_manager" },
    { label: "Action Taken By", name: "action_taken_by" },
    { label: "status", name: "status" },
    { label: "Action" },
  ];

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <div className="p-3 pb-0 bg-indigo-50">
          <div className="flex items-center justify-end flex-wrap gap-3 pb-3 pt-0">
            <>
              {selectedStatus !== "Approved" &&
                selectedStatus !== undefined &&
                selectedEmpData?.length ? (
                <button
                  class="border-solid border-1 border-green-600 rounded-md text-green-700 bg-green-0 hover:text-green-50 hover:bg-green-700 py-1 px-4 "
                  id="3"
                  type="button"
                  onClick={() => {
                    setConfirmStatus(true);
                    setAction("Approved");
                  }}
                >
                  Approve
                </button>
              ) : null}
              {selectedStatus !== "Rejected" &&
                selectedStatus !== undefined &&
                selectedEmpData?.length ? (
                <button
                  class="border-solid border-1 border-red-600 rounded-md text-red-700 bg-red-0 hover:text-red-50 hover:bg-red-700 py-1 px-4 "
                  id="3"
                  type="button"
                  onClick={() => {
                    setConfirmStatus(true);
                    setAction("Rejected");
                  }}
                >
                  Reject
                </button>
              ) : null}
            </>
            {workFromHomeAccess?.createAccess ? (
              <button
                onClick={() => setOpen(true)}
                class="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
                id="3"
                type="button"
              >
                <FontAwesomeIcon icon={faHouseLaptop} className="me-1" /> Apply
                WFH
              </button>
            ) : null}
            <button
              class="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
              id="3"
              type="button"
              onClick={() => setApplyFilter(true)}
            >
              <FontAwesomeIcon icon={faFilter} className="me-1" /> Filter
            </button>
          </div>
          <div className="bg-white px-3 pb-0 flex items-center gap-3 rounded-t-lg border-b border-b-gray-300 shadow-md">
            <div className="flex items-center gap-3 py-3">
              <div className="flex items-center gap-2 font-semibold after:content-['|']  after:text-gray-700">
                {data?.totalItems} <span>Records</span>{" "}
                <span className="border-r border-r-indigo-200"></span>
              </div>
              {filterValues?.employee && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.employee?.label}
                  <FontAwesomeIcon
                    id="employee"
                    onClick={(e) => handleFilterClose(e, "employee")}
                    className="pl-2 pr-3 cursor-pointer"
                    icon={faXmark}
                  />
                </div>
              )}

              {filterValues?.status && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.status?.label}
                  <FontAwesomeIcon
                    id="status"
                    onClick={(e) => handleFilterClose(e, "status")}
                    className="pl-2 pr-3 cursor-pointer"
                    icon={faXmark}
                  />
                </div>
              )}

              {filterValues?.date?.from_date && (
                <>
                  <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                    {`${dayjs(filterValues?.date?.from_date)?.format(
                      "DD-MMM-YYYY"
                    )} - ${dayjs(filterValues?.date?.to_date)?.format(
                      "DD-MMM-YYYY"
                    )}`}
                    <FontAwesomeIcon
                      id="date"
                      onClick={(e) => handleFilterClose(e, "date")}
                      className="pl-2 pr-3 cursor-pointer"
                      icon={faXmark}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="leaveAppTableContainer relative overflow-x-auto shadow-sm">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  rounded-b-lg whitespace-nowrap">
              <thead className="text-xs bg-white text-gray-700 uppercase">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        disabled={
                          !selectedStatus || selectedStatus === "Dismissed"
                        }
                        // checked = {selectedEmpData && selectedStatus ?true:false}
                        onChange={(e) => handleSelectAll(e)}
                        className="cursor-pointer disabled:cursor-not-allowed w-4 h-4 text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-55  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label for="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  {headers?.map((i) => {

                    return (
                      <th key={i} scope="col" className="px-6 py-3">
                        <div className="flex">{i.label}
                          {i?.name && <>{sorting === i.name ? <span className="pl-1" onClick={() => { setSorting(i?.name); order === "DESC" ? setOrder("ASC") : setOrder("DESC"); }}>
                            <FontAwesomeIcon icon={order === "DESC" ? faArrowDown : faArrowUp} ></FontAwesomeIcon></span> :
                            <span className="pl-1 opacity-50" onClick={() => { setSorting(i?.name); setOrder("ASC"); }}><FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon></span>
                          }</>}

                        </div>
                      </th>
                    );
                  })}
                  {/* <th scope="col" className="px-6 py-3">
                    Employee ID
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Employee Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total Days
                  </th>
                  <th scope="col" className="px-6 py-3">
                    From Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    To Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Reporting Manager
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action Taken By{" "}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {data?.item?.map((data) => {
                  return (
                    <tr className="bg-white border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="w-4 px-4 py-2">
                        {WorkFromHomeId?.[data?.masterleave?.EmployeeId]
                          ?.updateAccess ? (
                          <div className="flex items-center">
                            <input
                              onChange={(e) => handleCheckboxInput(e, data)}
                              id="checkbox-table-search-1"
                              type="checkbox"
                              checked={selectedEmpData?.includes(
                                data?.leaveApplicationId
                              )}
                              disabled={
                                (selectedStatus !== data?.status &&
                                  selectedStatus !== undefined &&
                                  selectedStatus !== null) ||
                                data.status === "Dismissed"
                              }
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-1"
                              type="checkbox"
                              disabled={true}
                              checked={
                                selectedEmpData.includes(
                                  data.leaveApplicationId
                                )
                                  ? true
                                  : false
                              }
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        )}
                      </td>
                      {/* <td
                        scope="row"
                        className="flex items-center px-3 py- text-gray-900 whitespace-nowrap dark:text-white"
                        >
                        <ListItemAvatar className="img-fluid">
                          {data?.masterleave?.profileImage ? (
                            <Avatar
                              className="img-fluid allEmpProfileImg w-10 h-10 rounded-full object-cover"
                              src={data?.masterleave?.profileImage}
                              alt={data?.employee_name}
                            />
                          ) : (
                            <Avatar
                              className="img-fluid empCardAvatar w-10 h-10 rounded-full object-cover"
                              {...stringAvatar(data?.masterleave?.firstName)}
                              alt={data?.employee_name}
                            />
                          )}
                        </ListItemAvatar>

                        <div className="pl-3">
                          <div className="text-base font-semibold">
                            {`${data?.masterleave?.firstName ?? ""} ${
                              data?.masterleave?.middleName ?? ""
                            } ${data?.masterleave?.lastName ?? ""} `}
                          </div>
                          <div className="font-normal text-gray-500">
                            #{data?.EmployeeId}
                          </div>
                        </div>
                      </td> */}
                      <td className="px-3 py-2">#{data?.EmployeeId}</td>
                      <td className="px-3 py-2">
                        {`${data?.masterleave?.firstName ?? ""} ${data?.masterleave?.middleName ?? ""
                          } ${data?.masterleave?.lastName ?? ""} `}
                      </td>
                      <td className="px-3 py-2">
                        {Number(parseFloat(data?.total_leave_days)).toFixed(2)}
                      </td>
                      <td className="px-3 py-2">
                        {dayjs(data.from_date)?.format("DD-MMM-YYYY")}
                      </td>
                      <td className="px-3 py-2">
                        {dayjs(data.to_date)?.format("DD-MMM-YYYY")}
                      </td>
                      <td className="px-3 py-2">
                        {`${data?.masterleave?.manager?.firstName ?? ""} 
                        ${data?.masterleave?.manager?.middleName ?? ""}  
                        ${data?.masterleave?.manager?.lastName ?? ""}`}
                      </td>
                      <td className="px-3 py-2">
                        {data.status !== "Open"
                          ? `${data?.approver?.firstName ?? ""} 
                            ${data?.approver?.middleName ?? ""}  
                            ${data?.approver?.lastName ?? ""}`
                          : "-"}
                      </td>
                      <td className="px-3 py-2">
                        <div
                          className={`flex items-center leaveTableStatus ${data.status} rounded-sm px-2`}
                        >
                          <div
                            className={`h-2.5 w-2.5 rounded-full mr-2 ${data.status}`}
                          ></div>
                          {data.status}
                        </div>
                      </td>
                      {/* <td className="px-3 py-2">
                        <div className="empLeaveComments relative inline-block">
                          <div className={
                                  data?.status === "Dismissed"
                                ? "tooltipWrapper relative statusDismissed"
                                : "tooltipWrapper relative"
                            }>
                            <FontAwesomeIcon
                              onClick={() => (
                                setCommentDialog(true),
                                setComment(data?._comments)
                              )}
                              className="text-blue-600 font-semibold cursor-pointer"
                              icon={faCommentLines}
                            />
                            <div className="tooltipHover comment hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold whitespace-nowrap">
                              View Comments
                            </div>
                          </div>
                        </div>
                      </td> */}
                      <td className="px-3 py-2">
                        <div className="flex items-center gap-3">
                          {WorkFromHomeId?.[data?.masterleave?.EmployeeId]
                            ?.readAccess ? (
                            <div
                              className={
                                data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <FontAwesomeIcon
                                onClick={() => {
                                  setOpenEdit(true);
                                  setUpdateData(data);
                                }}
                                icon={faEye}
                                className="cursor-pointer text-blue-600"
                              />
                              <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                                View
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                className="cursor-pointer text-blue-600"
                              />
                              <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                                View
                              </div>
                            </div>
                          )}
                          {WorkFromHomeId?.[data?.masterleave?.EmployeeId]
                            ?.updateAccess ? (
                            <div
                              className={
                                data?.status === "Approved"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="cursor-pointer text-green-500"
                                id="Approve"
                                onClick={() => {
                                  setConfirmStatus(true);
                                  setSelectedStatus(data?.status);
                                  setAction("Approved");
                                  setSelectedEmpData([
                                    data?.leaveApplicationId,
                                  ]);
                                }}
                              />
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status === "Approved"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="cursor-pointer text-green-500"
                                id="Approve"
                              />
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          )}
                          {WorkFromHomeId?.[data?.masterleave?.EmployeeId]
                            ?.updateAccess ? (
                            <div
                              className={
                                data?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                className="cursor-pointer text-red-700"
                                id="Reject"
                                onClick={() => {
                                  setConfirmStatus(true);
                                  setAction("Rejected");
                                  setSelectedStatus(data?.status);
                                  setSelectedEmpData([
                                    data?.leaveApplicationId,
                                  ]);
                                }}
                              />
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                className="cursor-pointer text-red-700"
                                id="Reject"
                              />
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="w-100 flex items-center justify-end py-3">
            <Pagination
              count={Math.ceil(data?.totalItems / limit)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
            <div className="totalNoOfPages ms-3 after:content-[''] after:ml-3 after:text-gray-500 after:border-r after:border-r-gray-500">
              On Page:{" "}
              <span className="totalCount">
                {data?.totalItems ? page : 0} of{" "}
                {Math.ceil(data?.totalItems / limit)}
              </span>
            </div>
            {/* <label className="pl-3">|</label> */}
            <div className="flex items-center ml-3">
              <div className="totalNoOfPages flex items-center">
                Rows per page: <span className="totalCount"></span>
              </div>
              <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div className="paginationLimitBox" onClick={handleChangeLimit}>
                  {limit}
                  {toggle ? (
                    <FontAwesomeIcon icon={faChevronUp} className="ms-2" />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  )}
                  {toggle && (
                    <ul className="paginationLimitBoxDropdown">
                      {[25, 50, 75, 100]?.map((list) => {
                        return (
                          <li
                            value={list}
                            onClick={(e) => {
                              setLimit(e?.target?.value);
                              setPage(1);
                            }}
                          >
                            {list}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      )}

      {applyFilter && (
        <FilterWFHComponent
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          getData={getData}
          setPage={setPage}
          employeeList={employeeList}
          wfhStatus={wfhStatus}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
      )}
      {open && (
        <ApplyWFHDialog
          setOpen={setOpen}
          open={open}
          // setAlert={setAlert}
          getData={getData}
        />
      )}
      {/* {commentDialog && (
        <WFHCommentsDialog
          commentDialog={commentDialog}
          setCommentDialog={setCommentDialog}
          userComment={comment?._comments}
          managementComment={comment?.management_comment}
        />
      )} */}
      {confirmStatus && (
        <WFHConfirmationDialog
          confirmStatus={confirmStatus}
          setConfirmStatus={setConfirmStatus}
          handleUpdate={handleUpdate}
          // setAlert={setAlert}
          setAlertClass={setAlertClass}
          alertClass={alertClass}
          selectedStatus={selectedStatus}
          status={action}
          setSelectedStatus={setSelectedStatus}
          setSelectedEmp={setSelectedEmpData}
        />
      )}

      {openEdit && (
        <UpdateWFHDialog
          updateData={updateData}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          getData={getData}
          filterValues={filterValues}
          handleUpdate={handleUpdate}
          status={action}
        // setAlert={setAlert}
        />
      )}
    </>
  );
};

export default WorkFromHome;
