import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConsentDialog = ({
  openConsent,
  setOpenConsent,
  Status,
  setStatus,
  regularizationId,
  setRegularizationId,
  getData,
  filterValues,
  handleSubmit,
  handleClose
}) => {

  const isFetching = useSelector(
    (state) => state?.leave?.updateRegularizationData?.isFetching
  );
  return (
    <Dialog
      open={openConsent}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <DialogTitle className="add_employee_main_heading">
        <span>
          {Status === "Approved" ? "Approve" : "Reject"} Regularization Request
        </span>
      </DialogTitle>
      {isFetching ? (
        <Loader />
      ) : (
        <DialogContent className="card-content-employee-form">
          <div className="updateLeaveBalance mb-2">
            Are You Sure You Want To{" "}
            {Status === "Approved" ? "Approve" : "Reject"} This Regularization
            Request ?
          </div>
        </DialogContent>
      )}
      <DialogActions className="employee_form_button_div">
        <button type="button" onClick={handleClose} className="cancel_button">
          No
        </button>
        <button type="button" onClick={handleSubmit} className="save_button">
          Yes
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDialog;
