import http from "../../utils/http";
export const allocationList = async (params) => {
  var response = await http.get(`/leave/allocation-list`, { params });
  return response.data.data;
};

export const updateAllocationStatus = async (req) => {
  var response = await http.post(`leave/approve-allocation-new`, req);
  return response.data;
};
