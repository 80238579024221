import React, { createContext, useContext, useState } from "react";
import './GlobalPopup.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleExclamation, faCircleInfo, faCircleXmark, faXmark } from '@fortawesome/pro-light-svg-icons';

// Create a context for managing the popup state
export const PopupContext = createContext();

// Create a component that will render the popup globally
const GlobalPopup = () => {
  const { message, closePopup,alertClass } = useContext(PopupContext);
  return (
    message && (
      <div className="alertMessageMain">
        <div
          className={`alert ${alertClass} alert-dismissible fade show`}
          role="alert"
        >
          <div className="alertMessageContent">
            <div className="alretMessageIcon">
              {alertClass === "alert-danger" ? (
                <FontAwesomeIcon icon={faCircleXmark} />
              ) : null || alertClass === "alert-success" ? (
                <FontAwesomeIcon icon={faCircleCheck} />
              ) : null || alertClass === "alert-warning" ? (
                <FontAwesomeIcon icon={faCircleExclamation} />
              ) : null || alertClass === "alert-info" ? (
                <FontAwesomeIcon icon={faCircleInfo} />
              ) : null}
            </div>
            <div>
              {/* <h5>Welcome back <strong> Name! </strong></h5>  */}
              <p>{message}</p>
            </div>
            <button
              type="button"
              className="alertMessageClose"
              onClick={() => closePopup()}
            >
              {" "}
              <FontAwesomeIcon icon={faXmark} />{" "}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

// Create a provider for the PopupContext
export const PopupProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");

  const displayPopup = (msg,alertClass) => {
    setMessage(msg);
    setAlertClass(alertClass)
    setTimeout(() => {
      closePopup();
    }, 5000);
  };

  const closePopup = () => {
    setMessage("");
  };

  return (
    <PopupContext.Provider value={{ message, displayPopup, closePopup,alertClass }}>
      {children}
      <GlobalPopup />
      {/* <AlertsMessage alertClass={"text-danger"} responseData={{message: message}}></AlertsMessage> */}
    </PopupContext.Provider>
  );
};
