import React, { useEffect, useState } from "react";
import "../LeaveConfigDashboard.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import {
  getAllLeaveTypes,
  getAssignedLeaveMapping,
  getMappedConfigData,
  mapLeavePlan,
} from "../../../store/slices/leaveConfigSlice";

const AssignLeaveTypes = ({
  assignLeaveTypes,
  setAssignLeaveTypes,
  activeLeavePlan,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLeaveTypes());
    dispatch(getAssignedLeaveMapping(activeLeavePlan));
  }, [dispatch, activeLeavePlan]);
  const getLeaveTypes = useSelector(
    (state) => state?.leaveConfig?.getAllLeaveTypesList?.response?.leaveTypeData
  );
  const getMappedLeavePlan = useSelector(
    (state) => state?.leaveConfig?.getAssignedLeaveMappingList?.response?.data
  );
  const [planArray, setPlanArray] = useState([]);
  useEffect(() => {
    setPlanArray(getMappedLeavePlan);
  }, [getMappedLeavePlan]);
  const methods = useForm({
    defaultValues:
      getLeaveTypes &&
      getMappedLeavePlan &&
      getLeaveTypes?.reduce((acc, item) => {
        acc[item?.leaveTypeLabel] = getMappedLeavePlan?.some(
          (plan) => plan === item?.id
        );
        return acc;
      }, {}),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = methods;
  useEffect(() => {
    const result =
      getLeaveTypes &&
      getMappedLeavePlan &&
      getLeaveTypes?.reduce((acc, item) => {
        acc[item?.leaveTypeLabel] = getMappedLeavePlan?.some(
          (plan) => plan === item?.id
        );
        return acc;
      }, {});
    reset(result);
  }, [getLeaveTypes, getMappedLeavePlan, reset]);
  const submitHandler = async () => {
    await dispatch(
      mapLeavePlan({ id: activeLeavePlan, body: { planArray: planArray } })
    );
    await dispatch(getAssignedLeaveMapping(activeLeavePlan));
    await dispatch(getMappedConfigData(activeLeavePlan));
    setPlanArray([]);
    reset();
    setAssignLeaveTypes(false);
  };
  return (
    <>
      <div
        className={
          assignLeaveTypes
            ? "createLeaveDrawer active relative"
            : "createLeaveDrawer relative"
        }
      >
        <div className="bg-blue-500 text-white py-4 px-3 w-full">
          <div className="text-lg mb-2">
            <div className="flex items-center justify-between">
              <div>Assign Leave Types</div>
              <div
                className="headerClose cursor-pointer"
                onClick={() => {
                  setAssignLeaveTypes(false);
                  reset();
                }}
              >
                <FontAwesomeIcon className="fa-lg" icon={faXmark} />
              </div>
            </div>
            <div className="text-sm text-gray-200">
              Select the leave types to be assigned to new leave plan
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={assignLeaveTypes ? "active" : ""}>
              <div className="addLeaveForm bg-white">
                <div className="formContainer pt-4">
                  {getLeaveTypes?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="">
                          <Controller
                            name={item?.leaveTypeLabel}
                            errors={errors}
                            control={control}
                            value={false}
                            render={({ field: { onChange, name, value } }) => (
                              <>
                                <Checkbox
                                  color="primary"
                                  id={item?.leaveTypeLabel}
                                  name={name}
                                  onChange={(e, val) => {
                                    onChange(val);
                                    if (val) {
                                      setPlanArray((prevState) => [
                                        ...prevState,
                                        item?.id,
                                      ]);
                                    } else {
                                      setPlanArray((prevState) =>
                                        prevState.filter(
                                          (leaveId) => leaveId !== item?.id
                                        )
                                      );
                                    }
                                  }}
                                  checked={value || false}
                                />
                              </>
                            )}
                          />
                          <label
                            htmlFor={item?.leaveTypeLabel}
                            className="text-sm cursor-pointer"
                          >
                            {item?.leaveTypeLabel}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="text-end p-3">
              <button
                onClick={() => {
                  setAssignLeaveTypes(false);
                  reset();
                }}
                type="button"
                className="text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-600 hover:border-gray-600 py-2 px-3"
              >
                Cancel
              </button>
              <button
                type="submit"
                id={!isDirty ? "newDisable" : ""}
                className="ml-2 text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-600 text-white hover:bg-blue-700 py-2 px-3"
              >
                Save
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          assignLeaveTypes ? "createLeaveOverlay active" : "createLeaveOverlay"
        }
        onClick={() => setAssignLeaveTypes(false)}
      ></div>
    </>
  );
};

export default AssignLeaveTypes;
