import React from "react";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import { fetchMiscellaneousDeductionsData } from "../../../store/slices/payrollSlice";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";

export default function CreateMiscDeduction({
  create,
  miscDeductionData,
  deductionType,
  setCreate,
  employeeData,
  startDate,
  params,
}) {
  const misDeduction = useForm();
  const dispatch = useDispatch();
  const MiscDeductionSchema = yup.object().shape({
    deduction_amount: yup
      .number()
      .max(100000)
      .label("Deduction Amount")
      .typeError("Deduction Amount must be a number")
      .min(0, "Amount can't be less than zero")
      .required(),
    description: yup.string().max(250).label("Description").required(),
    deduction_date: yup.string().label("Deduction Date").required(),
  });

  const methods = useForm({
    resolver: yupResolver(MiscDeductionSchema),
  });

  const {
    handleSubmit: submitPoll,
    control,
    formState: { errors, isDirty },
    trigger,
  } = methods;

  const submitHandler = async (data) => {
    try {
      if (!data?.employee_name) {
        toast.error("Kindly Select an Employee");
        return;
      }

      if (!data?.misc_deduction_type_id) {
        toast.error("Kindly Select Deduction Name");
        return;
      }
      if (data?.deduction_amount < 0) {
        toast.error("Deduction Amount Cannot be Negative");
        return;
      }

      const submitData = {
        employee_id: data?.employee_name?.value,
        misc_deduction_type_id: data?.misc_deduction_type_id?.value,
        deduction_amount: data?.deduction_amount,
        deduction_date: data?.deduction_date,
        description: data?.description,
      };
      const res = await http.post(`payroll/create-misc-deduction`, submitData);

      toast.success(res?.data?.message);
      dispatch(fetchMiscellaneousDeductionsData(params));
      setCreate(false);
    } catch (err) {
      toast?.error(err?.response?.data?.message);
    }
  };

  return (
    <div>
      <Dialog open={create} className="postModalsContainer">
        <div className="postModalsContent">
          <div className="postModalTitle">
            <div className="postModalTitleHeading">
              <span className="actionIcon">
                Add New Miscellaneous Deduction
              </span>
            </div>
          </div>
          <FormProvider {...misDeduction}>
            <form onSubmit={submitPoll(submitHandler)}>
              <DialogContent className="payrollModalContent pt-0">
                <div className="row">
                  <div className="col-md-6">
                    <label className="label">
                      Employee Name
                      <span className="create_form_asterisk">*</span>:
                    </label>

                    <AutoCompleteComponent
                      name="employee_name"
                      control={control}
                      errors={errors}
                      options={employeeData?.map((v) => ({
                        label: `${v?.EmployeeId}/${v?.employee_name}`,
                        value: v?.EmployeeId,
                      }))}
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="misc_deduction_type_id"
                      className="label"
                    >
                      Deduction Name
                      <span className="create_form_asterisk">*</span>:
                    </label>

                    <AutoCompleteComponent
                      name="misc_deduction_type_id"
                      control={control}
                      errors={errors}
                      options={deductionType?.map((v) => ({
                        label: v?.type_name,
                        value: v?.misc_deduction_type_id,
                      }))}
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="deduction_amount"
                      className="label"
                    >
                      Deduction Amount
                      <span className="create_form_asterisk">*</span>:
                    </label>
                    <TextFieldComponent
                      name="deduction_amount"
                      id="deduction_amount"
                      onChangeHandler={() => trigger("deduction_amount")}
                      control={control}
                      errors={errors}
                    />
                  </div>

                  <div className="col-md-6">
                    <label
                      htmlFor="deduction_date"
                      className="label"
                    >
                      Deduction Date
                      <span className="create_form_asterisk">*</span>:
                    </label>
                    <DatePickerComponent
                      name="deduction_date"
                      errors={errors}
                      control={control}
                      minDate={startDate}
                      onHandleChange={() => trigger("deduction_date")}
                      inputFormat="DD-MMM-YYYY"
                      disableMaskedInput={true}
                    />
                  </div>

                  <div className="col-md-12 mt-4">
                    <label className="label">
                      Description
                      <span className="create_form_asterisk">*</span>:
                    </label>
                    <TextAreaComponent
                      name="description"
                      id="description"
                      control={control}
                      errors={errors}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions className="payrollModalFooter pb-4 px-4">
                <button
                  className="btn cancelBtnOutline"
                  color="primary"
                  variant="outlined"
                  autoFocus
                  onClick={() => {
                    setCreate(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn primaryBtnOutline"
                  disabled={!isDirty}
                  type="submit"
                >
                  Submit
                </button>
              </DialogActions>
            </form>
          </FormProvider>
        </div>
      </Dialog>
    </div>
  );
}
