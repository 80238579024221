import * as yup from "yup";

const aaplyLeaveSchema = yup.object().shape({
  employee: yup.object().label("Employee Name").required(),
  leave_type: yup.object().label("Leave Type").required(),
  description: yup.string().label("Description").min(5).max(150).required().trim(),
  from_date: yup.string().label("From Date").optional().nullable(true),
  to_date: yup
    .string()
    .test(
      "to-date-required",
      "To Date is required when From Date is not null",
      function (toDate) {
        const fromDate = this.parent.from_date;

        if (fromDate !== null && fromDate !== undefined && fromDate !== "") {
          return !!toDate;
        }

        return true;
      }
    )
    .nullable(true)
    .default(null),
  leave_approver: yup
    .object()
    .label("Leave Approver")
    .required(),
});

export default aaplyLeaveSchema;
