import * as yup from "yup";

const leaveAllocationSchema = yup.object().shape({
  employee: yup.object().label("Employee Name").required(),
  leave_type: yup.object().label("Leave Type").required(),
  status: yup.object().label("Status").optional().nullable(true),
  // from_date: yup.string().label("From Date").optional().nullable(true),
  from_date: yup
    .string()
    .test(
      "from-date-required",
      "From Date is required when Fiscal Year is Not selected",
      function (fiscal_year) {
        const fromDate = this.parent.fiscal_year;
        if (fromDate == null) {
          return !!fiscal_year;
        }

        return true;
      }
    )
    .nullable(true)
    .default(null),
  // to_date: yup.string().label("To Date").optional().nullable(true),
  to_date: yup
    .string()
    .test(
      "to-date-required",
      "To Date is required when From Date is not null",
      function (toDate) {
        const fromDate = this.parent.from_date;

        if (fromDate !== null && fromDate !== undefined && fromDate !== "") {
          return !!toDate;
        }

        return true;
      }
    )
    .nullable(true)
    .default(null),
  fiscal_year: yup.object().label("Fiscal year").optional().nullable(true),
});
export default leaveAllocationSchema;
