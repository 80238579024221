import END_POINTS from "../../constants/endpoints";
import http from "../../utils/http";
import { getRefreshToken, setAccessToken } from "../../utils/token";

const RefreshServices = async () => {
  try {
    const postToken = getRefreshToken();
    if (postToken) {
      const { data } = await http.post(END_POINTS.REFRESH_URL, {
        refreshToken: postToken,
      });
      if (data && data.token && data.token.accessToken) {
        setAccessToken(data.token.accessToken);
      }
      return { status: true, token: data?.token?.accessToken };
    } else {
      throw new Error("Token Expired")
    }
  } catch (error) {
    return { status: false, error: error };
  }
};

export default RefreshServices;
