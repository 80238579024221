import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CONSTANTS from "../../../../constants/constants";
import http from "../../../../utils/http";
import { useDispatch, useSelector } from "react-redux";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "./MiscDeductionType.module.css";
import TextFieldComponent from "../../../MasterComponent/TextFieldComponent";

export const MiscDeductionTypeDialog = ({
  open,
  setOpen,
  defaultValue,
  miscDeductionData,
  fetchMiscDeductionTypeDataStore,
  fetchRecords,
}) => {

    const { reimbursementTypeList } = useSelector((state) => state.masterList);

    const reimbursementTypes = reimbursementTypeList?.data?.reimbursementData;
    
  const methods = useForm({
    defaultValues: defaultValue || {
      type_name: "",
      description: "",
    },
  });
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = methods;
//   const branchApiDataUpdated = miscDeductionData?.map((item) =>
//     item?.toLowerCase()
//   );
  const submitHandler = async (data) => {
    try {
      if (!isEmpty(defaultValue)) {
        handleUpdate(defaultValue, data);
      } else {
        handleAdd(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };
  const handleUpdate = async (defaultValue, data) => {
    try {
      const dataUpdated = {
        branch_id: defaultValue?.branch_id,
        branch_name: data.branch_name,
      };
      if (dataUpdated?.branch_name?.trim() == "") {
        toast.error(CONSTANTS.BRANCH_EMPTY);
        return;
      }
      if (
        dataUpdated.branch_name.toUpperCase() ===
        defaultValue?.branch_name.toUpperCase()
      ) {
        toast.error(CONSTANTS.NO_DATA_TO_UPDATE);
        return;
      }
      if (miscDeductionData?.includes(dataUpdated?.branch_name)) {
        toast.error(CONSTANTS.BRANCH_EXISTS);
        return;
      }
      const updateBranch = await http.patch(
        `master/update-branch`,
        dataUpdated
      );
      dispatch(fetchMiscDeductionTypeDataStore());
      toast.success(updateBranch.data.message);
      fetchRecords();
      setOpen(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleAdd = async (data) => {
    try {
      if (!data?.type_name || data?.type_name?.trim() == "") {
        toast.error('Miscellaneous Deduction Type Cannot be Empty');
        return;
      }

      if (miscDeductionData?.includes(data?.type_name)) {
        toast.error('Miscellaneous Deduction Type Already Exist');
        return;
      }
      const createMiscDeduction = {
          type_name: data?.type_name,
          description: data?.description
        };
      const addedBranch = await http.post(`payroll/misc-deduction-types`, createMiscDeduction);
      dispatch(fetchMiscDeductionTypeDataStore());
      toast.success('Successfully Added Miscellaneous Deduction Type');
      setOpen(false);
    } catch (err) {
      console.log('err:', err)
      setOpen(false);
      toast.error(err?.response?.data?.message);
    }
  };
  return (
    <>
      <Dialog open={open}>
        <DialogTitle className="leaveheading mb-3">
          {!isEmpty(defaultValue) ? "Update Miscellaneous Deduction Type" : "New Miscellaneous Deduction Type"}
        </DialogTitle>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <DialogContent style={{ width: "500px" }}>
              <button onClick={() => setOpen(false)} className="crossButton">
                <FontAwesomeIcon icon={faXmark} />
              </button>
              <div className={styles.div1}>
                <label className="label">Miscellaneous Deduction Type :</label>
                    <TextFieldComponent
                      name="type_name"
                      id="type_name"
                      control={control}
                      errors={errors}
                    />
              </div>

              <div className={styles.div1}>
                <label className="label">Description :</label>
                    <TextFieldComponent
                      name="description"
                      id="description"
                      control={control}
                      errors={errors}
                    />
              </div>
              
            </DialogContent>
            <DialogActions className="px-3">
              <Button
                className="newbutton text-white"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              {defaultValue ? (
                !isDirty ? (
                  <Button
                    className="newbutton text-white"
                    disabled={!isDirty}
                    id="newDisable"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    className="newbutton text-white"
                    disabled={!isDirty}
                    type="submit"
                  >
                    Update
                  </Button>
                )
              ) : !isDirty ? (
                <Button
                  className="newbutton text-white"
                  disabled={!isDirty}
                  id="newDisable"
                >
                  Add
                </Button>
              ) : (
                <Button
                  className="newbutton text-white"
                  disabled={!isDirty}
                  type="submit"
                >
                  Add
                </Button>
              )}
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default MiscDeductionTypeDialog;
