import { InputAdornment, TextField, Tooltip } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import "./MasterComponent.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  errormsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "5px",
    marginBottom: "15px",
  },
  root: {
    paddingRight: "1px",
  },
  popper: {
    backgroundColor: "#DD0000",
    borderColor: "#DD0000",
  },
  popperArrow: {
    color: "#DD0000",
  },
  inputErrorBorder: {
    border: "1px solid #DD0000 !important",
  },
}));

const TextFieldComponent = ({
  name,
  control,
  errors,
  type,
  disabled,
  InputProps,
  min,
  onChangeHandler,
  id,
  isTooltipError = false,
  required = true,
  placeholder = "",
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <TextField
            id={id}
            type={type}
            min={min}
            value={value !== undefined ? value : type === "number" ? 0 : ""}
            placeholder={placeholder}
            disabled={disabled}
            InputProps={{
              ...InputProps,
              classes: {
                root: classes.root,
                notchedOutline:
                  isTooltipError && getErrorMessage(name)
                    ? classes.inputErrorBorder
                    : "",
              },
              endAdornment: !!isTooltipError && getErrorMessage(name) && (
                <InputAdornment position="start">
                  <Tooltip
                    arrow
                    title={getErrorMessage(name)}
                    classes={{
                      tooltip: classes.popper,
                      arrow: classes.popperArrow,
                    }}
                  >
                    <InfoOutlinedIcon sx={{ fontSize: 18, color: "#DD0000" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={(e, value) => {
              onChange(e);
              onChangeHandler && onChangeHandler(e, value);
            }}
            className="form-control"
          />
        )}
      />
      {!isTooltipError &&
        (error ? <p className={classes?.errormsg}>{error}</p> : null)}
    </>
  );
};

export default TextFieldComponent;
