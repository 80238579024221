import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  removeAccessData,
  removeAccessToken,
  removeFilterData,
  removeLastRoute,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../../utils/token";

export const userLogin = createAsyncThunk(
  "verify-otp",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await http.post(END_POINTS.LOGIN_URL, postData);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const oauthLogin = createAsyncThunk(
  "google/login",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await http.post(END_POINTS.GOOGLE_LOGIN_URL, postData);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  success: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogout: (state) => {
      state.success = false;
      state.user = null;
      state.error = null;
      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
      removeAccessToken();
      removeRefreshToken();
      removeAccessData();
      removeLastRoute();
      removeFilterData();
      // return initialState;
    },
    setUser: (state, { payload }) => {
      state.user = payload.user;
    },
    setUserAuth: (state, { payload }) => {
      state.isAuthenticated = payload?.isAuthenticated;
      state.accessToken = payload?.token?.accessToken;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.accessToken = payload.token.accessToken;
        state.refreshToken = payload.token.refreshToken;
        state.error = null;
        state.isAuthenticated = true;
        setAccessToken(payload.token.accessToken);
        setRefreshToken(payload.token.refreshToken);
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.user = null;
        state.success = false;
        state.isAuthenticated = false;
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addCase(oauthLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(oauthLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.accessToken = payload.token.accessToken;
        state.refreshToken = payload.token.refreshToken;
        state.error = null;
        state.isAuthenticated = true;
        setAccessToken(payload.token.accessToken);
        setRefreshToken(payload.token.refreshToken);
      })
      .addCase(oauthLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.user = null;
        state.success = false;
        state.isAuthenticated = false;
        state.accessToken = null;
        state.refreshToken = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const { userLogout, setUser, setUserAuth } = authSlice.actions;

export default authSlice.reducer;
