import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";

export default function DownloadReport({
  handleExcelDownload,
  loading = false,
  heading="Report",
}) {
  return (
    <button
      className="btn primaryBtnOutline"
      onClick={handleExcelDownload}
      disabled={loading}
    >
      <FontAwesomeIcon icon={faDownload} className="me-2" />
      {heading}
      {loading && <CircularProgress className="downloadReportLoader" />}
    </button>
  );
}
