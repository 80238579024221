import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./store";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./contexts/AuthProvider";
import './index.css';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <ToastContainer
      style={{ toastifyToastWidth: 320 }}
      pauseOnHover={true}
      position="top-center"
      autoClose={2000}
      hideProgressBar={false}
      closeOnClick={true}
      draggable={true}
    />
    
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.Fragment>
);
