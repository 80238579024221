import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TextFieldComponent from "../../../MasterComponent/TextFieldComponent";
import SelectComponent from "../../../MasterComponent/SelectComponent";
import AutoCompleteComponent from "../../../MasterComponent/AutoCompleteComponent";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeaveTypes } from "../../../../store/slices/leaveConfigSlice";
const LeaveRestrictions = ({ setIsUpdateOpen, updateConfigData }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLeaveTypes());
  }, [dispatch]);
  let leaveTypesList = useSelector(
    (state) => state?.leaveConfig?.getAllLeaveTypesList?.response?.leaveTypeData
  );
  leaveTypesList = leaveTypesList?.map((data) => {
    return { label: data?.leaveTypeLabel, value: data?.id };
  });
  const methods = useForm({
    defaultValues: {
      leaveRestrictions: {
        canNewJoineeApplyLeave: "probation",
        leaveRestrictionsDaysDuringProbation: 0,
        leaveRestrictionsDaysDuringJoining: 0,
        wantToRestrictEmployeeFromAppyingLeave: false,
        applyingLeaveRestrictionsDays: 0,
        canApplyOnConsecutiveDays: "no",
        maxConsecutiveDaysExcludingWeekendsAndHolidays: 0,
        wantToRestrictLeaveApplication: "no",
        maxDaysRestriction: 0,
        canNoticePeriodEmployeeApplyLeave: "no",
        noticePeriodExtendByTimes: 0,
        canManagerOverrideRestrictions: "yes",
        isMinimumGapEnforced: false,
        minimumEnforcedDays: 0,
        isLeaveRequestCountRestrictedInCalendarYear: false,
        leaveRequestCountInCalendarYear: 0,
        isLeaveRequestCountRestrictedInCalendarMonth: false,
        leaveRequestCountInCalendarMonth: 0,
        isLeaveRequestCountRestrictedInTenure: false,
        leaveRequestCountInTenure: 0,
        isMinimumDaysApplicationLeaveRestricted: false,
        minimumDaysRequiredToAvailLeave: 0,
        leaveBalanceRequiredToAvailLeave: 0,
        isRestrictedToApplyLeaveAfterDayOfMonth: false,
        leaveRestrictedDayOfMonth: null,
        isLeaveCanNotBeClubbed: false,
        leaveCanNotBeClubbedWith: null,
        isLeaveCanNotBeApplied: false,
        leaveCanNotBeAppliedDueToBalanceIn: [],
      },
    },
  });
  const submitHandler = (data) => {
    console.log("data:", data);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = methods;
  const getDays = [];
  for (let i = 1; i <= 28; i++) {
    getDays?.push(i);
  }
  getDays?.push("Last Day");
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="leaveQuotaForm">
            <div className="mt-4">
              <div className="font-semibold">
                A new joinee employee can apply for a leave
              </div>
              <div className="flex mt-1">
                <Controller
                  name="leaveRestrictions.canNewJoineeApplyLeave"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "probation") {
                            setValue(
                              "leaveRestrictions.wantToRestrictEmployeeFromAppyingLeave",
                              false
                            );
                            setValue(
                              "leaveRestrictions.applyingLeaveRestrictionsDays",
                              0
                            );
                            setValue(
                              "leaveRestrictions.leaveRestrictionsDaysDuringJoining",
                              0
                            );
                          }
                        }}
                      >
                        <div className="flex items-center">
                          <FormControlLabel
                            value="probation"
                            control={<Radio />}
                          />
                          <div className="flex gap-2 items-center">
                            <TextFieldComponent
                              name={`leaveRestrictions.leaveRestrictionsDaysDuringProbation`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                watch(
                                  "leaveRestrictions.canNewJoineeApplyLeave"
                                ) === "joining"
                              }
                            />
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              day after Probation ends
                            </label>
                          </div>
                        </div>
                        <div className="flex">
                          <FormControlLabel
                            value="joining"
                            control={<Radio />}
                          />
                          <div className="flex gap-2 items-center">
                            <TextFieldComponent
                              name={`leaveRestrictions.leaveRestrictionsDaysDuringJoining`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                watch(
                                  "leaveRestrictions.canNewJoineeApplyLeave"
                                ) === "probation"
                              }
                            />
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              days after joining
                            </label>
                          </div>
                        </div>
                        {watch("leaveRestrictions.canNewJoineeApplyLeave") ===
                          "joining" && (
                          <div className="flex gap-2 items-center mt-1">
                            <Controller
                              name="leaveRestrictions.wantToRestrictEmployeeFromAppyingLeave"
                              control={control}
                              render={({
                                field: { onChange, name, value },
                              }) => (
                                <Checkbox
                                  color="primary"
                                  name={name}
                                  disabled={
                                    watch(
                                      "leaveRestrictions.canNewJoineeApplyLeave"
                                    ) === "probation"
                                  }
                                  onChange={(e, val) => {
                                    onChange(val);
                                    setValue(
                                      "leaveRestrictions.applyingLeaveRestrictionsDays",
                                      0
                                    );
                                  }}
                                  checked={Boolean(value) || false}
                                />
                              )}
                            />
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              Do not allow employees to avail/apply for more
                              than
                            </label>
                            <div className="w-20">
                              <TextFieldComponent
                                name={`leaveRestrictions.applyingLeaveRestrictionsDays`}
                                type="number"
                                control={control}
                                InputProps={{
                                  inputProps: { min: 0, max: 365 },
                                }}
                                errors={errors}
                                disabled={
                                  String(
                                    watch(
                                      "leaveRestrictions.wantToRestrictEmployeeFromAppyingLeave"
                                    )
                                  ) === "false"
                                }
                              />
                            </div>
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              days of leave during probation period
                              (irrespective of accural/credit).
                            </label>
                          </div>
                        )}
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Is there a limit on the number of consecutive days this leave
                can be taken?
              </div>
              <div className="flex">
                <Controller
                  name="leaveRestrictions.canApplyOnConsecutiveDays"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "no") {
                            setValue(
                              "leaveRestrictions.maxConsecutiveDaysExcludingWeekendsAndHolidays",
                              0
                            );
                          }
                        }}
                      >
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No limit. All the available balance can be used at a time."
                        />
                        <div className="flex items-center">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes, Limit to a maximum of"
                          />
                          <div className="w-20">
                            <TextFieldComponent
                              name={`leaveRestrictions.maxConsecutiveDaysExcludingWeekendsAndHolidays`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                String(
                                  watch(
                                    "leaveRestrictions.canApplyOnConsecutiveDays"
                                  )
                                ) === "no"
                              }
                            />
                          </div>
                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900 ml-3">
                            consecutive days of leave (excluding weekends &
                            holidays)
                          </label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Is there a limit on maximium number of leave balance that can be
                availed in a month?
              </div>
              <div className="flex">
                <Controller
                  name="leaveRestrictions.wantToRestrictLeaveApplication"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "no") {
                            setValue("leaveRestrictions.maxDaysRestriction", 0);
                          }
                        }}
                      >
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No Employee can avail all available balance in a month."
                        />
                        <div className="flex items-center">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes, Employee is resticted to avail maximum"
                          />
                          <div className="w-20">
                            <TextFieldComponent
                              name={`leaveRestrictions.maxDaysRestriction`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                String(
                                  watch(
                                    "leaveRestrictions.wantToRestrictLeaveApplication"
                                  )
                                ) === "no"
                              }
                            />
                          </div>
                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900 ml-3">
                            days of leave balance in a month
                          </label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Can an employee in notice period apply for this leave?
              </div>
              <div className="flex">
                <Controller
                  name="leaveRestrictions.canNoticePeriodEmployeeApplyLeave"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "no") {
                            setValue(
                              "leaveRestrictions.noticePeriodExtendByTimes",
                              0
                            );
                          }
                        }}
                      >
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No Employee in notice period cannot apply for this leave."
                        />
                        <div className="flex items-center">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes, Employee in notice period can consume available leave, given that the notice period extends by"
                          />
                          <div className="w-20">
                            <TextFieldComponent
                              name={`leaveRestrictions.noticePeriodExtendByTimes`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 100 },
                              }}
                              errors={errors}
                              disabled={
                                String(
                                  watch(
                                    "leaveRestrictions.canNoticePeriodEmployeeApplyLeave"
                                  )
                                ) === "no"
                              }
                            />
                          </div>
                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900 ml-3">
                            times the actual leave days
                          </label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Can manager override leave restrictions and apply leave?
              </div>
              <div className="flex">
                <Controller
                  name=" leaveRestrictions.canManagerOverrideRestrictions"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                        }}
                      >
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, Managers cannot apply by overriding restrictions."
                        />
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, Managers can apply by overriding restrictions."
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Check all restrictions you'd like to apply on the number of
                leave instances (requests)
              </div>
              <div className="flex">
                <div className="flex gap-2 items-center mt-1">
                  <Controller
                    name="leaveRestrictions.isMinimumGapEnforced"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue("leaveRestrictions.minimumEnforcedDays", 0);
                        }}
                        checked={Boolean(value) || false}
                      />
                    )}
                  />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    Enforce a minimum gap of
                  </label>
                  <div className="w-20">
                    <TextFieldComponent
                      name={`leaveRestrictions.minimumEnforcedDays`}
                      type="number"
                      control={control}
                      InputProps={{
                        inputProps: { min: 0, max: 365 },
                      }}
                      errors={errors}
                      disabled={
                        String(
                          watch("leaveRestrictions.isMinimumGapEnforced")
                        ) === "false"
                      }
                    />
                  </div>
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    calendar days between two leave requests.
                  </label>
                </div>
              </div>
              <div className="flex">
                <div className="flex gap-2 items-center mt-1">
                  <Controller
                    name="leaveRestrictions.isLeaveRequestCountRestrictedInCalendarYear"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue(
                            "leaveRestrictions.leaveRequestCountInCalendarYear",
                            0
                          );
                        }}
                        checked={Boolean(value) || false}
                      />
                    )}
                  />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    Do not allow more than
                  </label>
                  <div className="w-20">
                    <TextFieldComponent
                      name={`leaveRestrictions.leaveRequestCountInCalendarYear`}
                      type="number"
                      control={control}
                      InputProps={{
                        inputProps: { min: 0, max: 365 },
                      }}
                      errors={errors}
                      disabled={
                        String(
                          watch(
                            "leaveRestrictions.isLeaveRequestCountRestrictedInCalendarYear"
                          )
                        ) === "false"
                      }
                    />
                  </div>
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    instances (leave requests) in a leave calendar year.
                  </label>
                </div>
              </div>
              <div className="flex">
                <div className="flex gap-2 items-center mt-1">
                  <Controller
                    name="leaveRestrictions.isLeaveRequestCountRestrictedInCalendarMonth"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue(
                            "leaveRestrictions.leaveRequestCountInCalendarMonth",
                            0
                          );
                        }}
                        checked={Boolean(value) || false}
                      />
                    )}
                  />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    Do not allow more than
                  </label>
                  <div className="w-20">
                    <TextFieldComponent
                      name={`leaveRestrictions.leaveRequestCountInCalendarMonth`}
                      type="number"
                      control={control}
                      InputProps={{
                        inputProps: { min: 0, max: 365 },
                      }}
                      errors={errors}
                      disabled={
                        String(
                          watch(
                            "leaveRestrictions.isLeaveRequestCountRestrictedInCalendarMonth"
                          )
                        ) === "false"
                      }
                    />
                  </div>
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    instances (leave requests) in a leave calendar month.
                  </label>
                </div>
              </div>
              <div className="flex">
                <div className="flex gap-2 items-center mt-1">
                  <Controller
                    name="leaveRestrictions.isLeaveRequestCountRestrictedInTenure"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue(
                            "leaveRestrictions.leaveRequestCountInTenure",
                            0
                          );
                        }}
                        checked={Boolean(value) || false}
                      />
                    )}
                  />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    Do not allow more than
                  </label>
                  <div className="w-20">
                    <TextFieldComponent
                      name={`leaveRestrictions.leaveRequestCountInTenure`}
                      type="number"
                      control={control}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      errors={errors}
                      disabled={
                        String(
                          watch(
                            "leaveRestrictions.isLeaveRequestCountRestrictedInTenure"
                          )
                        ) === "false"
                      }
                    />
                  </div>
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    instances (leave requests) in entire tenure.
                  </label>
                </div>
              </div>
              <div className="flex">
                <div className="flex gap-2 items-center mt-1">
                  <Controller
                    name="leaveRestrictions.isMinimumDaysApplicationLeaveRestricted"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue(
                            "leaveRestrictions.minimumDaysRequiredToAvailLeave",
                            0
                          );
                          setValue(
                            "leaveRestrictions.leaveBalanceRequiredToAvailLeave",
                            0
                          );
                        }}
                        checked={Boolean(value) || false}
                      />
                    )}
                  />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    Do not allow availing less than
                  </label>
                  <div className="w-20">
                    <TextFieldComponent
                      name={`leaveRestrictions.minimumDaysRequiredToAvailLeave`}
                      type="number"
                      control={control}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      errors={errors}
                      disabled={
                        String(
                          watch(
                            "leaveRestrictions.isMinimumDaysApplicationLeaveRestricted"
                          )
                        ) === "false"
                      }
                    />
                  </div>
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    days of leave, in a single instance, when total available
                    leave balance is greater than or equal to
                  </label>
                  <div className="w-20">
                    <TextFieldComponent
                      name={`leaveRestrictions.leaveBalanceRequiredToAvailLeave`}
                      type="number"
                      control={control}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      errors={errors}
                      disabled={
                        String(
                          watch(
                            "leaveRestrictions.isMinimumDaysApplicationLeaveRestricted"
                          )
                        ) === "false"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex gap-2 items-center mt-1">
                  <Controller
                    name="leaveRestrictions.isRestrictedToApplyLeaveAfterDayOfMonth"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue(
                            "leaveRestrictions.leaveRestrictedDayOfMonth",
                            null
                          );
                        }}
                        checked={Boolean(value) || false}
                      />
                    )}
                  />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    Restrict employees from applying leave from
                  </label>
                  <div className="w-28">
                    <SelectComponent
                      name="leaveRestrictions.leaveRestrictedDayOfMonth"
                      id="leaveRestrictions.leaveRestrictedDayOfMonth"
                      control={control}
                      disabled={
                        !Boolean(
                          watch(
                            "leaveRestrictions.isRestrictedToApplyLeaveAfterDayOfMonth"
                          )
                        )
                      }
                      errors={errors}
                      width="50px"
                      options={
                        getDays &&
                        getDays?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      }
                    />
                  </div>
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    of every month.
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Check all restrictions you'd like to enforce in relation with
                other leave types
              </div>

              <div className="flex">
                <div className="flex gap-2 items-center mt-1">
                  <Controller
                    name="leaveRestrictions.isLeaveCanNotBeClubbed"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue(
                            "leaveRestrictions.leaveCanNotBeClubbedWith",
                            null
                          );
                        }}
                        checked={Boolean(value) || false}
                      />
                    )}
                  />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    This leave cannot be taken along with
                  </label>
                  <div className="w-28">
                    <SelectComponent
                      name="leaveRestrictions.leaveCanNotBeClubbedWith"
                      id="leaveRestrictions.leaveCanNotBeClubbedWith"
                      control={control}
                      errors={errors}
                      disabled={
                        !Boolean(
                          watch("leaveRestrictions.isLeaveCanNotBeClubbed")
                        )
                      }
                      width="50px"
                      options={
                        getDays &&
                        getDays?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row flex">
                <div className="col-md-12 flex gap-2 items-center mt-1">
                    <Controller
                      name="leaveRestrictions.isLeaveCanNotBeApplied"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <Checkbox
                          color="primary"
                          name={name}
                          onChange={(e, val) => {
                            onChange(val);
                            setValue(
                              "leaveRestrictions.leaveCanNotBeAppliedDueToBalanceIn",
                              []
                            );
                          }}
                          checked={Boolean(value) || false}
                        />
                      )}
                    />
                  <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                    This leave is not available when there is balance in
                  </label>
                  <div className="col-md-3 ml-3">
                    <AutoCompleteComponent
                      name="leaveRestrictions.leaveCanNotBeAppliedDueToBalanceIn"
                      control={control}
                      errors={errors}
                      disabled={
                        !Boolean(
                          watch("leaveRestrictions.isLeaveCanNotBeApplied")
                        )
                      }
                      multiple={true}
                      options={leaveTypesList ?? []}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 flex justify-end">
            <button
              onClick={() => {
                reset();
                setIsUpdateOpen(false);
              }}
              type="button"
              className="inline-flex font-sm text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-400 hover:border-gray-400 py-2 px-4"
            >
              Back
            </button>
            <button
              onClick={() => {
                reset();
              }}
              type="button"
              className="inline-flex ml-3 font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex font-sm text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-500 text-white hover:text-gray-50 hover:bg-blue-300 hover:border-blue-300 py-2 px-4"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LeaveRestrictions;
