import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { faCommentLines, faXmark } from '@fortawesome/pro-light-svg-icons';

const LeaveCommentsDialog = ({commentDialog, setCommentDialog, userComment, managementComment}) => {
  return (
    <Dialog
         open={commentDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          className="employee_form_dialog"
        >
            <DialogTitle className="add_employee_main_heading">
                <div className='commentsHeader'>
                    <div>Comments</div>
                    <FontAwesomeIcon icon={faXmark} className='closeIcon' onClick={()=>setCommentDialog(false)} />
                </div>
            </DialogTitle>
            <DialogContent className="card-content-employee-form">
                <div className="leaveComments bg-white ">
                    <div className="empComment p-3 border-1 border-gray-300 rounded-lg">
                        <div className="text-blue-600 font-medium whitespace-nowrap mb-2"> <FontAwesomeIcon icon={faCommentLines} /> Employee Comment</div>
                        <div className='text-sm'>{userComment}</div>
                    </div>
                    {managementComment && <div className="managementComment p-3 border-1 border-gray-300 rounded-lg mt-4">
                        <div className="text-blue-600 font-medium whitespace-nowrap mb-2"> <FontAwesomeIcon icon={faCommentLines} /> Approver's Comment</div>
                        <div className='text-sm'>{managementComment}</div>
                    </div>}
                </div>
            </DialogContent>
            {/* <DialogActions className="employee_form_button_div">
            <button
                type="button"
                onClick={()=>setCommentDialog(false)}
                className="cancel_button"
            >
                Ok
            </button>
            </DialogActions> */}
        </Dialog>
  )
}

export default LeaveCommentsDialog;