import React, { useCallback } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faFilter,
  faCommentLines,
  faXmark,
  faPersonToDoor,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/pro-light-svg-icons";
import "../../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchAttendanceRegularizationDataV1,
  updateRegularizationStatus,
} from "../../../store/slices/leaveSlice";
import Pagination from "@mui/material/Pagination";
import { ClickAwayListener } from "@mui/base";
import Loader from "../../Loader/Loader";
import dayjs from "dayjs";
import RegularizationCommentDialog from "./RegularizationCommentDialog.js";
import RegularizationFilter from "./RegularizationFilter";
import RegularizationReview from "./RegularizationReview";
import ConsentDialog from "./ConsentDialog";
import {
  componentDisplayAccess,
  getEmployeesAccess,
} from "../../../utils/userPermission";
import { useContext } from "react";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import { fetchEmployeeNameList } from "../../../store/slices/employeeSlice.js";

const Regularization = () => {
  const { displayPopup } = useContext(PopupContext);
  const statusFilter =
    localStorage.getItem("regularizationFilter") &&
    JSON?.parse(localStorage.getItem("regularizationFilter"))?.status?.value;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [toggle, setToggle] = useState(false);
  const [commentDialog, setCommentDialog] = useState(false);
  const [comment, setComment] = useState("");
  const [regularizationArray, setRegularizationArray] = useState([]);
  const [currentStatus, setCurrentStatus] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [Status, setStatus] = useState("");
  const [regularizationId, setRegularizationId] = useState([]);
  const [openConsent, setOpenConsent] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(statusFilter ?? null);
  const [filterValues, setFilterValues] = useState(
    localStorage?.getItem("regularizationFilter")
      ? JSON?.parse(localStorage?.getItem("regularizationFilter"))
      : null
  );
  // const [editRegularization, setEditRegularization] = useState(false);
  // const [editRegularizationRow, setEditRegularizationRow] = useState({});
  const [sorting, setSorting] = useState("creation");
  const [order, setOrder] = useState("DESC");
  const regularizationAccess = componentDisplayAccess({
    component: "Regularization_V1",
  });
  useEffect(() => {
    if (localStorage?.getItem("regularizationFilter")) {
      // const { search, status, fromDate, toDate } = JSON.parse(
      //   localStorage?.getItem("regularizationFilter")
      // );
      // if (!search && !status && !fromDate && !toDate) {
      //   localStorage?.setItem(
      //     "regularizationFilter",
      //     JSON?.stringify({
      //       search: null,
      //       status: { label: "Open", value: "Open" },
      //       fromDate: null,
      //       toDate: null,
      //     })
      //   );
      //   const initialData = JSON.parse(
      //     localStorage?.getItem("regularizationFilter")
      //   );
      //   setFilterValues(initialData);
      // }
      const initialData = JSON.parse(
        localStorage?.getItem("regularizationFilter")
      );
      setFilterValues(initialData);
    } else {
      localStorage?.setItem(
        "regularizationFilter",
        JSON?.stringify({
          search: null,
          status: { label: "Open", value: "Open" },
          fromDate: null,
          toDate: null,
        })
      );
      const initialData = JSON.parse(
        localStorage?.getItem("regularizationFilter")
      );
      setFilterValues(initialData);
    }
  }, []);

  const [openReview, setOpenReview] = useState(false);

  const dispatch = useDispatch();

  const getData = useCallback(
    async (params) => {
      await dispatch(
        fetchAttendanceRegularizationDataV1({
          ...params,
        })
      );
      setSelectedStatus(statusFilter ?? null);
    },
    [dispatch, statusFilter]
  );

  useEffect(() => {
    let response = JSON.parse(localStorage.getItem("regularizationFilter"));
    getData({
      fromDate: response?.fromDate,
      toDate: response?.toDate,
      search: response?.search?.value,
      status: response?.status?.value,
      page,
      limit,
      order,
      sort: sorting
    });
  }, [page, limit, getData, filterValues, order, sorting]);

  const data = useSelector(
    (state) => state?.leave?.attendanceRegularizationV1?.regularizationDataV1
  );

  const isFetching = useSelector(
    (state) => state?.leave?.attendanceRegularizationV1?.isFetching
  );
  useEffect(() => {
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
    //getData();
  }, [dispatch]);
  const employeeList = useSelector(
    (state) => state?.employee?.employeeNameList
  );
  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const leaveApplicationAccessById = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Regularization_V1",
  });
  let headers = [
    { label: "Employee ID", name: "EmployeeId" },
    { label: "Employee Name", name: "employee_name" },
    { label: "Reporting Manager", name: "reporting_manager" },
    { label: "Regularization Date", name: "attendance_date" },
    { label: "Regularization Reason", name: "reason" },
    { label: "Regularization Type", name: "attendance_type" },
    { label: "Half Day Info", name: "half_day_info" },
    { label: "Comment", },
    { label: "Work Mode", name: "mode" },
    { label: "Status", name: "status" },
    { label: "Action" },
  ];

  const handleSelect = (e, i, status) => {
    if (e?.target?.checked) {
      setRegularizationArray((prev) => [...prev, i]);
      setCurrentStatus((prev) => [...prev, status]);
      setSelectedStatus(status);
    } else {
      let newArray = regularizationArray?.filter((val) => val !== i);
      currentStatus?.pop(status);
      setRegularizationArray(newArray);
      !newArray?.length && setSelectedStatus(statusFilter ?? null);
    }
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const selectAllData = [];
      data?.data?.forEach((data) => {
        if (
          data.status === selectedStatus &&
          leaveApplicationAccessById?.[data?.EmployeeId]?.updateAccess
        )
          selectAllData.push(data.regularization_id);
      });
      setRegularizationArray(selectAllData);
    } else {
      setRegularizationArray([]);
      setSelectedStatus(statusFilter ?? null);
    }
  };
  const handleClose = () => {
    setOpenConsent(false);
    setStatus();
    setRegularizationId([]);
    setRegularizationArray([]);
  };
  const handleSubmit = async () => {
    try {
      let data = { regularization_id: regularizationArray, status: Status };
      const res = await dispatch(updateRegularizationStatus(data));
      getData({ status: filterValues?.status?.value });
      if (res?.payload?.status)
        displayPopup(res?.payload?.message, "alert-success");
      else displayPopup(res?.payload?.message, "alert-danger");
      setOpenConsent(false);
      setStatus();
      setRegularizationId([]);
      setRegularizationArray([]);
    } catch (error) {
      setOpenConsent(false);
      setStatus();
      setRegularizationId([]);
      setRegularizationArray([]);
    }
  };
  const handleFilterClose = (e, value) => {
    filterValues[value] = null;
    localStorage?.setItem(
      "regularizationFilter",
      JSON?.stringify(filterValues)
    );
    JSON?.parse(localStorage.getItem("regularizationFilter")) &&
      setFilterValues(
        JSON?.parse(localStorage.getItem("regularizationFilter"))
      );
    setCurrentStatus([]);
  };

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <div className="p-3 pb-0 bg-indigo-50">
          <div className="flex items-center justify-end flex-wrap gap-3 pb-3 pt-0">
            {regularizationArray?.length ? (
              <>
                {selectedStatus !== "Approved" ? (
                  <button
                    class="border-solid border-1 border-green-600 rounded-md text-green-700 bg-green-0 hover:text-green-50 hover:bg-green-700 py-1 px-4 "
                    id="3"
                    type="button"
                    onClick={async () => {
                      setStatus("Approved");
                      setOpenConsent(true);
                      setCurrentStatus([]);
                      getData({
                        fromDate: filterValues?.fromDate,
                        toDate: filterValues?.toDate,
                        search: filterValues?.search?.value,
                        status: filterValues?.status?.value,
                        page,
                        limit,
                      });
                    }}
                  >
                    Approve
                  </button>
                ) : null}
                {selectedStatus !== "Rejected" ? (
                  <button
                    class="border-solid border-1 border-red-600 rounded-md text-red-700 bg-red-0 hover:text-red-50 hover:bg-red-700 py-1 px-4 "
                    id="3"
                    type="button"
                    onClick={async () => {
                      // await dispatch(
                      //   updateRegularizationStatus({
                      //     regularization_id: regularizationArray,
                      //     status: "Rejected",
                      //   })
                      // ),
                      setStatus("Rejected");
                      setOpenConsent(true);
                      setCurrentStatus([]);
                    }}
                  >
                    Reject
                  </button>
                ) : null}
              </>
            ) : null}
            {regularizationAccess?.createAccess ? (
              <button
                class="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
                id="3"
                type="button"
                onClick={() => setOpenReview(true)}
              >
                <FontAwesomeIcon icon={faPersonToDoor} className="me-1" />{" "}
                Review Regularization
              </button>
            ) : null}
            <button
              class="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
              id="3"
              type="button"
              onClick={() => setApplyFilter(true)}
            >
              <FontAwesomeIcon icon={faFilter} className="me-1" /> Filter
            </button>
          </div>
          <div className="bg-white px-3 pb-0 flex items-center gap-3 rounded-t-lg border-b border-b-gray-300 shadow-md">
            <div className="flex items-center gap-3 py-3">
              <div className="flex items-center gap-2 font-semibold after:content-['|']  after:text-gray-700">
                {data?.totalCount} <span>Records</span>{" "}
                <span className="border-r border-r-indigo-200"></span>
              </div>
              {filterValues?.search && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.search?.label}
                  <FontAwesomeIcon
                    onClick={(e) => handleFilterClose(e, "search")}
                    className="pl-2 pr-3 cursor-pointer"
                    icon={faXmark}
                  />
                </div>
              )}
              {filterValues?.status && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.status?.label}
                  <FontAwesomeIcon
                    onClick={(e) => handleFilterClose(e, "status")}
                    className="pl-2 pr-3 cursor-pointer"
                    icon={faXmark}
                  />
                </div>
              )}
              {filterValues?.fromDate && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  From : {dayjs(filterValues?.fromDate)?.format("DD-MMM-YYYY")}
                  <FontAwesomeIcon
                    onClick={(e) => handleFilterClose(e, "fromDate")}
                    className="pl-2 pr-3 cursor-pointer"
                    icon={faXmark}
                  />
                </div>
              )}
              {filterValues?.toDate && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  To : {dayjs(filterValues?.toDate)?.format("DD-MMM-YYYY")}
                  <FontAwesomeIcon
                    onClick={(e) => handleFilterClose(e, "toDate")}
                    className="pl-2 pr-3 cursor-pointer"
                    icon={faXmark}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="leaveAppTableContainer relative overflow-x-auto shadow-sm">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  rounded-b-lg">
              <thead className="text-xs bg-white text-gray-700 uppercase ">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        disabled={
                          !selectedStatus || selectedStatus === "Dismissed"
                        }
                        onClick={handleSelectAll}
                        className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label for="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  {headers?.map((i) => {

                    return (
                      <th key={i} scope="col" className="px-6 py-3">
                        <div className="flex">{i.label}
                          {i?.name && <>{sorting === i.name ? <span className="pl-1" onClick={() => { setSorting(i?.name); order === "DESC" ? setOrder("ASC") : setOrder("DESC"); }}>
                            <FontAwesomeIcon icon={order === "DESC" ? faArrowDown : faArrowUp} ></FontAwesomeIcon></span> :
                            <span className="pl-1 opacity-50" onClick={() => { setSorting(i?.name); setOrder("ASC"); }}><FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon></span>
                          }</>}

                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((i) => {
                  return (
                    <tr
                      key={i?.regularization_id}
                      className="bg-white border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="w-4 px-4 py-2">
                        {leaveApplicationAccessById?.[i?.EmployeeId]
                          ?.updateAccess ? (
                          <div className="flex items-center">
                            <input
                              // id="checkbox-table-search-1"
                              type="checkbox"
                              checked={regularizationArray.includes(
                                i?.regularization_id
                              )}
                              onChange={(e) =>
                                handleSelect(e, i?.regularization_id, i?.status)
                              }
                              disabled={
                                (!(i?.status === selectedStatus) &&
                                  !(selectedStatus === undefined) &&
                                  !(selectedStatus === null)) ||
                                i?.status === "Dismissed"
                              }
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <input
                              // id="checkbox-table-search-1"
                              type="checkbox"
                              checked={regularizationArray.includes(
                                i?.regularization_id
                              )}
                              disabled={true}
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        )}
                      </td>
                      {/* <td
                        scope="row"
                        className="flex items-center px-3 py- text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <ListItemAvatar className="img-fluid">
                          {i?.profileImage ? (
                            <Avatar
                              className="img-fluid allEmpProfileImg w-10 h-10 rounded-full object-cover"
                              src={i?.profileImage}
                            />
                          ) : (
                            <Avatar
                              className="img-fluid empCardAvatar w-10 h-10 rounded-full object-cover"
                              {...stringAvatar(
                                i?.employeeReqularization?.firstName
                              )}
                            />
                          )}
                        </ListItemAvatar>
                        <div className="pl-3">
                          <div className="text-base font-semibold">
                            {`${
                              i?.employeeReqularization?.firstName
                                ? i?.employeeReqularization?.firstName
                                : ""
                            } ${
                              i?.employeeReqularization?.middleName
                                ? i?.employeeReqularization?.middleName
                                : ""
                            } ${
                              i?.employeeReqularization?.lastName
                                ? i?.employeeReqularization?.lastName
                                : ""
                            }`}
                          </div>
                          <div className="font-normal text-gray-500">
                            #{i?.EmployeeId}
                          </div>
                        </div>
                      </td> */}
                      <td className="px-3 py-2">#{i?.EmployeeId}</td>
                      <td className="px-3 py-2">
                        {`${i?.employeeReqularization?.firstName
                          ? i?.employeeReqularization?.firstName
                          : ""
                          } ${i?.employeeReqularization?.middleName
                            ? i?.employeeReqularization?.middleName
                            : ""
                          } ${i?.employeeReqularization?.lastName
                            ? i?.employeeReqularization?.lastName
                            : ""
                          }`}
                      </td>
                      <td>
                        <div className="pl-3">
                          <div className="">
                            {`${i?.employeeReqularization?.manager?.firstName
                              ? i?.employeeReqularization?.manager?.firstName
                              : ""
                              } ${i?.employeeReqularization?.manager?.middleName
                                ? i?.employeeReqularization?.manager?.middleName
                                : ""
                              } ${i?.employeeReqularization?.manager?.lastName
                                ? i?.employeeReqularization?.manager?.lastName
                                : ""
                              }`}
                          </div>
                          <div className="font-normal text-gray-500">
                            #{i?.employeeReqularization?.manager?.EmployeeId}
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        {dayjs(i?.attendance_date)?.format("DD-MMM-YYYY")}
                      </td>
                      <td className="px-3 py-2">
                        {i?.regularizationReason?.reason}
                      </td>
                      <td className="px-3 py-2">{i?.attendance_type}</td>
                      <td className="px-3 py-2">{i?.half_day_info}</td>
                      <td className="px-3 py-2">
                        <div className="empLeaveComments relative inline-block">
                          <div className="tooltipWrapper relative">
                            <FontAwesomeIcon
                              onClick={() => {
                                setCommentDialog(true);
                                setComment(i?.comment);
                              }}
                              className="text-blue-600 font-semibold cursor-pointer"
                              icon={faCommentLines}
                            />
                            <div className="tooltipHover comment hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold whitespace-nowrap">
                              View Comments
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-2">{i?.mode}</td>
                      <td className="px-3 py-2">
                        <div
                          className={`flex items-center leaveTableStatus ${i.status} rounded-sm px-2`}
                        >
                          <div
                            className={`h-2.5 w-2.5 rounded-full mr-2 ${i.status}`}
                          ></div>
                          {i.status}
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex items-center gap-3">
                          {leaveApplicationAccessById?.[i?.EmployeeId]
                            ?.updateAccess ? (
                            <div
                              className={
                                i?.status === "Approved"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    i?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="cursor-pointer text-green-500"
                                onClick={() => {
                                  setRegularizationArray([
                                    i?.regularization_id,
                                  ]);
                                  setStatus("Approved");
                                  setOpenConsent(true);
                                }}
                              />
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                i?.status === "Approved"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    i?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="cursor-pointer text-green-500"
                              />
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          )}
                          {leaveApplicationAccessById?.[i?.EmployeeId]
                            ?.updateAccess ? (
                            <div
                              className={
                                i?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    i?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                className="cursor-pointer text-red-700"
                                onClick={() => {
                                  setRegularizationArray([
                                    i?.regularization_id,
                                  ]);
                                  setStatus("Rejected");
                                  setOpenConsent(true);
                                }}
                              />
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                i?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    i?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                className="cursor-pointer text-red-700"
                              />
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          )}
                          {/* {leaveApplicationAccessById?.[i?.EmployeeId]
                            ?.updateAccess  && i?.status === "Approved" ? (
                            <div
                              className={
                                i?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    i?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPen}
                                className="cursor-pointer text-blue-600"
                                onClick={() => {
                                  // setRegularizationArray([
                                  //   i?.regularization_id,
                                  // ]);
                                  // setStatus("Edit");
                                  setEditRegularization(true);
                                  setEditRegularizationRow(data?.data?.filter(v => v?.regularization_id === i?.regularization_id)[0])
                                }}
                              />
                              <div className="tooltipHover edit hidden border-1 border-solid border-blue-600 bg-blue-50 text-blue-600 py-1 px-3 rounded-md text-xs font-semibold">
                                Edit
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                i?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    i?.status === "Dismissed"
                                    ? "tooltipWrapper relative statusDismissed"
                                    : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <FontAwesomeIcon
                                icon={faPen}
                                className="cursor-pointer text-blue-600"
                              />
                              <div className="tooltipHover edit hidden border-1 border-solid border-blue-600 bg-blue-50 text-blue-600 py-1 px-3 rounded-md text-xs font-semibold">
                                Edit
                              </div>
                            </div>
                          )} */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="w-100 flex justify-end py-3">
            <Pagination
              count={Math.ceil(data?.totalCount / limit)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPage(value)}
            />
            <div className="flex items-center ml-3">
              <div className="totalNoOfPages ms-3">
                On Page:{" "}
                <span className="font-semibold">
                  {data?.totalCount ? page : 0} of{" "}
                  {Math.ceil(data?.totalCount / limit)}
                </span>
              </div>
              <label className="PaginationLabelWhere">|</label>
              <div className="totalNoOfPages">
                Total Count:{" "}
                <span className="font-semibold">{data?.totalCount}</span>
              </div>
              <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div
                  className="paginationLimitBox"
                  onClick={() => setToggle(!toggle)}
                >
                  {limit}
                  {toggle ? (
                    <FontAwesomeIcon icon={faChevronUp} className="ms-2" />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  )}
                  {toggle && (
                    <ul className="paginationLimitBoxDropdown">
                      {[25, 50, 75, 100]?.map((list) => {
                        return (
                          <li
                            value={list}
                            onClick={(e) => {
                              setLimit(e?.target?.value);
                              setPage(1);
                            }}
                          >
                            {list}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
          {commentDialog && (
            <RegularizationCommentDialog
              commentDialog={commentDialog}
              setCommentDialog={setCommentDialog}
              comment={comment}
            />
          )}
          {applyFilter && (
            <RegularizationFilter
              setApplyFilter={setApplyFilter}
              filterValues={filterValues}
              setPage={setPage}
              setFilterValues={setFilterValues}
            />
          )}
          {openReview && <RegularizationReview setOpenReview={setOpenReview} />}
          {openConsent && (
            <ConsentDialog
              openConsent={openConsent}
              setOpenConsent={setOpenConsent}
              Status={Status}
              setStatus={setStatus}
              regularizationId={regularizationId}
              setRegularizationId={setRegularizationId}
              getData={getData}
              filterValues={filterValues}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
            />
          )}

          {/* {editRegularization && (
            <EditRegularization
              setEditRegularization={setEditRegularization}
              editRegularization={editRegularization}
              editRegularizationRow={editRegularizationRow}
              setEditRegularizationRow={setEditRegularizationRow}
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default Regularization;
