import React from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  errormsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "5px",
    marginBottom: "15px",
  },
}));

const TextAreaComponent = ({
  name,
  control,
  errors,
  placeholder = "",
  rows = 5,
  handleChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, name, value } }) => (
          <textarea
            value={value || ""}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => {
              onChange(e);
              handleChange && handleChange(value);
            }}
            rows={rows}
            className="form-control"
          />
        )}
      />
      {error ? <p className={classes.errormsg}>{error}</p> : null}
    </>
  );
};

export default TextAreaComponent;
