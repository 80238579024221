import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  errorMsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "-10px",
    marginBottom: "15px",
  },
}));
const PayrollAutoSelectComponent = ({
  name,
  defaultValue,
  options,
  control,
  errors,
  onChangeHandler,
  disableClearable = false,
  disabled = false,
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, name, value } }) => {
          return (
            <Autocomplete
              popupIcon={<KeyboardArrowDownIcon />}
              disabled={disabled}
              options={options}
              onChange={(e, value) => {
                onChange(value);
                onChangeHandler && onChangeHandler(value);
              }}
              disableClearable={disableClearable}
              getOptionLabel={(option) => option?.label || ""}
              value={value || defaultValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  className="payrollSummaryDate"
                  disabled={true}
                />
              )}
            />
          );
        }}
      />

      <p className={classes.errorMsg}>{getErrorMessage(name)}</p>
    </>
  );
};

export default PayrollAutoSelectComponent;
