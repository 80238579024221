const END_POINTS = Object.freeze({
  LOGIN_URL: "/auth/verify-otp",
  GOOGLE_LOGIN_URL: "/auth/google-login",
  REFRESH_URL: "/auth/refresh",
  COMPONENT_ACCESS_URL: "/master/component-access",
  EMPLOYEE_DATA_URL: "/employee/all",
  CREATE_AADHAAR_DATA_URL: "aadhaar/create-aadhaar",
  LEAVE_PLAN_DATA_URL: "config/leave-plan-list",
  ALLOCATION_CONFIG_ROLES: "leave-allocation-config/get-employee-role-list",
  ADD_LEAVE_PLAN_DATA_URL: "config/add-leave-plan",
  UPDATE_LEAVE_PLAN_DATA_URL: "config/update-leave-plan",
  MAP_LEAVE_PLAN: "config/map-leave-plan",
  ADD_LEAVE_TYPE: "leave-type/add-leave-type",
  ADD_LEAVE_ALLOCATION_CONFIG: "leave-allocation-config/create",
  ADD_OT_CONFIG: "ot/create-ot",
  GET_OT_CONFIG: "ot/get-list",
  UPDATE_OT_CONFIG: "ot/update-ot-config",
  UPDATE_LEAVE_ALLOCATION_CONFIG: "leave-allocation-config/update",
  GET_LEAVE_TYPE_WITH_PAGINATION: "leave-type/leave-type-list-with-pagination",
  GET_LEAVE_TYPE_WITH_OUT_PAGINATION: "leave-type/leave-type-list-without-pagination",
  LEAVE_ALLOCATION_LIST_CONFIG: "leave-allocation-config/get-list",
  GET_MAPPED_PLANS: "config/get-mapped-leave-plan",
  GET_MAPPED_CONFIGS: "config/get-mapped-config-data",
  UPDATE_LEAVE_TYPE: "leave-type/update-leave-type",
  CREATE_PAN_DATA_URL: "pan_card/create-pan",
  CREATE_PASSPORT_DATA_URL: "passport/create-passport",
  CREATE_PERSONAL_DATA_URL: "personal_info/create_personal",
  CREATE_PERSONAL_CONTACT_DATA_URL: "personal_info/emergency_contact/create",
  FETCH_PAN_DATA_URL: "pan_card",
  FETCH_PASSPORT_DATA_URL: "passport",
  FETCH_PERSONAL_DATA_URL: "personal_info",
  FETCH_EMPLOYEE_DATA_URL: "profile/employeeList",
  FETCH_AADHAAR_DATA_URL: "aadhaar",
  FETCH_SEPARATION_DATA_URL: "resignation/employee",
  Fetch_REVOKE_REQUEST_URL: "resignation/request-revoke",
  FETCH_RESIGNATION_DATA_URL: "resignation/approval_data",
  FETCH_SEPARATION_REASON_DATA_URL: "resignation/resignationList",
  FETCH_SEPARATION_REVOKE_APPROVAL: "resignation/revoke_approval",
  CREATE_SEPARATION_DATA_URL: "resignation/approval_status",
  CREATE_REVOKE_REQUEST_URL: "resignation/allow-revoke",
  FETCH_EMERGENCY_CONTACT_DATA_URL: "personal_info/address",
  CREATE_EMERGENCY_CONTACT_DATA_URL: "personal_info/post/address",
  FETCH_STATE_LIST_DATA_URL: "personal_info/list/states",
  FETCH_CITY_LIST_DATA_URL: "personal_info/list/cities",
  FETCH_BASIC_PROFILE_DATA_URL: "profile",
  PROFILE_COMPLETED: "profile/completion",
  FETCH_BASIC_PROFILE_IMAGE_DATA_URL: "profile/image",
  UPDATE_BASIC_PROFILE_DATA_URL: "profile/basic-info",
  UPDATE_LEAVE_APPROVER_DATA_URL: "profile/update_leave_approver",
  UPDATE_EMPLOYMENT_DETAILS_DATA_URL: "profile/employment-info",
  UPDATE_IMAGE_DATA_URL: "profile/change-profile-image",
  UPDATE_RESUME_DATA_URL: "profile/upload-resume",
  UPDATE_JOB_PROFILE_DATA_URL: "profile/job-profile",
  FETCH_LEAVE_NOTICE_DATA_URL: "profile/notice-period",
  UPDATE_BIO_DATA_URL: "profile/bio",
  FETCH_COMPANY_LIST_DATA_URL: "profile/basic/company-list",
  FETCH_APPROVER_BY_ID_URL: "leave/approver-list",
  FETCH_EVENT_LIST: "profile/employee/event-list",
  CREATE_SEPARATION_REASON_DATA_URL: "resignation/create_resignation",
  CREATE_INITATION_SEPARATION_REASON_DATA_URL: "resignation/initiate-exit",
  DESIGNATION_DATA_URL: "master/designation",
  SKILL_DATA_URL: "master/skill",
  EMPLOYMENT_DATA_URL: "master/employment-type",
  EMPLOYMENT_TYPE_DATA_URL: "master/employmentType",
  HOLIDAY_TYPE_DATA_URL: "master/get-holidayList",
  COMPANY_TYPE_DATA_URL: "master/company-list",
  HOLIDAY_LIST_URL: "master/holiday",
  DEPARTMENT_DATA_URL: "master/department",
  DEPARTMENT_TYPE_DATA_URL: "master/departmentType",
  DESIGNATION_TYPE_DATA_URL: "master/designationType",
  BRANCH_TYPE_DATA_URL: "master/branchType",
  BRANCH_DATA_URL: "master/branch",
  CLIENT_LIST_URL: "master/clientList",
  MEAL_LIST_URL: "master/today-meal",
  FISCAL_YEAR_MONTHS: "/payroll/payroll-months",
  PAYROLL_SUMMARY: "/payroll/payroll-summary",
  ATTENDANCE_SUMMARY: "/payroll/attendance-summary",
  ACTIONS_SUMMARY: "/payroll/actions-summary",
  STRUCTURES_SUMMARY: "/payroll/structure-stats",
  ARREARS_LEAVE_ENCASHMENT: "/payroll/arrears-leave-encashment",
  DECLARATION_SECTION_URL: "declaration/declaration-section",
  DECLARATION_TYPE_URL: "declaration/declaration-type",
  LEAVE_ALLOCATION: "leave/create-allocation",
  MEAL_FEEDBACK: "meal/meal-feedback",
  POST_TEMPORARY_ACCESS_CARD: "employee/temporary-card-allotment",
  RECENT_LEAVES: "/leave/recent-leaves",
  CURRENT_DAY_ATTENDANCE_LOG: "/employee/employee-today-attendance",
  LEAVE_ALLOTMENT_DAYS: "/leave/leave-allotment-days",
  ON_LEAVE_TODAY: "/leave/on-leave-today",
  ON_LEAVE_TODAY_V1: "v2/leave/report/onLeaveToday",
  GET_RECEIVER_GROUPS: "/poll/receiver-groups",
  ACTIVE_POLLS: "/poll/active-polls",
  NEW_JOINEES: "employee/newJoineeList",
  WORKING_REMOTELY: "employee/remoteEmployees",
  ALL_POST: "employee/allPost",
  EMPLOYEE_NAME_LIST: "employee/id-list",
  EMPLOYEE_MANAGEMENT_NAME_LIST: "resignation/management-data",
  EMPLOYEE_CURRENT_WORK_STATUS: "employee/current-work-status",
  EMPLOYEE_PERMANENT_WORK_STATUS: "employee-workMode",
  ATTENDANCE_HISTORY: "calendar/monthly-data",
  APPLIED_LEAVE: "leave/applied-days",
  APPLY_LEAVE: "leave/apply",
  LEAVE_APPROVE: "leave/approver",
  EMPLOYEE_USER_IDS: "employee/empUserNames",
  EMPLOYEE_CARD: "profile/employee-card",
  LEAVE_TYPE: "leave/leavetype",
  LEAVE_ALLOCATION_LIST: "leave/allocation-list",
  LEAVE_ALLOCATION_REQUEST: "v2/leave/allocation/get-AllocationRequest",
  LEAVE_ALLOCATION_BY_ID: "leave/leaveAllocation",
  LEAVE_BALANCE_BY_ID: "leave/leaveBalance",
  UPDATE_ALLOCATION_STATUS: "leave/update-allocation",
  UPDATE_LEAVE_STATUS: "leave/dismiss-leave",
  POST_LEAVE_APPLICATION: "leave/apply",
  AVAILABLE_LEAVE: "leave/available",
  MASTER_EARNING_TYPE: "salary/earningType",
  CALENDAR_DATA: "calendar/monthly-data",
  CONFIG_DATA: "master/config",
  LUNCH_REQUEST: "lunch/getRequests",
  WEEK_OFFS: "shift/shiftDetails",
  Att_REGULARIZATION_REASONS: "master/attRegularizationReasons",
  REQUEST_REGULARIZATION: "calendar/attendance-regularization",
  CREATE_EARNING: "salary/createEarningType",
  UPDATE_EARNING: "salary/earningType",
  MASTER_DEDUCTION_TYPE: "salary/deductionType",
  CREATE_DEDUCTION: "salary/createDeductionType",
  UPDATE_DEDUCTION: "salary/deductionType",
  MASTER_BRACKETS: "salary/bracket-list",
  UPDATE_BRACKET: "salary/update-bracket",
  SALARY_BRACKETS: "salary/bracket",
  DELETE_BRACKET: "salary/delete-bracket",
  SALARY_CALC: `salary/calc-salary-breakup`,
  EMP_SALARY_STRUCTURE: `salary/structure`,
  SALARY_SLIP: "salary/structure/salaryslip",
  SALARY_SLIP_FETCH: "salary/salaryslip",
  EMP_SALARY_STRUCTURE_HISTORY: `salary/structure-history`,
  MASTER_VARIABLE_TYPE: "salary/variableType",
  ARREARS_DATA: "salary/arrears-amount",
  CREATE_VARIABLE_PAY: "salary/createVariableType",
  UPDATE_VARIABLE_PAY: "salary/updateVariablePay",
  GET_MONTH_WISE: "finance/monthWise",
  GET_MONTH_WISE_SALARY: "finance/getSalary",
  EMPLOYEE_FINANCE: "salary/employee-structure",
  VACCINE_DETAILS_URL: "vaccination/get-vaccination",
  IMMEDIATE_FAMILY_DETAILS: "family/get-family",
  CREATE_VACCINE_DATA_URL: "vaccination/create-vaccination",
  UPDATE_VACCINE_DATA_URL: "vaccination/update-vaccination",
  CREATE_FAMILY_DATA_URL: "family/create-familydetails",
  UPDATE_FAMILY_DATA_URL: "family/update-familydetails",
  UPDATE_PASSPORT_DATA_URL: "passport/upload",
  UPDATE_PAN_DATA_URL: "pan_card/upload",
  UPLOAD_DOCUMENTS_DATA_URL: "profile/upload-documents",
  CREATE_AADHAAR_PDF_DATA_URL: "aadhaar/upload-aadhaar",
  DELETE_PAN_DATA_URL: "pan_card/delete",
  DELETE_DOCUMENTS_DATA_URL: "profile/deleteDocument",
  DELETE_PASSPORT_DATA_URL: "passport/delete-document",
  DELETE_AADHAAR_DATA_URL: "aadhaar/delete-aadhaar",
  SKILLS_SET: "master-skill/all-skill",
  PASSPORT_DOWNLOAD: "passport/downloadFile",
  EMPLOYEE_SKILLS_SET_DETAILS: "skill-set/get-employeeSkillSet",
  FETCH_WORKMODE_POPUP: "profile/workMode/creation-alert",
  CREATE_EMPLOYEE_SKILLS_SET: "skill-set/create-employeeSkillSet",
  UPDATE_EMPLOYEE_SKILLS_SET: "skill-set/update-employeeSkillSet",
  CREATE_EMPLOYEE_WORK_MODE: "profile/workMode/create",
  CREATE_EMPLOYEE_WORK_SHIFT: "shift/create-employeeShift",
  GET_EMPLOYEE_WORK_SHIFT: "shift/shiftDetails",
  GET_RESUME_DOWNLOAD_LINK: "profile/download-resume",
  GET_DOCUMENTS_DOWNLOAD_LINK: "profile/download-documents",
  EMPLOYEE_CERTIFICATION_DETAILS: "certification/get-certification",
  CREATE_EMPLOYEE_CERTIFICATION: "certification/create-certificationDetail",
  UPDATE_EMPLOYEE_CERTIFICATION: "certification/update-certificationDetail",
  FETCH_EDUCATION_LEVEL: "education/get-educationLevel",
  EMPLOYEE_EDUCATION_QUALIFICATION_DETAILS: "education/get-education",
  CREATE_EMPLOYEE_EDUCATION_QUALIFICATION: "education/create-educationDetail",
  UPDATE_EMPLOYEE_EDUCATION_QUALIFICATION: "education/update-educationDetail",
  UPLOAD_EDUCATION_DOCUMENTS: "education/upload-educationDoc",
  EMPLOYEE_WORK_EXPERIENCE_DETAILS:
    "work-experience/get-employeeWorkExperience",
  CREATE_EMPLOYEE_WORK_EXPERIENCE:
    "work-experience/create-employeeWorkExperience",
  UPDATE_EMPLOYEE_WORK_EXPERIENCE:
    "work-experience/update-employeeWorkExperience",
  TEAM_TODAY_ATTENDANCE: "team/today-attendance",
  TEAM_ATTENDANCE: "team/attendance",
  ORGANIZATION_CLIENT_LIST: "client/getAll-client",
  CREATE_ORGANIZATION_CLIENT: "client/create-client",
  UPDATE_ORGANIZATION_CLIENT: "client/update-client",
  CLIENT_PROJECT_LIST: "project/getAll-project",
  CREATE_CLIENT_PROJECT: "project/create-project",
  UPDATE_CLIENT_PROJECT: "project/update-project",
  UPLOAD_CLIENT_LOGO: "client/upload-clientLogo",
  ADD_EMPLOYEES_IN_PROJECT: "project-employees/create-employeeProject",
  DELETE_EMPLOYEES_FROM_PROJECT: "project-employees/delete-employeeFromProject",
  IS_TEAM_LEAD: "employee/team-lead",
  DEPARTMENT_RESOURCES: "team/departmentwise-list",
  GET_EMPLOYEES_PROJECT_WITH_TEAM: "project/get-EmployeeProject",
  GET_EMPLOYEES_OF_PROJECT: "project/get-EmployeeProject",
  PAYROLL_DASHBOARD_SUMMARY: "/payroll/payroll-dashboard-summary",
  YEARLY_PAYROLL_SUMMARY: "/payroll/yearly-payroll-summary",
  BANK_DETAILS: "bank-details",
  DELETE_EDUCATION_DOCUMENTS: "education/delete-educationDoc",
  PAYROLL_COMPLETE_ATTENDANCE: "payroll/complete-attendance",
  PAYROLL_PENDING_LEAVES: "payroll/pending-leaves",
  MASTER_CURRENCY: "master/currency",
  FETCH_EMPLOYEE_HISTORY: "profile/employee-history",
  UPLOAD_PREVIOUS_COMPANY_DOCUMENTS: "last-company/upload-lastCompanyDoc",
  DELETE_PREVIOUS_COMPANY_DOCUMENTS: "last-company/delete-lastCompanyDoc",
  GET_PREVIOUS_COMPANY_DOCUMENTS: "last-company/get-lastCompanyDoc",
  RESIGNATION_DATA: "resignation/resignation_data",
  MONTHLY_RESIGNATION_DATA: "resignation/monthly_resignation_data",
  RESIGNATION_REPORT_DATA: "resignation/resignationReport",
  RESIGNATION_APPLIED_LIST: "resignation/applied-list",
  NEW_JOINEE: "profile/newJoineeList/v1",
  REMOTELY_WORKING: "profile/workingRemotely/v1",
  ALL_POSTS: "profile//allPost/v1",
  HELPDESK_RECIPIENT_LIST: "helpdesk/recipientList",
  UPLOAD_CERTIFICATION_DOCUMENTS: "certification/upload-certificationDoc",
  DELETE_CERTIFICATION_DOCUMENTS: "certification/delete-certificationDoc",
  PAYROLL_NO_ATTENDANCE: "payroll/no-attendance",
  REGULARIZE_DATA: "payroll/regularize-attendance",
  ATTENDANCE_NOTIFICATION: "payroll/attendance-notification",
  REGULARIZATION_REPORT: "attendance/regularization-report",
  FETCH_WFH_REPORT: "report/wfh-report",
  FETCH_LEAVE_REPORT: "v2/leave/report/leave-report",
  FETCH_USER_LIST: "users/getUsers",
  UPDATE_USER_BY_ID: "users/updateUserById",
  ADMIN_PASSWORD_UPDATE: "user/admin-update-password",
  FETCH_USER_BY_ID: "users/getUserById",
  FETCH_APPROVER_HISTORY: "profile/leave-approver-history",
  PENDING_LEAVE_NOTIFICATION: "payroll/pending-leave-notification",
  HELPDESK_TICKET: "helpdesk/ticket",
  TODAY_ATTENDANCE_REPORT: "attendance/attendance-report",
  WORK_MODE_DATA: "employee-workMode/report",
  WORK_MODE_RECORDS: "employee-workMode/records",
  NO_ATTENDANCE_NOTIFICATION: "payroll/no-attendance-notification",
  CREATE_HELPDESK: "helpdesk/ticket",
  LWP_NOTIFICATION: "payroll/lwp-notification",
  PAYROLL_LWP: "payroll/lwp",
  LEAVE_BALANCE_REPORT: "report/leave-balance-report",
  HELPDESK_REPLIES: "helpdesk/ticket-repiles",
  UPDATE_LEAVE_BALANCE: "report/update-leave-balance",
  HELPDESK_RECORD: "helpdesk/all-ticket",
  HELPDESK_USER: "helpdesk/helpdesk-group",
  ALLOCATE_LEAVE_BALANCE: "report/allocate-leave-balance",
  UPDATE_HELPDESK: "helpdesk/update-ticket",
  SEND_HELPDESK_REPLY: "helpdesk/ticket-reply",
  EXIT_EMPLOYEE_LIST: "payroll/employee-exit-list",
  CREATE_EMPLOYEE_SALARY_STATUS: "payroll/salary-hold",
  HOLD_BONUS: "payroll/hold-bonus",
  UPDATE_BONUS: "payroll/update-bonus",
  UPDATE_EMPLOYEE_SALARY_STATUS: "payroll/salary-release",
  PAYROLL_NEW_JOINEES: "payroll/new-joinees",
  CREATE_NEW_TIMESHEET_TASK: "timeSheet/create-task",
  CREATE_NEW_TEAM_TIMESHEET_TASK: "timeSheet/team-task",
  SELF_TIMESHEET_TASK_LIST: "timeSheet/employee-task-list",
  EMPLOYEE_TIMESHEET_TASK_REPORT: "timeSheet/employee-task-report",
  DISMISS_TIMESHEET_SELF_TASK: "timeSheet/dismiss-task",
  TEAM_TIMESHEET_TASK_LIST: "timeSheet/all-task-list",
  TEAM_TIMESHEET_REPORT: "timeSheet/time-sheet-report",
  APPROVE_TIMESHEET_TASK: "timeSheet/task-status-update",
  PAYROLL_BONUS: "payroll/bonus",
  DOWNLOAD_NEW_JOINEE_SALARY_STATUS_EXCL: "payroll/joinee-salary-status",
  DOWNLOAD_EXIT_EMPLOYEE_SALARY_STATUS_EXCL:
    "payroll/exit-employee-salary-status",
  PAYROLL_REVISION: "payroll/revised-structures",
  PAYROLL_PENDING: "payroll/pending-structures",
  EMPLOYEE_ATTENDANCE_LIST: "attendance/get-employee-attendance-list",
  HOLD_SALARY_EMPLOYEE_LIST: "payroll/get-employees-hold-salary",
  MISCELLANEOUS_DEDUCTIONS: "payroll/all-misc-deduction",
  MISCELLANEOUS_DEDUCTIONS_TYPES: "payroll/misc-deduction-types",
  MISCELLANEOUS_DEDUCTIONS_UPDATE: "payroll/update-misc-deduction",
  HOLD_DEDUCTION: "payroll/hold-misc-deduction",
  RELEASE_DEDUCTION: "payroll/release-misc-deduction",
  CREATE_NEW_ATTENDANCE: "employee/addAttendance",
  UPDATE_NEW_ATTENDANCE: "attendance/update-attendance",
  REIMBURSEMENTS_LIST: "payroll/reimbursement-list",
  HOLD_REIMBURSEMENT: "payroll/hold-reimbursement",
  MASTER_REIMBURSEMENT_TYPE: "payroll/reimbursement-type",
  UPDATE_REIMBURSEMENT: "payroll/update-reimbursement",
  CREATE_REIMBURSEMENT: "payroll/employee-reimbursement",
  GET_REIMBURSEMENT_TYPE: "payroll/reimbursement-type",
  GET_MISC_DEDUCTION_TYPE: "payroll/misc-deduction-types",
  DOWNLOAD_EMPLOYEE_TIMESHEET_TASK: "timeSheet/download-task-list",
  GET_CALCULATE_OT: "ot/calculate-employee-ot-time",
  GENERATED_PREVIEW: "payroll/run-payroll",
  PREVIEW_LIST: "payroll/preview",
  FINALIZED_SALARY_SLIP: "payroll/bulk-finalize-salary",
  GET_POLICY_CONSENT: "policyConsent/get-policyConsent",
  FETCH_EMPLOYEE_REPORT: "report/employee-report",
  UPDATE_PAYROLL_CONFIG: "payroll/set_payroll_config",
  GET_PAYROLL_CYCLE: "payroll/get_payroll_cycle",
  PENDING_STRUCTURES: "payroll/no-structures",
  PAYROLL_DASHBOARD_COUNTS: "/payroll/payroll-dashboard-counts",
  UPDATE_MAIL_CONFIG_STATUS: "email/update-mail-config",
  CREATE_MAIL_CONFIG: "email/create-mail-config",
  CREATE_OT_CONFIG: "ot/create-ot-employee-data",
  CREATE_NEW_CONFIG: "master/create-config",
  UPDATE_MASTER_CONFIG: "master/update-config",
  GET_MASTER_CONFIG_DATA: "module-approval/module-approval-list",
  CREATE_MASTER_CONFIG_DATA: "module-approval/module-approval-create",
  UPDATE_MASTER_CONFIG_DATA: "module-approval/module-approval-update",
  MASTER_ROLES_AND_COMPONENT: "master/roleAndComponent",
  GET_MAIL_CONFIG_LIST: "email/get-config",
  // FINALIZED_SALARY_SLIP: "payroll/finalize-salary",
  GET_LEAVE_APPLICATION_V1: "v2/leave/application/get-leaveApplication",
  GET_WFH_APPLICATION_V1: "v2/wfh/get-wfhApplication",
  LEAVE_UPDATE_STATUS_V1: "leave/update-status",
  REVIEW_LEAVE_ALLOCATION: "v2/leave/allocation/get-leaveAllocation",
  ATTENDANCE_REGULARIZATION_V1:
    "attendance-regularization-v1/get-all-attendance-regularization",
  MONTHLY_REGULARIZATION:
    "attendance-regularization-v1/get-monthly-regularization-count",
  UPDATE_REGULARIZATION_STATUS: "attendance/process-regularization",
  REGULARIZE_ATTENDANCE_NOTIFICATION:
    "payroll/regularize-attendance-notification",
  ALL_COMPANY_EMPLOYEES: "master/direct-employee-data",
  RESET_USER_LOCK: "users/resetUserLockById",
  PAYROLL_CONFIG_HISTORY_LIST: "payroll/payroll_config_history_list",
  CLOSE_PAYROLL_CYCLE: "payroll/close_payroll_cycle",
  CLOSED_PAYROLL: "payroll/closed-payroll",
  PREVIOUS_MONTH_PAYROLL_CONFIG: "payroll/month_payroll_config",
  GET_YEAR_WISE_SALARY: "finance/getAllSalary",
  CREATE_OWN_REIMBURSEMENT: "payroll/create_reimbursement",
  GET_OWN_REIMBURSEMENT: "payroll/reimbursement_by_employee",
  GET_REIMBURSEMENT_BY_ID: "payroll/reimbursement",
  UPDATE_REIMBURSEMENT_BY_ID: "payroll/update_employee_reimbursement",
  SALARY_SLIP_BREAK_UP: "salary/get-salaryslip-breakup",
  FETCH_ALL_REPORTING_MANAGERS: "master/direct-leave-approver-data",
  FETCH_ALL_HODS: "master/get-all-hods",
  PROJECT_APPROVER_CONFIG: "project/get-approval-levels",
  EMPLOYEES_FROM_ALL_PROJECTS: "timeSheet/get-all-project-employees",
  GET_TIMESHEET_HISTORY: "timeSheet/task-data-history",
  EMPLOYEE_TIME_SHEET_SUMMARY: "timeSheet/time-sheet-summary",
  UPDATE_REGULARIZED_ATTENDANCE:
    "attendance-regularization-v1/update-regularized-attendance",
  GET_ALL_PROJECT_LIST: "timeSheet/get-all-project",
  CREATE_HOLIDAY_LIST: "master/create-holidayList",
  UPDATE_HOLIDAY_LIST: "master/update-holiday-list",
  UPLOAD_BULK_ATTENDANCE: "attendance/import-attendance",
  GET_OVERTIME_REPORT: "attendance/get-overtime-list",
  DOWNLOAD_SAMPLE_FILE: "attendance/download-sample-attendance",
  FETCH_OT_DATA_PAYROLL:"ot/get-ot-data",
  FETCH_PENDING_EMPLOYEE_OT: "ot/get-pending-ot-employees",
});

export default END_POINTS;
