import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import SelectComponent from "../../../MasterComponent/SelectComponent";
import dayjs from "dayjs";
import TextFieldComponent from "../../../MasterComponent/TextFieldComponent";
import { Checkbox } from "@mui/material";

const LeaveAccrual = ({ setIsUpdateOpen, updateConfigData }) => {
  // Get month name
  let getMonths = [];
  for (let i = 1; i <= 12; i++) {
    getMonths?.push(dayjs(`2024-${i}-01`)?.format("MMM"));
  }
  // Get month days
  const getDays = [];
  for (let i = 1; i <= 28; i++) {
    getDays?.push(i);
  }
  getDays?.push("Last Day");

  const getAnnualQuota = ["Monthly", "Quarterly", "Half Yearly"];
  const probationList = ["During Probation", "After Probation"];
  const supValidation = (data) => {
    if (Number(data) === 1) {
      return "st";
    } else if (Number(data) === 2) {
      return "nd";
    } else if (Number(data) === 3) {
      return "rd";
    } else {
      return "th";
    }
  };
  const methods = useForm({
    defaultValues: {
      leaveAccural: {
        availableLeaveCalculated: "annual",
        leaveAccuralHappens: "",
        leaveAccuralHappensDay: 0,
        leaveQuotaForNewJoinee: "prorate",
        leaveQuotaForResignedEmployee: "prorate",
        joining: [],
        exit: [],
        leaveAccuralBasedOnProbationOrExperience: "no",
        leaveAccuralBasedOnProbationOrExperienceType: "",
        leaveAccuralBasedOnProbationOrExperienceJoiningDays: 0,
        leaveAccuralBasedOnProbationOrExperienceProbtionDays: 0,
        probationOrExperience: [],
        leaveAccuralAttendanceImpacted: "no",
        weeklyOffNotConsideredAsAbsentDays: 0,
        holidayNotConsideredAsAbsentDays: 0,
        canEmployeeApplyForProjectedDate: "no",
        canEmployeeApplyBeyondBalance: "yes",
        beyondBalanceLeaveAllowedDays: 0,
        isLeaveAccuralCreditLimitExceeding: false,
        leaveAccuralCreditLimitDays: 0,
        isLeaveAccuralCreditAppliedLeaveRestricted: false,
        leaveAccuralAppliedLeaveLimitDays: 0,
        leaveAccuralRoundedTo: "no",
        doesLeaveExpire: "no",
        leaveExpiresAfterDays: 0,
      },
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = methods;
  const {
    fields: joiningFields,
    append: joiningAppend,
    remove: joiningRemove,
  } = useFieldArray({
    name: `leaveAccural.joining`,
    control,
  });
  const {
    fields: exitFields,
    append: exitAppend,
    remove: exitRemove,
  } = useFieldArray({
    name: `leaveAccural.exit`,
    control,
  });
  const {
    fields: probationOrExperienceFields,
    append: probationOrExperienceAppend,
    remove: probationOrExperienceRemove,
  } = useFieldArray({
    name: `leaveAccural.probationOrExperience`,
    control,
  });
  const submitHandler = (data) => {
    console.log("data:", data);
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="leaveQuotaForm">
            <div className="mt-4">
              <div className="font-semibold">
                How is available leave balance of an employee calculated?
              </div>
              <div className="inline-flex gap-2">
                <Controller
                  name="leaveAccural.availableLeaveCalculated"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "regular") {
                            setValue(
                              "leaveAccural.leaveAccuralHappens",
                              "Monthly"
                            );
                            setValue("leaveAccural.leaveAccuralHappensDay", 1);
                          } else {
                            setValue("leaveAccural.leaveAccuralHappens", null);
                            setValue("leaveAccural.leaveAccuralHappensDay", 0);
                          }
                        }}
                      >
                        <FormControlLabel
                          value="annual"
                          control={<Radio />}
                          label="Entire annual quota can be availed anytime during the year"
                        />
                        <FormControlLabel
                          value="regular"
                          control={<Radio />}
                          label="Leave is accrued (earned) at regular intervals (Define accrual pattern below)"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
              {watch("leaveAccural.availableLeaveCalculated") === "regular" && (
                <div className="mt-4">
                  <div className="font-semibold mb-2">
                    Leave accrual of Annual quota happens at the following rate
                  </div>
                  <div className="p-3 h-20 text-sm bg-gray-50 border-1 border-gray-200 w-4/12 flex items-center gap-4">
                    <SelectComponent
                      name="leaveAccural.leaveAccuralHappens"
                      id="leaveAccural.leaveAccuralHappens"
                      control={control}
                      width="60px"
                      errors={errors}
                      options={
                        getAnnualQuota &&
                        getAnnualQuota?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      }
                    />
                    <div>On</div>
                    <SelectComponent
                      name="leaveAccural.leaveAccuralHappensDay"
                      id="leaveAccural.leaveAccuralHappensDay"
                      width="40px"
                      control={control}
                      errors={errors}
                      options={
                        getDays &&
                        getDays?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      }
                    />
                    <div>
                      {watch("leaveAccural.leaveAccuralHappens") === "Monthly"
                        ? "Of Month"
                        : watch("leaveAccural.leaveAccuralHappens") ===
                          "Quaterly"
                        ? `${supValidation(
                            watch("leaveAccural.leaveAccuralHappensDay")
                          )} day of the Quarter`
                        : `${supValidation(
                            watch("leaveAccural.leaveAccuralHappensDay")
                          )} day of the Half Year`}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                What happens to the leave quota when an employee joins company
                in the middle of the leave calendar year?
              </div>
              <div className="flex">
                <Controller
                  name="leaveAccural.leaveQuotaForNewJoinee"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "joiningMonth") {
                            joiningAppend({
                              leaveAccuralJoiningDateStart: "",
                              leaveAccuralJoiningDateEnd: "",
                              leaveAccuralJoiningDateDays: 0,
                            });
                          } else {
                            setValue("leaveAccural.joining", []);
                          }
                        }}
                      >
                        <div className="flex items-center">
                          <FormControlLabel
                            value="prorate"
                            control={<Radio />}
                            label="Leave accrual is prorate based on joining date (recommended)."
                          />
                        </div>
                        <FormControlLabel
                          value="joiningMonth"
                          control={<Radio />}
                          label="Leave is credited (in joining month) based on, date of month, on which employee had joined the company"
                        />
                        {watch("leaveAccural.leaveQuotaForNewJoinee") ===
                          "joiningMonth" && (
                          <div className="mt-4">
                            <div className="flex items-center">
                              <div className="font-semibold">
                                Define the leave credit below on employee's
                                joining date
                              </div>
                            </div>
                            {joiningFields?.map((data, index) => {
                              return (
                                <div className="flex">
                                  <div
                                    key={data?.id}
                                    className="p-3 h-20 bg-gray-50 border-1 w-6/12 text-sm border-gray-200 rounded-sm d-flex justify-center flex-column mt-3"
                                  >
                                    <div className="d-flex">
                                      <div className="flex flex-row align-items-center text-left gap-2">
                                        <div>If date of joining is between</div>
                                        <div className="w-28">
                                          <FormControl>
                                            <SelectComponent
                                              name={`leaveAccural.joining.${index}.leaveAccuralJoiningDateStart`}
                                              id={`leaveAccural.joining.${index}.leaveAccuralJoiningDateStart`}
                                              control={control}
                                              errors={errors}
                                              options={
                                                getDays &&
                                                getDays?.map((v) => ({
                                                  label: v,
                                                  value: v,
                                                }))
                                              }
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          <div>to</div>
                                        </div>
                                        <div className="w-28">
                                          <FormControl>
                                            <SelectComponent
                                              name={`leaveAccural.joining.${index}.leaveAccuralJoiningDateEnd`}
                                              id={`leaveAccural.joining.${index}.leaveAccuralJoiningDateEnd`}
                                              control={control}
                                              errors={errors}
                                              options={
                                                getDays &&
                                                getDays?.map((v) => ({
                                                  label: v,
                                                  value: v,
                                                }))
                                              }
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          <div>of the month, allocate</div>
                                        </div>
                                        <div className="w-28">
                                          <TextFieldComponent
                                            name={`leaveAccural.joining.${index}.leaveAccuralJoiningDateDays`}
                                            type="number"
                                            control={control}
                                            InputProps={{
                                              inputProps: { min: 0 },
                                            }}
                                            errors={errors}
                                          />
                                        </div>
                                        <div>days(s) of leave</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="p-3 d-flex items-center mt-3"
                                    onClick={() => {
                                      joiningAppend({
                                        leaveAccuralJoiningDateStart: "",
                                        leaveAccuralJoiningDateEnd: "",
                                        leaveAccuralJoiningDateDays: 0,
                                      });
                                    }}
                                  >
                                    <div className="">
                                      <FontAwesomeIcon
                                        className="primary"
                                        icon={faCirclePlus}
                                      />
                                    </div>
                                  </div>
                                  {index !== 0 && (
                                    <div
                                      className="d-flex items-center mt-3"
                                      onClick={() => {
                                        joiningRemove(index);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="primary"
                                        icon={faCircleMinus}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                What happens to the leave quota when an employee exits company
                in the middle of the leave calendar year?
              </div>
              <div className="flex gap-4">
                <Controller
                  name="leaveAccural.leaveQuotaForResignedEmployee"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "exitMonth") {
                            exitAppend({
                              leaveAccuralExitDateStart: "",
                              leaveAccuralExitDateEnd: "",
                              leaveAccuralExitDateDays: 0,
                            });
                          } else {
                            setValue("leaveAccural.exit", []);
                          }
                        }}
                      >
                        <div className="flex items-center">
                          <FormControlLabel
                            value="prorate"
                            control={<Radio />}
                            label="Leave is prorate based on actual exit date of employee (recommended)."
                          />
                        </div>
                        <FormControlLabel
                          value="exitMonth"
                          control={<Radio />}
                          label="Leave is updated based on, date of month, on which employee is exiting the company"
                        />
                        {watch("leaveAccural.leaveQuotaForResignedEmployee") ===
                          "exitMonth" && (
                          <div className="mt-4">
                            <div className="flex items-center gap-4 mb-3">
                              <div className="font-semibold">
                                Define the leave credit below (for the exit
                                month) based on employee's exit date
                              </div>
                            </div>
                            {exitFields?.map((data, index) => {
                              return (
                                <div className="flex">
                                  <div
                                    key={data?.id}
                                    className="p-3 h-20 bg-gray-50 text-sm border-1 w-6/12 border-gray-200 rounded-sm  d-flex justify-center  flex-column mt-2"
                                  >
                                    <div className="d-flex">
                                      <div className="flex flex-row align-items-center text-left gap-4">
                                        <div>If date of exit is between</div>
                                        <div className="w-28">
                                          <FormControl>
                                            <SelectComponent
                                              name={`leaveAccural.exit.${index}.leaveAccuralExitDateStart`}
                                              id={`leaveAccural.exit.${index}.leaveAccuralExitDateStart`}
                                              control={control}
                                              errors={errors}
                                              options={
                                                getDays &&
                                                getDays?.map((v) => ({
                                                  label: v,
                                                  value: v,
                                                }))
                                              }
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          <div>to</div>
                                        </div>
                                        <div className="w-28">
                                          <FormControl>
                                            <SelectComponent
                                              name={`leaveAccural.exit.${index}.leaveAccuralExitDateEnd`}
                                              id={`leaveAccural.exit.${index}.leaveAccuralExitDateEnd`}
                                              control={control}
                                              errors={errors}
                                              options={
                                                getDays &&
                                                getDays?.map((v) => ({
                                                  label: v,
                                                  value: v,
                                                }))
                                              }
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          <div>of the month, allocate</div>
                                        </div>
                                        <div className="w-28">
                                          <TextFieldComponent
                                            name={`leaveAccural.exit.${index}.leaveAccuralExitDateDays`}
                                            type="number"
                                            control={control}
                                            InputProps={{
                                              inputProps: { min: 0 },
                                            }}
                                            errors={errors}
                                          />
                                        </div>
                                        <div>days(s) of leave</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="p-3 d-flex items-center"
                                    onClick={() => {
                                      exitAppend({
                                        leaveAccuralExitDateStart: "",
                                        leaveAccuralExitDateEnd: "",
                                        leaveAccuralExitDateDays: 0,
                                      });
                                    }}
                                  >
                                    <div className="">
                                      <FontAwesomeIcon
                                        className="primary"
                                        icon={faCirclePlus}
                                      />
                                    </div>
                                  </div>
                                  {index !== 0 && (
                                    <div
                                      className="d-flex items-center"
                                      onClick={() => {
                                        exitRemove(index);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="primary"
                                        icon={faCircleMinus}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <FormControlLabel
                          value="noChange"
                          control={<Radio />}
                          label="No change in accural, employee gets full Month accural regardless of exit date"
                        />
                        <div className="flex items-center">
                          <FormControlLabel
                            value="noAccural"
                            control={<Radio />}
                            label="Do not accrue leave when an employee has resigned and is under notice period."
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Does Leave accrual very based on probation period or experience?
              </div>
              <div className="flex gap-4">
                <Controller
                  name="leaveAccural.leaveAccuralBasedOnProbationOrExperience"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "yes") {
                            probationOrExperienceAppend({
                              leaveAccuralProbationOrExperienceType: "",
                              leaveAccuralProbationOrExperienceDays: 0,
                              leaveAccuralProbationOrExperienceDaysPerYear: 0,
                            });
                            setValue(
                              "leaveAccural.leaveAccuralBasedOnProbationOrExperienceType",
                              "joining"
                            );
                          } else {
                            setValue("leaveAccural.probationOrExperience", []);
                            setValue(
                              "leaveAccural.leaveAccuralBasedOnProbationOrExperienceType",
                              null
                            );
                            setValue(
                              "leaveAccural.leaveAccuralBasedOnProbationOrExperienceJoiningDays",
                              0
                            );
                            setValue(
                              "leaveAccural.leaveAccuralBasedOnProbationOrExperienceProbtionDays",
                              0
                            );
                          }
                        }}
                      >
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No. Accrual is same for all employees regardless of Experience"
                        />
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes. Accrual varies based on experience of employee from Date of Joining/Probation"
                        />
                        {watch(
                          "leaveAccural.leaveAccuralBasedOnProbationOrExperience"
                        ) === "yes" && (
                          <div className="mt-4">
                            <div className="flex items-center gap-4 mb-3">
                              <div className="font-semibold">
                                From when does the accural start?
                              </div>
                            </div>
                            <div className="mb-3">
                              <Controller
                                name="leaveAccural.leaveAccuralBasedOnProbationOrExperienceType"
                                control={control}
                                render={({
                                  field: { onChange, name, value },
                                }) => (
                                  <FormControl className="radioBtnStyle">
                                    <RadioGroup
                                      name={name}
                                      value={value || ""}
                                      onChange={(e, val) => {
                                        onChange(val);
                                      }}
                                    >
                                      <div className="flex items-center">
                                        <FormControlLabel
                                          value="joining"
                                          control={<Radio />}
                                        />
                                        <div className="flex gap-2 items-center">
                                          <TextFieldComponent
                                            name={`leaveAccural.leaveAccuralBasedOnProbationOrExperienceJoiningDays`}
                                            type="number"
                                            control={control}
                                            InputProps={{
                                              inputProps: { min: 0, max: 365 },
                                            }}
                                            errors={errors}
                                            disabled={
                                              watch(
                                                "leaveAccural.leaveAccuralBasedOnProbationOrExperienceType"
                                              ) === "probation"
                                            }
                                          />
                                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                                            day after joining
                                          </label>
                                        </div>
                                      </div>
                                      <div className="flex">
                                        <FormControlLabel
                                          value="probation"
                                          control={<Radio />}
                                        />
                                        <div className="flex gap-2 items-center">
                                          <TextFieldComponent
                                            name={`leaveAccural.leaveAccuralBasedOnProbationOrExperienceProbtionDays`}
                                            type="number"
                                            control={control}
                                            InputProps={{
                                              inputProps: { min: 0, max: 365 },
                                            }}
                                            errors={errors}
                                            disabled={
                                              watch(
                                                "leaveAccural.leaveAccuralBasedOnProbationOrExperienceType"
                                              ) === "joining"
                                            }
                                          />
                                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                                            days after Probation ends
                                          </label>
                                        </div>
                                      </div>
                                    </RadioGroup>
                                  </FormControl>
                                )}
                              />
                            </div>
                            <div className="flex items-center gap-4 mb-3">
                              <div className="font-semibold">
                                Define the accural rates below on employee
                                experience
                              </div>
                            </div>
                            {probationOrExperienceFields?.map((data, index) => {
                              return (
                                <div className="flex">
                                  <div
                                    key={data?.id}
                                    className="p-3 h-20 text-sm bg-gray-50 border-1 w-4/12 border-gray-200 rounded-sm d-flex justify-center flex-column mt-3"
                                  >
                                    <div className="d-flex">
                                      <div className="flex flex-row align-items-center text-left gap-2">
                                        <div className="w-28">
                                          <FormControl>
                                            <SelectComponent
                                              name={`leaveAccural.probationOrExperience.${index}.leaveAccuralProbationOrExperienceType`}
                                              id={`leaveAccural.probationOrExperience.${index}.leaveAccuralProbationOrExperienceType`}
                                              control={control}
                                              errors={errors}
                                              options={
                                                probationList &&
                                                probationList?.map((v) => ({
                                                  label: v,
                                                  value: v,
                                                }))
                                              }
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          <div>Accure</div>
                                        </div>
                                        <div className="w-24 ">
                                          <TextFieldComponent
                                            name={`leaveAccural.probationOrExperience.${index}.leaveAccuralProbationOrExperienceDays`}
                                            type="number"
                                            onChangeHandler={(e) => {
                                              setValue(
                                                `leaveAccural.probationOrExperience.${index}.leaveAccuralProbationOrExperienceDaysPerYear`,
                                                Number(e?.target?.value) * 12
                                              );
                                            }}
                                            control={control}
                                            InputProps={{
                                              inputProps: { min: 0 },
                                            }}
                                            errors={errors}
                                          />
                                        </div>
                                        <div>days Monthly</div>
                                        <div className="w-24 ">
                                          <TextFieldComponent
                                            name={`leaveAccural.probationOrExperience.${index}.leaveAccuralProbationOrExperienceDaysPerYear`}
                                            type="number"
                                            control={control}
                                            disabled={true}
                                            InputProps={{
                                              inputProps: { min: 0 },
                                            }}
                                            errors={errors}
                                          />
                                        </div>
                                        <div>days/year</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="p-3 d-flex items-center mt-3"
                                    onClick={() => {
                                      probationOrExperienceAppend({
                                        leaveAccuralProbationOrExperienceType:
                                          "",
                                        leaveAccuralProbationOrExperienceDays: 0,
                                        leaveAccuralProbationOrExperienceDaysPerYear: 0,
                                      });
                                    }}
                                  >
                                    <div className="">
                                      <FontAwesomeIcon
                                        className="primary"
                                        icon={faCirclePlus}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex items-center mt-3"
                                    onClick={() => {
                                      probationOrExperienceRemove(index);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="primary"
                                      icon={faCircleMinus}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Is leave accrual impacted by employee's attendance/number of
                days worked every month?{" "}
              </div>
              <Controller
                name="leaveAccural.leaveAccuralAttendanceImpacted"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl className="radioBtnStyle">
                    <RadioGroup
                      name={name}
                      value={value}
                      onChange={(e, val) => {
                        onChange(val);
                        if (val === "no") {
                          setValue(
                            "leaveAccural.weeklyOffNotConsideredAsAbsentDays",
                            0
                          );
                          setValue(
                            "leaveAccural.holidayNotConsideredAsAbsentDays",
                            0
                          );
                        }
                        //   !val && setValue
                      }}
                    >
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No. Accrual has nothing to with employees's atendance/numberr of days worked."
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes. Leave is prorate on actual attendance/numberr of days worked by employees."
                      />
                      {watch("leaveAccural.leaveAccuralAttendanceImpacted") ===
                        "yes" && (
                        <div className="mt-4">
                          <div className="flex items-center gap-4 mb-3">
                            <div className="font-semibold">
                              For the purpose of accural based on attendance of
                              employee, how do you consider Weekly- Off &
                              Holidays:
                            </div>
                          </div>
                          <div className="flex gap-2 items-center mb-3">
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              Do not consider weekly-off as absent, for the
                              purpose of attendance, if employee is present for
                              at least
                            </label>
                            <div className="w-20">
                              <TextFieldComponent
                                name={`leaveAccural.weeklyOffNotConsideredAsAbsentDays`}
                                type="number"
                                control={control}
                                InputProps={{
                                  inputProps: { min: 0, max: 365 },
                                }}
                                errors={errors}
                                disabled={
                                  watch(
                                    "leaveAccural.leaveAccuralAttendanceImpacted"
                                  ) === "no"
                                }
                              />
                            </div>
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              days in a Month.
                            </label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              Do not consider holiday as absent, for the purpose
                              of attendance, if employee is present for at least
                            </label>
                            <div className="w-20">
                              <TextFieldComponent
                                name={`leaveAccural.holidayNotConsideredAsAbsentDays`}
                                type="number"
                                control={control}
                                InputProps={{
                                  inputProps: { min: 0, max: 365 },
                                }}
                                errors={errors}
                                disabled={
                                  watch(
                                    "leaveAccural.leaveAccuralAttendanceImpacted"
                                  ) === "no"
                                }
                              />
                            </div>
                            <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                              days in a Month.
                            </label>
                          </div>
                        </div>
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Can employee apply leave for a future date based on leave
                accural balance projected as of that date?
              </div>
              <Controller
                name="leaveAccural.canEmployeeApplyForProjectedDate"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl className="radioBtnStyle">
                    <RadioGroup
                      name={name}
                      value={value}
                      onChange={(e, val) => {
                        onChange(val);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes. Employee can avail the leave balance accured as of the day for which leave is being applied."
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No. Employee is limited to apply for leave balance accured on the day of leave application and not on the future day for whih leave is applied."
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Can employee apply leave beyond their accured balance?
              </div>
              <div className="mb-3">
                <Controller
                  name="leaveAccural.canEmployeeApplyBeyondBalance"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "no") {
                            setValue(
                              "leaveAccural.isLeaveAccuralCreditLimitExceeding",
                              true
                            );
                          } else {
                            setValue(
                              "leaveAccural.isLeaveAccuralCreditLimitExceeding",
                              false
                            );
                            setValue(
                              "leaveAccural.leaveAccuralCreditLimitDays",
                              0
                            );
                            setValue(
                              "leaveAccural.isLeaveAccuralCreditAppliedLeaveRestricted",
                              false
                            );
                            setValue(
                              "leaveAccural.leaveAccuralAppliedLeaveLimitDays",
                              false
                            );
                          }
                        }}
                      >
                        <div className="flex items-center">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes. Employee can go beyond their accured balance but no more than"
                          />
                          <div className="w-20">
                            <TextFieldComponent
                              name={`leaveAccural.beyondBalanceLeaveAllowedDays`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                watch(
                                  "leaveAccural.canEmployeeApplyBeyondBalance"
                                ) === "no"
                              }
                            />
                          </div>
                          <label className="whitespace-nowrap text-sm mb-1 ml-3 text-gray-900">
                            days and not exceeding their annual quota.
                          </label>
                        </div>
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No. Employee is limited to their accured balance and cannot take leave beyond the accured balance."
                        />
                        <div className="flex gap-2 items-center mt-3">
                          <Controller
                            name="leaveAccural.isLeaveAccuralCreditLimitExceeding"
                            control={control}
                            render={({ field: { onChange, name, value } }) => (
                              <Checkbox
                                color="primary"
                                name={name}
                                disabled={
                                  watch(
                                    "leaveAccural.canEmployeeApplyBeyondBalance"
                                  ) === "yes"
                                }
                                onChange={(e, val) => {
                                  onChange(val);
                                  setValue(
                                    "leaveAccural.leaveAccuralCreditLimitDays",
                                    0
                                  );
                                }}
                                checked={Boolean(value) || false}
                              />
                            )}
                          />
                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                            Do not accure leave when the leave balance
                            (including carry forwarded and other manual credits)
                            exceeds
                          </label>
                          <div className="w-20">
                            <TextFieldComponent
                              name={`leaveAccural.leaveAccuralCreditLimitDays`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                String(
                                  watch(
                                    "leaveAccural.isLeaveAccuralCreditLimitExceeding"
                                  )
                                ) === "false"
                              }
                            />
                          </div>
                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                            days.
                          </label>
                        </div>
                        <div className="flex gap-2 items-center mt-2">
                          <Controller
                            name="leaveAccural.isLeaveAccuralCreditAppliedLeaveRestricted"
                            control={control}
                            render={({ field: { onChange, name, value } }) => (
                              <Checkbox
                                color="primary"
                                name={name}
                                disabled={
                                  watch(
                                    "leaveAccural.canEmployeeApplyBeyondBalance"
                                  ) === "yes"
                                }
                                onChange={(e, val) => {
                                  onChange(val);
                                  setValue(
                                    "leaveAccural.leaveAccuralAppliedLeaveLimitDays",
                                    0
                                  );
                                }}
                                checked={Boolean(value) || false}
                              />
                            )}
                          />
                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                            Do not accure leave if an employee is on any leave
                            for more than
                          </label>
                          <div className="w-20">
                            <TextFieldComponent
                              name={`leaveAccural.leaveAccuralAppliedLeaveLimitDays`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                String(
                                  watch(
                                    "leaveAccural.isLeaveAccuralCreditAppliedLeaveRestricted"
                                  )
                                ) === "false"
                              }
                            />
                          </div>
                          <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                            days in previous Month. (irrespective of leave
                            calendar year).
                          </label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                What happens to the leave quota when an employee exits company
                in the middle of the leave calendar year?
              </div>
              <div className="flex gap-4">
                <Controller
                  name="leaveAccural.leaveAccuralRoundedTo"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                        }}
                      >
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, Do not round-off decimals, Employee will not be able to consume leave if does not have sufficient leave balance."
                        />
                        <FormControlLabel
                          value="nearestHalfDay"
                          control={<Radio />}
                          label="Round off to nearest half day"
                        />
                        <FormControlLabel
                          value="nearestFullDay"
                          control={<Radio />}
                          label="Round off to nearest full day"
                        />
                        <FormControlLabel
                          value="nextAvailableHalfDay"
                          control={<Radio />}
                          label="Round off to next available half day"
                        />
                        <FormControlLabel
                          value="nextAvailableFullDay"
                          control={<Radio />}
                          label="Round off to next available full day"
                        />
                        <FormControlLabel
                          value="previousAvailableHalfDay"
                          control={<Radio />}
                          label="Round off to previous available half day"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Can employee apply leave beyond their accured balance?
              </div>
              <div className="mb-3">
                <Controller
                  name="leaveAccural.doesLeaveExpire"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "no") {
                            setValue("leaveAccural.leaveExpiresAfterDays", 0);
                          }
                        }}
                      >
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No. Leave do not expire."
                        />
                        <div className="flex items-center">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes, Leave will expire after "
                          />
                          <div className="w-20">
                            <TextFieldComponent
                              name={`leaveAccural.leaveExpiresAfterDays`}
                              type="number"
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                              disabled={
                                watch("leaveAccural.doesLeaveExpire") === "no"
                              }
                            />
                          </div>
                          <label className="whitespace-nowrap text-sm mb-1 ml-3 text-gray-900">
                            days from the date of accural or credit.
                          </label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="p-3 flex justify-end">
            <button
              onClick={() => {
                reset();
                setIsUpdateOpen(false);
              }}
              type="button"
              className="inline-flex font-sm text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-400 hover:border-gray-400 py-2 px-4"
            >
              Back
            </button>
            <button
              onClick={() => {
                reset();
              }}
              type="button"
              className="inline-flex ml-3 font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex font-sm text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-500 text-white hover:text-gray-50 hover:bg-blue-300 hover:border-blue-300 py-2 px-4"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default LeaveAccrual;
