import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import TextFieldComponent from "../../../MasterComponent/TextFieldComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/pro-light-svg-icons";

const ApplyingForLeave = ({ setIsUpdateOpen, updateConfigData }) => {
  const methods = useForm({
    defaultValues: {
      applyLeave: {
        canApplyForHalfDay: "yes",
        canSeeAndApplyLeave: "yes",
        isCommentRequired: "yes",
        isDocumentProofRequired: "no",
        attachmentRequiredExceedingDays: 0,
        priorNotice: [
          {
            durationDays: 0,
            neededCalendarDays: 0,
            requiredWorkingDays: 0,
          },
        ],
        canEmployeeApplyEarlyLeave: false,
        employeeApplyEarlyLeaveDays: 0,
        canEmployeeApplyBackDatedLeave: false,
        employeeApplyBackDatedLeaveDays: 0,
        wantToRestrictBackDatedLeave: false,
        employeeRestrictedBackDatedLeaveDays: 0,
      },
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = methods;
  const {
    fields: priorNoticeFields,
    append: priorNoticeAppend,
    remove: priorNoticeRemove,
  } = useFieldArray({
    name: `applyLeave.priorNotice`,
    control,
  });

  const submitHandler = (data) => {
    console.log("data:", data);
  };
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="leaveQuotaForm">
            <div className="mt-4">
              <div className="font-semibold">Allow applying for a half-day</div>
              <div className="flex">
                <Controller
                  name="applyLeave.canApplyForHalfDay"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Allow an employee to see and apply for this leave?
              </div>
              <div className="flex">
                <Controller
                  name="applyLeave.canSeeAndApplyLeave"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue("applyLeave.priorNotice", [
                            {
                              durationDays: 0,
                              neededCalendarDays: 0,
                              requiredWorkingDays: 0,
                            },
                          ]);
                          setValue(
                            "applyLeave.canEmployeeApplyEarlyLeave",
                            false
                          );
                          setValue("applyLeave.employeeApplyEarlyLeaveDays", 0);
                          setValue(
                            "applyLeave.canEmployeeApplyBackDatedLeave",
                            false
                          );
                          setValue(
                            "applyLeave.employeeApplyBackDatedLeaveDays",
                            0
                          );
                          setValue(
                            "applyLeave.wantToRestrictBackDatedLeave",
                            false
                          );
                          setValue(
                            "applyLeave.employeeRestrictedBackDatedLeaveDays",
                            0
                          );
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, Employee can see and able to apply for a leave"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, Employees cannot apply by themselves, HR manager or a Reporting Manager can apply on their behalf"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            {watch("applyLeave.canSeeAndApplyLeave") === "yes" && (
              <>
                <div className="mt-4">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="font-semibold">
                      How many days prior notice is qrequired to apply for this
                      leave?
                    </div>
                  </div>
                  {priorNoticeFields?.map((data, index) => {
                    return (
                      <div className="flex">
                        <div
                          key={data?.id}
                          className="p-3 bg-gray-50 border-1 w-7/12 border-gray-200 rounded-sm d-flex justify-center flex-column mt-2"
                        >
                          <div className="flex mb-1 items-center gap-1">
                            <div className="w-100">
                              <div className="flex align-items-center text-left gap-2 text-sm">
                                <div>If the duration is more and includes</div>
                                <div className="w-24">
                                  <FormControl>
                                    <TextFieldComponent
                                      name={`applyLeave.priorNotice.${index}.durationDays`}
                                      type="number"
                                      control={control}
                                      InputProps={{
                                        inputProps: { min: 0, max: 365 },
                                      }}
                                      errors={errors}
                                    />
                                  </FormControl>
                                </div>
                                <div>Calendar days</div>
                                <div>Need</div>
                                <div className="w-24">
                                  <FormControl>
                                    <TextFieldComponent
                                      name={`applyLeave.priorNotice.${index}.neededCalendarDays`}
                                      type="number"
                                      control={control}
                                      InputProps={{
                                        inputProps: { min: 0, max: 365 },
                                      }}
                                      errors={errors}
                                    />
                                  </FormControl>
                                </div>
                                <div>calendar days Notice</div>
                                <div>and contain at least</div>
                                <div className="w-24">
                                  <FormControl>
                                    <TextFieldComponent
                                      name={`applyLeave.priorNotice.${index}.requiredWorkingDays`}
                                      type="number"
                                      control={control}
                                      InputProps={{
                                        inputProps: { min: 0, max: 365 },
                                      }}
                                      errors={errors}
                                    />
                                  </FormControl>
                                </div>
                                <div>
                                  <div>working days</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="p-3 d-flex items-center"
                          onClick={() => {
                            priorNoticeAppend({
                              durationDays: 0,
                              neededCalendarDays: 0,
                              requiredWorkingDays: 0,
                            });
                          }}
                        >
                          <div className="">
                            <FontAwesomeIcon
                              className="primary"
                              icon={faCirclePlus}
                            />
                          </div>
                        </div>
                        {index !== 0 && (
                          <div
                            className="d-flex items-center"
                            onClick={() => {
                              priorNoticeRemove(index);
                            }}
                          >
                            <FontAwesomeIcon
                              className="primary"
                              icon={faCircleMinus}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="font-semibold">
                      How early can an employee apply for this leave?
                    </div>
                  </div>
                  <div className="flex gap-2 items-center mt-3">
                    <Controller
                      name="applyLeave.canEmployeeApplyEarlyLeave"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <Checkbox
                          color="primary"
                          name={name}
                          disabled={
                            watch("applyLeave.canSeeAndApplyLeave") === "no"
                          }
                          onChange={(e, val) => {
                            onChange(val);
                            setValue(
                              "applyLeave.employeeApplyEarlyLeaveDays",
                              0
                            );
                          }}
                          checked={Boolean(value) || false}
                        />
                      )}
                    />
                    <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                      Leave request must be made no sooner than
                    </label>
                    <div className="w-20">
                      <TextFieldComponent
                        name={`applyLeave.employeeApplyEarlyLeaveDays`}
                        type="number"
                        control={control}
                        InputProps={{
                          inputProps: { min: 0, max: 365 },
                        }}
                        errors={errors}
                        disabled={
                          String(
                            watch("applyLeave.canEmployeeApplyEarlyLeave")
                          ) === "false"
                        }
                      />
                    </div>
                    <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                      calendar days prior to the leave date.
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="font-semibold">
                      Check restrictions that are applicable for back dated
                      leave application by employees?
                    </div>
                  </div>
                  <div className="flex gap-2 items-center mt-3">
                    <Controller
                      name="applyLeave.canEmployeeApplyBackDatedLeave"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <Checkbox
                          color="primary"
                          name={name}
                          disabled={
                            watch("applyLeave.canSeeAndApplyLeave") === "no"
                          }
                          onChange={(e, val) => {
                            onChange(val);
                            setValue(
                              "applyLeave.employeeApplyBackDatedLeaveDays",
                              0
                            );
                          }}
                          checked={Boolean(value) || false}
                        />
                      )}
                    />
                    <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                      Employee can apply leave for past days, but no beyond than
                    </label>
                    <div className="w-20">
                      <TextFieldComponent
                        name={`applyLeave.employeeApplyBackDatedLeaveDays`}
                        type="number"
                        control={control}
                        InputProps={{
                          inputProps: { min: 0, max: 365 },
                        }}
                        errors={errors}
                        disabled={
                          String(
                            watch("applyLeave.canEmployeeApplyBackDatedLeave")
                          ) === "false"
                        }
                      />
                    </div>
                    <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                      calendar days back.
                    </label>
                  </div>
                  <div className="flex gap-2 items-center mt-3">
                    <Controller
                      name="applyLeave.wantToRestrictBackDatedLeave"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <Checkbox
                          color="primary"
                          name={name}
                          disabled={
                            watch("applyLeave.canSeeAndApplyLeave") === "no"
                          }
                          onChange={(e, val) => {
                            onChange(val);
                            setValue(
                              "applyLeave.employeeRestrictedBackDatedLeaveDays",
                              0
                            );
                          }}
                          checked={Boolean(value) || false}
                        />
                      )}
                    />
                    <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                      Do not allow applying for past dated leave after
                    </label>
                    <div className="w-20">
                      <TextFieldComponent
                        name={`applyLeave.employeeRestrictedBackDatedLeaveDays`}
                        type="number"
                        control={control}
                        InputProps={{
                          inputProps: { min: 0, max: 365 },
                        }}
                        errors={errors}
                        disabled={
                          String(
                            watch("applyLeave.wantToRestrictBackDatedLeave")
                          ) === "false"
                        }
                      />
                    </div>
                    <label className="whitespace-nowrap text-sm mb-1 text-gray-900">
                      of every month.
                    </label>
                  </div>
                </div>
              </>
            )}
            <div className="mt-4">
              <div className="font-semibold">
                Does this leave require a comment?
              </div>
              <div className="flex">
                <Controller
                  name="applyLeave.isCommentRequired"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, Requires a comment"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, This leave does not require any comments although the employee is shown a comment box field."
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Do you require a document proof or a attachment for extended
                days of this leave?
              </div>
              <div className="flex">
                <Controller
                  name="applyLeave.isDocumentProofRequired"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "no") {
                            setValue(
                              "applyLeave.attachmentRequiredExceedingDays",
                              0
                            );
                          }
                        }}
                      >
                        <div className="flex items-center">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes, Requires an attachment if the leaves exceeds"
                          />
                          <div className="w-20 ">
                            <TextFieldComponent
                              name={`applyLeave.attachmentRequiredExceedingDays`}
                              type="number"
                              disabled={
                                watch("applyLeave.isDocumentProofRequired") ===
                                "no"
                              }
                              control={control}
                              InputProps={{
                                inputProps: { min: 0, max: 365 },
                              }}
                              errors={errors}
                            />
                          </div>
                          <div className="ml-3 text-sm">calendar days</div>
                        </div>
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, This leave does not require any attachments of document proofs."
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="p-3 flex justify-end">
            <button
              onClick={() => {
                reset();
                setIsUpdateOpen(false);
              }}
              type="button"
              className="inline-flex font-sm text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-400 hover:border-gray-400 py-2 px-4"
            >
              Back
            </button>
            <button
              onClick={() => {
                reset();
              }}
              type="button"
              className="inline-flex ml-3 font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex font-sm text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-500 text-white hover:text-gray-50 hover:bg-blue-300 hover:border-blue-300 py-2 px-4"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ApplyingForLeave;
