import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextFieldComponent from "../../../MasterComponent/TextFieldComponent";
import { Checkbox } from "@mui/material";

const LeaveQuota = ({ setIsUpdateOpen, updateConfigData }) => {
  const methods = useForm({
    defaultValues: {
      leaveQuota: {
        annualPaidLeave: updateConfigData?.leaveConfigJson
          ? updateConfigData?.leaveConfigJson?.annualPaidLeave
          : "limited",
        annualPaidLeaveLimit: updateConfigData?.leaveConfigJson
          ? updateConfigData?.leaveConfigJson?.annualPaidLeaveLimit
          : 0,
        canEmployeeApplyLeaveInYearCredited: updateConfigData?.leaveConfigJson
          ? updateConfigData?.leaveConfigJson
              ?.canEmployeeApplyLeaveInYearCredited
          : "yes",
        isLeaveQuotaJoiningMonthRestrictedForLeaveCredited:
          updateConfigData?.leaveConfigJson
            ? updateConfigData?.leaveConfigJson
                ?.isLeaveQuotaJoiningMonthRestrictedForLeaveCredited
            : false,
        joiningMonthRestrictedMaxAllocationDayForLeaveCredited:
          updateConfigData?.leaveConfigJson
            ? updateConfigData?.leaveConfigJson
                ?.joiningMonthRestrictedMaxAllocationDayForLeaveCredited
            : 0,
        isLeaveQuotaJoiningMonthRestrictedForAnnualQuota:
          updateConfigData?.leaveConfigJson
            ? updateConfigData?.leaveConfigJson
                ?.isLeaveQuotaJoiningMonthRestrictedForAnnualQuota
            : true,
        joiningMonthRestrictedMaxAllocationDayForAnnualQuota:
          updateConfigData?.leaveConfigJson
            ? updateConfigData?.leaveConfigJson
                ?.joiningMonthRestrictedMaxAllocationDayForAnnualQuota
            : 0,
        annualQuotaExceedingLimit: updateConfigData?.leaveConfigJson
          ? updateConfigData?.leaveConfigJson?.annualQuotaExceedingLimit
          : 0,
        canApplyBeyondAnnualQuota: updateConfigData?.leaveConfigJson
          ? updateConfigData?.leaveConfigJson?.canApplyBeyondAnnualQuota
          : "no",
        canRMAwardPaidLeave: updateConfigData?.leaveConfigJson
          ? updateConfigData?.leaveConfigJson?.canRMAwardPaidLeave
          : "no",
      },
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = methods;
  const supValidation = (data) => {
    if (Number(data) === 1) {
      return "st";
    } else if (Number(data) === 2) {
      return "nd";
    } else if (Number(data) === 3) {
      return "rd";
    } else {
      return "th";
    }
  };
  const submitHandler = (data) => {
    console.log("data:", data);
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="leaveQuotaForm">
            <div className="mt-4">
              <div className="font-semibold">
                What is the annual quota of paid leave days for an employee?
              </div>
              <div className="inline-flex gap-2">
                <Controller
                  name="leaveQuota.annualPaidLeave"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue("leaveQuota.annualPaidLeaveLimit", 0);
                        }}
                      >
                        <div className="flex items-center">
                          <FormControlLabel
                            value="limited"
                            control={<Radio />}
                            label="Limited"
                          />

                          <div className="flex items-center gap-2">
                            <TextFieldComponent
                              name="leaveQuota.annualPaidLeaveLimit"
                              type="number"
                              control={control}
                              InputProps={{ inputProps: { min: 0, max: 365 } }}
                              errors={errors}
                              disabled={
                                watch("leaveQuota.annualPaidLeave") ===
                                "unlimited"
                              }
                            />
                            <label
                              htmlFor="leaveQuota.annualPaidLeaveLimit"
                              className="whitespace-nowrap text-sm mb-1 text-gray-900"
                            >
                              day per year
                            </label>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <FormControlLabel
                            value="unlimited"
                            control={<Radio />}
                            label="Unlimited"
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="font-semibold">
                Can an employee apply for leave in the year it is being
                accrued/credited?
              </div>
              <div className="inline-flex gap-3">
                <Controller
                  name="leaveQuota.canEmployeeApplyLeaveInYearCredited"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormControl className="radioBtnStyle">
                      <RadioGroup
                        name={name}
                        value={value || ""}
                        onChange={(e, val) => {
                          onChange(val);
                          if (val === "no") {
                            setValue(
                              "leaveQuota.isLeaveQuotaJoiningMonthRestrictedForLeaveCredited",
                              true
                            );
                          } else {
                            setValue(
                              "leaveQuota.isLeaveQuotaJoiningMonthRestrictedForLeaveCredited",
                              false
                            );
                          }
                          setValue(
                            "leaveQuota.joiningMonthRestrictedMaxAllocationDayForLeaveCredited",
                            0
                          );
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, Employee can apply for leave in the year it is being accrued/credited"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, Employee cannot apply for leave in the year it is being accrued/credited"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            {String(watch("leaveQuota.canEmployeeApplyLeaveInYearCredited")) ===
              "no" && (
              <div className="mt-4">
                <div className="font-semibold inline-flex items-center">
                  <Controller
                    name="leaveQuota.isLeaveQuotaJoiningMonthRestrictedForLeaveCredited"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        onChange={(e, val) => {
                          onChange(val);
                          setValue(
                            "leaveQuota.joiningMonthRestrictedMaxAllocationDayForLeaveCredited",
                            0
                          );
                        }}
                        checked={value || false}
                      />
                    )}
                  />
                  <div>
                    No Leave quota is allocated in the joining month if the
                    employee joins after
                  </div>
                  <div className="flex items-end pl-2">
                    <div className="flex items-center gap-2">
                      <TextFieldComponent
                        name="leaveQuota.joiningMonthRestrictedMaxAllocationDayForLeaveCredited"
                        control={control}
                        errors={errors}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 31 } }}
                        disabled={
                          String(
                            watch(
                              "leaveQuota.isLeaveQuotaJoiningMonthRestrictedForLeaveCredited"
                            )
                          ) === "false"
                        }
                      />
                      <label
                        htmlFor="limit"
                        className="whitespace-nowrap mb-1 text-gray-900"
                      >
                        <sup>
                          {supValidation(
                            watch(
                              "leaveQuota.joiningMonthRestrictedMaxAllocationDayForLeaveCredited"
                            )
                          )}
                        </sup>{" "}
                        of the month.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-4">
              <div className="font-semibold">
                Can an employee apply for leave beyond the Annual Quota?
              </div>
              <Controller
                name="leaveQuota.canApplyBeyondAnnualQuota"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl className="radioBtnStyle">
                    <RadioGroup
                      name={name}
                      value={value}
                      onChange={(e, val) => {
                        onChange(val);
                        if (val === "no") {
                          setValue(
                            "leaveQuota.isLeaveQuotaJoiningMonthRestrictedForAnnualQuota",
                            true
                          );
                        } else {
                          setValue(
                            "leaveQuota.isLeaveQuotaJoiningMonthRestrictedForAnnualQuota",
                            false
                          );
                        }
                        setValue(
                          "leaveQuota.joiningMonthRestrictedMaxAllocationDayForAnnualQuota",
                          0
                        );
                        setValue("leaveQuota.annualQuotaExceedingLimit", 0);
                      }}
                    >
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No, Employee cannot axceed annual quota"
                      />
                      <div className="flex items-center">
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, An employee can apply for leave beyond annual quota and not axceeding"
                        />
                        <div className="flex items-center gap-2">
                          <TextFieldComponent
                            name="leaveQuota.annualQuotaExceedingLimit"
                            type="number"
                            control={control}
                            errors={errors}
                            disabled={
                              watch("leaveQuota.canApplyBeyondAnnualQuota") ===
                              "no"
                            }
                          />
                          <label
                            htmlFor="limit"
                            className="whitespace-nowrap text-sm mb-1 text-gray-900"
                          >
                            days in a year
                          </label>
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </div>
            {watch("leaveQuota.canApplyBeyondAnnualQuota") === "no" && (
              <div className="mt-4">
                <div className="font-semibold inline-flex items-center">
                  <Controller
                    name="leaveQuota.isLeaveQuotaJoiningMonthRestrictedForAnnualQuota"
                    control={control}
                    value={false}
                    render={({ field: { onChange, name, value } }) => (
                      <>
                        <Checkbox
                          color="primary"
                          name={name}
                          onChange={(e, val) => {
                            onChange(val);
                            setValue(
                              "leaveQuota.joiningMonthRestrictedMaxAllocationDayForAnnualQuota",
                              0
                            );
                          }}
                          checked={value || false}
                        />
                      </>
                    )}
                  />
                  <div>
                    No Leave quota is allocated in the joining month if the
                    employee joins after
                  </div>
                  <div className="flex items-end pl-2">
                    <div className="flex items-center gap-2">
                      <TextFieldComponent
                        name="leaveQuota.joiningMonthRestrictedMaxAllocationDayForAnnualQuota"
                        control={control}
                        type="number"
                        errors={errors}
                        disabled={
                          String(
                            watch(
                              "leaveQuota.isLeaveQuotaJoiningMonthRestrictedForAnnualQuota"
                            )
                          ) === "false"
                        }
                      />
                      <label
                        htmlFor="leaveQuota.joiningMonthRestrictedMaxAllocationDayForAnnualQuota"
                        className="whitespace-nowrap text-sm mb-1 text-gray-900"
                      >
                        <sup>
                          {supValidation(
                            watch(
                              "leaveQuota.joiningMonthRestrictedMaxAllocationDayForAnnualQuota"
                            )
                          )}
                        </sup>{" "}
                        of the month.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-4">
              <div className="font-semibold">
                Can reporting manager award paid leave credits to his reportees?
              </div>
              <Controller
                name="leaveQuota.canRMAwardPaidLeave"
                control={control}
                value={false}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl className="radioBtnStyle">
                    <RadioGroup
                      name={name}
                      value={value}
                      onChange={(e, val) => {
                        onChange(val);
                      }}
                    >
                      <div className="flex items-center">
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, Reporting manager cannot award leave credits"
                        />
                      </div>
                      <div className="flex items-center">
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, Reporting manager can award leave credits"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className="p-3 flex justify-end">
            <button
              onClick={() => {
                reset();
                setIsUpdateOpen(false);
              }}
              type="button"
              className="inline-flex font-sm text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-400 hover:border-gray-400 py-2 px-4"
            >
              Back
            </button>
            <button
              onClick={() => {
                reset();
              }}
              type="button"
              className="inline-flex ml-3 font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex font-sm text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-500 text-white hover:text-gray-50 hover:bg-blue-300 hover:border-blue-300 py-2 px-4"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default LeaveQuota;
