import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchReimbursementsData,
  holdEmployeeReimbursement,
} from "../../../store/slices/payrollSlice";
import MUIDataTable from "mui-datatables";
import Loader from "../../Loader/Loader";
import SearchBox from "../../TopHeader/SearchBox/SearchBox";
import DownloadReport from "../DownloadReport";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPauseCircle,
  faPen,
} from "@fortawesome/pro-light-svg-icons";
import http from "../../../utils/http";
import fileDownload from "js-file-download";
import { editActionHandler, rowActionHandler } from "./reimbursementsConfig";
import ReimbursementConfirmationDialog from "./ReimbursementConfirmationDialog";
import EditReimbursement from "./EditReimbursement";
import CreateReimbursement from "./CreateReimbursement";
import {
  componentDisplayAccess,
  getEmployeesAccess,
} from "../../../utils/userPermission";
import { Tooltip } from "@mui/material";
import { isEmpty, isNil } from "ramda";
import { toast } from "react-toastify";
import { getTwoDecimalFloatNumber } from "../../../utils/helper";

export default function Reimbursement({ startDate, endDate }) {
  const dispatch = useDispatch();

  const [toggelDataUpdate, setToggelDataUpdate] = useState(false);
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(15);
  const [sortBy, setsortBy] = useState("creation");
  const [order, setorder] = useState("DESC");
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [type, setType] = useState(null);
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const reimbursementsState = useSelector(
    (state) => state?.payRoll?.reimbursementListData ?? {}
  );
  const reimbursementData =
    reimbursementsState?.employeeReimbursementList ?? [];
  const loading = useSelector((state) => state.payRoll.isFetching);
  const reimbursementMonth = dayjs(endDate)
    ?.startOf("month")
    ?.format("YYYY-MM-DD");
  let employeeList = reimbursementData?.map((data) => data?.employee_id);
  const reimbursementAndDeductionAccessById = getEmployeesAccess({
    employees: employeeList,
    component: "Payroll_Reimbursement_Deduction",
  });
  const reimbursementAndDeductionAccess = componentDisplayAccess({
    component: "Payroll_Reimbursement_Deduction",
  });
  useEffect(() => {
    const params = {
      payrollMonth: reimbursementMonth,
      limit: limit,
      page: page + 1,
      sortBy: sortBy,
      order: order,
      search: search,
    };
    dispatch(fetchReimbursementsData(params));
  }, [
    startDate,
    page,
    limit,
    dispatch,
    sortBy,
    reimbursementMonth,
    search,
    order,
    toggelDataUpdate,
  ]);

  const columns = [
    {
      label: "Employee Id",
      name: "employee_id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Employee Name",
      name: "employee_name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Reimbursement Type",
      name: "reimbursement_type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Comments",
      name: "comment",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Reimbursement Amount",
      name: "reimbursement_amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? getTwoDecimalFloatNumber(value) : "-";
        },
      },
    },
    {
      label: "Approved Amount",
      name: "approved_amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? getTwoDecimalFloatNumber(value) : "-";
        },
      },
    },
    {
      label: "Approved On",
      name: "approval_date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? dayjs(value)?.format("DD-MMM-YYYY") : "-";
        },
      },
    },
    {
      label: "Hold Reason",
      name: "hold_reason",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Paid On",
      name: "paid_on",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          let color = "white";
          let textColor = "black";
          if (value === "Pending") {
            color = "#FFFBEE";
            textColor = "#AE5700";
          } else if (value === "On Hold") {
            color = "#FFE8E8";
            textColor = "#8A0000";
          } else if (value === "Release") {
            color = "#CDFECD";
            textColor = "#006C00";
          }
          return (
            <span
              style={{
                padding: "5px 10px",
                borderRadius: "20px",
                backgroundColor: color,
                color: textColor,
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      label: "   ",
      name: "reimbursementAction",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = reimbursementData?.[tableMeta?.rowIndex];
          return (
            <>
              <div className="actionIcon" style={{ justifyContent: "start" }}>
                {reimbursementAndDeductionAccessById?.[rowData?.employee_id]
                  ?.updateAccess ? (
                  <Tooltip title="Edit" arrow>
                    <span
                      type="edit"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={(e) => {
                        editActionHandler(rowData, setRow, setOpenEdit);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ pointerEvents: "none" }}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit" arrow>
                    {" "}
                    <span
                      type="edit"
                      style={{
                        marginRight: "10px",
                        // pointerEvents: "none",
                        opacity: 0.5,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ pointerEvents: "none" }}
                      />
                    </span>
                  </Tooltip>
                )}
                {reimbursementAndDeductionAccessById?.[rowData?.employee_id]
                  ?.updateAccess ? (
                  <Tooltip title="Hold" arrow>
                    <span
                      type="hold"
                      style={
                        rowData.on_hold || rowData.is_paid
                          ? {
                              marginRight: "10px",
                              // pointerEvents: "none",
                              opacity: 0.5,
                            }
                          : { marginRight: "10px", cursor: "pointer" }
                      }
                      onClick={(e) => {
                        !rowData.on_hold &&
                          !rowData.is_paid &&
                          rowActionHandler(
                            e,
                            rowData,
                            setRow,
                            setType,
                            setOpen
                          );
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          pointerEvents: "none",
                        }}
                        icon={faPauseCircle}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Hold" arrow>
                    <span
                      type="hold"
                      style={{
                        marginRight: "10px",
                        // pointerEvents: "none",
                        opacity: 0.5,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPauseCircle}
                        //  style={{ pointerEvents: "none" }}
                      />
                    </span>
                  </Tooltip>
                )}
                {reimbursementAndDeductionAccessById?.[rowData?.employee_id]
                  ?.updateAccess ? (
                  <Tooltip title="Release" arrow>
                    <span
                      type="release"
                      style={
                        rowData.on_hold === 0 ||
                        rowData.is_approved !== 1 ||
                        rowData.is_paid
                          ? { opacity: 0.5 }
                          : { cursor: "pointer" }
                      }
                      onClick={(e) => {
                        rowData.on_hold !== 0 &&
                          rowData.is_approved === 1 &&
                          !rowData.is_paid &&
                          rowActionHandler(
                            e,
                            rowData,
                            setRow,
                            setType,
                            setOpen
                          );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ pointerEvents: "none" }}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Release" arrow>
                    <span type="release" style={{ opacity: 0.5 }}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                  </Tooltip>
                )}
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    selectToolbarPlacement: "none",
    selectableRows: "multiple",
    filterType: "dropdown",
    responsive: "standard",
    count: reimbursementsState?.found ?? 0,
    rowsPerPage: limit,
    page: page,
    search: false,
    download: false,
    print: false,
    serverSide: true,
    viewColumns: false,
    onChangePage: (currentPage) => {
      setpage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setlimit(numberOfRows);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setsortBy(changedColumn);
      setorder(direction.toUpperCase());
    },
    onRowSelectionChange: (rowsSelected, allRowsSelected) => {
      let allselecteRows = [];
      allRowsSelected?.forEach((i) => {
        allselecteRows.push(reimbursementData[i?.index]);
      });

      setSelectedRows(allselecteRows);
    },
    isRowSelectable: (index) => {
      const employeeId = reimbursementData[index]?.employee_id;
      const hasUpdateAccess =
        reimbursementAndDeductionAccessById[employeeId]?.updateAccess;
      return hasUpdateAccess;
    },
    expandableRows: false,
  };

  const bulkHoldReimbursementHandler = async (event) => {
    const type = event.target.id;
    if (selectedRows.length && type === "hold") {
      const filterRows = selectedRows.filter((v) => v.on_hold === 1);
      if (!isEmpty(filterRows)) {
        return toast.error(
          "Please select users having reimbursement status release"
        );
      } else {
        setRow(
          selectedRows.map((v) => {
            return {
              reimbursementId: v.reimbursement_id,
              on_hold: true,
            };
          })
        );
        setType(type);
        return setOpen(true);
      }
    }

    if (selectedRows.length && type === "release") {
      const filterRows = selectedRows.filter(
        (v) => isNil(v.on_hold) || v.on_hold === 0
      );
      if (!isEmpty(filterRows)) {
        return toast.error(
          "Please select users having reimbursement status on hold"
        );
      } else {
        setRow(
          selectedRows.map((v) => {
            return {
              reimbursementId: v.reimbursement_id,
              on_hold: false,
            };
          })
        );
        setType(type);
        return setOpen(true);
      }
    }
    // if (selectedRows && selectedRows.length) {
    //   const holdData = selectedRows.map((el) => {
    //     return {
    //       reimbursementId: el.reimbursement_id,
    //       on_hold: type === "hold" ? true : false,
    //     };
    //   });

    //   const result = await dispatch(
    //     holdEmployeeReimbursement({ employeeReimbursementList: holdData })
    //   );

    //   if (result?.payload?.status) {
    //     setToggelDataUpdate(!toggelDataUpdate);
    //   }
    //   setSelectedRows([]);
    // }
  };

  const handleReimbursementDownload = async (e) => {
    try {
      setDownloading(true);

      const response = await http({
        url: `payroll/Reimbursement-Excel?payrollMonth=${reimbursementMonth}`,
        method: "GET",
        responseType: "blob",
      });

      fileDownload(
        response.data,
        `reimbursement_${dayjs(reimbursementMonth).format("MMM-YYYY")}.xlsx`
      );
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="payrollContentDiv">
        <div className="payrollActionsDiv">
          <div className="compAttSearchBtn">
            <SearchBox setSearch={setSearch} />
          </div>
          <button
            type="button"
            className="btn primaryBtnOutline"
            id="release"
            onClick={bulkHoldReimbursementHandler}
            disabled={!selectedRows.length}
          >
            Release
          </button>
          <button
            type="button"
            className="btn primaryBtnOutline"
            id="hold"
            onClick={bulkHoldReimbursementHandler}
            disabled={!selectedRows.length}
          >
            Hold
          </button>
          {/* <button
            type="button"
            className="btn primaryBtnOutline"
            onClick={bulkHoldReimbursementHandler}
          >
            Process
          </button> */}
          <button
            type="button"
            className="btn primaryBtnOutline"
            disabled={!reimbursementAndDeductionAccess?.createAccess}
            onClick={() => {
              setOpenCreate(true);
            }}
          >
            Create +
          </button>

          <DownloadReport
            handleExcelDownload={handleReimbursementDownload}
            loading={downloading}
          />
        </div>
        <div className="container-fluid">
          <div className="payrollTableResponsive">
            <div className="payrollTableConteiner">
              <MUIDataTable
                data={reimbursementData}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
        {open && (
          <ReimbursementConfirmationDialog
            open={open}
            setOpen={setOpen}
            selectedRow={row}
            type={type}
            setToggelDataUpdate={setToggelDataUpdate}
            toggelDataUpdate={toggelDataUpdate}
          />
        )}
        {openEdit && (
          <EditReimbursement
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
            selectedRow={row}
            setToggelDataUpdate={setToggelDataUpdate}
            params={{
              payrollMonth: reimbursementMonth,
              limit: limit,
              page: page + 1,
              sortBy: sortBy,
              order: order,
              search: search,
            }}
          />
        )}
        {openCreate && (
          <CreateReimbursement
            openCreate={openCreate}
            setOpenCreate={setOpenCreate}
            setToggelDataUpdate={setToggelDataUpdate}
          />
        )}
      </div>
    </>
  );
}
