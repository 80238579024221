import CONSTANTS from "../../../constants/constants";

export const config = {
    Notification: {
    [CONSTANTS.NOTIFICATION_TYPE.ATTENDANCE]: {
       color: '#664D03',
       backgroundColor: '#FFFCF2',
       padding:'10px 10px',
       margin:'0px 10px' 
    },
    [CONSTANTS.NOTIFICATION_TYPE.INVESTMENT_DECLARATION]: {
        color:'#175E6C',
        backgroundColor: '#ECFBFE',
        padding:'10px 10px',
        margin:'0px 10px' 
    },
    [CONSTANTS.NOTIFICATION_TYPE.PROBATION_PERIOD]: {
        color: '#A01E2A',
        backgroundColor: '#FFF4F5',
        padding:'10px 10px',
        margin:'0px 10px' 
    },
    [CONSTANTS.NOTIFICATION_TYPE.SALARY_SLIP]: {
        color:'#0F673E !important',
        backgroundColor: '#EFFEF7',
        padding:'10px 10px',
        margin:'0px 10px' 
    }},
    severity: {
        [CONSTANTS.NOTIFICATION_TYPE.ATTENDANCE]: 'info',
         [CONSTANTS.NOTIFICATION_TYPE.INVESTMENT_DECLARATION]: 'info',
         [CONSTANTS.NOTIFICATION_TYPE.PROBATION_PERIOD]: 'info',
         [CONSTANTS.NOTIFICATION_TYPE.SALARY_SLIP]: 'success'
    },
    notificationColor: {
        [CONSTANTS.NOTIFICATION_TYPE.ATTENDANCE]: 'warning',
         [CONSTANTS.NOTIFICATION_TYPE.INVESTMENT_DECLARATION]: 'info',
         [CONSTANTS.NOTIFICATION_TYPE.PROBATION_PERIOD]: 'error',
         [CONSTANTS.NOTIFICATION_TYPE.SALARY_SLIP]: 'success'
    }

}