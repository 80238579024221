import LeaveConfig from "./LeavePlans/LeavePlans";
import LeaveTypes from "./LeaveTypes/LeaveTypes";

const LeaveConfigRoute = [
  {
    path: "leave-config",
    element:"",
    title: "LEAVE CONFIG",
    index: true,
    access: true,
    subList: [
      {
        path: "leave-plans",
        element: <LeaveConfig />,
        title: "Leave Plans",
        index: true,
        access: true,
      },
      {
        path: "leave-type",
        element: <LeaveTypes />,
        title: "Leave Types",
        index: true,
        access: true,
      },
    ],
  },
];

export default LeaveConfigRoute;
