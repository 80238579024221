import React from "react";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  fetchLeaveApprovers,
  fetchTotalAppliedLeave,
  resetAppliedLeave,
} from "../../../store/slices/leaveSlice";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUserData } from "../../../utils/helper";
import http from "../../../utils/http";
import dayjs from "dayjs";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import AutoCompleteMultiSelect from "../../MasterComponent/AutoCompleteMultiSelect";
import {
  fetchEmployeeNameList,
  fetchEmployeesUserIds,
} from "../../../store/slices/employeeSlice";
import { useEffect } from "react";
import aaplyWFHSchema from "./applyWFHSchema";
import { useContext } from "react";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import Loader from "../../Loader/Loader";

const ApplyWFHDialog = ({ open, setOpen, getData }) => {
  const { displayPopup } = useContext(PopupContext);

  const loggedEmployee = getLoggedUserData();
  const EmployeeId = loggedEmployee?.employeeId ?? null;
  const [employeeId, setEmployeeId] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [halfDay, setHalfDay] = useState("");
  const [click, setClick] = useState();
  const [activeHalfDay, setActiveHalfDay] = useState();
  const [totalLeaveDays, setTotalLeaveDays] = useState();

  const dispatch = useDispatch();
  let employeeList = useSelector((state) => state?.employee?.employeeNameList);
  // employeeList = employeeList?.filter((item) => item.EmployeeId != EmployeeId);

  const LeaveApproveList = useSelector(
    (state) => state?.leave?.userLeaveApprover?.approvers
  );
  const EmployeeUserIds = useSelector(
    (state) => state?.employee?.employeeUserIds?.userIds
  );
  const methods = useForm({
    defaultValues: {
      employee: null,
      leave_approver: null,
      half_day_info: null,
      half_day_check: 0,
      from_date: null,
      to_date: null,
      description: "",
      cc: [],
    },
    resolver: yupResolver(aaplyWFHSchema),
  });
  const handleEmployeeChange = (e) => {
    setEmployeeId(e?.value);
    setValue("leave_approver", null);
    setValue("half_day_info", null);
    setValue("half_day_check", 0);
    setValue("from_date", null);
    setValue("to_date", null);
    setValue("description", "");
    setValue("cc", []);
    dispatch(resetAppliedLeave());
  };
  useEffect(() => {
    if (employeeId) {
      dispatch(fetchLeaveApprovers({ EmployeeId: employeeId }));
    }
    dispatch(fetchEmployeesUserIds());
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
  }, [employeeId, dispatch]);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = methods;
  const validateLeave = async () => {
    if (watch("from_date") && watch("to_date") && employeeId) {
      const result = await dispatch(
        fetchTotalAppliedLeave({
          from_date: dayjs(watch("from_date")).format("YYYY-MM-DD"),
          to_date: dayjs(watch("to_date")).format("YYYY-MM-DD"),
          employeeId: employeeId,
          leaveType: "Work From Home",
        })
      );
      if (result?.error?.message === "Rejected") {
        displayPopup(result?.payload?.message, "alert-danger");
        setValue("from_date", null);
        setValue("to_date", null);
      }
    }
  };

  const onSubmit = async (data) => {
    setIsFetching(true);
    const body = {
      employeeId: data.employee?.value,
      leave_approver: data?.leave_approver?.value?.split("/")[1],
      cc: data.cc,
      description: data.description,
      from_date: data.from_date,
      to_date: data.to_date,
      half_day: +data.half_day_check,
      half_day_info: activeHalfDay,
      leave_type: "Work From Home",
      follow_via_email: 1,
      total_leave_days: totalLeaveDays,
    };
    try {
      const res = await http.post(`leave/apply`, body);
      setIsFetching(false);
      setOpen(false);
      // setAlert(true);
      getData();
      displayPopup("Successfully Applied Work From Home", "alert-success");
      return res;
    } catch (e) {
      setIsFetching(false);
      // setAlert(true);
      displayPopup(e.response.data.message, "alert-danger");
    }
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(resetAppliedLeave());
    reset();
  };
  const halfDayData = async () => {
    try {
      let data = {
        from_date: watch("from_date"),
        to_date: watch("to_date"),
        employeeId: EmployeeId,
        leaveType: watch("leave_type"),
      };
      const res = await http.post(`leave/halfDayLeave`, data);
      setHalfDay(res?.data?.response?.half_day_info);
      setTotalLeaveDays(0.5);
      return res?.data?.response;
    } catch (e) {
      console.log("e:", e);
    }
  };
  const handleHalfDay = (e) => {
    setActiveHalfDay(e.target.value);
    setClick(e.target.id);
  };
  const totalSelectedLeave = useSelector(
    (state) => state?.leave?.appliedLeave?.actualDaysApplied
  );
  const fromDateChangeHandler = (date) => {
    setValue("from_date", dayjs(date).format("YYYY-MM-DD"));
    dispatch(resetAppliedLeave());
    setValue("to_date", null);
  };
  const toDateChangeHandler = (date) => {
    validateLeave();
    setValue("to_date", dayjs(date).format("YYYY-MM-DD"));
    const from_date = watch("from_date");
    const to_date = dayjs(date).format("YYYY-MM-DD");
    const daysDifference = dayjs(to_date).diff(dayjs(from_date), "days", true);
    const calculatedTotalLeaveDays = watch("half_day_check")
      ? 0.5
      : daysDifference + 1;

    // Update the totalLeaveDays state
    setTotalLeaveDays(calculatedTotalLeaveDays);
  };
  return (
    <Dialog
      open={open}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <DialogTitle className="add_employee_main_heading">
        <span>Apply Work From Home</span>
      </DialogTitle>
      {isFetching && <Loader />}
      <DialogContent className="card-content-employee-form">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Employee <span className="create_form_asterisk">*</span>:
                </label>
                {employeeList?.length > 1 ? (
                  <AutoCompleteComponent
                    name="employee"
                    id="employee"
                    control={control}
                    errors={errors}
                    options={employeeList?.map((v) => ({
                      label: `${v?.EmployeeId} - ${v?.employee_name}`,
                      value: v?.EmployeeId,
                    }))}
                    onChangeHandler={handleEmployeeChange}
                  />
                ) : null}
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Leave Approver <span className="create_form_asterisk">*</span>
                  :
                </label>
                <AutoCompleteComponent
                  name="leave_approver"
                  control={control}
                  errors={errors}
                  disabled={!watch("employee")}
                  // isFirstValueDefault={true}
                  options={LeaveApproveList?.map((v) => ({
                    label: v.approverName,
                    value: v.approverEmployeeId,
                    userId: v.approver_userId,
                  }))}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">CC:</label>
                <AutoCompleteMultiSelect
                  name="cc"
                  control={control}
                  errors={errors}
                  options={EmployeeUserIds}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="text-sm">
                  Employee Comment{" "}
                  <span className="create_form_asterisk">*</span>:
                </label>
                <TextAreaComponent
                  name="description"
                  control={control}
                  errors={errors}
                  rows="2"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  From Date <span className="create_form_asterisk">*</span>:
                </label>
                <DatePickerComponent
                  errors={errors}
                  control={control}
                  name="from_date"
                  id="from_date"
                  placeHolder="YYYY-MM-DD"
                  disableMaskedInput={true}
                  backSpaceChange={false}
                  onHandleChange={fromDateChangeHandler}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  To Date <span className="create_form_asterisk">*</span>:
                </label>
                <DatePickerComponent
                  errors={errors}
                  control={control}
                  name="to_date"
                  id="to_date"
                  placeHolder="YYYY-MM-DD"
                  disableMaskedInput={true}
                  backSpaceChange={false}
                  minDate={watch("from_date")}
                  disabled={watch("half_day_check")}
                  // maxDate={currentDate}
                  onHandleChange={toDateChangeHandler}
                />
              </div>

              <div className="col-md-12 mb-3">
                <div className="halfDay flex items-center justify-between">
                  <span className="col-5 checkbox-align">
                    <Controller
                      name="half_day_check"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <Checkbox
                          name={name}
                          onChange={(e, val) => {
                            onChange(val);
                            if (val) {
                              halfDayData();
                            }
                          }}
                          checked={value}
                          className="p-0"
                          disabled={
                            !(watch("from_date") && watch("to_date")) ||
                            (watch("from_date") &&
                              watch("to_date") &&
                              moment(watch("to_date")).diff(
                                moment(watch("from_date")),
                                "days",
                                false
                              ) > 0)
                            // || watch("leave_type")?.value === "Restricted Holiday"
                          }
                        />
                      )}
                    />
                    <span>Half Day</span>
                  </span>
                  {watch("half_day_check") &&
                  !moment(watch("to_date")).diff(
                    moment(watch("from_date")),
                    "days",
                    false
                  ) ? (
                    <>
                      {/* <RadioButtonComponent
                    control={control}
                    name="half_day_info"  
                    types={["First Half", "Second Half"]}
                  /> */}
                      <ul className="attanReguBtn list-unstyled flex items-center whitespace-nowrap gap-3">
                        <li className="w-100 p-0">
                          <button
                            id="firstHalf"
                            type="button"
                            name="half_day_info"
                            className={
                              click === "firstHalf"
                                ? "primarySquareBtn w-100"
                                : "outlinedSquareButton w-100"
                            }
                            onClick={(e) => handleHalfDay(e)}
                            disabled={
                              halfDay && halfDay === "First Half" ? true : false
                            }
                            value="First Half"
                          >
                            First Half
                          </button>
                        </li>
                        <li className="w-100 p-0">
                          <button
                            id="secondHalf"
                            type="button"
                            name="half_day_info"
                            className={
                              click === "secondHalf"
                                ? "primarySquareBtn w-100"
                                : "outlinedSquareButton w-100"
                            }
                            onClick={(e) => handleHalfDay(e)}
                            disabled={
                              halfDay && halfDay === "Second Half"
                                ? true
                                : false
                            }
                            value="Second Half"
                          >
                            Second Half
                          </button>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <span className="col-7 text-end">
                  Total Days :{" "}
                  <b>
                    {watch("half_day_check") ? 0.5 : totalSelectedLeave}{" "}
                    {totalSelectedLeave <= 1 ? "day" : "days"}
                  </b>
                </span>
              </div>
            </div>
            <DialogActions className="employee_form_button_div">
              <button
                type="button"
                onClick={handleClose}
                className="btn cancelBtnOutline"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                id={!isDirty ? "newDisable" : ""}
                className="btn primaryBtnOutline"
              >
                Save
              </button>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ApplyWFHDialog;
