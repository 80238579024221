const accessTokenName = "accessToken";
const refreshTokenName = "refreshToken";
const accessDataName = "accessData";
const lastRoute = "lastRoute";
const filter = ["filter.employee", "filter.leave", 'filter.wfh', 'filter.leaveAllocation', 'filter.salarySlip', 'filter.attendanceList', 'filter.policiesList', 'filter.salaryStructure','leaveFilter',"allocationFilter","regularizationFilter","wfhFilter"];

export const getAccessToken = () => {
  return localStorage.getItem(accessTokenName);
};

export const setAccessToken = (tokenValue) => {
  localStorage.setItem(accessTokenName, tokenValue);
};

export const removeAccessToken = () => {
  localStorage.removeItem(accessTokenName);
};

export const removeLastRoute = () => {
  localStorage.removeItem(lastRoute);
};

export const getRefreshToken = () => {
  return localStorage.getItem(refreshTokenName);
};

export const setRefreshToken = (tokenValue) => {
  localStorage.setItem(refreshTokenName, tokenValue);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(refreshTokenName);
};

export const setAccessData = (accessData) => {
  localStorage.removeItem(accessDataName);
  localStorage.setItem(accessDataName, accessData);
};

export const getAccessData = () => {
  return localStorage.getItem(accessDataName);
};
export const removeAccessData = () => {
  localStorage.removeItem(accessDataName);
};

export const removeFilterData = () => {
for (let key of filter) {
    localStorage.removeItem(key);
}
};
