import React from "react";
import "../LeaveConfigDashboard.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import {
  addLeaveType,
  getLeaveType,
  updateLeaveType,
} from "../../../store/slices/leaveConfigSlice";
import { Checkbox } from "@mui/material";
import data from "../../../Components/JSON/Data.json";
import SelectComponent from "../../MasterComponent/SelectComponent";

const addLeavePlanSchema = yup?.object({
  leaveTypeName: yup?.string()?.label("Leave Type Name")?.required()?.trim(),
  leaveTypeLabel: yup?.string()?.label("Leave Type Label")?.required()?.trim(),
  leaveTypeCode: yup?.string()?.label("Leave Type Code")?.required()?.trim(),
  description: yup?.string()?.label("Description")?.required()?.trim(),
  isPaidLeave: yup?.boolean()?.label("Paid Leave")?.optional()?.nullable(),
  isSickLeave: yup?.boolean()?.label("Sick Leave")?.optional()?.nullable(),
  isStatutoryLeave: yup
    ?.boolean()
    ?.label("statutory Leave")
    ?.optional()
    ?.nullable(),
  isGenderRestricted: yup
    ?.boolean()
    ?.label("Gender Restrict to")
    ?.optional()
    ?.nullable(),
  restrictedGender: yup?.string()?.label("Gender")?.optional()?.nullable(),
  isMaritalStatusRestricted: yup
    ?.boolean()
    ?.label("Merital Status")
    ?.optional()
    ?.nullable(),
  restrictedMaritalStatus: yup
    ?.string()
    ?.label("Merital Status")
    ?.optional()
    ?.nullable(),
});
const AddLeaveType = ({
  openLeaveType,
  setOpenLeaveType,
  updateLeaveId,
  setUpdateLeaveId,
}) => {
  console.log("updateLeaveId:", updateLeaveId);
  const dispatch = useDispatch();
  let genderList = data.gender;
  let maritalStatus = data.marital_status;

  const methods = useForm({
    defaultValues: {
      leaveTypeName: updateLeaveId ? updateLeaveId?.leaveTypeName : null,
      leaveTypeLabel: updateLeaveId ? updateLeaveId?.leaveTypeLabel : null,
      leaveTypeCode: updateLeaveId ? updateLeaveId?.leaveTypeCode : null,
      description: updateLeaveId ? updateLeaveId?.description : null,
      isPaidLeave: updateLeaveId ? updateLeaveId?.isPaidLeave : null,
      isSickLeave: updateLeaveId ? updateLeaveId?.isSickLeave : false,
      isStatutoryLeave: updateLeaveId ? updateLeaveId?.isStatutoryLeave : false,
      isGenderRestricted: updateLeaveId
        ? updateLeaveId?.isGenderRestricted
        : false,
      restrictedGender: updateLeaveId ? updateLeaveId?.restrictedGender : null,
      isMaritalStatusRestricted: updateLeaveId
        ? updateLeaveId?.isMaritalStatusRestricted
        : false,
      restrictedMaritalStatus: updateLeaveId
        ? updateLeaveId?.restrictedMaritalStatus
        : null,
    },
    resolver: yupResolver(addLeavePlanSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    trigger,
    watch,
  } = methods;
  console.log("watch:", watch());

  const isFetching = useSelector(
    (state) => state?.leaveConfig?.addLeaveTypeList?.isFetching
  );

  const submitHandler = async (data) => {
    console.log("data----------------:", data);
    if (updateLeaveId) {
      await dispatch(updateLeaveType({ id: updateLeaveId?.id, body: data }));
    } else {
      await dispatch(addLeaveType(data));
    }
    await dispatch(getLeaveType());
    reset();
    setOpenLeaveType(false);
    setUpdateLeaveId(null);
  };
  return (
    <>
      <div
        className={
          openLeaveType
            ? "createLeaveDrawer active relative"
            : "createLeaveDrawer relative"
        }
      >
        <div className="bg-blue-500 text-white py-4 px-3 w-full whitespace-nowrap">
          <div className="text-lg mb-2">
            <div className="flex items-center justify-between">
              <div>{updateLeaveId ? "Update " : "Add "} Leave Type</div>
              <div
                className="headerClose cursor-pointer"
                onClick={() => {
                  setOpenLeaveType(false);
                  setUpdateLeaveId(null);
                  reset();
                }}
              >
                <FontAwesomeIcon className="fa-lg" icon={faXmark} />
              </div>
            </div>
            <div className="text-sm text-gray-200">
              Get started by filling the information below to{" "}
              {updateLeaveId ? "update " : "add "} leave type.
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={openLeaveType ? "active" : ""}>
              <div className="addLeaveForm bg-white relative">
                {isFetching && <Loader />}
                <div className="formContainer whitespace-nowrap pt-4">
                  <div className="mb-3">
                    <label
                      htmlFor="leaveTypeName"
                      className="block text-sm mb-1 text-gray-900"
                    >
                      Leave Type Name<span className="text-red-500">*</span>
                    </label>
                    <TextFieldComponent
                      name="leaveTypeName"
                      onChangeHandler={() => trigger("leaveTypeName")}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="leaveTypeName"
                      className="block text-sm mb-1 text-gray-900"
                    >
                      Leave Type Label<span className="text-red-500">*</span>
                    </label>
                    <TextFieldComponent
                      name="leaveTypeLabel"
                      onChangeHandler={() => trigger("leaveTypeLabel")}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="leaveTypeCode"
                      className="block text-sm mb-1 text-gray-900"
                    >
                      Code <span className="text-red-500">*</span>
                    </label>
                    <TextFieldComponent
                      name="leaveTypeCode"
                      onChangeHandler={() => trigger("leaveTypeCode")}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="block text-sm mb-1 text-gray-900"
                    >
                      Description <span className="text-red-500">*</span>
                    </label>
                    <div className="font-sans">
                      <TextAreaComponent
                        name="description"
                        className="focus: shadow-none"
                        placeholder="Write your description here..."
                        onChangeHandler={() => trigger("description")}
                        control={control}
                        errors={errors}
                        rows={4}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="isPaidLeave"
                      control={control}
                      value={false}
                      render={({ field: { onChange, name, value } }) => (
                        <>
                          <Checkbox
                            color="primary"
                            id="isPaidLeave"
                            name={name}
                            onChange={(e, val) => {
                              onChange(val);
                            }}
                            checked={value || false}
                          />
                        </>
                      )}
                    />
                    <label
                      htmlFor="isPaidLeave"
                      className="block text-sm text-gray-900"
                    >
                      This is a paid leave
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="isSickLeave"
                      control={control}
                      value={false}
                      render={({ field: { onChange, name, value } }) => (
                        <>
                          <Checkbox
                            color="primary"
                            id="isSickLeave"
                            name={name}
                            onChange={(e, val) => {
                              onChange(val);
                            }}
                            checked={value || false}
                          />
                        </>
                      )}
                    />
                    <label
                      htmlFor="isSickLeave"
                      className="block text-sm text-gray-900"
                    >
                      This is a sick Leave
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="isStatutoryLeave"
                      control={control}
                      value={false}
                      render={({ field: { onChange, name, value } }) => (
                        <>
                          <Checkbox
                            color="primary"
                            id="isStatutoryLeave"
                            name={name}
                            onChange={(e, val) => {
                              onChange(val);
                            }}
                            checked={value || false}
                          />
                        </>
                      )}
                    />
                    <label
                      htmlFor="isStatutoryLeave"
                      className="block text-sm text-gray-900"
                    >
                      This is a statutory Leave
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="isGenderRestricted"
                      control={control}
                      value={false}
                      render={({ field: { onChange, name, value } }) => (
                        <>
                          <Checkbox
                            color="primary"
                            id="isGenderRestricted"
                            name={name}
                            onChange={(e, val) => {
                              onChange(val);
                            }}
                            checked={value || false}
                          />
                        </>
                      )}
                    />
                    <label
                      htmlFor="isGenderRestricted"
                      className="block text-sm text-gray-900"
                    >
                      Restrict to
                      <span className="px-2">
                        <SelectComponent
                          name="restrictedGender"
                          id="restrictedGender"
                          control={control}
                          errors={errors}
                          options={
                            genderList &&
                            genderList?.map((v) => ({
                              label: v,
                              value: v,
                            }))
                          }
                        />
                      </span>
                      Gender
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="isMaritalStatusRestricted"
                      control={control}
                      value={false}
                      render={({ field: { onChange, name, value } }) => (
                        <>
                          <Checkbox
                            color="primary"
                            id="isMaritalStatusRestricted"
                            name={name}
                            onChange={(e, val) => {
                              onChange(val);
                            }}
                            checked={value || false}
                          />
                        </>
                      )}
                    />
                    <label
                      htmlFor="isMaritalStatusRestricted"
                      className="block text-sm text-gray-900"
                    >
                      Restrict to employees having
                      <span className="px-2">
                        <SelectComponent
                          name="restrictedMaritalStatus"
                          id="restrictedMaritalStatus"
                          control={control}
                          errors={errors}
                          options={
                            maritalStatus &&
                            maritalStatus?.map((v) => ({
                              label: v,
                              value: v,
                            }))
                          }
                        />
                      </span>{" "}
                      marital status
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end p-3 whitespace-nowrap">
              <button
                onClick={() => {
                  setOpenLeaveType(false);
                  setUpdateLeaveId(null);
                  reset();
                }}
                type="button"
                className="text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-600 hover:border-gray-600 py-2 px-3"
              >
                Cancel
              </button>
              <button
                type="submit"
                id={!isDirty ? "newDisable" : ""}
                className="ml-2 text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-600 text-white hover:bg-blue-700 py-2 px-3"
              >
                {updateLeaveId ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          openLeaveType ? "createLeaveOverlay active" : "createLeaveOverlay"
        }
        onClick={() => {
          setOpenLeaveType(false);
          setUpdateLeaveId(null);
        }}
      ></div>
    </>
  );
};

export default AddLeaveType;
