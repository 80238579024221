import React, { useEffect } from "react";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
  fetchLeaveApprovers,
  fetchLeaveType,
  postLeaveAllocation,
  resetAppliedLeave,
  resetLeaveTypeBalance,
} from "../../../store/slices/leaveSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  fetchEmployeeNameList,
  fetchEmployeesUserIds,
} from "../../../store/slices/employeeSlice";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import aaplyLeaveSchema from "./applyLeaveSchema";
import { useContext } from "react";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import { getEmployeesAccess } from "../../../utils/userPermission";

const ApplyAllocationDialog = ({ open, setOpen, getData }) => {
  const { displayPopup } = useContext(PopupContext);

  const [employeeId, setEmployeeId] = useState(null);
  const [maxDays, setMaxDays] = useState(0);
  const [leaveList, setLeaveList] = useState([]);

  let employeeList = useSelector((state) => state?.employee?.employeeNameList);
  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const accessPermission = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Leave_Allocation_V1",
    accessType: "createAccess",
  });
  const employeeListData = employeeList
    ?.filter((data) => {
      return accessPermission?.[data?.EmployeeId];
    })
    ?.map((employeeData) => {
      return {
        EmployeeId: employeeData?.EmployeeId,
        employee_name: employeeData?.employee_name,
        gender: employeeData?.[`personalInfo.gender`],
      };
    });
  const dispatch = useDispatch();
  const LeaveApproveList =
    useSelector((state) => state?.leave?.userLeaveApprover?.approvers) ?? [];

  let leaveType = useSelector((state) => state?.leave?.leaveType?.types);
  let leaveData = {};
  leaveType.forEach((data) => {
    leaveData = {
      ...leaveData,
      [data.leave_type_name]: data.max_days_allowed,
    };
  });
  // useEffect(() => {
  //   let leavetypesList = LeaveType.filter(
  //     (data) =>
  //       data.leave_type_name !== "Earned Leave" &&
  //       data.leave_type_name !== "Sick Leave" &&
  //       data.leave_type_name !== "Restricted Holiday" &&
  //       data.leave_type_name !== "Leave Without Pay" &&
  //       data.leave_type_name !== "Work From Home"
  //   )?.map((v) => ({
  //     label: v?.leave_type_name,
  //     value: v?.leave_type_name,
  //     maxAllowed: v?.max_days_allowed,
  //   }));
  //   setLeaveList(leavetypesList);
  // }, []);

  useEffect(() => {
    dispatch(resetLeaveTypeBalance());
    if (employeeId) {
      dispatch(fetchLeaveApprovers({ EmployeeId: employeeId }));
    }
    dispatch(fetchEmployeesUserIds());
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
    dispatch(fetchLeaveType());
  }, [employeeId, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(resetAppliedLeave());
    };
  }, [dispatch]);

  const methods = useForm({
    defaultValues: {
      employee: null,
      leave_approver: null,
      leave_type: null,
      from_date: null,
      to_date: null,
      description: "",
    },
    resolver: yupResolver(aaplyLeaveSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = methods;
  const onSubmit = async (data) => {
    try {
      const submitData = {
        EmployeeId: data?.employee?.value,
        leave_type: data.leave_type.value,
        description: data.description,
        newLeavesAllocated: maxDays,
        from_date: data?.from_date,
        to_date: data?.to_date,
      };
      const result = await dispatch(postLeaveAllocation(submitData));

      if (result?.payload?.status) {
        setOpen(false);
        displayPopup(result.payload.message, "alert-success");
      } else displayPopup(result.payload.message, "alert-danger");
    } catch (err) {
      console.log("err:", err);
    }
    getData();
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    setMaxDays(0);
  };
  const fromDateChangeHandler = (date) => {
    setValue("from_date", dayjs(date).format("YYYY-MM-DD"));
  };
  const toDateChangeHandler = (date) => {
    setMaxDays(dayjs(watch("to_date")).diff(watch("from_date"), "days") + 1);
  };
  const LeaveTypeChangeHandler = async (newValue) => {
    setMaxDays(newValue?.maxAllowed);
  };
  const handleEmployeeChange = (e) => {
    if (e) {
      setEmployeeId(e?.value);
    }
    setValue("leave_approver", null);
    setValue("leave_type", null);
    setValue("from_date", null);
    setValue("to_date", null);
    setValue("description", "");
    setMaxDays(0);

    const updatedLeaveList = leaveType
      .filter((data) => {
        if (e?.gender === "Male") {
          return (
            data.leave_type_name !== "Earned Leave" &&
            data.leave_type_name !== "Sick Leave" &&
            data.leave_type_name !== "Maternity Leave" &&
            data.leave_type_name !== "Restricted Holiday" &&
            data.leave_type_name !== "Leave Without Pay" &&
            data.leave_type_name !== "Work From Home"
          );
        } else if (e?.gender === "Female") {
          return (
            data.leave_type_name !== "Earned Leave" &&
            data.leave_type_name !== "Sick Leave" &&
            data.leave_type_name !== "Restricted Holiday" &&
            data.leave_type_name !== "Paternity Leave" &&
            data.leave_type_name !== "Leave Without Pay" &&
            data.leave_type_name !== "Work From Home"
          );
        }
        return false;
      })
      .map((v) => ({
        label: v.leave_type_name,
        value: v.leave_type_name,
        maxAllowed: v.max_days_allowed,
      }));
    setLeaveList(updatedLeaveList);
  };
  return (
    <Dialog
      open={open}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <DialogTitle className="add_employee_main_heading">
        <span>New Allocation</span>
      </DialogTitle>
      <DialogContent className="card-content-employee-form">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Employee <span className="create_form_asterisk">*</span>:
                </label>
                {employeeList?.length > 1 ? (
                  <AutoCompleteComponent
                    name="employee"
                    id="employee"
                    control={control}
                    errors={errors}
                    options={employeeListData?.map((v) => ({
                      label: `${v?.EmployeeId} - ${v?.employee_name}`,
                      value: v?.EmployeeId,
                      gender: v?.gender,
                    }))}
                    onChangeHandler={handleEmployeeChange}
                  />
                ) : null}
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Leave Type <span className="create_form_asterisk">*</span>:
                </label>
                <AutoCompleteComponent
                  name="leave_type"
                  control={control}
                  errors={errors}
                  disabled={!watch("employee")}
                  options={leaveList}
                  onChangeHandler={LeaveTypeChangeHandler}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Leave Approver <span className="create_form_asterisk">*</span>
                  :
                </label>
                <AutoCompleteComponent
                  name="leave_approver"
                  control={control}
                  errors={errors}
                  disabled={!watch("employee")}
                  options={LeaveApproveList?.map((v) => ({
                    label: v.approverName,
                    value: v.approverEmployeeId,
                    userId: v.approver_userId,
                  }))}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="text-sm">
                  Employee Comment{" "}
                  <span className="create_form_asterisk">*</span>:
                </label>
                <TextAreaComponent
                  name="description"
                  control={control}
                  errors={errors}
                  rows="2"
                />
              </div>
              {watch("leave_type")?.value === "Compensatory Off" && (
                <>
                  {" "}
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">
                      From Date <span className="create_form_asterisk">*</span>:
                    </label>
                    <DatePickerComponent
                      errors={errors}
                      control={control}
                      name="from_date"
                      id="from_date"
                      placeHolder="YYYY-MM-DD"
                      disableMaskedInput={true}
                      onHandleChange={fromDateChangeHandler}
                      maxDate={new Date()}
                      backSpaceChange={false}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">
                      To Date <span className="create_form_asterisk">*</span>:
                    </label>
                    <DatePickerComponent
                      errors={errors}
                      control={control}
                      name="to_date"
                      id="to_date"
                      placeHolder="YYYY-MM-DD"
                      disableMaskedInput={true}
                      minDate={watch("from_date")}
                      maxDate={new Date()}
                      backSpaceChange={false}
                      onHandleChange={toDateChangeHandler}
                    />
                  </div>
                </>
              )}
              <div className="col-md-12 mb-3">
                <span className="col-7 text-end">
                  Leave To Allocate :{" "}
                  <b>
                    {" "}
                    {maxDays ? maxDays : 0} {maxDays <= 1 ? "day" : "days"}
                  </b>
                </span>
              </div>
            </div>
            <DialogActions className="employee_form_button_div">
              <button
                type="button"
                onClick={handleClose}
                className="btn cancelBtnOutline"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                id={!isDirty ? "newDisable" : ""}
                className="btn primaryBtnOutline"
              >
                Save
              </button>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ApplyAllocationDialog;
