import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "../../../Components/EmployeeNew/EmployeeForm/EmployeeFormDialog.css";
import * as yup from "yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectComponent from "../../MasterComponent/SelectComponent";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import { Stack, Typography } from "@mui/material";
import {
  createEmployeeReimbursement,
  fetchAllReimbursementTypes,
  fetchEmployeeList,
} from "../../../store/slices/payrollSlice";
import Loader from "../../Loader/Loader";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import dayjs from "dayjs";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import { toast } from "react-toastify";

export const ReimbursementSchema = yup.object().shape({
  employee_id: yup.object().label("Employee Name").required(),
  reimbursement_type_id: yup.number().label("Reimbursemnt Type").required(),
  reimbursement_amount: yup
    .number()
    .label("Reimbursemnt Amount")
    .required()
    .nullable(true)
    .transform((_, val) => (val == Number(val) ? Number(val) : null))
    .min(0, "Amount can't be less than zero"),
  payment_date: yup.string().label("Payment Date").required().nullable(true),
  comment: yup.string().label("Comment").optional().nullable(true),
  approved_amount: yup
    .number()
    .nullable(true)
    .label("Approved Amount")
    .optional()
    .transform((_, val) => (val == Number(val) ? Number(val) : null))
    .min(0, "Amount can't be less than zero"),
  is_approved: yup.boolean().label("Approved").optional().nullable(true),
  approval_date: yup.string().label("Approval Date").optional().nullable(true),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CreateReimbursement = ({
  openCreate,
  setOpenCreate,
  setToggelDataUpdate,
}) => {
  const dispatch = useDispatch();
  const currentDate = dayjs(new Date()).format("YYYY-MM-DD");
  React.useEffect(() => {
    dispatch(fetchAllReimbursementTypes());
    dispatch(fetchEmployeeList({ module: "PAYROLL_MODULE" }));
  }, [dispatch]);

  let payrollState = useSelector((state) => state?.payRoll ?? {});

  const reimbursementTypes =
    payrollState?.reimbursementTypes?.reimbursementData?.rows ?? [];
  let employeeList = payrollState?.employeeListData?.allEmployeeData ?? [];

  const methods = useForm({
    resolver: yupResolver(ReimbursementSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    trigger,
    setValue,
    watch,
  } = methods;
  const loading = useSelector((state) => state.payRoll.isFetching);

  const submitHandler = async (data) => {
    try {
      if (data?.reimbursement_amount < 0) {
        toast.error("Reimbursement Amount Cannot be Negative");
        return;
      }
      const submitData = {
        ...data,
      };
      submitData.employee_id = submitData.employee_id.value;

      await dispatch(
        createEmployeeReimbursement({
          data: submitData,
        })
      );

      setOpenCreate(false);
      setToggelDataUpdate((prev) => !prev);
      reset();
    } catch (err) {
      setOpenCreate(false);
      reset();
    }
  };

  const handleClose = () => {
    setOpenCreate(false);
    reset();
  };

  return (
    <Dialog
      open={openCreate}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <DialogTitle className="add_employee_main_heading">
            <span>Create Reimbursement </span>
          </DialogTitle>
          {loading ? (
            <Loader />
          ) : (
            <>
              <DialogContent className="card-content-employee-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="create_form_input_div">
                      <label
                        htmlFor="employee_id"
                        className="create_form_labels"
                      >
                        Employee Name
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <AutoCompleteComponent
                        name="employee_id"
                        id="employee_id"
                        handleChange={() => trigger("employee_id")}
                        control={control}
                        errors={errors}
                        options={employeeList?.map((v) => ({
                          label: `${v.employee_name} (${v.EmployeeId})`,
                          value: v.EmployeeId,
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="create_form_input_div">
                      <label
                        htmlFor="reimbursement_type_id"
                        className="create_form_labels"
                      >
                        Reimbursement Type
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <SelectComponent
                        name="reimbursement_type_id"
                        id="reimbursement_type_id"
                        handleChange={() => trigger("reimbursement_type_id")}
                        control={control}
                        errors={errors}
                        options={reimbursementTypes?.map((v) => ({
                          label: v.type_name,
                          value: v.reimbursement_type_id,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="create_form_input_div">
                      <label
                        htmlFor="reimbursement_amount"
                        className="create_form_labels"
                      >
                        Reimbursement Amount
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <TextFieldComponent
                        name="reimbursement_amount"
                        id="reimbursement_amount"
                        control={control}
                        errors={errors}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="create_form_input_div">
                      <label
                        htmlFor="payment_date"
                        className="create_form_labels"
                      >
                        Payment Date
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <DatePickerComponent
                        name="payment_date"
                        errors={errors}
                        control={control}
                        onHandleChange={() => trigger("payment_date")}
                        inputFormat="DD-MMM-YYYY"
                        disableMaskedInput={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label
                      htmlFor="unpaid"
                      className="create_form_labels"
                    ></label>
                    <Controller
                      name="is_approved"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>Pending</Typography>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              {...field}
                              checked={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                                trigger("is_approved");
                                setValue("approval_date", currentDate);
                              }}
                              name="is_approved"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                          <Typography>Approved</Typography>
                        </Stack>
                      )}
                    />
                  </div>
                  {watch("is_approved") ? (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="create_form_input_div">
                          <label
                            htmlFor="approved_amount"
                            className="create_form_labels"
                          >
                            Approved Amount:
                          </label>
                          <TextFieldComponent
                            name="approved_amount"
                            id="approved_amount"
                            control={control}
                            errors={errors}
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="create_form_input_div">
                          <label
                            htmlFor="approval_date"
                            className="create_form_labels"
                          >
                            Approved On:
                          </label>
                          <DatePickerComponent
                            name="approval_date"
                            errors={errors}
                            control={control}
                            onHandleChange={() => trigger("approval_date")}
                            inputFormat="DD-MMM-YYYY"
                            disableMaskedInput={true}
                            maxDate={currentDate}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label
                      htmlFor="unpaid"
                      className="create_form_labels"
                    ></label>
                    <Controller
                      name="is_paid"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>Pending</Typography>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              {...field}
                              checked={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                                trigger("is_paid");
                                setValue("paid_on", currentDate);
                              }}
                              name="is_paid"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                          <Typography>Paid</Typography>
                        </Stack>
                      )}
                    />
                  </div>
                  {watch("is_paid") ? (
                    <div className="col-md-6">
                      <div className="create_form_input_div">
                        <label htmlFor="paid_on" className="create_form_labels">
                          Paid On:
                        </label>
                        <DatePickerComponent
                          name="paid_on"
                          errors={errors}
                          control={control}
                          onHandleChange={() => trigger("paid_on")}
                          inputFormat="DD-MMM-YYYY"
                          disableMaskedInput={true}
                          maxDate={currentDate}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="comment" className="create_form_labels">
                      Comment:
                    </label>
                    <TextAreaComponent
                      name="comment"
                      handleChange={() => trigger("comment")}
                      control={control}
                      errors={errors}
                      rows={3}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions className="employee_form_button_div">
                <button onClick={handleClose} className="cancel_button">
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!isDirty}
                  className="save_button"
                  id={!isDirty ? "newDisable" : ""}
                >
                  Save
                </button>
              </DialogActions>
            </>
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default CreateReimbursement;
