import React from "react";
import { getLoggedUserData } from "../../../utils/helper";
import "./WelcomeName.css";
import { useLocation } from "react-router-dom";
import { getHeaderTitle } from "./config";
import { useEffect } from "react";
import { useState } from "react";

const WelcomeName = (props) => {
  const [path, setPath] = useState();
  const loggedEmployee = getLoggedUserData();
  const location = useLocation();
  const employeeName = loggedEmployee?.employeeName ?? null;
  function digitsBeGone(str) {
    return str.match(/\D/g)?.join("");
  }
  const url = location.pathname;
  const responseURL = getHeaderTitle(path, location, employeeName);
  useEffect(() => {
    const str = url.replace("/", "");
    setPath(digitsBeGone(str));
  }, [url]);
  return <div className="WelcomeName">{responseURL ? responseURL : ""}</div>;
};
export default WelcomeName;
