import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchPendingEmployeeOT } from "../../../store/slices/payrollSlice";
import MUIDataTable from "mui-datatables";
import Loader from "../../Loader/Loader";
import SearchBox from "../../TopHeader/SearchBox/SearchBox";
import DownloadReport from "../DownloadReport";
import http from "../../../utils/http";
import fileDownload from "js-file-download";
import moment from "moment";
import END_POINTS from "../../../constants/endpoints";
import NewOvertimeDialog from "./NewOvertime";
import { fetchEmployeeNameList } from "../../../store/slices/employeeSlice";
import { faPen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateOverTimeDialog from "./UpdateOverTimeDialog";

const PendingOverTimeReport = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(15);
  const [sortBy, setsortBy] = useState("employee_name");
  const [order, setorder] = useState("ASC");
  const [search, setSearch] = useState("");
  const [downloading, setDownloading] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  const pendingOTData = useSelector(
    (state) => state?.payRoll?.pendingOTData ?? []
    );

    const pendingOTList = pendingOTData?.data;

  React.useEffect(() => {
    dispatch(fetchEmployeeNameList({ module: "EMPLOYEE_MODULE" }));
  }, [dispatch]);
  const employeeList = useSelector(
    (state) => state?.employee?.employeeNameList
  );
  let EmployeeList = [];
  employeeList &&
    employeeList?.map((i) => {
      return EmployeeList?.push({
        label: `${i?.EmployeeId} - ${i?.employee_name}`,
        value: i?.EmployeeId,
      });
    });

    const loading = pendingOTData?.isFetching;

  const attendanceMonth = moment(endDate).startOf("month").format("YYYY-MM-DD");

  const params = {
    payrollMonth: attendanceMonth,
    limit: limit,
    page: page + 1,
    sortBy: sortBy,
    order: order,
    search: search,
  };
  useEffect(() => {
    dispatch(fetchPendingEmployeeOT(params));
  }, [dispatch, startDate, page, limit, search, sortBy, order]);
  const handleEdit = (rowData) => {
    setUpdateData(rowData);
    setOpenEditDialog(true);
  };
  const columns = [
    {
      label: "Id",
      name: "employee_id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Name",
      name: "employee_name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value?.employee_name ?? "-";
        },
      },
    },
    {
      label: "OT Type",
      name: "ot_type",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Edit",
      name: "employee_id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="attHistoryEditBtn cursor-pointer">
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={() => {
                    handleEdit(pendingOTList[tableMeta.rowIndex]);
                  }}
                />
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    selectToolbarPlacement: "none",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    // count: totalRecord ?? 0,
    rowsPerPage: limit,
    page: page,
    search: false,
    download: false,
    print: false,
    serverSide: true,
    viewColumns: false,
    onChangePage: (currentPage) => {
      setpage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setlimit(numberOfRows);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setsortBy(changedColumn);
      setorder(direction.toUpperCase());
    },
    expandableRows: false,
  };

  const handleDownloadReport = async (e) => {
    try {
      setDownloading(true);

      const response = await http({
        url: `${END_POINTS.FETCH_OT_DATA_PAYROLL}=${attendanceMonth}`,
        method: "GET",
        responseType: "blob",
      });

      fileDownload(
        response.data,
        `overtime_report_${moment(attendanceMonth).format("MMM-YYYY")}.xlsx`
      );
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };
  // const handleDialog = () => {
  //   setOpenDialog(true);
  // };

  return (
    <>
      {(loading || isLoading) && <Loader />}

      <div className="payrollContentDiv">
        <div className="payrollActionsDiv">
          {/* <button
            className="py-1 px-3 border-1 border-fuchsia-900 text-fuchsia-900  rounded-2xl hover:border-white hover:text-white hover:bg-fuchsia-900"
            onClick={handleDialog}
          >
            Add New OverTime
          </button> */}

          <div className="compAttSearchBtn">
            <SearchBox setSearch={setSearch} />
          </div>
          <DownloadReport
            handleExcelDownload={handleDownloadReport}
            loading={downloading}
          />
        </div>
        <div className="container-fluid">
          <div className="payrollTableResponsive">
            <div className="payrollTableConteiner">
              <MUIDataTable
                data={pendingOTList}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {openDialog && (
        <NewOvertimeDialog
          employeeList={employeeList}
          setOpen={setOpenDialog}
          open={openDialog}
          updateData={updateData}
        />
      )} */}
      {openEditDialog && (
        <UpdateOverTimeDialog
          employeeList={employeeList}
          setOpen={setOpenEditDialog}
          open={openEditDialog}
          updateData={updateData}
          payrollMonth={endDate}
          params={params}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default PendingOverTimeReport;
