import axios from "axios";
import { APP_API_URL } from "../constants";
import { getAccessToken, getRefreshToken } from "./token";
import RefreshServices from "../Components/Services/RefreshServices";
import store from "../store";
import { userLogout } from "../store/slices/authSlice";

const http = axios.create({
  baseURL: `${APP_API_URL}/api`,
});
http.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (config) => config,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && prevRequest) {
      const newAccessToken = await RefreshServices();
      const isRefreshTokenNotValid = !newAccessToken?.error?.response?.data
        ?.isValidRefreshToken
        ? !newAccessToken?.error?.response?.data?.isValidRefreshToken
        : false;
      if (!newAccessToken.status) {
        if (isRefreshTokenNotValid) {
          const postToken = await getRefreshToken();
          postToken && alert("Session Expired! Kindly Login Again");
          if (postToken) {
            window.location.href = "/";
          }
          store.dispatch(userLogout());
          const currentPath = window.location.pathname;
          const protectedPaths = [
            "/",
            "/login",
            "/Home",
            "/unauthorized",
            "/Calendar",
          ];

          if (!protectedPaths.includes(currentPath)) {
            localStorage.setItem("lastRoute", currentPath);
          }
          return Promise.reject(error);
        }
        return;
      }
      prevRequest.headers["Authorization"] = `Bearer ${newAccessToken.token}`;
      return http(prevRequest);
    }
    return Promise.reject(error);
  }
);

export default http;

