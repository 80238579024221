import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  holdDeduction,
  releaseDeduction,
} from "../../../store/slices/payrollSlice";
import { useDispatch } from "react-redux";
import { isEmpty, isNil } from "ramda";
import Slide from "@mui/material/Slide";
import "../../../Components/EmployeeNew/EmployeeForm/EmployeeFormDialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DeductionConfirmationPopup = ({
  type,
  selectedRow = [],
  open,
  setOpen,
  setToggelDataUpdate,
  toggelDataUpdate,
}) => {
  const dispatch = useDispatch();
  const [replyValue, setReplyValue] = useState(null);
  const [disable, setDisable] = useState(false);

  const confirmButtonHandler = async () => {
    if (type === "hold" && (isNil(replyValue) || isEmpty(replyValue))) {
      setDisable(true);
      return;
    } else {
      setDisable(false);
    }
    let result;
    if (type === "hold") {
      const row = selectedRow.map((v) => {
        return { ...v, comment: replyValue };
      });
      result = await dispatch(
        holdDeduction({
          miscDeductionList: row,
        })
      );
    }
    if (type === "release") {
      result = await dispatch(
        releaseDeduction({ miscDeductionList: selectedRow })
      );
    }
    if (result?.payload?.status) {
      setToggelDataUpdate(!toggelDataUpdate);
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <DialogTitle className="add_employee_main_heading">
        <span>
          {" "}
          {type === "release" ? "Release" : "Hold"} Miscellaneous Deduction
        </span>
      </DialogTitle>

      <DialogContent className="card-content-employee-form">
        <DialogContentText>
          {selectedRow?.length === 1 &&
            type === "hold" &&
            `Please enter the reason for ${type} and submit.`}
          {selectedRow?.length > 1 &&
            type === "hold" &&
            `Please enter the reason for ${type} and submit`}
          {type === "hold" && (
            <div className="replyMsg">
              <br />
              <TextField
                type="text"
                className="reasonInput"
                name="msg"
                placeholder="Enter the reason for misc deduction hold"
                value={replyValue}
                onChange={(e) => setReplyValue(e.target.value)}
              />
              {disable ? (
                <span className="errorMessagePopUp">Reason is required</span>
              ) : (
                ""
              )}
            </div>
          )}
          {selectedRow?.length === 1 &&
            type === "release" &&
            `Are you sure you want to ${type} ?`}
          {selectedRow?.length > 1 &&
            type === "release" &&
            `Are you sure you want to ${type} ?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="employee_form_button_div">
        <button
          type="button"
          onClick={() => {
            setOpen(false);
          }}
          className="cancel_button"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={confirmButtonHandler}
          className="save_button"
        >
          {type === "release" ? "Yes" : "Submit"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DeductionConfirmationPopup;
