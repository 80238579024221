import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

export const fetchAllEarningTypes = createAsyncThunk(
  END_POINTS.MASTER_EARNING_TYPE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.MASTER_EARNING_TYPE, {
        params,
      });

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllDeductionTypes = createAsyncThunk(
  END_POINTS.MASTER_DEDUCTION_TYPE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.MASTER_DEDUCTION_TYPE, {
        params,
      });

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const postEarningType = createAsyncThunk(
  END_POINTS.CREATE_EARNING,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.CREATE_EARNING}`, {
        ...params.createData,
      });
      params.setIsLoading(false);
      if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      params.setIsLoading(false);
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const patchEarningType = createAsyncThunk(
  END_POINTS.UPDATE_EARNING,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_EARNING}/${params.earningTypeId}`,
        params.updateData
      );
      params.setIsLoading(false);

      if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      params.setIsLoading(false);
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const postDeductionType = createAsyncThunk(
  END_POINTS.CREATE_DEDUCTION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.CREATE_DEDUCTION}`, {
        ...params.createData,
      });
      params.setIsLoading(false);
      if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      params.setIsLoading(false);
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const patchDeductionType = createAsyncThunk(
  END_POINTS.UPDATE_DEDUCTION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_DEDUCTION}/${params.deductionTypeId}`,
        params.updateData
      );
      params.setIsLoading(false);
      if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      params.setIsLoading(false);
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllBrackets = createAsyncThunk(
  END_POINTS.MASTER_BRACKETS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.MASTER_BRACKETS, {
        params,
      });

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const patchSalaryBracket = createAsyncThunk(
  END_POINTS.UPDATE_BRACKET,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_BRACKET}`,
        params.updateData
      );
      params.setIsLoading(false);
      params.setToggelDataUpdate((prev) => !prev);
      if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      params.setIsLoading(false);
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchBracketData = createAsyncThunk(
  END_POINTS.SALARY_BRACKETS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.SALARY_BRACKETS}/${params.BracketId}`,
        {
          params,
        }
      );

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const SalaryBracketCalcData = createAsyncThunk(
  END_POINTS.SALARY_CALC,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.SALARY_CALC}`, {
        params,
      });
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteSalaryBracket = createAsyncThunk(
  END_POINTS.DELETE_BRACKET,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.DELETE_BRACKET}/${params.salaryBracketId}`,
        params.updateData
      );
      params.setIsLoading(false);
      params.setToggelDataUpdate((prev) => !prev);
      if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      params.setIsLoading(false);
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmpSalaryStruture = createAsyncThunk(
  END_POINTS.EMP_SALARY_STRUCTURE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMP_SALARY_STRUCTURE}/${params.salaryStructureId}`
      );

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmpSalarySlip = createAsyncThunk(
  END_POINTS.SALARY_SLIP,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.SALARY_SLIP}/${params.employeeId}`,
        { params: { monthYear: params.salaryMonth } }
      );
      if (!data?.status) {
        toast.error(data?.message);
        return null;
      } else {
        return data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error?.response?.data : error);
    }
  }
);

export const fetchEmpSalarySlipBySlipId = createAsyncThunk(
  END_POINTS.SALARY_SLIP_FETCH,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.SALARY_SLIP_FETCH}/${params.salarySlipId}`
      );
      if (!data?.status) {
        toast.error(data?.message);
        return null;
      } else {
        return data.data;
      }
    } catch (error) {
      if (error?.response?.statusText === "Unauthorized") {
        setTimeout(() => Navigate("/Dashboard"), 0);
        toast.error("Unauthorized User");
      }

      if (error?.response?.statusText !== "Unauthorized") {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmpSalaryStrutureHistory = createAsyncThunk(
  END_POINTS.EMP_SALARY_STRUCTURE_HISTORY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMP_SALARY_STRUCTURE_HISTORY}/${params.employeeId}?status=Inactive`
      );

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllVariablePayTypes = createAsyncThunk(
  END_POINTS.MASTER_VARIABLE_TYPE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.MASTER_VARIABLE_TYPE, {
        params,
      });

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchArrearsData = createAsyncThunk(
  END_POINTS.ARREARS_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.ARREARS_DATA}/${params.employeeId}?newStructureStartDate=${params.newStructureStartDate}&updatedNetPay=${params.netPay}`
      );

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmpFinance = createAsyncThunk(
  END_POINTS.EMPLOYEE_FINANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_FINANCE}/${params.employeeId}`
      );

      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const patchVariableType = createAsyncThunk(
  END_POINTS.UPDATE_VARIABLE_PAY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_VARIABLE_PAY}/${params.variablePayTypeId}`,
        params.updateData
      );
      params.setIsLoading(false);

      if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      params.setIsLoading(false);
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchSalaryMonthDropDown = createAsyncThunk(
  END_POINTS.GET_MONTH_WISE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MONTH_WISE}?fiscal_year=${params.fiscal_year}`
      );
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchSalaryByToken = createAsyncThunk(
  END_POINTS.GET_MONTH_WISE_SALARY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MONTH_WISE_SALARY}?fiscal_year=${params?.fiscal_year}&month=${params?.month}`
      );
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchAllSalaryByToken = createAsyncThunk(
  END_POINTS.GET_YEAR_WISE_SALARY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_YEAR_WISE_SALARY}?fiscal_year=${params?.fiscal_year}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchSalarySlipBreakUp = createAsyncThunk(
  END_POINTS.SALARY_SLIP_BREAK_UP,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.SALARY_SLIP_BREAK_UP}/${params.salarySlipId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  earningTypes: { data: [], loading: false, error: null, success: null },
  deductionTypes: { data: [], loading: false, error: null, success: null },
  variableTypes: { data: [], loading: false, error: null, success: null },
  salaryBrackets: { data: [], loading: false, error: null, success: null },
  bracketData: { data: [], loading: false, error: null, success: null },
  selectedSalaryCalc: { loading: false, data: [] },
  empStructureHistoryData: {
    data: [],
    loading: false,
    error: null,
    success: null,
  },
  loading: false,
  error: null,
  success: false,
  empSalaryStructure: { data: {}, loading: false },
  salarySlip: { loading: false, data: {} },
  arrearsData: { data: {}, loading: false, error: null, success: null },
  monthlyData: { isFetching: false, response: null },
  salaryByToken: { isFetching: false, response: null },
  AllsalaryByToken: { isFetching: false, response: null },
};

export const salarySlice = createSlice({
  name: "salary",
  initialState,
  reducers: {
    resetSalaryBracketCalc(state) {
      return {
        ...state,
        selectedSalaryCalc: { ...state.selectedSalaryCalc, data: [] },
      };
    },
    resetEmpSalaryStruture(state) {
      return {
        ...state,
        empSalaryStructure: { ...state.empSalaryStructure, data: {} },
      };
    },
    resetSalarySlip(state) {
      return { ...state, salarySlip: { ...state.salarySlip, data: {} } };
    },
    resetArrearsData(state) {
      return { ...state, arrearsData: { ...state.arrearsData, data: {} } };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEarningTypes.pending, (state) => {
        state.earningTypes.loading = true;
        state.earningTypes.error = null;
        state.loading = true;
      })
      .addCase(fetchAllEarningTypes.fulfilled, (state, { payload }) => {
        state.earningTypes.loading = false;
        state.earningTypes.success = true;
        state.earningTypes.data = payload;
        state.earningTypes.error = null;
        state.loading = false;
      })
      .addCase(fetchAllEarningTypes.rejected, (state, { payload }) => {
        state.earningTypes.loading = false;
        state.earningTypes.error = payload;
        state.earningTypes.data = [];
        state.earningTypes.success = false;
        state.loading = false;
      })
      .addCase(fetchAllDeductionTypes.pending, (state) => {
        state.deductionTypes.loading = true;
        state.deductionTypes.error = null;
        state.loading = true;
      })
      .addCase(fetchAllDeductionTypes.fulfilled, (state, { payload }) => {
        state.deductionTypes.loading = false;
        state.deductionTypes.success = true;
        state.deductionTypes.data = payload;
        state.deductionTypes.error = null;
        state.loading = false;
      })
      .addCase(fetchAllDeductionTypes.rejected, (state, { payload }) => {
        state.deductionTypes.loading = false;
        state.deductionTypes.error = payload;
        state.deductionTypes.data = [];
        state.deductionTypes.success = false;
        state.loading = false;
      })
      .addCase(fetchAllBrackets.pending, (state) => {
        state.salaryBrackets.loading = true;
        state.salaryBrackets.error = null;
        state.loading = true;
      })
      .addCase(fetchAllBrackets.fulfilled, (state, { payload }) => {
        state.salaryBrackets.loading = false;
        state.salaryBrackets.success = true;
        state.salaryBrackets.data = payload;
        state.salaryBrackets.error = null;
        state.loading = false;
      })
      .addCase(fetchAllBrackets.rejected, (state, { payload }) => {
        state.salaryBrackets.loading = false;
        state.salaryBrackets.error = payload;
        state.salaryBrackets.data = [];
        state.salaryBrackets.success = false;
        state.loading = false;
      })
      .addCase(fetchBracketData.pending, (state) => {
        state.bracketData.loading = true;
        state.bracketData.error = null;
        state.loading = true;
      })
      .addCase(fetchBracketData.fulfilled, (state, { payload }) => {
        state.bracketData.loading = false;
        state.bracketData.success = true;
        state.bracketData.data = payload;
        state.bracketData.error = null;
        state.loading = false;
      })
      .addCase(fetchBracketData.rejected, (state, { payload }) => {
        state.bracketData.loading = false;
        state.bracketData.error = payload;
        state.bracketData.data = [];
        state.bracketData.success = false;
        state.loading = false;
      })

      // Salary Calc
      .addCase(SalaryBracketCalcData.pending, (state) => {
        state.selectedSalaryCalc.loading = true;
      })
      .addCase(SalaryBracketCalcData.fulfilled, (state, { payload }) => {
        state.selectedSalaryCalc.loading = false;
        state.selectedSalaryCalc.data = payload;
      })
      .addCase(SalaryBracketCalcData.rejected, (state, { payload }) => {
        state.selectedSalaryCalc.loading = false;
        state.selectedSalaryCalc.data = [];
      })

      // fetch Salary Structure Emp
      .addCase(fetchEmpSalaryStruture.pending, (state) => {
        state.empSalaryStructure.loading = true;
      })
      .addCase(fetchEmpSalaryStruture.fulfilled, (state, { payload }) => {
        state.empSalaryStructure.loading = false;
        state.empSalaryStructure.data = payload;
      })
      .addCase(fetchEmpSalaryStruture.rejected, (state, { payload }) => {
        state.empSalaryStructure.loading = false;
        state.empSalaryStructure.data = {};
      })

      // fetch Salary Slip Emp
      .addCase(fetchEmpSalarySlip.pending, (state) => {
        state.salarySlip.loading = true;
      })
      .addCase(fetchEmpSalarySlip.fulfilled, (state, { payload }) => {
        state.salarySlip.data = payload?.data;
        state.salarySlip.loading = false;
      })
      .addCase(fetchEmpSalarySlip.rejected, (state, { payload }) => {
        state.salarySlip.loading = false;
        state.salarySlip.data = {};
      })

      // fetch Salary Slip Emp by slipId
      .addCase(fetchEmpSalarySlipBySlipId.pending, (state) => {
        state.salarySlip.loading = true;
      })
      .addCase(fetchEmpSalarySlipBySlipId.fulfilled, (state, { payload }) => {
        state.salarySlip.data = payload;
        state.salarySlip.loading = false;
      })
      .addCase(fetchEmpSalarySlipBySlipId.rejected, (state, { payload }) => {
        state.salarySlip.loading = false;
        state.salarySlip.data = {};
      })

      // fetch Salary Slip Emp by slipId
      .addCase(fetchEmpSalaryStrutureHistory.pending, (state) => {
        state.empStructureHistoryData.loading = true;
      })
      .addCase(
        fetchEmpSalaryStrutureHistory.fulfilled,
        (state, { payload }) => {
          state.empStructureHistoryData.data = payload;
          state.empStructureHistoryData.loading = false;
        }
      )
      .addCase(fetchEmpSalaryStrutureHistory.rejected, (state, { payload }) => {
        state.empStructureHistoryData.loading = false;
        state.empStructureHistoryData.data = {};
      })
      .addCase(fetchAllVariablePayTypes.pending, (state) => {
        state.variableTypes.loading = true;
        state.variableTypes.error = null;
        state.loading = true;
      })
      .addCase(fetchAllVariablePayTypes.fulfilled, (state, { payload }) => {
        state.variableTypes.loading = false;
        state.variableTypes.success = true;
        state.variableTypes.data = payload;
        state.variableTypes.error = null;
        state.loading = false;
      })
      .addCase(fetchAllVariablePayTypes.rejected, (state, { payload }) => {
        state.variableTypes.loading = false;
        state.variableTypes.error = payload;
        state.variableTypes.data = [];
        state.variableTypes.success = false;
        state.loading = false;
      })
      .addCase(fetchArrearsData.pending, (state) => {
        state.arrearsData.loading = true;
        state.arrearsData.error = null;
        state.loading = true;
      })
      .addCase(fetchArrearsData.fulfilled, (state, { payload }) => {
        state.arrearsData.loading = false;
        state.arrearsData.success = true;
        state.arrearsData.data = payload;
        state.arrearsData.error = null;
        state.loading = false;
      })
      .addCase(fetchArrearsData.rejected, (state, { payload }) => {
        state.arrearsData.loading = false;
        state.arrearsData.error = payload;
        state.arrearsData.data = [];
        state.arrearsData.success = false;
        state.loading = false;
      })
      .addCase(fetchEmpFinance.pending, (state) => {
        state.empSalaryStructure.loading = true;
      })
      .addCase(fetchEmpFinance.fulfilled, (state, { payload }) => {
        state.empSalaryStructure.data = payload;
        state.empSalaryStructure.loading = false;
      })
      .addCase(fetchEmpFinance.rejected, (state, { payload }) => {
        state.empSalaryStructure.loading = false;
        state.empSalaryStructure.data = {};
      })
      .addCase(fetchSalaryMonthDropDown.pending, (state) => {
        state.monthlyData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchSalaryMonthDropDown.fulfilled, (state, { payload }) => {
        state.monthlyData = {
          isFetching: false,
          response: payload?.data,
        };
      })
      .addCase(fetchSalaryMonthDropDown.rejected, (state, { payload }) => {
        state.monthlyData = {
          isFetching: false,
          response: null,
        };
      })
      .addCase(fetchSalaryByToken.pending, (state) => {
        state.salaryByToken = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchSalaryByToken.fulfilled, (state, { payload }) => {
        state.salaryByToken = {
          isFetching: false,
          response: payload?.data,
        };
      })
      .addCase(fetchSalaryByToken.rejected, (state, { payload }) => {
        state.salaryByToken = {
          isFetching: false,
          response: null,
        };
      })

      .addCase(fetchAllSalaryByToken.pending, (state) => {
        state.AllsalaryByToken = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchAllSalaryByToken.fulfilled, (state, { payload }) => {
        state.AllsalaryByToken = {
          isFetching: false,
          response: payload?.data,
        };
      })
      .addCase(fetchAllSalaryByToken.rejected, (state, { payload }) => {
        state.AllsalaryByToken = {
          isFetching: false,
          response: null,
        };
      });
  },
});

export const {
  resetSalaryBracketCalc,
  resetEmpSalaryStruture,
  resetSalarySlip,
} = salarySlice.actions;

export default salarySlice.reducer;
