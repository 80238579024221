import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import LeaveQuota from "./LeaveConfigSetup/LeaveQuota";
import LeaveAccrual from "./LeaveConfigSetup/LeaveAccrual";
import ApplyingForLeave from "./LeaveConfigSetup/ApplyingForLeave";
import LeaveRestrictions from "./LeaveConfigSetup/LeaveRestrictions";

const UpdateLeavePlanConfig = ({
  isUpdateOpen,
  setIsUpdateOpen,
  updateConfigData,
  setUpdateConfigData,
}) => {
  const [active, setActive] = useState("1");

  return (
    <div className="bg-indigo-50 py-4 px-3">
      <div className="bg-white flex items-center gap-3 rounded-md">
        <div
          onClick={() => {
            setIsUpdateOpen(false);
            setUpdateConfigData("");
          }}
          className="cursor-pointer rounded-s-md text-blue-500 hover:bg-blue-500 hover:text-blue-50 py-2 px-3 border-r border-gray-200"
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </div>
        <ul className="flex items-center uppercase text-sm">
          <li
            id="1"
            onClick={(e) => setActive(e?.target?.id)}
            className={
              String(active) === "1"
                ? "py-2 px-3 text-blue-500 hover:text-blue-500 border-b border-blue-500 hover:border-b hover:border-blue-500 cursor-pointer"
                : "py-2 px-3 hover:text-blue-500 border-b border-transparent cursor-pointer"
            }
          >
            Leave Quota
          </li>
          <li
            id="2"
            onClick={(e) => setActive(e?.target?.id)}
            className={
              String(active) === "2"
                ? "py-2 px-3 text-blue-500 hover:text-blue-500 border-b border-blue-500 hover:border-b hover:border-blue-500 cursor-pointer"
                : "py-2 px-3 hover:text-blue-500 border-b border-transparent cursor-pointer"
            }
          >
            Leave Accrual
          </li>
          <li
            id="3"
            onClick={(e) => setActive(e?.target?.id)}
            className={
              String(active) === "3"
                ? "py-2 px-3 text-blue-500 hover:text-blue-500 border-b border-blue-500 hover:border-b hover:border-blue-500 cursor-pointer"
                : "py-2 px-3 hover:text-blue-500 border-b border-transparent cursor-pointer"
            }
          >
            Applying For Leave
          </li>
          <li
            id="4"
            onClick={(e) => setActive(e?.target?.id)}
            className={
              String(active) === "4"
                ? "py-2 px-3 text-blue-500 hover:text-blue-500 border-b border-blue-500 hover:border-b hover:border-blue-500 cursor-pointer"
                : "py-2 px-3 hover:text-blue-500 border-b border-transparent cursor-pointer"
            }
          >
            Leave Restrictions
          </li>
          <li
            id="5"
            onClick={(e) => setActive(e?.target?.id)}
            className={
              String(active) === "5"
                ? "py-2 px-3 text-blue-500 hover:text-blue-500 border-b border-blue-500 hover:border-b hover:border-blue-500 cursor-pointer"
                : "py-2 px-3 hover:text-blue-500 border-b border-transparent cursor-pointer"
            }
          >
            Holiday And Weekoffs
          </li>
          <li
            id="6"
            onClick={(e) => setActive(e?.target?.id)}
            className={
              String(active) === "6"
                ? "py-2 px-3 text-blue-500 hover:text-blue-500 border-b border-blue-500 hover:border-b hover:border-blue-500 cursor-pointer"
                : "py-2 px-3 hover:text-blue-500 border-b border-transparent hover:border-b cursor-pointer"
            }
          >
            Leave Approval
          </li>
        </ul>
      </div>
      <div className="configSetupContainer bg-white rounded-md mt-4">
        {String(active) === "1" && (
          <LeaveQuota
            setIsUpdateOpen={setIsUpdateOpen}
            updateConfigData={updateConfigData?.configData}
          />
        )}
        {String(active) === "2" && (
          <LeaveAccrual
            setIsUpdateOpen={setIsUpdateOpen}
            updateConfigData={updateConfigData?.configData}
          />
        )}
        {String(active) === "3" && (
          <ApplyingForLeave
            setIsUpdateOpen={setIsUpdateOpen}
            updateConfigData={updateConfigData?.configData}
          />
        )}
        {String(active) === "4" && (
          <LeaveRestrictions
            setIsUpdateOpen={setIsUpdateOpen}
            updateConfigData={updateConfigData?.configData}
          />
        )}
      </div>
    </div>
  );
};

export default UpdateLeavePlanConfig;
