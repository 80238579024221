import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";

export const fetchLunchRequests = createAsyncThunk(
  "lunch/getRequests",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LUNCH_REQUEST}/${params.EmployeeId}`,
        { params: { selectedMonth: params.selectedMonth } }
      );

      return { data: data, isCalendar: params.calendar ? true : false };
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  lunchRequests: [],
  calendar: [],
  loading: false,
  error: null,
  success: false,
};

export const lunchRequestSlice = createSlice({
  name: "lunchRequestSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLunchRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLunchRequests.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.calendar = payload.isCalendar ? payload?.data : state.calendar;
        state.lunchRequests = !payload.isCalendar
          ? payload?.data
          : state.lunchRequests;
        state.error = null;
      })
      .addCase(fetchLunchRequests.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.lunchRequests = [];
        state.success = false;
      });
  },
});

export default lunchRequestSlice.reducer;
