import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "../../EmployeeNew/EmployeeForm/EmployeeFormDialog.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../Loader/Loader";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Checkbox, TextField } from "@mui/material";
import dayjs from "dayjs";
import http from "../../../utils/http";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  createNewAttendance,
  fetchParticularEmployeeAttendanceList,
  updateAttendance,
} from "../../../store/slices/masterSlice";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NewAttendanceSchema = yup.object().shape({
  employee: yup.object().label("Employee Name").required(),
  att_date: yup.string().label("Attendance Date").required(),
  inTime: yup.string().label("In Time").required(),
  outTime: yup.string().label("Out Time").required(),
  // overTime: yup.number().label("Over Time").optional().default(0),
  is_regularized: yup.boolean().label("Is Regularized").optional(),
});
const UpdateAttendanceSchema = yup.object().shape({
  employee: yup.object().label("Employee Name").optional().nullable(),
  total_ot_hours: yup.string().label("Attendance Date").required(),
  approved_hours: yup.string().label("In Time").required(),
  carry_forward_hours: yup.string().label("Out Time").required(),
  amount: yup.number().label("Over Time").optional().default(0),
  is_regularized: yup.boolean().label("Is Regularized").optional(),
});

const NewOvertimeDialog = ({
  employeeList,
  setOpen,
  open,
  updateData,
  Name,
  getData,
  EmployeeId,
  fromDate,
  toDate,
  sort,
}) => {
  console.log("updateData:", updateData);
  const [disabled, setDisabled] = React.useState(false);
  const [approvedHours, setApprovedHours] = React.useState(0);
  const dispatch = useDispatch();
  let maxDate = dayjs().format("YYYY-MM");
  const methods = useForm({
    defaultValues: {
      employee:
        updateData && updateData?.employee ? updateData?.employee : null,
      ot_payment_type:
        updateData && updateData?.ot_payment_type
          ? updateData?.ot_payment_type
          : "Hourly",
      total_ot_hours:
        updateData && updateData?.total_ot_hours
          ? updateData?.total_ot_hours
          : 0,
      previousMonthHours:
        updateData && updateData?.previousMonthHours
          ? updateData?.previousMonthHours
          : null,
      amount: updateData && updateData?.amount ? updateData?.amount : 0,
      is_regularized:
        updateData?.is_regularized === undefined ||
        Number(updateData?.is_regularized) === 1
          ? true
          : false,
    },
    resolver:yupResolver(NewAttendanceSchema)
      
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = methods;
  const handleTotalOTHours = () => {
    setValue("approved_hours", null);
    setValue("carry_forward_hours", null);
  };
  const handleApprovedOt = (e) => {
    const totalHours = Number(watch("total_ot_hours"));
    const approvedHours = Number(e?.target?.value) ?? 0;
    if (totalHours >= approvedHours) {
      const carryForwardHours = totalHours - approvedHours;
      setValue("carry_forward_hours", carryForwardHours);
    } else {
      setValue("carry_forward_hours", 0);
      toast.error("Approved hours are greater then total hours");
    }

    // console.log('e:', e)
    // if (e) {
    //   setApprovedHours(e?.value);
    // }
  };
  const handleEmployeeChange = async (e) => {
    try {
      if (e) {
        const payroll_month = dayjs();
        const { data } = await http.get(
          `/ot/calculate-employee-ot-time/${
            e?.value
          }?payrollMonth=${payroll_month.format(
            "YYYY-MM-DD"
          )}&previousMonth=${payroll_month.format("MM")}`
        );
        console.log("data:", data.response[0]);
        let currDate = dayjs()?.format("YYYY-MM-DD");
        let totOtHours = Number(data?.response[0]?.totalOverTime);
        let previousOtHours = Number(data?.response[0]?.previousMonthHours);
        let carryForwardHours = Number(totOtHours - approvedHours);
        if (data?.response[0]?.isSeparationCase) {
          setDisabled(data?.response[0]?.isSeparationCase);
          setValue("carry_forward_hours", 0);
          setValue("approved_hours", totOtHours);
        } else {
          setValue("carry_forward_hours", carryForwardHours);
        }
        setValue("approved_hours", null);
        setValue("carry_forward_hours", null);

        setValue("month", currDate);
        setValue(
          "totalHoursWithCarryForward",
          Number(data?.response[0]?.totalHoursWithCarryForward) || 0
        );

        setValue("ot_payment_type", data?.response[0]?.otPaymentType);

        setValue("previous_month_hours", previousOtHours);
        setValue("total_ot_hours", totOtHours);
        // setValue(
        //   "amount",
        //   Number(
        //     data?.response[0]?.previousMonthAmount
        //       ? data?.response[0]?.previousMonthAmount
        //       : 0
        //   )
        // );
      }
    } catch (error) {
      toast.error(
        "No Work Shift Allotted To This Employee, WorkShift Is Required For Marking Attendance"
      );
    }
  };
  // React.useEffect(() => {
  //   // Watch for changes in userData and reset form values when available
  //   if (updateData) {
  //     const { employee, attendanceDate, InTime, OutTime, overTime } =
  //       updateData;
  //     const updatedValues = {
  //       employee: employee || null,
  //       att_date: attendanceDate || null,
  //       inTime: InTime || null,
  //       outTime: OutTime || null,
  //       overTime: overTime || null,
  //     };
  //     methods.reset(updatedValues);
  //   }
  // }, [updateData, methods]);
  const handleDateChange = (e) => {};
  const submitHandler = async (data) => {
    console.log("data:", data);
    return;
    try {
      if (!updateData?.attendanceId) {
        data.employee = data?.employee?.value;
        data.inTime = `${data?.att_date}T${data?.inTime?.split("T")[1]}`;
        let startHour = dayjs(data?.inTime)?.format("HH");
        let endHour = dayjs(data?.outTime)?.format("HH");
        if (endHour <= startHour) {
          data.outTime = `${dayjs(data?.att_date)
            ?.add(1, "day")
            ?.format("YYYY-MM-DD")}T${data?.outTime?.split("T")[1]}`;
        } else {
          data.outTime = `${data?.att_date}T${data?.outTime?.split("T")[1]}`;
        }
        // await dispatch(createNewAttendance(data));
        getData();
      } else {
        delete data?.employee;
        let updatedData = data;
        updatedData.fromDateTime = data?.inTime;
        updatedData.toDateTime = data?.outTime;
        updatedData.isRegularized = data?.is_regularized;
        // updatedData.overTime = data?.overTime;
        delete data?.att_date;
        delete data?.inTime;
        delete data?.outTime;
        delete data?.is_regularized;
        await dispatch(
          updateAttendance({
            data: updatedData,
            attendanceId: updateData?.attendanceId,
          })
        );
        await dispatch(
          fetchParticularEmployeeAttendanceList({
            EmployeeId,
            fromDate,
            toDate,
            sort,
          })
        );
      }
      reset();
      setOpen(false);
    } catch (err) {
      reset();
      setOpen(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const isFetchingCreate = useSelector(
    (state) => state?.masterList?.createNewAttendanceData?.isFetching
  );
  const isFetchingUpdate = useSelector(
    (state) => state?.masterList?.updateAttendanceData?.isFetching
  );

  return (
    <>
      {isFetchingCreate || isFetchingUpdate ? (
        <Loader />
      ) : (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          className="employee_form_dialog"
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <DialogTitle className="add_employee_main_heading">
                <span>
                  {updateData?.attendanceId
                    ? "Update Employee OverTime"
                    : "New Employee OverTime"}
                </span>
              </DialogTitle>
              <DialogContent className="card-content-employee-form">
                <div className="row">
                  <div className="col-md-12">
                    <div className="create_form_input_div">
                      <label htmlFor="employee" className="create_form_labels">
                        Employee
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      {updateData?.attendanceId ? (
                        <label className="Name">{Name}</label>
                      ) : (
                        <AutoCompleteComponent
                          name="employee"
                          id="employee"
                          control={control}
                          errors={errors}
                          options={employeeList?.map((v) => ({
                            label: `${v?.EmployeeId} - ${v?.employee_name}`,
                            value: v?.EmployeeId,
                          }))}
                          onChangeHandler={handleEmployeeChange}
                        />
                      )}
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="ot_payment_type"
                        className="create_form_labels"
                      >
                        OT Payment Type
                        <span className="create_form_asterisk"></span>:
                      </label>
                      <TextFieldComponent
                        name="ot_payment_type"
                        id="ot_payment_type"
                        type="text"
                        control={control}
                        errors={errors}
                        min={0}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="total_ot_hours"
                        className="create_form_labels"
                      >
                        Total OT Hours
                        <span className="create_form_asterisk"></span>:
                      </label>
                      <TextFieldComponent
                        name="total_ot_hours"
                        id="total_ot_hours"
                        type="number"
                        onChangeHandler={handleTotalOTHours}
                        control={control}
                        errors={errors}
                        min={0}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="previousMonthHours"
                        className="create_form_labels"
                      >
                        Previous Month Hours
                        <span className="create_form_asterisk"></span>:
                      </label>
                      <TextFieldComponent
                        name="previousMonthHours"
                        id="previousMonthHours"
                        type="number"
                        control={control}
                        errors={errors}
                        min={0}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="totalHoursWithCarryForward"
                      className="create_form_labels"
                    >
                      Total Hours With Last Month Carry Forward
                      <span className="create_form_asterisk"></span>:
                    </label>
                    <TextFieldComponent
                      name="totalHoursWithCarryForward"
                      id="totalHoursWithCarryForward"
                      type="number"
                      control={control}
                      errors={errors}
                      min={0}
                      disabled={true}
                      default={0}
                    />
                  </div>

                  <div className="col-md-12">
                    <label
                      htmlFor="approved_hours"
                      className="create_form_labels"
                    >
                      Approved OT Hours
                      <span className="create_form_asterisk"></span>:
                    </label>
                    <TextFieldComponent
                      onChangeHandler={handleApprovedOt}
                      name="approved_hours"
                      id="approved_hours"
                      type="number"
                      control={control}
                      errors={errors}
                      min={0}
                    />
                  </div>

                  <div className="col-md-12">
                    <label
                      htmlFor="carry_forward_hours"
                      className="create_form_labels"
                    >
                      Carry Forward Hours
                      <span className="create_form_asterisk"></span>:
                    </label>
                    <TextFieldComponent
                      name="carry_forward_hours"
                      id="carry_forward_hours"
                      type="number"
                      control={control}
                      errors={errors}
                      min={0}
                      disabled={true}
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="amount" className="create_form_labels">
                      Amount
                      <span className="create_form_asterisk"></span>:
                    </label>
                    <TextFieldComponent
                      name="amount"
                      id="amount"
                      type="number"
                      control={control}
                      errors={errors}
                      min={0}
                    />
                  </div>
                  {/* <div className="col-md-12">
                    <label htmlFor="month" className="create_form_labels">
                      OverTime Month
                      <span className="create_form_asterisk">*</span>:
                    </label>
                    <div className="attDatePicker">
                      <DatePickerComponent
                        name="month"
                        errors={errors}
                        control={control}
                        disableMaskedInput={true}
                        backSpaceChange={false}
                        maxDate={maxDate}
                        disabled={updateData?.attendanceId}
                        views={["month", "year"]}
                        onHandleChange={handleDateChange}
                      />
                    </div>
                  </div> */}
                </div>
              </DialogContent>
              <DialogActions className="employee_form_button_div">
                <button
                  type="button"
                  onClick={handleClose}
                  className="cancel_button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!isDirty}
                  className="save_button"
                  id={!isDirty ? "newDisable" : ""}
                >
                  Save
                </button>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
};
export default NewOvertimeDialog;
