import image1 from "../images/1-large.jpg";
import image2 from "../images/2-large.jpg";
import image3 from "../images/3-large.jpg";
import image4 from "../images/4-large.jpg";
import image5 from "../images/5-large.jpg";
import image6 from "../images/6-large.jpg";
import image7 from "../images/7-large.jpg";
import image8 from "../images/8-large.jpg";
import image9 from "../images/9-large.jpg";
import image10 from "../images/10-large.jpg";
import image11 from "../images/11-large.jpg";
import image12 from "../images/12-large.jpg";
import image13 from "../images/13-large.jpg";
import image14 from "../images/14-large.jpg";
import image15 from "../images/15-large.jpg";
import image16 from "../images/16-large.jpg";
import image17 from "../images/17-large.jpg";
import image18 from "../images/18-large.jpg";
import image19 from "../images/19-large.jpg";
import image20 from "../images/20-large.jpg";
import image21 from "../images/21-large.jpg";
import image22 from "../images/22-large.jpg";
import image23 from "../images/23-large.jpg";
import image24 from "../images/24-large.jpg";
import image25 from "../images/25-large.jpg";
import image26 from "../images/26-large.jpg";
import image27 from "../images/27-large.jpg";
import image28 from "../images/28-large.jpg";
import image29 from "../images/29-large.jpg";
import image30 from "../images/30-large.jpg";
import image31 from "../images/31-large.jpg";
import image32 from "../images/32-large.jpg";
import image33 from "../images/33-large.jpg";
import image34 from "../images/34-large.jpg";
import image35 from "../images/35-large.jpg";
export const SliderData = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
  {
    image: image5,
  },
  {
    image: image6,
  },
  {
    image: image7,
  },
  {
    image: image8,
  },
  {
    image: image9,
  },
  {
    image:image10,
  },
  {
    image:image11
  },
  {
    image:image12
  },
  {
    image:image13
  },
  {
    image: image14,
  },
  {
    image: image15,
  },
  {
    image: image16,
  },
  {
    image: image17,
  },
  {
    image: image18,
  },
  {
    image: image19,
  },
  {
    image: image20,
  },
  {
    image: image21,
  },
  {
    image: image22,
  },
  {
    image: image23,
  },
  {
    image: image24,
  },
  {
    image: image25,
  },
  {
    image: image26,
  },
  {
    image: image27,
  },
  {
    image: image28,
  },
  {
    image: image29,
  },
  {
    image: image30,
  },
  {
    image: image31,
  },
  {
    image: image32,
  },
  {
    image: image33,
  },
  {
    image: image34,
  },
  {
    image: image35,
  },
];
