import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchOtDataForPayRoll } from "../../../store/slices/payrollSlice";
import MUIDataTable from "mui-datatables";
import Loader from "../../Loader/Loader";
import SearchBox from "../../TopHeader/SearchBox/SearchBox";
import DownloadReport from "../DownloadReport";
import http from "../../../utils/http";
import fileDownload from "js-file-download";
import moment from "moment";
import END_POINTS from "../../../constants/endpoints";
import { fetchEmployeeNameList } from "../../../store/slices/employeeSlice";

const OverTimeTable = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(15);
  const [sortBy, setsortBy] = useState("employee_name");
  const [order, setorder] = useState("ASC");
  const [search, setSearch] = useState("");
  const [downloading, setDownloading] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [updateData, setUpdateData] = useState({});
  const OverTimeRecords = useSelector(
    (state) => state?.payRoll?.otDataPayRoll.data ?? []
  );
  React.useEffect(() => {
    dispatch(fetchEmployeeNameList({ module: "EMPLOYEE_MODULE" }));
  }, [dispatch]);
  const employeeList = useSelector(
    (state) => state?.employee?.employeeNameList
  );
  let EmployeeList = [];
  employeeList &&
    employeeList?.map((i) => {
      return EmployeeList?.push({
        label: `${i?.EmployeeId} - ${i?.employee_name}`,
        value: i?.EmployeeId,
      });
    });
  const overTimeData = OverTimeRecords ?? [];

  const loading = useSelector(
    (state) => state.payRoll.otDataPayRoll.isFetching
  );
  const attendanceMonth = moment(endDate).startOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    const params = {
      payrollMonth: attendanceMonth,
      limit: limit,
      page: page + 1,
      sortBy: sortBy,
      order: order,
      search: search,
    };
    dispatch(fetchOtDataForPayRoll(params));
  }, [startDate, page, limit, search, sortBy, order]);

  const columns = [
    {
      label: "Id",
      name: "employee_id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Name",
      name: "overTimeDataEmployee",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value?.employee_name : "-";
        },
      },
    },
    {
      label: "Total OT Hours",
      name: "total_ot_hours",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Approved Hours",
      name: "approved_hours",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "OT Payment Type",
      name: "ot_payment_type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Carry Forward Hours",
      name: "carry_forward_hours",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },

    {
      label: "Amount",
      name: "amount",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Approved By",
      name: "overTimeApprover",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value?.employee_name ? value?.employee_name : "-";
        },
      },
    },
  ];

  const options = {
    filter: false,
    selectToolbarPlacement: "none",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    count: OverTimeRecords?.totalRecord ?? 0,
    rowsPerPage: limit,
    page: page,
    search: false,
    download: false,
    print: false,
    serverSide: true,
    viewColumns: false,
    onChangePage: (currentPage) => {
      setpage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setlimit(numberOfRows);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setsortBy(changedColumn === "overTimeDataEmployee" ? "employee_name" : changedColumn);
      setorder(direction.toUpperCase());
    },
    expandableRows: false,
  };

  const handleDownloadReport = async (e) => {
    try {
      setDownloading(true);

      const response = await http({
        url: `${END_POINTS.FETCH_OT_DATA_PAYROLL}=${attendanceMonth}`,
        method: "GET",
        responseType: "blob",
      });

      fileDownload(
        response.data,
        `overtime_report_${moment(attendanceMonth).format("MMM-YYYY")}.xlsx`
      );
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };
  // const handleDialog = () => {
  //   setOpenDialog(true);
  // };

  return (
    <>
      {loading && <Loader />}

      <div className="payrollContentDiv">
        <div className="payrollActionsDiv">
          {/* <button
            className="py-1 px-3 border-1 border-fuchsia-900 text-fuchsia-900  rounded-2xl hover:border-white hover:text-white hover:bg-fuchsia-900"
            onClick={handleDialog}
          >
            Add New OverTime
          </button> */}

          <div className="compAttSearchBtn">
            <SearchBox setSearch={setSearch} />
          </div>
          <DownloadReport
            handleExcelDownload={handleDownloadReport}
            loading={downloading}
          />
        </div>
        <div className="container-fluid">
          <div className="payrollTableResponsive">
            <div className="payrollTableConteiner">
              <MUIDataTable
                data={overTimeData}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {openDialog && (
        <NewOvertimeDialog
          employeeList={employeeList}
          setOpen={setOpenDialog}
          open={openDialog}
          updateData={updateData}
        />
      )} */}
    </>
  );
};

export default OverTimeTable;
