import { isEmpty, isNil } from "ramda";
import { toast } from "react-toastify";

export const rowActionHandler = (e, rowData, setRow, setType, setOpen) => {
  const type = e.target.getAttribute("type");
  if (type === "hold") {
    setRow(
      [rowData].map((v) => {
        return {
          miscDeductionId: v.miscDeductionId,
          on_hold: true,
        };
      })
    );
    setType(e.target.getAttribute("type"));
    setOpen(true);
  }

  if (type === "release") {
    setRow(
      [rowData].map((v) => {
        return {
          miscDeductionId: v.miscDeductionId,
          on_hold: false,
        };
      })
    );
    setType(e.target.getAttribute("type"));
    setOpen(true);
  }
};

export const editActionHandler = (rowData, setRow, setOpenEdit) => {
  setRow(rowData);
  return setOpenEdit(true);
};

export const buttonActionHandler = (
  e,
  setRow,
  setType,
  setOpen,
  selectedRows
) => {
  e.preventDefault();
  const type = e.target.getAttribute("type");
  if (selectedRows.length && type === "hold") {
    const filterRows = selectedRows.filter((v) => v.on_hold === 1);
    if (!isEmpty(filterRows)) {
      return toast.error("Please select users having deduction status release");
    } else {
      setRow(
        selectedRows.map((v) => {
          return {
            miscDeductionId: v.miscDeductionId,
            on_hold: true,
          };
        })
      );
      setType(e.target.getAttribute("type"));
      return setOpen(true);
    }
  }

  if (selectedRows.length && type === "release") {
    const filterRows = selectedRows.filter(
      (v) => isNil(v.on_hold) || v.on_hold === 0
    );
    if (!isEmpty(filterRows)) {
      return toast.error("Please select users having deduction status on hold");
    } else {
      setRow(
        selectedRows.map((v) => {
          return {
            miscDeductionId: v.miscDeductionId,
            on_hold: false,
          };
        })
      );
      setType(e.target.getAttribute("type"));
      return setOpen(true);
    }
  }
};
