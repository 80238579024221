export const rowActionHandler = (e, rowData, setRow, setType, setOpen) => {
  const type = e.target.getAttribute("type");
  if (type === "hold") {
    setRow(
      [rowData].map((v) => {
        return {
          reimbursementId: v.reimbursement_id,
          on_hold: true,
        };
      })
    );
    setType(e.target.getAttribute("type"));
    setOpen(true);
  }

  if (type === "release") {
    setRow(
      [rowData].map((v) => {
        return {
          reimbursementId: v.reimbursement_id,
          on_hold: false,
        };
      })
    );
    setType(e.target.getAttribute("type"));
    setOpen(true);
  }
};

export const editActionHandler = (rowData, setRow, setOpenEdit) => {
  setRow(rowData);
  return setOpenEdit(true);
};
