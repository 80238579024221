import * as yup from "yup";

const FilterleaveSchema = yup.object().shape({
  employee: yup.object().label("Employee Name").optional().nullable(true),
  leave_type: yup.object().label("Leave Type").optional().nullable(true),
  status: yup.object().label("Status").optional().nullable(true),
  from_date: yup.string().label("From Date").optional().nullable(true),
  to_date: yup.string().test("to-date-required", "To Date is required when From Date is not null", function (toDate) {
    const fromDate = this.parent.from_date;

    if (fromDate !== null && fromDate !== undefined && fromDate !== "") {
      return !!toDate;
    }

    return true;
  }).nullable(true).default(null),
});

export default FilterleaveSchema;
