import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";

export const userComponentAccess = createAsyncThunk(
  "component/componentAccess",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.COMPONENT_ACCESS_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  componentAccess: {},
  userAccessData: {},
  loading: false,
  error: null,
  success: false,
};

export const componentSlice = createSlice({
  name: "component",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userComponentAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userComponentAccess.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.userAccessData = payload.userAccessData;
        state.error = null;
      })
      .addCase(userComponentAccess.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.userAccessData = {};
        state.success = false;
      });
  },
});

export default componentSlice.reducer;
