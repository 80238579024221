import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const AutoCompleteMultiSelect = ({ name, options, control, errors }) => {
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            options={options}
            name={name}
            filterSelectedOptions
            onChange={(e, value) => {
              onChange(value);
            }}
            value={value}
            getOptionLabel={(option) => option || ""}
            multiple={true}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                className="empInput"
                size="small"
              />
            )}
          />
        )}
      />
      {error ? <p>{error}</p> : null}
    </>
  );
};

export default AutoCompleteMultiSelect;
