import { toast } from "react-toastify";
import {
  // allocationList,
  updateAllocationStatus,
} from "../../Services/LeaveAllocationServices";
import { getFormatedDate, getLoggedUserData } from "../../../utils/helper";

export const allocationStatusUpdate = async (allocatioData) => {
  try {
    const data = await updateAllocationStatus(allocatioData);
    return data;
  } catch (err) {
    return err;
    // toast.error(err.response.data.message);
  }
};

export const approveLeave = async (statusModifiedBy, allocationIds) => {
  if (!statusModifiedBy.length) {
    toast.error("You Do Not Have Access To Approve/Reject Selected Allocation");
    return;
  }
  // const res = await allocationStatusUpdate({
  //   statusModifiedBy: statusModifiedBy,
  //   allocationIds: allocationIds,
  //   status: "Approved",
  // });

  // return res;
};

export const rejectLeave = async (statusModifiedBy, allocationIds) => {
  if (!statusModifiedBy.length) {
    toast.error("You Do Not Have Access To Approve/Reject Selected Allocation");
    return;
  }
  // const res = await allocationStatusUpdate({
  //   statusModifiedBy: statusModifiedBy,
  //   allocationIds: allocationIds,
  //   status: "Rejected",
  // });
  // return res;
};

export const getstatusModifiedBy = () => {
  const loggedEmployee = getLoggedUserData();
  let userRoles = loggedEmployee?.userRoles ?? [];

  userRoles = userRoles.map((data) => data.roleName);

  const userAuthRoles = [];
  if (userRoles.includes("Head Of Department")) {
    userAuthRoles.push("HOD");
  }
  if (userRoles.includes("Leave Approver")) {
    userAuthRoles.push("Reporting Manager");
  }
  if (userRoles.includes("HR Manager")) {
    userAuthRoles.push("HR");
  }

  return userAuthRoles;
};
