import React from "react";
import PayrollAutoSelectComponent from "./PayrollAutoSelectComponent";
import moment from "moment";
import DatePickerComponent from "../MasterComponent/DatePickerComponent";

export default function PayrollMonthSelectComponent({
  control,
  errors,
  setPayrollMonth,
  payrollMonthsList,
}) {
  const payrollMonthChangeHandler = (selectedMonth) => {
    if (selectedMonth) {
      setPayrollMonth(moment(new Date(selectedMonth)).format("YYYY-MM-DD"));
    }
  };

  // function getPayrollMonthLabel(payrollData) {
  //   return `${payrollData.payrollMonthYear} (${moment(
  //     payrollData.payrollStartDate
  //   ).format("MMM DD")} - ${moment(payrollData.payrollEndDate).format(
  //     "MMM DD"
  //   )})`;
  // }

  return (
    <div className="DashboardpayrollSummaryData">
      <label>Select Month</label>
      <DatePickerComponent
        errors={errors}
        control={control}
        name="payroll_month"
        placeHolder="mmm-yyyy"
        inputFormat="MMM-YYYY"
        views={["year", "month"]}
        disableMaskedInput={true}
        onHandleChange={(date) => payrollMonthChangeHandler(date)}
      />
    </div>
  );
}
