import http from "../../utils/http";

const FetchEmployeeNotifcations = async ({employeeId, params}) => {
  var response = await http.get(`/notification/${employeeId}`, {params});
  return response?.data?.data;
};

const UpdateEmployeeNotifcations = async ({employeeId, params}) => {
    var response = await http.post(`/notification/${employeeId}`, { params });
    return response.data;
  };

export { FetchEmployeeNotifcations,  UpdateEmployeeNotifcations};