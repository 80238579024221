import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../../EmployeeNew/EmployeeForm/EmployeeFormDialog.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../Loader/Loader";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import * as yup from "yup";
import {
  createApproveOT,
  getCalculateOtData,
} from "../../../store/slices/masterSlice";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import { Checkbox } from "@mui/material";
import { getLoggedUserData } from "../../../utils/helper";
import dayjs from "dayjs";
import { fetchOtDataForPayRoll, fetchPendingEmployeeOT } from "../../../store/slices/payrollSlice";

const UpdateAttendanceSchema = yup.object().shape({
  employee: yup.object().label("Employee Name").required(),
  isCarryForward: yup.boolean().label("Is CarryForward").optional(),
  isSeparationCase: yup.boolean().label("Is SeparationCase").optional(),
  amount: yup.string().label("Amount").required(),
  approvedHours: yup.string().label("Approved Hours").optional(),
  carryForwardHours: yup.string().label("Carry Forward Hours").optional(),
  otPaymentType: yup.string().label("OT Payment Type").required(),
  previousMonthHours: yup
    .number()
    .label("Previous Month Hours")
    .optional()
    .default(0),
  totalHoursWithCarryForward: yup
    .number()
    .label("Total Hours With Carry Forward")
    .optional()
    .default(0),
  totalOverTime: yup.number().label("Total OverTime").optional().default(0),
});

const UpdateOverTimeDialog = ({
  employeeList,
  setOpen,
  open,
  updateData,
  payrollMonth,
  params,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      getCalculateOtData({
        employeeId: updateData?.employee_id,
        payrollMonth: payrollMonth,
      })
    );
  }, [dispatch, payrollMonth, updateData]);
  const otData = useSelector(
    (state) => state?.masterList?.calculatedOtData?.data?.response?.[0]
  );
  const defaultValue = React.useMemo(
    () => ({
      employee:
        employeeList
          ?.map((v) => ({
            label: `${v?.EmployeeId} - ${v?.employee_name}`,
            value: v?.EmployeeId,
          }))
          ?.find((data) => data?.value === updateData?.employee_id) ?? null,
      otPaymentType: otData?.otPaymentType ?? null,
      totalOverTime: Number(otData?.totalOverTime) ?? 0,
      previousMonthHours: Number(otData?.previousMonthHours ? otData?.previousMonthHours : 0),
      totalHoursWithCarryForward:
        Number(otData?.totalHoursWithCarryForward) ?? 0,
      isCarryForward: otData?.isCarryForward ?? false,
      isSeparationCase: otData?.isSeparationCase ?? false,
      approvedHours: 0,
      carryForwardHours: 0,
      amount: 0,
    }),
    [employeeList, otData, updateData]
  );

  const methods = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(UpdateAttendanceSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = methods;
  const handleTotalOTHours = () => {
    setValue("approvedHours", null);
    setValue("carryForwardHours", null);
  };
  React.useEffect(() => {
    let submitValue = defaultValue;
    if (watch("isSeparationCase")) {
      const approvedHours = watch("totalHoursWithCarryForward");
      submitValue = { ...defaultValue, approvedHours: approvedHours };
    }
    methods.reset(submitValue);
  }, [methods, otData, defaultValue, watch]);
  const handleApprovedOt = (e) => {
    const totalCarryForwardHours = watch("totalHoursWithCarryForward");
    const approvedHours = Number(e?.target?.value);
    const carryForwardHours = totalCarryForwardHours - approvedHours;
    setValue("carryForwardHours", carryForwardHours);
  };
  const handleIsCarryForwardChange = async (value) => {
    if (!value) {
      const totalCarryForwardHours = watch("totalHoursWithCarryForward");
      setValue("approvedHours", totalCarryForwardHours);
      setValue("carryForwardHours", 0);
    }
  };
  const loggedEmployee = getLoggedUserData();
  const approverId = loggedEmployee?.employeeId;
  const submitHandler = async (data) => {
    setIsLoading(true);
    const submitData = {
      employee_id: updateData?.employee_id,
      month: dayjs(payrollMonth)?.format("M"),
      total_ot_hours: data?.totalOverTime,
      approved_hours: data?.approvedHours,
      ot_payment_type: data?.otPaymentType,
      carry_forward_hours: data?.carryForwardHours,
      is_approved: 1,
      previous_month_hours: data?.previousMonthHours,
      approved_by: approverId,
      amount: data?.amount,
      is_ot_skipped: 0,
      modified_by: approverId,
    };
    await dispatch(createApproveOT(submitData));
    dispatch(fetchPendingEmployeeOT(params));
    setOpen(false);
    reset();
    setIsLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog1"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <DialogTitle className="add_employee_main_heading">
            <span>Approve Employee OverTime</span>
          </DialogTitle>
          <DialogContent className="card-content-employee-form">
            <div className="row">
              <div className="col-md-12">
                <div className="create_form_input_div">
                  <label htmlFor="employee" className="create_form_labels">
                    Employee
                    <span className="create_form_asterisk">*</span>:
                  </label>
                  <div className="create_form_input_div autoSelect">
                    <AutoCompleteComponent
                      name="employee"
                      id="employee"
                      control={control}
                      errors={errors}
                      disabled={true}
                      options={employeeList?.map((v) => ({
                        label: `${v?.EmployeeId} - ${v?.employee_name}`,
                        value: v?.EmployeeId,
                      }))}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="otPaymentType" className="create_form_labels">
                    OT Payment Type
                    <span className="create_form_asterisk"></span>:
                  </label>
                  <TextFieldComponent
                    name="otPaymentType"
                    id="otPaymentType"
                    type="text"
                    control={control}
                    errors={errors}
                    min={0}
                    disabled={true}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="totalOverTime" className="create_form_labels">
                    Total OT Hours
                    <span className="create_form_asterisk"></span>:
                  </label>
                  <TextFieldComponent
                    name="totalOverTime"
                    id="totalOverTime"
                    type="number"
                    onChangeHandler={handleTotalOTHours}
                    control={control}
                    errors={errors}
                    min={0}
                    disabled={true}
                  />
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="previousMonthHours"
                    className="create_form_labels"
                  >
                    Previous Month Hours
                    <span className="create_form_asterisk"></span>:
                  </label>
                  <TextFieldComponent
                    name="previousMonthHours"
                    id="previousMonthHours"
                    type="number"
                    control={control}
                    errors={errors}
                    min={0}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <label
                  htmlFor="totalHoursWithCarryForward"
                  className="create_form_labels"
                >
                  Total Hours With Last Month Carry Forward
                  <span className="create_form_asterisk"></span>:
                </label>
                <TextFieldComponent
                  name="totalHoursWithCarryForward"
                  id="totalHoursWithCarryForward"
                  type="number"
                  control={control}
                  errors={errors}
                  min={0}
                  disabled={true}
                  default={0}
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="approvedHours" className="create_form_labels">
                  Approved OT Hours
                  <span className="create_form_asterisk"></span>:
                </label>
                <TextFieldComponent
                  onChangeHandler={handleApprovedOt}
                  disabled={
                    watch("isSeparationCase") || !watch("isCarryForward")
                  }
                  name="approvedHours"
                  id="approvedHours"
                  type="number"
                  control={control}
                  errors={errors}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: watch("totalHoursWithCarryForward" ?? 0),
                    },
                  }}
                />
              </div>
              <div className="col-md-12 flex">
                <div className="col-md-6">
                  <label
                    htmlFor="isCarryForward"
                    className="create_form_labels mr-2"
                  >
                    Is Carry Forward
                    <span className="create_form_asterisk"></span>:
                  </label>
                  <Controller
                    name="isCarryForward"
                    control={control}
                    value={false}
                    render={({ field: { onChange, name, value } }) => (
                      <>
                        <Checkbox
                          name={name}
                          value="isCarryForward"
                          onChange={(e, val) => {
                            onChange(val);
                            handleIsCarryForwardChange(val);
                          }}
                          checked={Boolean(value) || false}
                          className="p-0"
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="isSeparationCase"
                    className="create_form_labels mr-2"
                  >
                    Employee On Resignation
                    <span className="create_form_asterisk"></span>:
                  </label>
                  <Controller
                    name="isSeparationCase"
                    control={control}
                    value={false}
                    render={({ field: { onChange, name, value } }) => (
                      <>
                        <Checkbox
                          name={name}
                          disabled={true}
                          value="isSeparationCase"
                          onChange={(e, val) => {
                            onChange(val);
                          }}
                          checked={Boolean(value) || false}
                          className="p-0"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <label
                  htmlFor="carryForwardHours"
                  className="create_form_labels"
                >
                  Carry Forward Hours
                  <span className="create_form_asterisk"></span>:
                </label>
                <TextFieldComponent
                  name="carryForwardHours"
                  id="carryForwardHours"
                  type="number"
                  control={control}
                  errors={errors}
                  min={0}
                  disabled={true}
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="amount" className="create_form_labels">
                  Amount
                  <span className="create_form_asterisk">*</span>:
                </label>
                <TextFieldComponent
                  name="amount"
                  id="amount"
                  type="number"
                  control={control}
                  errors={errors}
                  min={0}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="employee_form_button_div">
            <button
              type="button"
              onClick={handleClose}
              className="cancel_button"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!isDirty}
              className="save_button"
              id={!isDirty ? "newDisable" : ""}
            >
              Save
            </button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default UpdateOverTimeDialog;
