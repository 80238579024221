import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { getFormatedDate } from "../../utils/helper";
import CONSTANTS from "../../constants/constants";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  errormsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "5px",
    marginBottom: "15px",
  },
  inputClass: {
    pointerEvents: "none",
  },
  popper: {
    backgroundColor: "#DD0000",
  },
  popperArrow: {
    color: "#DD0000",
  },
  adronment: {
    position: "absolute",
    right: "38px",
  },
  datePicker: {
    paddingLeft: "0px",
  },
  datePikcerError: {
    paddingLeft: "0px",
    border: "1px solid #DD0000 !important",
  },
}));

const DatePickerComponent = ({
  name,
  control,
  errors,
  multiple,
  disabled = false,
  datePlaceholder,
  minDate,
  maxDate,
  onHandleChange,
  disableMaskedInput = true,
  views = ["year", "month", "day"],
  inputFormat = CONSTANTS.DD_MMM_YYYY,
  placeHolder,
  backSpaceChange = true,
  isTooltipError = false,
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <>
      <Controller
        name={name}
        multiple={multiple}
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(date) => {
                onChange(
                  date
                    ? getFormatedDate({
                        date: date,
                        dateFormat: CONSTANTS.YYYY_MM_DD,
                      })
                    : null
                );
                onHandleChange && onHandleChange(date);
              }}
              value={value ? value : null}
              disabled={disabled}
              disableMaskedInput={disableMaskedInput}
              InputProps={{
                ...datePlaceholder,
                classes: {
                  root: classes.datePicker,
                  notchedOutline:
                    isTooltipError && getErrorMessage(name)
                      ? classes.datePikcerError
                      : classes.datePicker,
                },
                placeholder: placeHolder,
                startAdornment: (
                  <InputAdornment classes={{ root: classes.adronment }}>
                    {!!isTooltipError && getErrorMessage(name) && (
                      <Tooltip
                        arrow
                        title={getErrorMessage(name)}
                        classes={{
                          tooltip: classes.popper,
                          arrow: classes.popperArrow,
                        }}
                      >
                        <InfoOutlinedIcon
                          sx={{ fontSize: 18, color: "#DD0000" }}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={(e) => {
                    if (e.code === "Backspace" && backSpaceChange) {
                      onChange();
                    } else {
                      e.preventDefault();
                    }
                  }}
                />
              )}
              inputFormat={inputFormat}
              views={views}
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
        )}
      />
      {!isTooltipError &&
        (error ? <p className={classes.errormsg}>{error}</p> : null)}
    </>
  );
};

export default DatePickerComponent;
