import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getLoggedUserData } from "../../utils/helper";
import imageIcon from "../images/thinksys-favicon-white.png";
import { componentDisplayAccess } from "../../utils/userPermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faCalendarDays,
  faUserGroup,
  faUserShield,
  faIslandTropical,
  faThumbsUp,
  faPersonToDoor,
  faLockKeyhole,
  faBuildingUser,
  faUserVneck,
  faMoneyCheckDollarPen,
  faUserClock,
  faDisplayChartUp,
  faBookUser,
  faWallet,
  faObjectsColumn,
  faSackDollar,
  // faSitemap,
  // faScrewdriverWrench,
  // faEnvelope,
  // faChevronDown,
  faCalendarCheck,
} from "@fortawesome/pro-light-svg-icons";
import { faPotFood } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../store/slices/authSlice";
import {
  faArrowRightFromBracket,
  faGear,
  faUserTie,
} from "@fortawesome/pro-light-svg-icons";
import "../ProfileSetting/ProfileSetting.css";
import { fetchEmployeeCard } from "../../store/slices/employeeSlice";
import { useSelector } from "react-redux";
import "./Navbar.css";
// import { ClickAwayListener } from "@mui/base";
import { fetchOrgClientsApproverConfig } from "../../store/slices/masterSlice";

const Navbar = () => {
  const [active, setActive] = useState("");
  // const [activeSubmenu, setActiveSubmenu] = useState("");
  const teamLead = useSelector(
    (state) => state?.employee?.userRole?.isTeamLead
  );
  const success = useSelector((state) => state?.component?.success);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!success) {
      dispatch(fetchOrgClientsApproverConfig({ project_id: null }));
    }
  }, [success, dispatch]);
  const approversDetails = useSelector(
    (state) => state?.masterList?.OrgClientsApproverConfigData?.response
  );
  const loggedEmployee = getLoggedUserData();
  // const workMode = loggedEmployee?.workMode ?? null;
  // const linkAccess = componentDisplayAccess({ component: "Apply_WFH" });
  const AttendanceListAccess = componentDisplayAccess({
    component: "Attendance_New",
  });
  const PolicyAccess = componentDisplayAccess({
    component: "Policy_Access",
  });
  const HolidayAccess = componentDisplayAccess({
    component: "Holiday_Access",
  });
  const LunchAccess = componentDisplayAccess({
    component: "Lunch_Access",
  });
  const slipDisplay = componentDisplayAccess({
    component: "Salary_Slip",
  });
  const slipDisplayHR = componentDisplayAccess({
    component: "Salary_Slip_HR",
  });
  const leaveListAccess = componentDisplayAccess({
    component: "Leave_LeaveList",
  });
  const employeeAccess = componentDisplayAccess({
    component: "Employee_EmployeeList",
  });
  const salaryPayrollAccess = componentDisplayAccess({
    component: "Master_Payroll",
  });

  const termAppraisal = componentDisplayAccess({
    component: "Term_Appraisal_Employee",
  });
  const SetupAccess = componentDisplayAccess({
    component: "Setup",
  });
  const HRAccess = componentDisplayAccess({
    component: "Human_Resources",
  });

  const EmployeeWorkModeReportAccess = componentDisplayAccess({
    component: "Employee_Work_Mode_Report",
  });
  const LeaveBalanceReportAccess = componentDisplayAccess({
    component: "Leave_Balance_Report",
  });
  const WorkFromHomeReportAccess = componentDisplayAccess({
    component: "Work_From_Home_Report",
  });
  const LeavesReportAccess = componentDisplayAccess({
    component: "Leaves_Report",
  });
  const AttendanceRegularizationReportAccess = componentDisplayAccess({
    component: "Attendance_Regularization_Report",
  });
  const TodayAttendanceReportAccess = componentDisplayAccess({
    component: "Today_Attendance_Report",
  });
  const EmployeeReportAccess = componentDisplayAccess({
    component: "Employee_Report",
  });

  const ReportAccess = componentDisplayAccess({
    component: "Time_Sheet_Report",
  });

  const ApprovalRequest = componentDisplayAccess({
    component: "Seperation_Approval",
  });

  // const history = useNavigate();
  const EmployeeId = loggedEmployee?.employeeId ?? null;
  useEffect(() => {
    if (!!success) {
      dispatch(fetchEmployeeCard(EmployeeId));
    }
  }, [EmployeeId, dispatch, success]);

  const onSignoutSuccess = () => {
    // setTimeout(() => history("/login"), 0.1)
    dispatch(userLogout());
  };

  // const handleSubmenu = (e) => {
  //   setActiveSubmenu(e?.target?.id);
  // };
  const handleClick = (element) => {
    setActive(element?.target?.id);
    // setActiveSubmenu("");
  };
  // const handleClickAway = (e) => {
  //   if (e?.target?.id !== activeSubmenu) {
  //     setActiveSubmenu("");
  //   }
  // };
  return (
    <>
      <div className="sidenav">
        <div className="sidenavLogo">
          <img src={imageIcon} alt="Thinksys FavIcon" className="favicon" />
        </div>
        <ul className="nav-links" id="navlinks">
          <li className="navlist">
            <NavLink
              to="/Dashboard"
              id="Dashboard"
              className={active === "Dashboard" ? "active" : ""}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faObjectsColumn}
                className="sidenav-icon"
              />
              <span className="link_name">Dashboard</span>
            </NavLink>
          </li>

          {/* <ClickAwayListener onClickAway={handleClickAway}>
            <li className="navlist">
              <NavLink
                to="/Organization"
                id="orgList"
                className={active === "Organization" ? "active" : ""}
                onClick={(e) => (e?.preventDefault(), handleSubmenu(e))}
              >
                <FontAwesomeIcon icon={faSitemap} className="sidenav-icon" />
                <span className="link_name">Organization</span>
              </NavLink>
              <ul
                className={
                  activeSubmenu === "orgList"
                    ? "submenuList active"
                    : "submenuList"
                }
              >
                <li className="submenuListItem">
                  <FontAwesomeIcon icon={faEnvelope} className="me-1" /> Emails
                  <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  <ul className="submenuInner">
                    <li>
                      <NavLink
                        to="EmailConfig"
                        id="Organization"
                        onClick={handleClick}
                      >
                        Notifications
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="submenuListItem">
                  <FontAwesomeIcon
                    icon={faScrewdriverWrench}
                    className="me-1"
                  />
                  Setup
                  <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  <ul className="submenuInner">
                    {!!HRTemporaryAccessCardAccess?.readAccess ? (
                      <li>
                        <NavLink
                          to="/TemporaryAccessCardList"
                          id="Organization"
                          onClick={handleClick}
                        >
                          Temporary Access Card
                        </NavLink>
                      </li>
                    ) : null}
                  </ul>
                </li>
              </ul>
            </li>
          </ClickAwayListener> */}
          {!!SetupAccess?.readAccess || HRAccess?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/Home"
                id="Home"
                className={active === "Home" ? "active" : ""}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faHouse} className="sidenav-icon" />
                <span className="link_name">Home</span>
              </NavLink>
            </li>
          ) : null}
          {!!employeeAccess?.readAccess ? (
            <li className="navlist">
              <NavLink
                to={`/EmployeeDetails/${EmployeeId}`}
                id="MyProfile"
                className={active === "MyProfile" ? "active" : ""}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faUserVneck} className="sidenav-icon" />
                <span className="link_name">My Profile</span>
              </NavLink>
            </li>
          ) : null}

          {/* ******************************************************************************** */}
          {teamLead && (
            <li className="navlist">
              <NavLink
                to="/MyTeam"
                id="MyTeam"
                onClick={handleClick}
                className={active === "MyTeam" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faUserGroup} className="sidenav-icon" />
                <span className="link_name">My Team</span>
              </NavLink>
            </li>
          )}

          <li className="navlist">
            <NavLink
              to="/AllEmployees"
              id="AllEmployees"
              onClick={handleClick}
              className={active === "AllEmployees" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faBuildingUser} className="sidenav-icon" />
              <span className="link_name">Employees</span>
            </NavLink>
          </li>

          <li className="navlist">
            <NavLink
              to="/Calendar"
              id="Calendar"
              onClick={handleClick}
              className={active === "Calendar" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faCalendarDays} className="sidenav-icon" />
              <span className="link_name">Calendar</span>
            </NavLink>
          </li>
          {leaveListAccess?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/leave-dashboard"
                id="Leave"
                onClick={handleClick}
                className={active === "Leave" ? "active" : ""}
              >
                <FontAwesomeIcon
                  icon={faPersonToDoor}
                  className="sidenav-icon"
                />
                <span className="link_name">Leave</span>
              </NavLink>
            </li>
          ) : null}
          {!!AttendanceListAccess?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/AttendanceDashboard"
                id="Attendance"
                onClick={handleClick}
                className={active === "Attendance" ? "active" : ""}
              >
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  className="sidenav-icon"
                />
                <span className="link_name">Attendance</span>
              </NavLink>
            </li>
          ) : null}
          {!!slipDisplayHR?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/Salaryslip"
                id="Salaryslip"
                onClick={handleClick}
                className={active === "Salaryslip" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faWallet} className="sidenav-icon" />
                <span className="link_name">Salary Slip</span>
              </NavLink>
            </li>
          ) : null}
          {!!slipDisplay?.readAccess ? (
            <li className="navlist">
              <NavLink to="/Finance" id="Finance" onClick={handleClick}>
                <FontAwesomeIcon icon={faSackDollar} className="sidenav-icon" />
                <span className="link_name">Finance</span>
              </NavLink>
            </li>
          ) : null}
          {!!EmployeeWorkModeReportAccess?.readAccess ||
          !!LeaveBalanceReportAccess?.readAccess ||
          !!WorkFromHomeReportAccess?.readAccess ||
          !!LeavesReportAccess?.readAccess ||
          !!AttendanceRegularizationReportAccess?.readAccess ||
          !!TodayAttendanceReportAccess?.readAccess ||
          !!EmployeeReportAccess?.readAccess ||
          !!ReportAccess?.readAccess
          ? (
            <li className="navlist">
              <NavLink
                to="/Reports"
                id="Reports"
                onClick={handleClick}
                className={active === "Reports" ? "active" : ""}
              >
                <FontAwesomeIcon
                  icon={faDisplayChartUp}
                  className="sidenav-icon"
                />
                <span className="link_name">Reports</span>
              </NavLink>
            </li>
          ) : null}
          {!!salaryPayrollAccess?.readAccess ||
          !!salaryPayrollAccess?.updateAccess ? (
            <li className="navlist">
              <NavLink
                to="/Payroll"
                id="Payroll"
                onClick={handleClick}
                className={active === "Payroll" ? "active" : ""}
              >
                <FontAwesomeIcon
                  icon={faMoneyCheckDollarPen}
                  className="sidenav-icon"
                />
                <span className="link_name">Payroll</span>
              </NavLink>
            </li>
          ) : null}
          {!!PolicyAccess?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/Policies"
                id="Policies"
                onClick={handleClick}
                className={active === "Policies" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faUserShield} className="sidenav-icon" />
                <span className="link_name">Policies</span>
              </NavLink>
            </li>
          ) : null}
          {/* {!!HolidayAccess?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/Holidays"
                id="Holidays"
                onClick={handleClick}
                className={active === "Holidays" ? "active" : ""}
              >
                <FontAwesomeIcon
                  icon={faIslandTropical}
                  className="sidenav-icon"
                />
                <span className="link_name">Holidays</span>
              </NavLink>
            </li>
          ) : null} */}
          {!!LunchAccess?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/LunchRequestList"
                id="Lunch"
                onClick={handleClick}
                className={active === "Lunch" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faPotFood} className="sidenav-icon" />
                <span className="link_name">Lunch</span>
              </NavLink>
            </li>
          ) : null}
          {/* {!!termAppraisal?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/TermAppraisal"
                id="Appraisal"
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faThumbsUp} className="sidenav-icon" />
                <span className="link_name">Appraisal</span>
              </NavLink>
            </li>
          ) : null} */}

          {ApprovalRequest?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/ApprovalRequest"
                id="ApprovalRequest"
                onClick={handleClick}
              >
                <FontAwesomeIcon
                  icon={faObjectsColumn}
                  className="sidenav-icon"
                />
                <span className="link_name">Approval Request</span>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {!!termAppraisal?.readAccess ? (
            <li className="navlist">
              <NavLink
                to="/EmployeeTermAppraisal"
                id="Appraisal"
                onClick={handleClick}
                className={active === "Appraisal" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faThumbsUp} className="sidenav-icon" />
                <span className="link_name">Appraisal</span>
              </NavLink>
            </li>
          ) : null}
          {/* <li className="navlist">
            <NavLink
              to="/EmployeeHandbook"
              id="Handbook"
              onClick={handleClick}
              className={active === "Handbook" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faBookUser} className="sidenav-icon" />
              <span className="link_name">Handbook</span>
            </NavLink>
          </li> */}
          {approversDetails?.length ? (
            <li className="navlist">
              <NavLink
                to="/TimeSheetDashboard"
                id="Timesheet"
                onClick={handleClick}
                className={active === "Timesheet" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faUserClock} className="sidenav-icon" />
                <span className="link_name">Time Sheet</span>
              </NavLink>
            </li>
          ) : null}

          <div className="userProfileSettingMobile">
            <ul>
              <li>
                <Link to={`/Employee/${EmployeeId}`}>
                  <FontAwesomeIcon
                    icon={faUserTie}
                    className="userSettingIcons"
                  />
                </Link>
              </li>
              <li>
                <Link to={`/ChangePassword`}>
                  <div className="uSettingPassIcon">
                    <FontAwesomeIcon icon={faLockKeyhole} />
                  </div>
                </Link>
              </li>
              <li>
                <Link to={`/MySettingsForm/${loggedEmployee?.id}`}>
                  <span>
                    <FontAwesomeIcon
                      icon={faGear}
                      className="userSettingIcons"
                    />
                  </span>
                </Link>
              </li>
              <li>
                <Link to="#" onClick={onSignoutSuccess}>
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="userSettingIcons"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
