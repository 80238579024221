import { useNavigate } from "react-router-dom";
import React from "react";
import { getAccessToken } from "./token";

export const Unauthorized = () => {
  const navigate = useNavigate();
  const token = getAccessToken();
  const handleChange = () => {
    if (token) {
      navigate("/Dashboard")
    } else {
      navigate("/login")
    }
  };
  return (
    <div className="error-wrapper">
      <div className="error-content">
        <h1 style={{ fontWeight: "900", fontSize: "250px", background: "linearGradient(#01a9f4, #0a3e5f, #0a3e5f, #01a9f4)", backgroundClip: "text", textFillColor: "transparent" }}>401</h1>
        <div className="error-inner-content">
          <h2>Unauthorized</h2>
          <p className="mb-4">You do not have access to the requested page.</p>
          <button className="btn newbutton home-button" onClick={handleChange}>Go Back</button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
