import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { getAccessToken } from "./token";

const ProtectedRoute = ({ allowed, isLoading }) => {
  const auth = getAccessToken();
  const location = useLocation();

  return isLoading ? null :
    auth && allowed ? (
      <Outlet />
    ) : auth ? (
      <Navigate to="/Dashboard" state={{ from: location }} replace />
    ) : (
      <Navigate to="/" state={{ from: location }} replace />
    );
};

export default ProtectedRoute;
