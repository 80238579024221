import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "../../../Components/EmployeeNew/EmployeeForm/EmployeeFormDialog.css";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchAllVariablePayTypes } from "../../../store/slices/salarySlice";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import {
  fetchMiscellaneousDeductionsData,
  updateMiscDeduction,
} from "../../../store/slices/payrollSlice";
import Loader from "../../Loader/Loader";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import SelectComponent from "../../MasterComponent/SelectComponent";
import { toast } from "react-toastify";
import { getTwoDecimalFloatNumber } from "../../../utils/helper";

export const MiscDeductionSchema = yup.object().shape({
  miscDeductionId: yup.number().label("Id").required(),
  deduction_amount: yup
    .number()
    .max(100000)
    .label("Deduction Amount")
    .typeError("Deduction Amount must be a number")
    .min(0, "Amount can't be less than zero")
    .required(),
  description: yup.string().max(250).label("Description").required(),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EditDeduction = ({
  params,
  selectedRow = [],
  openEdit,
  deductionType,
  setOpenEdit,
  startDate,
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAllVariablePayTypes());
  }, [dispatch]);

  const methods = useForm({
    defaultValues: {
      miscDeductionId: selectedRow ? selectedRow?.miscDeductionId : null,
      deduction_name: selectedRow ? selectedRow?.misc_deduction_type_id : null,
      deduction_amount: selectedRow ? getTwoDecimalFloatNumber(selectedRow?.deduction_amount) : null,
      deduction_date: selectedRow ? selectedRow?.deduction_date : null,
      description: selectedRow ? selectedRow?.description : null,
    },
    resolver: yupResolver(MiscDeductionSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    trigger,
    getValues,
  } = methods;
  const loading = useSelector((state) => state.payRoll.isFetching);
  const submitHandler = async (data) => {
    if (data?.deduction_amount < 0) {
      toast.error("Deduction Amount Cannot be Negative");
      return;
    }

    let submitObject = {
      misc_deduction_type_id: data?.deduction_name,
      deduction_amount: data?.deduction_amount,
      deduction_date: data?.deduction_date,
      description: data?.description,
    };
    try {
      await dispatch(
        updateMiscDeduction({
          data: submitObject,
          miscDeductionId: selectedRow?.miscDeductionId,
        })
      );
      await dispatch(fetchMiscellaneousDeductionsData(params));

      setOpenEdit(false);
      reset();
    } catch (err) {
      setOpenEdit(false);
      reset();
    }
  };
  const handleClose = () => {
    setOpenEdit(false);
    reset();
  };

  return (
    <Dialog
      open={openEdit}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <DialogTitle className="add_employee_main_heading">
            <span>Edit Misc Deduction - {selectedRow?.employee_name}</span>
          </DialogTitle>
          {loading ? (
            <Loader />
          ) : (
            <>
              <DialogContent className="card-content-employee-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="create_form_input_div">
                      <label
                        htmlFor="deduction_name"
                        className="create_form_labels"
                      >
                        Deduction Name
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <SelectComponent
                        name="deduction_name"
                        id="deduction_name"
                        handleChange={() => trigger("deduction_name")}
                        control={control}
                        errors={errors}
                        options={deductionType?.map((v) => ({
                          label: v.type_name,
                          value: v.misc_deduction_type_id,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="create_form_input_div">
                      <label
                        htmlFor="deduction_amount"
                        className="create_form_labels"
                      >
                        Deduction Amount
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <TextFieldComponent
                        name="deduction_amount"
                        id="deduction_amount"
                        onChangeHandler={() => trigger("deduction_amount")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="create_form_input_div">
                      <label
                        htmlFor="deduction_date"
                        className="create_form_labels"
                      >
                        Deduction Date
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <DatePickerComponent
                        name="deduction_date"
                        errors={errors}
                        control={control}
                        minDate={startDate}
                        onHandleChange={() => trigger("deduction_date")}
                        inputFormat="DD-MMM-YYYY"
                        disableMaskedInput={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="description" className="create_form_labels">
                      Description:
                    </label>
                    <TextAreaComponent
                      name="description"
                      handleChange={() => trigger("description")}
                      control={control}
                      errors={errors}
                      rows={3}
                    />
                  </div>
                </div>
                <div className="row"></div>
              </DialogContent>
              <DialogActions className="employee_form_button_div">
                <button onClick={handleClose} className="cancel_button">
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!isDirty}
                  className="save_button"
                  id={!isDirty ? "newDisable" : ""}
                >
                  Save
                </button>
              </DialogActions>
            </>
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default EditDeduction;
