import React, { useEffect, useRef, useState } from "react";
import "../LeaveConfigDashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faMagnifyingGlass,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveType } from "../../../store/slices/leaveConfigSlice";
import { ClickAwayListener, Pagination, Stack } from "@mui/material";
import AddLeaveType from "./AddLeaveType";
import Loader from "../../Loader/Loader";
const LeaveTypes = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [openLeaveType, setOpenLeaveType] = useState(false);
  const [updateLeaveId, setUpdateLeaveId] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [limit, setLimit] = useState(15);
  const [toggle, setToggle] = useState(false);
  const [count, setCount] = useState(false);

  useEffect(() => {
    dispatch(getLeaveType({ limit, search, page }));
  }, [limit, search, page, dispatch]);

  const getLeaveTypeData = useSelector(
    (state) => state?.leaveConfig?.getLeaveTypeList?.response?.data
  );
  useEffect(() => {
    if (getLeaveTypeData) {
      setPageCount(getLeaveTypeData?.totalPages);
      setCount(getLeaveTypeData?.totalRecord);
    }
  }, [getLeaveTypeData]);

  const isFetching = useSelector(
    (state) => state?.leaveConfig?.getLeaveTypeList?.isFetching
  );

  const handleSearchChange = (event) => {
    const delayInput = setTimeout(() => {
      setSearch(event?.target?.value);
    }, 800);
    return () => clearTimeout(delayInput);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleChangeLimit = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div className="leaveTypes px-3">
        <div className="row pt-4 items-center">
          <div className="col-md-6">
            <div className="">
              <div className="text-2xl">Leave Types</div>
              <p className="text-sm">
                Below are the leave types that you'd like to use in your
                organization or you can add a new leave type as you desire.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-end">
              <button
                onClick={() => setOpenLeaveType(true)}
                className=" text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-600 py-2 px-3"
              >
                <FontAwesomeIcon className="mr-1" icon={faPlus} />
                Add Leave Type
              </button>
            </div>
          </div>
        </div>
        <div className="pt-3 relative">
          <div className="bg-white rounded-t-md border-b border-gray-200">
            <div className="inline-flex items-center py-2 px-3 text-black border-r border-gray-200">
              <span
                className="text-gray-500 cursor-text"
                onClick={() => ref.current.focus()}
              >
                <FontAwesomeIcon className="fa-1x" icon={faMagnifyingGlass} />
              </span>
              <input
                ref={ref}
                onChange={handleSearchChange}
                type="text"
                name="search input"
                placeholder="Search"
                className="text-sm border-none"
              />
            </div>
          </div>
          {isFetching && <Loader />}
          <div className="leaveTypeTable bg-white overflow-x-auto rounded-t-md">
            <table className="w-full whitespace-nowrap">
              <thead className="text-sm bg-gray-50 uppercase">
                <tr className="border-b border-gray-100">
                  <th className="py-3">LEAVE TYPE</th>
                  <th className="py-3">LEAVE TYPE LABEL</th>
                  <th className="py-3">CODE</th>
                  <th className="py-3">DESCRIPTION</th>
                  <th className="py-3">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {getLeaveTypeData?.leaveTypeData?.map((list, index) => {
                  return (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="text-sm p-3" key={index}>
                        {list?.leaveTypeName}
                      </td>
                      <td className="text-sm p-3">{list?.leaveTypeLabel}</td>
                      <td className="text-sm p-3">{list?.leaveTypeCode}</td>
                      <td className="text-sm p-3">{list?.description}</td>
                      <td className="text-sm p-3">
                        <FontAwesomeIcon
                          className="text-blue-500 cursor-pointer"
                          onClick={() => {
                            setOpenLeaveType(true);
                            setUpdateLeaveId(list);
                          }}
                          icon={faEdit}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="timeSheetPagination">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              color="primary"
              className="paginationCount"
            />
          </Stack>
          <div className="totalNoOfPages ms-3">
            On Page:{" "}
            <span className="totalCount">
              {page} of {pageCount}
            </span>
          </div>
          <label className="PaginationLabelWhere">|</label>
          <div className="totalNoOfPages">
            Total Count: <span className="totalCount">{count}</span>
          </div>
          <ClickAwayListener onClickAway={() => setToggle(false)}>
            <div className="paginationLimitBox" onClick={handleChangeLimit}>
              {limit}
              {toggle ? (
                <FontAwesomeIcon icon={faChevronUp} className="ms-2" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              )}
              {toggle && (
                <ul className="paginationLimitBoxDropdown">
                  {[10, 15, 25, 50, 75, 100]?.map((list) => {
                    return (
                      <li
                        value={list}
                        onClick={(e) => (
                          setLimit(e?.target?.value), setPage(1)
                        )}
                      >
                        {list}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </ClickAwayListener>
        </div>
      </div>
      {openLeaveType && (
        <AddLeaveType
          setOpenLeaveType={setOpenLeaveType}
          openLeaveType={openLeaveType}
          updateLeaveId={updateLeaveId}
          setUpdateLeaveId={setUpdateLeaveId}
        />
      )}
    </>
  );
};

export default LeaveTypes;
