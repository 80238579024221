import React from "react";
import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { Avatar, ListItemAvatar } from "@mui/material";
import stringAvatar from "../../PMS/AvatarFunction";
import { useSelector } from "react-redux";
// import { leaveUpdateStatusV1 } from "../../../store/slices/leaveSlice";
import Loader from "../../Loader/Loader";

const UpdateWFHDialog = ({ openEdit, setOpenEdit, updateData }) => {
  // const [managementComment, setManagementComment] = useState("");
  // const dispatch = useDispatch();

  const isFetching = useSelector(
    (state) => state?.leave?.leaveAllocationDataV1?.isFetching
  );
  const statusIsFetching = useSelector(
    (state) => state?.leave?.leaveUpdateStatusDataV1?.isFetching
  );
  return (
    <Dialog
      open={openEdit}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog leaveUpdateDialog"
    >
      {statusIsFetching && <Loader />}
      <DialogTitle className="flex justify-between add_employee_main_heading">
        <span>Work From Home Details</span>
        <FontAwesomeIcon
          icon={faXmark}
          className="cursor-pointer text-gray-600 text-xl"
          onClick={() => setOpenEdit(false)}
        />
      </DialogTitle>
      <DialogContent className="leaveApplication">
        <div className="row">
          {isFetching && <Loader />}
          <div className="border-b border-gray-200 pb-3 w-full flex gap-3 justify-between items-center mb-3">
            <div className="flex items-center text-gray-900 whitespace-nowrap dark:text-white">
              <ListItemAvatar className="img-fluid">
                {updateData?.masterleave?.profileImage ? (
                  <Avatar
                    className="img-fluid updateLeaveProfileImg rounded-full object-cover"
                    src={updateData?.masterleave?.profileImage}
                    alt={updateData?.masterleave?.employee_name}
                  />
                ) : (
                  <Avatar
                    className="img-fluid updateLeaveProfileImg  rounded-full object-cover"
                    {...stringAvatar(updateData?.masterleave?.firstName)}
                    alt={updateData?.masterleave?.firstName}
                  />
                )}
              </ListItemAvatar>
              <div className="pl-3">
                <div className="text-lg font-bold ">
                  {`${updateData?.masterleave?.firstName ?? ""} ${
                    updateData?.masterleave?.middleName ?? ""
                  } ${updateData?.masterleave?.lastName ?? ""}`}
                </div>
                <div className=" text-xs font-normal text-gray-600 ">
                  {updateData?.masterleave?.designation}
                </div>
                <div className="inline-flex  text-sm font-normal text-gray-600 items-center">
                  <label className="text-xs"> Reports To : </label>{" "}
                  {` ${updateData?.masterleave?.manager?.firstName ?? ""} ${
                    updateData?.masterleave?.manager?.middleName ?? ""
                  } ${updateData?.masterleave?.manager?.lastName ?? ""} `}
                </div>
              </div>
            </div>
            <div>
              <label className="mb-1 text-sm text-gray-950">
                Current Status:
              </label>
              <div
                className={`px-2 item-center w-full flex items-center text-sm mb-3 rounded ${
                  updateData?.status === "Open"
                    ? "bg-blue-50 border-1 border-solid border-blue-200"
                    : updateData?.status === "Approved"
                    ? "bg-green-50 border-1 border-solid border-green-200"
                    : updateData?.status === "Rejected"
                    ? "bg-red-50 border-1 border-solid border-red-200"
                    : "bg-yellow-50 border-1 border-solid border-yellow-200"
                }`}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`text-xs mr-2 ${
                    updateData?.status === "Open"
                      ? "text-blue-500"
                      : updateData?.status === "Approved"
                      ? "text-green-500"
                      : updateData?.status === "Rejected"
                      ? "text-red-600"
                      : "text-yellow-500"
                  }`}
                />{" "}
                <label
                  className={`${
                    updateData?.status === "Open"
                      ? "text-blue-500"
                      : updateData?.status === "Approved"
                      ? "text-green-500"
                      : updateData?.status === "Rejected"
                      ? "text-red-600"
                      : "text-yellow-500"
                  }`}
                >
                  {updateData?.status}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 border border-solid border-gray-300 rounded px-2 py-3">
            <div className="text-lg font-bold">
              {updateData?.leave_type} :{" "}
              <label className="text-lg text-blue-800 font-bold bg-blue-50 rounded px-2">
                {Number(updateData?.total_leave_days)?.toFixed(2)}{" "}
                {Number(updateData?.total_leave_days)?.toFixed(2) > 1 ? (
                  <label className="text-xs">Days</label>
                ) : (
                  <label className="text-xs">Day</label>
                )}
              </label>
              {updateData?.half_day_info ? (
                <label className="text-xs bg-orange-100 text-yellow-600 py-1 px-2 rounded float-right	">
                  {updateData?.half_day_info}
                </label>
              ) : null}
            </div>
            <div className="flex justify-between my-3 items-center">
              <div className="flex flex-col border border-solid border-gray-300 rounded p-2 gap-1 w-fit">
                <label className="text-sm">
                  {dayjs(updateData?.from_date)?.format("dddd")}
                </label>
                <div className="flex items-center">
                  <label className="font-bold">
                    {dayjs(updateData?.from_date)?.format("MMMM DD")}
                  </label>
                  <label className="text-sm ml-2">
                    {dayjs(updateData?.from_date)?.format("YYYY")}
                  </label>
                </div>
              </div>
              <FontAwesomeIcon icon={faArrowRight} />
              <div className="flex flex-col border border-solid border-gray-300 rounded p-2 gap-1 w-fit">
                <label className="text-sm">
                  {dayjs(updateData?.to_date)?.format("dddd")}
                </label>
                <div className="flex items-center">
                  <label className="font-bold">
                    {dayjs(updateData?.to_date)?.format("MMMM DD")}
                  </label>
                  <label className="text-sm ml-2">
                    {dayjs(updateData?.to_date)?.format("YYYY")}
                  </label>
                </div>
              </div>
            </div>
            <label className="text-sm	font-semibold	mb-1">Employee Comment</label>
            <div className="border border-solid border-gray-300 rounded p-2 bg-slate-50 text-sm mb-3">
              {updateData?.description}
            </div>
            {/* <label className="text-sm	font-semibold	mb-1">
              Note From Management
            </label>
            <textarea
              id="message"
              rows="2"
              class="text-sm text-gray-900 bg-slate-50 rounded border border-gray-300 focus:ring-slate-500 focus:border-slate-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              onChange={(e) => setManagementComment(e?.target?.value)}
            ></textarea> */}
          </div>
          <div className="col-md-4">
            {/* <div
              className={`px-2 item-center w-fit mb-3 rounded ${
                updateData?.status === "Open"
                  ? "bg-blue-50 border-1 border-solid border-blue-200"
                  : updateData?.status === "Approved"
                  ? "bg-green-50 border-1 border-solid border-green-200"
                  : updateData?.status === "Rejected"
                  ? "bg-red-50 border-1 border-solid border-red-200"
                  : "bg-yellow-50 border-1 border-solid border-yellow-200"
              }`}
            >
              <FontAwesomeIcon
                icon={faCircle}
                className={`text-xs mr-2 ${
                  updateData?.status === "Open"
                    ? "text-blue-500"
                    : updateData?.status === "Approved"
                    ? "text-green-500"
                    : updateData?.status === "Rejected"
                    ? "text-red-600"
                    : "text-yellow-500"
                }`}
              />{" "}
              <label
                className={`${
                  updateData?.status === "Open"
                    ? "text-blue-500"
                    : updateData?.status === "Approved"
                    ? "text-green-500"
                    : updateData?.status === "Rejected"
                    ? "text-red-600"
                    : "text-yellow-500"
                }`}
              >
                {updateData?.status}
              </label>
            </div> */}
            <label className="mb-2 text-lg font-bold text-gray-950">
              History
            </label>

            {updateData?.status !== "Open" ? (
              <>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className={`${
                      updateData?.status === "Approved"
                        ? "text-green-500"
                        : updateData?.status === "Rejected"
                        ? "text-red-600"
                        : "text-yellow-400"
                    } "text-xs"`}
                  />{" "}
                  <label className="font-semibold text-sm">
                    Request {updateData?.status}
                  </label>
                  <label className="text-xs ml-5">
                    {dayjs(updateData?.modified)?.format("MMMM DD, YYYY")}
                  </label>
                </div>
                {updateData?.leaveHistory?.map((item) => {
                  return item?.status !== "Open" ? (
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={`${
                          item?.status === "Approved"
                            ? "text-green-500"
                            : item?.status === "Rejected"
                            ? "text-red-600"
                            : "text-yellow-400"
                        } "text-xs"`}
                      />{" "}
                      <label className="font-semibold text-sm">
                        Request {item.status}
                      </label>
                      <label className="text-xs ml-5">
                        {dayjs(item?.modified)?.format("MMMM DD, YYYY")}
                      </label>
                    </div>
                  ) : null;
                })}
              </>
            ) : null}
            <div className="mb-2">
              <FontAwesomeIcon
                icon={faCircle}
                className="text-yellow-200 text-xs"
              />{" "}
              <label className="font-semibold text-sm">Request Submitted</label>
              <label className="text-xs ml-5">
                {dayjs(updateData?.posting_date)?.format("MMMM DD, YYYY")}
              </label>
            </div>
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions className="employee_form_button_div">
        <button
          onClick={async () => (
            await dispatch(
              leaveUpdateStatusV1({
                status: "Rejected",
                leavelist: [updateData?.leaveApplicationId],
                management_comment: managementComment,
              })
            ),
            await getData({
              filterValue: "Work From Home",
              search: filterValues?.employee?.value,
              status: filterValues?.status?.value,
              fromDate: filterValues?.date?.from_date,
              toDate: filterValues?.date?.to_date,
            }),
            setAlert(true),
            // handleUpdate(status),
            setOpenEdit(false)
          )}
          className={`rounded border-1 border-solid border-red-500 px-3 py-1 text-red-500 bg-red-0 ${
            updateData?.status === "Rejected" ||
            updateData?.status === "Dismissed"
              ? "text-red-300 border border-solid border-red-300"
              : "hover:bg-red-500 hover:text-red-50"
          }`}
          disabled={
            updateData?.status === "Rejected" ||
            updateData?.status === "Dismissed"
          }
        >
          Reject Request
        </button>
        <button
          onClick={async () => (
            await dispatch(
              leaveUpdateStatusV1({
                status: "Approved",
                leavelist: [updateData?.leaveApplicationId],
                management_comment: managementComment,
              })
            ),
            await getData({
              filterValue: "Work From Home",
              search: filterValues?.employee?.value,
              status: filterValues?.status?.value,
              fromDate: filterValues?.date?.from_date,
              toDate: filterValues?.date?.to_date,
            }),
            setAlert(true),
            // handleUpdate(status),
            setOpenEdit(false)
          )}
          className={`rounded text-white px-3 py-1 ${
            updateData?.status !== "Approved"
              ? "bg-green-500 hover:bg-green-700"
              : "bg-green-300"
          } `}
          disabled={updateData?.status === "Approved"}
        >
          Approve Request
        </button>
      </DialogActions> */}
    </Dialog>
  );
};

export default UpdateWFHDialog;
