import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const resetTicketReplies = createAction("ticketRepliesreset");
export const fetchHelpdeskRecipientList = createAsyncThunk(
  END_POINTS.HELPDESK_RECIPIENT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.HELPDESK_RECIPIENT_LIST}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchHelpdeskTicket = createAsyncThunk(
  END_POINTS.HELPDESK_TICKET,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.HELPDESK_TICKET}?filterValue=${params.filterValue}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const createHelpdeskTicket = createAsyncThunk(
  END_POINTS.CREATE_HELPDESK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.CREATE_HELPDESK}`,
        params,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      );
      toast.success(data.message);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateHelpdeskTicket = createAsyncThunk(
  END_POINTS.UPDATE_HELPDESK,
  async (params,{rejectWithValue})=>{
    try {
      const { data } = await http.patch(`${END_POINTS.UPDATE_HELPDESK}`, {
       ...params
      });
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchHelpdeskRecord = createAsyncThunk(
  END_POINTS.HELPDESK_RECORD,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.HELPDESK_RECORD}`, {
        params: {
          start_date: params.fromDate,
          end_date: params.toDate,
          search: params.search,
          filterValue: params.status,
          priority : params.priorityVal,
          groupId : params.filterVal,
          sortField : params.sortField,
          sortOrder : params.sortOrder,
          page: params?.page,
          limit: params?.limit,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getTicketReplies = createAsyncThunk(
  END_POINTS.HELPDESK_REPLIES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.HELPDESK_REPLIES}/${params?.ticket_id}`
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchHelpdeskUser = createAsyncThunk(
  END_POINTS.HELPDESK_USER,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.HELPDESK_USER}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const sendTicketReply = createAsyncThunk(
  END_POINTS.SEND_HELPDESK_REPLY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.SEND_HELPDESK_REPLY}`, params
      );
      toast.success(data.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  recipientList: {
    isFetching: false,
    data: [],
  },
  ticketList: {
    isFetching: false,
    data: [],
  },
  ticketRecord: {
    isFetching: false,
    data: [],
  },
  ticketReplies: {
    isFetching: false,
    data: [],
  },
  helpdeskUser: {
    isFetching: false,
    data: [],
  },
  loading: false,
  error: null,
  success: false,
};

export const helpdeskSlice = createSlice({
  name: "helpdesk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHelpdeskRecipientList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHelpdeskRecipientList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.recipientList = payload;
        state.error = null;
      })
      .addCase(fetchHelpdeskRecipientList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.recipientList = [];
        state.success = false;
      })
      .addCase(fetchHelpdeskTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHelpdeskTicket.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.ticketList = payload;
        state.error = null;
      })
      .addCase(fetchHelpdeskTicket.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.ticketList = [];
      })
      .addCase(fetchHelpdeskRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHelpdeskRecord.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.ticketRecord = payload;
        state.error = null;
      })
      .addCase(fetchHelpdeskRecord.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.ticketRecord = [];
        state.success = false;
      })
      .addCase(getTicketReplies.pending, (state) => {
        state.ticketReplies.isFetching = true;
      })
      .addCase(getTicketReplies.fulfilled, (state, { payload }) => {
        state.ticketReplies.isFetching = false;
        state.ticketReplies.data = payload;
      })
      .addCase(getTicketReplies.rejected, (state, { payload }) => {
        state.ticketReplies.isFetching = false;
        state.ticketReplies.data = [];
      })
      .addCase(resetTicketReplies, (state) => {
        state.ticketReplies.data = [];
      })
      .addCase(fetchHelpdeskUser.pending, (state) => {
        state.helpdeskUser.isFetching = true;
      })
      .addCase(fetchHelpdeskUser.fulfilled, (state, { payload }) => {
        state.helpdeskUser.isFetching = false;
        state.helpdeskUser.data = payload;
      })
      .addCase(fetchHelpdeskUser.rejected, (state, { payload }) => {
        state.helpdeskUser.isFetching = false;
        state.helpdeskUser.data = [];
      });
  },
});

export default helpdeskSlice.reducer;
