import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { fetchEmployeeNameList } from "../../../store/slices/employeeSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import dayjs from "dayjs";
import RegularizationReviewChart from "./RegularizationReviewChart";
import { fetchMonthlyRegularizationData } from "../../../store/slices/leaveSlice";

const ReviewRegularizationSchema = yup.object().shape({
  EmployeeId: yup
    .object()
    .label("Employee Name")
    .required()
    ?.typeError("Employee Name is a required field"),
  year: yup
    .object()
    .label("Year")
    .required()
    ?.typeError("Year is a required field"),
});

const RegularizationReview = ({ setOpenReview }) => {
  const [back, setBack] = useState(false);
  const [Year, setYear] = useState();

  const methods = useForm({
    defaultValues: {
      EmployeeId: null,
      year: null,
    },
    resolver: yupResolver(ReviewRegularizationSchema),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
  }, [dispatch]);

  let employeeList = useSelector((state) => state?.employee?.employeeNameList);
  let year = [];
  for (let i = dayjs(new Date())?.get("year"); i >= 2022; i--) {
    year?.push(i);
  }
  const handleEmployeeChange = () => {
    setValue("year", null);
  };
  const submitHandler = async (data) => {
    data.EmployeeId = data?.EmployeeId?.value;
    data.year = data?.year?.value ? data?.year?.value : year[0];
    setYear(data.year);
    await dispatch(fetchMonthlyRegularizationData(data));
    setBack(true);
  };

  return (
    <div className="absolute top-0 right-0 z-10 w-100 h-screen">
      <div className="absolute top-0 left-0 z-10 w-100 h-100 bg-gray-950/40"></div>
      <div className="absolute top-0 right-0 z-20 w-3/6 h-100 bg-white">
        <div
          className="flex p-3 items-center justify-center absolute top-0 right-0 text-xl cursor-pointer"
          onClick={() => setOpenReview(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {!back && (
          <div className="">
            <div className="verifyAllocationHeader p-3  bg-indigo-50 font-semibold text-lg">
              Review Regularization
            </div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitHandler)}>
                <div className="p-3">
                  <div className="row mt-3">
                    <div className="col-md-8 mb-3">
                      <label>
                        Employee Name
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <AutoCompleteComponent
                        name="EmployeeId"
                        id="EmployeeId"
                        control={control}
                        errors={errors}
                        options={employeeList?.map((v) => ({
                          label: `${v?.EmployeeId} - ${v?.employee_name}`,
                          value: v?.EmployeeId,
                        }))}
                        onChangeHandler={handleEmployeeChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Year
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <AutoCompleteComponent
                        name="year"
                        id="year"
                        control={control}
                        errors={errors}
                        options={year?.map((v) => ({
                          label: v,
                          value: v,
                        }))}
                      />
                    </div>
                    <div className="col-md-12 mb-3 flex gap-3 justify-end">
                      <button
                        className="border-solid border-1 border-gray-400 rounded-full text-gray-500 bg-gray-50 hover:text-red-50 hover:bg-gray-400 py-1 px-4 "
                        id="3"
                        type="button"
                        onClick={() => setOpenReview(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="border-solid border-1 border-green-500 rounded-full text-green-50 bg-green-500 hover:text-green-50 hover:bg-green-600 py-1 px-4 "
                        id="3"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        )}
        {back && <RegularizationReviewChart setBack={setBack} Year={Year} />}
      </div>
    </div>
  );
};

export default RegularizationReview;
