import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const getLeavePlanList = createAsyncThunk(
  END_POINTS.LEAVE_PLAN_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LEAVE_PLAN_DATA_URL}?search=${params?.search || ""}`
      );
      //   toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const getLeaveConfigAllRoles = createAsyncThunk(
  END_POINTS.ALLOCATION_CONFIG_ROLES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.ALLOCATION_CONFIG_ROLES}`);
      //   toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const addNewLeavePlan = createAsyncThunk(
  END_POINTS.ADD_LEAVE_PLAN_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.ADD_LEAVE_PLAN_DATA_URL}`,
        params
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateExistingLeavePlan = createAsyncThunk(
  END_POINTS.UPDATE_LEAVE_PLAN_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_LEAVE_PLAN_DATA_URL}/${params?.id}`,
        params?.body
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const mapLeavePlan = createAsyncThunk(
  END_POINTS.MAP_LEAVE_PLAN,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.MAP_LEAVE_PLAN}/${params?.id}`,
        params?.body
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateLeaveType = createAsyncThunk(
  END_POINTS.UPDATE_LEAVE_TYPE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_LEAVE_TYPE}/${params?.id}`,
        params?.body
      );
      toast?.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateAllocationData = createAsyncThunk(
  END_POINTS.UPDATE_LEAVE_ALLOCATION_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_LEAVE_ALLOCATION_CONFIG}`,
        params
      );
      toast?.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateOTConfigData = createAsyncThunk(
  END_POINTS.UPDATE_OT_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_OT_CONFIG}/${params?.id}`,
        params?.body
      );
      toast?.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const addLeaveType = createAsyncThunk(
  END_POINTS.ADD_LEAVE_TYPE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.ADD_LEAVE_TYPE}`, params);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const addLeaveAllocationConfig = createAsyncThunk(
  END_POINTS.ADD_LEAVE_ALLOCATION_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.ADD_LEAVE_ALLOCATION_CONFIG}`,
        params
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const addoverTimeConfig = createAsyncThunk(
  END_POINTS.ADD_OT_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.ADD_OT_CONFIG}`, params);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const getLeaveType = createAsyncThunk(
  END_POINTS.GET_LEAVE_TYPE_WITH_PAGINATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_LEAVE_TYPE_WITH_PAGINATION}`,
        {
          params: {
            search: params?.search ?? "",
            // order: params?.order ?? "ASC",
            page: params?.page ?? 1,
            limit: params?.limit ?? 15,
            // sortBy: params?.sortBy ?? "id",
          },
        }
      );
      //   toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const getAllLeaveTypes = createAsyncThunk(
  END_POINTS.GET_LEAVE_TYPE_WITH_OUT_PAGINATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_LEAVE_TYPE_WITH_OUT_PAGINATION}`
      );
      //   toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const getOTConfigData = createAsyncThunk(
  END_POINTS.GET_OT_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_OT_CONFIG}/${params}`);
      //   toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const getAllLeaveAllocation = createAsyncThunk(
  END_POINTS.LEAVE_ALLOCATION_LIST_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LEAVE_ALLOCATION_LIST_CONFIG}`
      );
      //   toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getAssignedLeaveMapping = createAsyncThunk(
  END_POINTS.GET_MAPPED_PLANS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MAPPED_PLANS}/${params}`
      );
      //   toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getMappedConfigData = createAsyncThunk(
  END_POINTS.GET_MAPPED_CONFIGS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MAPPED_CONFIGS}/${params}`
      );
      //   toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  planList: {
    isFetching: false,
    response: null,
  },
  rolesList: {
    isFetching: false,
    response: null,
  },
  getOtConfigData: {
    isFetching: false,
    response: null,
  },
  addLeavePlan: {
    isFetching: false,
  },
  addLeaveTypeList: {
    isFetching: false,
  },
  getLeaveTypeList: {
    isFetching: false,
    data: [],
  },
  addLeaveAllocationConfigList: {
    isFetching: false,
    data: [],
  },
  addoverTimeConfigList: {
    isFetching: false,
    data: [],
  },
  getallLeaveTypesList: {
    isFetching: false,
    data: [],
  },
  getAllLeaveAllocationList: {
    isFetching: false,
    response: [],
  },
  getAssignedLeaveMappingList: {
    isFetching: false,
    response: [],
  },
  getMappedConfigDataList: {
    isFetching: false,
    response: [],
  },
  updateLeavePlan: {
    isFetching: false,
  },
  mapLeavePlan: {
    isFetching: false,
  },
  updateLeaveType: {
    isFetching: false,
  },
  updateAllocationData: {
    isFetching: false,
  },
  updateOTConfigData: {
    isFetching: false,
  },
  loading: false,
  error: null,
  success: false,
};
export const leaveConfigSlice = createSlice({
  name: "leaveConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLeavePlanList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.planList = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getLeavePlanList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.planList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getLeavePlanList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.planList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getLeaveConfigAllRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.rolesList = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getLeaveConfigAllRoles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.rolesList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getLeaveConfigAllRoles.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.rolesList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(addNewLeavePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addLeavePlan = {
          isFetching: true,
        };
      })
      .addCase(addNewLeavePlan.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.addLeavePlan = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(addNewLeavePlan.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.addLeavePlan = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(updateExistingLeavePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateLeavePlan = {
          isFetching: true,
        };
      })
      .addCase(updateExistingLeavePlan.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateLeavePlan = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(updateExistingLeavePlan.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateLeavePlan = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(mapLeavePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.mapLeavePlan = {
          isFetching: true,
        };
      })
      .addCase(mapLeavePlan.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.mapLeavePlan = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(mapLeavePlan.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.mapLeavePlan = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(updateLeaveType.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateLeaveType = {
          isFetching: true,
        };
      })
      .addCase(updateLeaveType.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateLeaveType = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(updateLeaveType.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateLeaveType = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(updateAllocationData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateAllocationData = {
          isFetching: true,
        };
      })
      .addCase(updateAllocationData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateAllocationData = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(updateAllocationData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateAllocationData = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(updateOTConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateOTConfigData = {
          isFetching: true,
        };
      })
      .addCase(updateOTConfigData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateOTConfigData = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(updateOTConfigData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateOTConfigData = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(addLeaveType.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addLeaveTypeList = {
          isFetching: true,
        };
      })
      .addCase(addLeaveType.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.addLeaveTypeList = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(addLeaveType.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.addLeaveTypeList = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(addLeaveAllocationConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addLeaveAllocationConfigList = {
          isFetching: true,
        };
      })
      .addCase(addLeaveAllocationConfig.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.addLeaveAllocationConfigList = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(addLeaveAllocationConfig.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.addLeaveAllocationConfigList = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(addoverTimeConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addoverTimeConfigList = {
          isFetching: true,
        };
      })
      .addCase(addoverTimeConfig.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.addoverTimeConfigList = {
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(addoverTimeConfig.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.addoverTimeConfigList = {
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(getLeaveType.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getLeaveTypeList = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getLeaveType.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getLeaveTypeList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getLeaveType.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getLeaveTypeList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getAllLeaveTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAllLeaveTypesList = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getAllLeaveTypes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAllLeaveTypesList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getAllLeaveTypes.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAllLeaveTypesList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getOTConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getOtConfigData = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getOTConfigData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getOtConfigData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getOTConfigData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getOtConfigData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getAllLeaveAllocation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAllLeaveAllocationList = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getAllLeaveAllocation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAllLeaveAllocationList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getAllLeaveAllocation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAllLeaveAllocationList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getAssignedLeaveMapping.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAssignedLeaveMappingList = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getAssignedLeaveMapping.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAssignedLeaveMappingList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getAssignedLeaveMapping.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAssignedLeaveMappingList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getMappedConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getMappedConfigDataList = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getMappedConfigData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getMappedConfigDataList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getMappedConfigData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getMappedConfigDataList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      });
  },
});
export default leaveConfigSlice.reducer;
