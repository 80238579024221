import { Link } from "react-router-dom";

const Missing = ({ success }) => {
  return (
    <>
      {!!success && success && (
        <div className="error-wrapper">
          <div className="error-content">
            <h1
              style={{
                fontWeight: "900",
                fontSize: "250px",
                background:
                  "linearGradient(#01a9f4, #0a3e5f, #0a3e5f, #01a9f4)",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              404
            </h1>
            <div className="error-inner-content">
              <h2>Oops! Page Not Found</h2>
              <p className="mb-4">The page you requested could not be found</p>
              <Link to="/Dashboard" className="newbutton home-button">
                Return Dashboard
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Missing;
