import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const fetchFiscalYearMonths = createAsyncThunk(
  END_POINTS.FISCAL_YEAR_MONTHS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.FISCAL_YEAR_MONTHS, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPayrollSummary = createAsyncThunk(
  "payroll/payroll-summary",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.PAYROLL_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAttendanceSummary = createAsyncThunk(
  "payroll/attendance-summary",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.ATTENDANCE_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchActionsSummary = createAsyncThunk(
  "payroll/actions-summary",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.ACTIONS_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchStructuresSummary = createAsyncThunk(
  "payroll/structure-stats",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.STRUCTURES_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchArrearsAndLeaveEncashment = createAsyncThunk(
  END_POINTS.ARREARS_LEAVE_ENCASHMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.ARREARS_LEAVE_ENCASHMENT, {
        params: params,
      });

      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllAttendanceData = createAsyncThunk(
  "payroll/attendance-summary-full",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.ATTENDANCE_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchArrearsLeaveEncashment = createAsyncThunk(
  "payroll/attendance-summary-full",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.ATTENDANCE_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchArrearsAndLeaveEncashmentByEmpId = createAsyncThunk(
  "payroll/arrears-leave-encashment",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.ARREARS_LEAVE_ENCASHMENT}/${params.employeeId}`,
        {
          params: { payrollStartDate: params.payrollStartDate },
        }
      );

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPayrollDashboardSummary = createAsyncThunk(
  END_POINTS.PAYROLL_DASHBOARD_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.PAYROLL_DASHBOARD_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchPayrollDashboardCounts = createAsyncThunk(
  END_POINTS.PAYROLL_DASHBOARD_COUNTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.PAYROLL_DASHBOARD_COUNTS, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchYearlyPayrollSummary = createAsyncThunk(
  END_POINTS.YEARLY_PAYROLL_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.YEARLY_PAYROLL_SUMMARY, {
        params: params,
      });

      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchCompleteAttendanceData = createAsyncThunk(
  END_POINTS.PAYROLL_COMPLETE_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.PAYROLL_COMPLETE_ATTENDANCE}`,
        {
          params: params,
        }
      );

      return { attendanceData: data?.data };
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPendingLeavesData = createAsyncThunk(
  END_POINTS.PAYROLL_PENDING_LEAVES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PAYROLL_PENDING_LEAVES}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchNoAttendanceData = createAsyncThunk(
  END_POINTS.PAYROLL_NO_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PAYROLL_NO_ATTENDANCE}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchRegularizeData = createAsyncThunk(
  END_POINTS.REGULARIZE_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.REGULARIZE_DATA}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const completeAttendanceNotificationById = createAsyncThunk(
  END_POINTS.ATTENDANCE_NOTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.ATTENDANCE_NOTIFICATION}/${params.EmployeeId}`,
        params
      );
      toast.success(data?.message);
      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const completeAttendanceNotification = createAsyncThunk(
  END_POINTS.ATTENDANCE_NOTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.ATTENDANCE_NOTIFICATION}`,
        {
          startDate: params.startDate,
          endDate: params.endDate,
        }
      );
      toast.success(data?.message);
      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const sendPendingLeaveNotification = createAsyncThunk(
  END_POINTS.PENDING_LEAVE_NOTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.PENDING_LEAVE_NOTIFICATION}`,
        { employeeLeaveData: params }
      );
      toast.success(data?.message);
      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const sendNoAttendanceNotification = createAsyncThunk(
  END_POINTS.NO_ATTENDANCE_NOTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.NO_ATTENDANCE_NOTIFICATION}`,
        { noAttendanceData: params }
      );
      toast.success(data?.message);
      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const sendRegularizeAttendanceNotification = createAsyncThunk(
  END_POINTS.REGULARIZE_ATTENDANCE_NOTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.REGULARIZE_ATTENDANCE_NOTIFICATION}`,
        { regularizeAttendanceData: params }
      );
      toast.success(data?.message);
      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const sendLWPNotification = createAsyncThunk(
  END_POINTS.LWP_NOTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.LWP_NOTIFICATION}`, {
        lwpData: params,
      });
      toast.success(data?.message);
      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchLWPData = createAsyncThunk(
  END_POINTS.PAYROLL_LWP,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PAYROLL_LWP}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchNewJoineeData = createAsyncThunk(
  END_POINTS.PAYROLL_NEW_JOINEES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PAYROLL_NEW_JOINEES}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchExitEmployeeListData = createAsyncThunk(
  END_POINTS.EXIT_EMPLOYEE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.EXIT_EMPLOYEE_LIST}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchBonusData = createAsyncThunk(
  END_POINTS.PAYROLL_BONUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PAYROLL_BONUS}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMiscellaneousDeductionsData = createAsyncThunk(
  END_POINTS.MISCELLANEOUS_DEDUCTIONS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.MISCELLANEOUS_DEDUCTIONS}`,
        {
          params: params,
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMiscellaneousDeductionsTypes = createAsyncThunk(
  END_POINTS.MISCELLANEOUS_DEDUCTIONS_TYPES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.MISCELLANEOUS_DEDUCTIONS_TYPES}`,
        {
          params: params,
        }
      );
      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchRevisionData = createAsyncThunk(
  END_POINTS.PAYROLL_REVISION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PAYROLL_REVISION}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPendingData = createAsyncThunk(
  END_POINTS.PAYROLL_PENDING,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PAYROLL_PENDING}`, {
        params: params,
      });

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeSalaryStatus = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYEE_SALARY_STATUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPDATE_EMPLOYEE_SALARY_STATUS}`,
        params
      );

      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeSalaryStatus = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_SALARY_STATUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.CREATE_EMPLOYEE_SALARY_STATUS}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchHoldSalaryEmployeeList = createAsyncThunk(
  END_POINTS.HOLD_SALARY_EMPLOYEE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.HOLD_SALARY_EMPLOYEE_LIST}`,
        {
          params: params,
        }
      );

      return data?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchOtDataForPayRoll = createAsyncThunk(
  END_POINTS.FETCH_OT_DATA_PAYROLL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_OT_DATA_PAYROLL}`, {
        params: {
          date: params?.payrollMonth,
          limit: params?.limit,
          page: params?.page,
          sortBy: params?.sortBy,
          order: params?.order,
          search: params?.search,
        },
      });

      return data?.response.rows;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPendingEmployeeOT = createAsyncThunk(
  END_POINTS.FETCH_PENDING_EMPLOYEE_OT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_PENDING_EMPLOYEE_OT}`, {
        params: {
          payrollMonth: params?.payrollMonth,
          limit: params?.limit,
          page: params?.page,
          sortBy: params?.sortBy,
          order: params?.order,
          search: params?.search,
        },
      });

      return data?.response;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeBonus = createAsyncThunk(
  END_POINTS.HOLD_BONUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(`${END_POINTS.HOLD_BONUS}`, params);
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeBonus = createAsyncThunk(
  END_POINTS.UPDATE_BONUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_BONUS}/${params?.bonusId}`,
        params?.data
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchReimbursementsData = createAsyncThunk(
  END_POINTS.REIMBURSEMENTS_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.REIMBURSEMENTS_LIST}`, {
        params: params,
      });
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const holdEmployeeReimbursement = createAsyncThunk(
  END_POINTS.HOLD_REIMBURSEMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.HOLD_REIMBURSEMENT}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllReimbursementTypes = createAsyncThunk(
  END_POINTS.MASTER_REIMBURSEMENT_TYPE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.MASTER_REIMBURSEMENT_TYPE, {
        params,
      });

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeReimbursement = createAsyncThunk(
  END_POINTS.UPDATE_REIMBURSEMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_REIMBURSEMENT}`,
        params?.data
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeReimbursement = createAsyncThunk(
  END_POINTS.CREATE_REIMBURSEMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.CREATE_REIMBURSEMENT}`,
        params?.data
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeList = createAsyncThunk(
  END_POINTS.EMPLOYEE_NAME_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.EMPLOYEE_NAME_LIST, {
        params,
      });

      return data.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateMiscDeduction = createAsyncThunk(
  END_POINTS.MISCELLANEOUS_DEDUCTIONS_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.MISCELLANEOUS_DEDUCTIONS_UPDATE}/${params?.miscDeductionId}`,
        params?.data
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const holdDeduction = createAsyncThunk(
  END_POINTS.HOLD_DEDUCTION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(`${END_POINTS.HOLD_DEDUCTION}`, params);
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const releaseDeduction = createAsyncThunk(
  END_POINTS.RELEASE_DEDUCTION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.RELEASE_DEDUCTION}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const generatePreview = createAsyncThunk(
  END_POINTS.GENERATED_PREVIEW,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.GENERATED_PREVIEW}/${params.date}`
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const previewList = createAsyncThunk(
  END_POINTS.PREVIEW_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PREVIEW_LIST}`, { params });
      // toast.success(data?.message );
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const finalizedSalarySlip = createAsyncThunk(
  END_POINTS.FINALIZED_SALARY_SLIP,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.FINALIZED_SALARY_SLIP}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const UpdatePayrollConfig = createAsyncThunk(
  END_POINTS.UPDATE_PAYROLL_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPDATE_PAYROLL_CONFIG}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getPayrollCycle = createAsyncThunk(
  END_POINTS.GET_PAYROLL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_PAYROLL_CYCLE}`,
        params
      );

      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPendingStructures = createAsyncThunk(
  END_POINTS.PENDING_STRUCTURES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PENDING_STRUCTURES}`, {
        params: params,
      });
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPerviousMonthPayrollHistory = createAsyncThunk(
  END_POINTS.PREVIOUS_MONTH_PAYROLL_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.PREVIOUS_MONTH_PAYROLL_CONFIG}/${params.selectedMonth}`
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPayrollConfigHistory = createAsyncThunk(
  END_POINTS.PAYROLL_CONFIG_HISTORY_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.PAYROLL_CONFIG_HISTORY_LIST}`,
        { params: params }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const ClosePayrollCycle = createAsyncThunk(
  END_POINTS.CLOSE_PAYROLL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.CLOSE_PAYROLL_CYCLE}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error:", error);
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPreviousClosedPayrollMonth = createAsyncThunk(
  END_POINTS.CLOSED_PAYROLL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.CLOSED_PAYROLL}`);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createOwnReimbursement = createAsyncThunk(
  END_POINTS.CREATE_OWN_REIMBURSEMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.CREATE_OWN_REIMBURSEMENT}`,
        params,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      );
      toast.success(data.message);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchOwnReimbursement = createAsyncThunk(
  END_POINTS.GET_OWN_REIMBURSEMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_OWN_REIMBURSEMENT}`, {
        params,
      });
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchReimbursementById = createAsyncThunk(
  END_POINTS.GET_REIMBURSEMENT_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_REIMBURSEMENT_BY_ID}/${params.reimbursementId}`
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateReimbursementById = createAsyncThunk(
  END_POINTS.UPDATE_REIMBURSEMENT_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_REIMBURSEMENT_BY_ID}/${params.reimbursementId}`,
        params.formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  fiscalYearMonths: { status: false, fiscalYearMonths: [] },
  payrollSummary: { status: false, payrollSummary: {} },
  payrollDashboardSummary: {
    isLoading: false,
    status: false,
    payrollDashboardSummary: {},
  },
  yearlyPayrollSummary: { status: false, yearlyPayrollSummary: {} },
  actionsSummary: { status: false, actionsSummary: {} },
  attendanceSummary: { status: false, attendanceSummary: {} },
  structureSummary: { status: false, structureSummary: {} },
  arrearsLeaveEncashment: { status: false, arrearsLeaveEncashment: {} },
  arrearsEncashmentByEmp: {
    loading: false,
    data: {
      arrears: 0,
      leave_encashment: 0,
    },
  },
  allAttendanceData: { status: false, allAttendanceData: {} },
  attendanceData: {},
  pendingLeavesData: [],
  noAttendanceData: [],
  lwpData: [],
  newJoineeData: [],
  bonus: [],
  pendingStructure: [],
  revision: [],
  regularizeData: {
    isFetching: false,
    data: [],
    count: 0,
  },
  loading: false,
  error: null,
  success: false,
  isFetching: false,
  exitEmpList: {},
  salaryOnHold: {},
  otDataPayRoll: {},
  pendingOTData: { isFetching: false, data: [] },
  reimbursementListData: {},
  reimbursementTypes: {},
  employeeListData: {},
  previewList: {},
  pendingStructures: {
    isFetching: false,
    data: {},
  },
  payrollCycleConfig: {
    isFetching: false,
    data: {},
  },
  payrollDashboardCounts: {
    isLoading: false,
    status: false,
    payrollDashboardCounts: {},
  },
  configPayrollHistroy: {
    isFetching: false,
    data: [],
  },
  sendRegularizeAttendanceNotification: {
    isFetching: false,
    status: false,
  },
  payrollClosedData: {},
  employeeReimbursement: {},
};

export const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: [],
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiscalYearMonths.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFiscalYearMonths.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.fiscalYearMonths = {
          status: true,
          fiscalYearMonths: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchFiscalYearMonths.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.fiscalYearMonths = { status: false, fiscalYearMonths: [] };
        state.success = false;
      })
      .addCase(fetchPayrollSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPayrollSummary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.payrollSummary = {
          status: true,
          payrollSummary: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchPayrollSummary.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.payrollSummary = { status: false, payrollSummary: {} };
        state.success = false;
      })
      .addCase(fetchPayrollDashboardSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.payrollDashboardSummary = {
          isLoading: true,
          status: false,
          payrollDashboardSummary: {},
        };
      })
      .addCase(fetchPayrollDashboardSummary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.payrollDashboardSummary = {
          isLoading: false,
          status: true,
          payrollDashboardSummary: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchPayrollDashboardSummary.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.payrollDashboardSummary = {
          status: false,
          isLoading: false,
          payrollDashboardSummary: {},
        };
        state.success = false;
      })
      .addCase(fetchYearlyPayrollSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchYearlyPayrollSummary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.yearlyPayrollSummary = {
          status: true,
          yearlyPayrollSummary: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchYearlyPayrollSummary.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.yearlyPayrollSummary = {
          status: false,
          yearlyPayrollSummary: {},
        };
        state.success = false;
      })
      .addCase(fetchAttendanceSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttendanceSummary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.attendanceSummary = {
          status: true,
          attendanceSummary: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchAttendanceSummary.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.attendanceSummary = { status: false, attendanceSummary: {} };
        state.success = false;
      })
      .addCase(fetchActionsSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActionsSummary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.actionsSummary = {
          status: true,
          actionsSummary: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchActionsSummary.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.actionsSummary = { status: false, actionsSummary: {} };
        state.success = false;
      })
      .addCase(fetchStructuresSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStructuresSummary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.structureSummary = {
          status: true,
          structureSummary: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchStructuresSummary.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.structureSummary = { status: false, structureSummary: {} };
        state.success = false;
      })
      .addCase(fetchArrearsAndLeaveEncashment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchArrearsAndLeaveEncashment.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.arrearsLeaveEncashment = {
            status: true,
            arrearsLeaveEncashment: payload.data,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchArrearsAndLeaveEncashment.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.arrearsLeaveEncashment = {
            status: false,
            arrearsLeaveEncashment: {},
          };
          state.success = false;
        }
      )
      .addCase(fetchAllAttendanceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAttendanceData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.allAttendanceData = {
          status: true,
          allAttendanceData: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchAllAttendanceData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allAttendanceData = {
          status: false,
          allAttendanceData: {},
        };
        state.success = false;
      })

      // fetch Salary Slip Emp
      .addCase(fetchArrearsAndLeaveEncashmentByEmpId.pending, (state) => {
        state.arrearsEncashmentByEmp.loading = true;
      })
      .addCase(
        fetchArrearsAndLeaveEncashmentByEmpId.fulfilled,
        (state, { payload }) => {
          state.arrearsEncashmentByEmp.data.arrears = parseFloat(
            payload?.arrears_amount ?? 0
          );
          state.arrearsEncashmentByEmp.data.leave_encashment = parseFloat(
            payload?.leave_encashment_amount ?? 0
          );
          state.arrearsEncashmentByEmp.loading = false;
        }
      )
      .addCase(
        fetchArrearsAndLeaveEncashmentByEmpId.rejected,
        (state, { payload }) => {
          state.arrearsEncashmentByEmp.loading = false;
          state.arrearsEncashmentByEmp.data = {};
        }
      )

      .addCase(fetchCompleteAttendanceData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCompleteAttendanceData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.attendanceData = payload.attendanceData;
      })
      .addCase(fetchCompleteAttendanceData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.attendanceData = {};
        state.success = false;
      })
      .addCase(fetchPendingLeavesData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPendingLeavesData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.pendingLeavesData = payload;
      })
      .addCase(fetchPendingLeavesData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.pendingLeavesData = [];
        state.success = false;
      })
      .addCase(fetchNoAttendanceData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchNoAttendanceData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.noAttendanceData = payload;
      })
      .addCase(fetchNoAttendanceData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.noAttendanceData = [];
        state.success = false;
      })
      .addCase(fetchRegularizeData.pending, (state) => {
        state.regularizeData.isFetching = true;
      })
      .addCase(fetchRegularizeData.fulfilled, (state, { payload }) => {
        state.regularizeData.isFetching = false;
        state.regularizeData.data = payload?.rows;
        state.regularizeData.count = payload?.totalRecord;
      })
      .addCase(fetchRegularizeData.rejected, (state, { payload }) => {
        state.regularizeData.isFetching = false;
        state.regularizeData.data = [];
        state.regularizeData.count = 0;
      })

      .addCase(fetchLWPData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLWPData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.lwpData = payload;
      })
      .addCase(fetchLWPData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.lwpData = [];
        state.success = false;
      })
      .addCase(fetchNewJoineeData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchNewJoineeData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.newJoineeData = payload;
      })
      .addCase(fetchNewJoineeData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.newJoineeData = [];
        state.success = false;
      })
      .addCase(fetchExitEmployeeListData.pending, (state) => {
        state.exitEmpList.isFetching = true;
      })
      .addCase(fetchExitEmployeeListData.fulfilled, (state, { payload }) => {
        state.exitEmpList.isFetching = false;
        state.exitEmpList.data = payload;
      })
      .addCase(fetchExitEmployeeListData.rejected, (state, { payload }) => {
        state.exitEmpList.isFetching = false;
        state.exitEmpList.data = {};
      })
      .addCase(fetchBonusData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBonusData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.bonus = payload;
      })
      .addCase(fetchBonusData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.bonus = [];
        state.success = false;
      })

      .addCase(fetchMiscellaneousDeductionsData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(
        fetchMiscellaneousDeductionsData.fulfilled,
        (state, { payload }) => {
          state.isFetching = false;
          state.success = true;
          state.msicDeductionData = payload;
        }
      )
      .addCase(
        fetchMiscellaneousDeductionsData.rejected,
        (state, { payload }) => {
          state.isFetching = false;
          state.msicDeductionData = [];
          state.success = false;
        }
      )

      .addCase(fetchMiscellaneousDeductionsTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(
        fetchMiscellaneousDeductionsTypes.fulfilled,
        (state, { payload }) => {
          state.isFetching = false;
          state.success = true;
          state.msicDeductionTypes = payload;
        }
      )
      .addCase(
        fetchMiscellaneousDeductionsTypes.rejected,
        (state, { payload }) => {
          state.isFetching = false;
          state.msicDeductionTypes = [];
          state.success = false;
        }
      )

      .addCase(fetchRevisionData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchRevisionData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.revision = payload;
      })
      .addCase(fetchRevisionData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.revision = [];
        state.success = false;
      })
      .addCase(fetchPendingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPendingData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.pendingStructure = payload;
      })
      .addCase(fetchPendingData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.pendingStructure = [];
        state.success = false;
      })
      .addCase(fetchHoldSalaryEmployeeList.pending, (state) => {
        state.salaryOnHold.isFetching = true;
      })
      .addCase(fetchHoldSalaryEmployeeList.fulfilled, (state, { payload }) => {
        state.salaryOnHold.isFetching = false;
        state.salaryOnHold.data = payload;
      })
      .addCase(fetchHoldSalaryEmployeeList.rejected, (state, { payload }) => {
        state.salaryOnHold.isFetching = false;
        state.salaryOnHold.data = [];
      })
      .addCase(fetchOtDataForPayRoll.pending, (state) => {
        state.otDataPayRoll.isFetching = true;
      })
      .addCase(fetchOtDataForPayRoll.fulfilled, (state, { payload }) => {
        state.otDataPayRoll.isFetching = false;
        state.otDataPayRoll.data = payload;
      })
      .addCase(fetchOtDataForPayRoll.rejected, (state, { payload }) => {
        state.otDataPayRoll.isFetching = false;
        state.otDataPayRoll.data = [];
      })

      .addCase(fetchPendingEmployeeOT.pending, (state) => {
        state.pendingOTData = { isFetching: true, data: [] };
      })
      .addCase(fetchPendingEmployeeOT.fulfilled, (state, { payload }) => {
        state.pendingOTData = { isFetching: false, data: payload };
      })
      .addCase(fetchPendingEmployeeOT.rejected, (state, { payload }) => {
        state.pendingOTData = { isFetching: false, data: [] };

      })
      .addCase(fetchReimbursementsData.pending, (state) => {
        state.error = null;
        state.success = false;
        state.isFetching = true;
      })
      .addCase(fetchReimbursementsData.fulfilled, (state, { payload }) => {
        state.error = null;
        state.success = true;
        state.isFetching = false;
        state.reimbursementListData = payload.data;
      })
      .addCase(fetchReimbursementsData.rejected, (state, { payload }) => {
        state.error = payload;
        state.success = false;
        state.isFetching = false;
        state.reimbursementListData = {};
      })
      .addCase(fetchAllReimbursementTypes.pending, (state) => {
        state.error = null;
        state.success = false;
        state.isFetching = true;
      })
      .addCase(fetchAllReimbursementTypes.fulfilled, (state, { payload }) => {
        state.error = null;
        state.success = true;
        state.isFetching = false;
        state.reimbursementTypes = payload;
      })
      .addCase(fetchAllReimbursementTypes.rejected, (state, { payload }) => {
        state.error = payload;
        state.success = false;
        state.isFetching = false;
        state.reimbursementTypes = {};
      })
      .addCase(fetchEmployeeList.pending, (state) => {
        state.error = null;
        state.success = false;
        state.isFetching = true;
      })
      .addCase(fetchEmployeeList.fulfilled, (state, { payload }) => {
        state.error = null;
        state.success = true;
        state.isFetching = false;
        state.employeeListData = payload;
      })
      .addCase(fetchEmployeeList.rejected, (state, { payload }) => {
        state.error = payload;
        state.success = false;
        state.isFetching = false;
        state.employeeListData = {};
      })
      .addCase(previewList.pending, (state) => {
        state.previewList.isFetching = true;
      })
      .addCase(previewList.fulfilled, (state, { payload }) => {
        state.previewList.isFetching = false;
        state.previewList.data = payload;
      })
      .addCase(previewList.rejected, (state, { payload }) => {
        state.previewList.isFetching = payload?.status;
        state.previewList.data = {};
      })
      .addCase(getPayrollCycle.pending, (state) => {
        state.payrollCycleConfig.isFetching = true;
      })
      .addCase(getPayrollCycle.fulfilled, (state, { payload }) => {
        state.payrollCycleConfig.isFetching = false;
        state.payrollCycleConfig.data = payload;
      })
      .addCase(getPayrollCycle.rejected, (state, { payload }) => {
        state.payrollCycleConfig.isFetching = payload?.status;
        state.payrollCycleConfig.data = {};
      })
      .addCase(fetchPendingStructures.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.pendingStructures = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchPendingStructures.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.pendingStructures = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(fetchPendingStructures.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.pendingStructures = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })
      .addCase(fetchPayrollDashboardCounts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.payrollDashboardCounts = {
          isLoading: true,
          status: false,
          payrollDashboardCounts: {},
        };
      })
      .addCase(fetchPayrollDashboardCounts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.payrollDashboardCounts = {
          isLoading: false,
          status: true,
          payrollDashboardCounts: payload.data,
        };
        state.error = null;
      })
      .addCase(fetchPayrollDashboardCounts.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.payrollDashboardCounts = {
          status: false,
          isLoading: false,
          payrollDashboardCounts: {},
        };
        state.success = false;
      })
      .addCase(fetchPayrollConfigHistory.pending, (state) => {
        state.configPayrollHistroy.isFetching = true;
      })
      .addCase(fetchPayrollConfigHistory.fulfilled, (state, { payload }) => {
        state.configPayrollHistroy.isFetching = false;
        state.configPayrollHistroy.data = payload.data;
      })
      .addCase(fetchPayrollConfigHistory.rejected, (state, { payload }) => {
        state.configPayrollHistroy.isFetching = payload?.status;
        state.configPayrollHistroy.data = {};
      })
      .addCase(fetchPreviousClosedPayrollMonth.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.payrollClosedData = {};
      })
      .addCase(
        fetchPreviousClosedPayrollMonth.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.payrollClosedData = payload.data;
          state.error = null;
        }
      )
      .addCase(
        fetchPreviousClosedPayrollMonth.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.payrollClosedData = {};
          state.success = false;
        }
      )
      .addCase(fetchPerviousMonthPayrollHistory.pending, (state) => {
        state.configPayrollHistroy.isFetching = true;
      })
      .addCase(
        fetchPerviousMonthPayrollHistory.fulfilled,
        (state, { payload }) => {
          state.configPayrollHistroy.isFetching = false;
          state.configPayrollHistroy.data = payload.data;
        }
      )
      .addCase(
        fetchPerviousMonthPayrollHistory.rejected,
        (state, { payload }) => {
          state.configPayrollHistroy.isFetching = payload?.status;
          state.configPayrollHistroy.data = {};
        }
      )
      .addCase(fetchOwnReimbursement.pending, (state) => {
        state.employeeReimbursement.isFetching = true;
      })
      .addCase(fetchOwnReimbursement.fulfilled, (state, { payload }) => {
        state.employeeReimbursement.isFetching = false;
        state.employeeReimbursement.data = payload.data;
      })
      .addCase(fetchOwnReimbursement.rejected, (state, { payload }) => {
        state.employeeReimbursement.isFetching = payload?.status;
        state.employeeReimbursement.data = {};
      })
      .addCase(sendRegularizeAttendanceNotification.pending, (state) => {
        state.sendRegularizeAttendanceNotification = {
          isFetching: true,
          status: false,
        };
      })
      .addCase(
        sendRegularizeAttendanceNotification.fulfilled,
        (state, { payload }) => {
          state.sendRegularizeAttendanceNotification = {
            isFetching: false,
            status: true,
          };
        }
      )
      .addCase(
        sendRegularizeAttendanceNotification.rejected,
        (state, { payload }) => {
          state.sendRegularizeAttendanceNotification = {
            isFetching: false,
            status: false,
          };
        }
      );
  },
});

export default payrollSlice.reducer;
