import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmDeleteLeavePlanDialog = ({
  setOpen,
  open,
  handleLeavePlanDelete,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <DialogTitle className="text-blue-600">
        <span>Delete Leave Plan</span>
      </DialogTitle>
      <DialogContent className="card-content-employee-form">
        Are you sure you want to delete this leave plan?
      </DialogContent>
      <DialogActions className="employee_form_button_div">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-600 hover:border-gray-600 py-2 px-3"
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={handleLeavePlanDelete}
          className="ml-2 text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-600 text-white hover:bg-blue-700 py-2 px-3"
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDeleteLeavePlanDialog;
