import { InputAdornment, MenuItem, Select, Tooltip } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles(() => ({
  errormsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "5px",
    marginBottom: "15px",
  },
  root: {
    paddingRight: "1px",
  },
  popper: {
    backgroundColor: "#DD0000",
  },
  popperArrow: {
    color: "#DD0000",
  },
  inputErrorBorder: {
    border: "1px solid #DD0000 !important",
    borderColor: "#DD0000 !important",
  },
}));

const SelectComponent = ({
  options,
  name,
  control,
  errors,
  id,
  required = false,
  handleChange,
  disabled = false,
  isTooltipError = false,
  width = "20px",
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, name, value, error } }) => (
          <Select
            id={id}
            options={options}
            name={name}
            required={required}
            disabled={disabled}
            sx={{
              "& fieldset": {
                border:
                  isTooltipError && getErrorMessage(name)
                    ? "1px solid #DD0000 !important"
                    : "",
              },
            }}
            error={isTooltipError && getErrorMessage(name) ? true : false}
            onChange={(e, value) => {
              onChange(value?.props?.value);
              handleChange && handleChange(value?.props?.value);
            }}
            endAdornment={
              <InputAdornment
                position="start"
                style={{
                  width: width ?? "20px",
                  right: "25px",
                  position: "relative",
                  zIndex: "0",
                }}
              >
                {!!isTooltipError && getErrorMessage(name) && (
                  <Tooltip
                    arrow
                    title={getErrorMessage(name)}
                    classes={{
                      tooltip: classes.popper,
                      arrow: classes.popperArrow,
                    }}
                  >
                    <InfoOutlinedIcon
                      sx={{ fontSize: 18, color: "#DD0000", margin: "0px 5px" }}
                    />
                  </Tooltip>
                )}
              </InputAdornment>
            }
            value={value || ""}
          >
            {options &&
              options?.map((data) => (
                <MenuItem key={data?.value} value={data?.value}>
                  {data?.label}
                </MenuItem>
              ))}
          </Select>
        )}
      />
      {!isTooltipError &&
        (error ? <p className={classes.errormsg}>{error}</p> : null)}
    </>
  );
};

export default SelectComponent;
