import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import PayrollMonthSelectComponent from "../PayrollMonthSelectComponent";
import OverTimeTable from "./OverTimeTable";
import PendingOverTimeReport from "./PendingOverTimeTable";
// import NewJoinees from "./NewJoinees";
// import ExitEmployeeList from "./ExitEmployeeList";

const OverTimeRecords = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [payrollMonth, setPayrollMonth] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  useEffect(() => {
    setValue("payroll_month", moment(new Date()).format("YYYY-MM-DD"));
  }, []);

  const [activeTab, setActiveTab] = useState("0");
  const [search, setSearch] = useState("");

  const tabs = [
    {
      id: "Overtime Report",
      label: "Overtime Report",
      access: true,
      element: (
        <OverTimeTable
          startDate={payrollMonth}
          endDate={payrollMonth}
          search={search}
        />
      ),
    },
    {
      id: "Pending Approval Overtime ",
      label: "Pending ApprovalOvertime ",
      access: true,
      element: (
        <PendingOverTimeReport
          startDate={payrollMonth}
          endDate={payrollMonth}
          search={search}
        />
      ),
    },
  ];

  const tabToRender = tabs[activeTab].element;
  const tabChangeHandler = (e) => {
    setActiveTab(e.target.id);
  };

  return (
    <>
      {/* main attendancetabs */}
      <div className="leave_Att_TopHeader">
        <ul className="leave_Att_TopHeader_Menu">
          <li
            className={activeTab === "0" ? "leaveAttTab active" : "leaveAttTab"}
            id="0"
            onClick={tabChangeHandler}
          >
            Approved Over-Time
          </li>
          <li
            className={activeTab === "1" ? "leaveAttTab active" : "leaveAttTab"}
            id="1"
            onClick={tabChangeHandler}
          >
            Pending Approval Over-Time
          </li>
        </ul>
        <PayrollMonthSelectComponent
          control={control}
          errors={errors}
          setPayrollMonth={setPayrollMonth}
        />
      </div>

      {/* components tab */}
      <div>{tabToRender}</div>
    </>
  );
};

export default OverTimeRecords;
