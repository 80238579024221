import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import PayrollMonthSelectComponent from "../PayrollMonthSelectComponent";
import Reimbursement from "./Reimbursement";
import Deductions from "./Deductions";
import { componentDisplayAccess } from "../../../utils/userPermission";

const ReimbursementAndDeductions = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [activeTab, setActiveTab] = useState("0");
  const [payrollMonth, setPayrollMonth] = useState(moment(new Date()).format('YYYY-MM-DD'));
  useEffect(()=>{
    setValue("payroll_month", moment(new Date()).format('YYYY-MM-DD'));
  }, []);

  const reimbursementAndDeductionAccess = componentDisplayAccess({
    component: "Payroll_Reimbursement_Deduction",
  })

  const tabs = [
    {
      id: "Reimbursement",
      label: "Reimbursement",
      access: true,
      element: (
        <Reimbursement
          startDate={payrollMonth}
          endDate={payrollMonth}
        />
      ),
    },
    {
      id: "deductions",
      label: "deductions",
      access: true,
      element: (
        <Deductions
          startDate={payrollMonth}
          endDate={payrollMonth}
        />
      ),
    },
  ];

  const tabToRender = tabs[activeTab].element;

  const tabChangeHandler = (e) => {
    setActiveTab(e.target.id);
  };

  return (
    <>
      {reimbursementAndDeductionAccess?.readAccess ?
        <div>
          {/* main attendancetabs */}
          <div className="leave_Att_TopHeader">
            <ul className="leave_Att_TopHeader_Menu">
              <li
                className={activeTab === "0" ? "leaveAttTab active" : "leaveAttTab"}
                id="0"
                onClick={tabChangeHandler}
              >
                Reimbursement
              </li>
              <li
                className={activeTab === "1" ? "leaveAttTab active" : "leaveAttTab"}
                id="1"
                onClick={tabChangeHandler}
              >
                Deductions
              </li>
            </ul>
            <PayrollMonthSelectComponent
              control={control}
              errors={errors}
              setPayrollMonth={setPayrollMonth}
            />
          </div>

          {/* components tab */}
          <div>{tabToRender}</div>
        </div> : null}
    </>
  );
};

export default ReimbursementAndDeductions;
