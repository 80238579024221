import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";

export const fetchCalendarData = createAsyncThunk(
  END_POINTS.CALENDAR_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.CALENDAR_DATA}`, {
        params: { employeeId :params.EmployeeId ,startDate: params.startDate, endDate: params.endDate },
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  calendarData: {},
  isFetching: false,
  error: null,
  success: false,
};

export const CalendarSlice = createSlice({
  name: "CalendarSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendarData.pending, (state) => {
        state.isFetching = true;
        state.error = null;
      })
      .addCase(fetchCalendarData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.calendarData = payload;
        state.error = null;
      })
      .addCase(fetchCalendarData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.error = payload;
        state.calendarData = {};
        state.success = false;
      });
  },
});

export default CalendarSlice.reducer;
