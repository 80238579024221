import React from "react";
import "../LeaveConfigDashboard.css";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import {
  addNewLeavePlan,
  getLeavePlanList,
} from "../../../store/slices/leaveConfigSlice";

const addLeavePlanSchema = yup?.object({
  planName: yup?.string()?.label("Leave Plan Name")?.required().trim(),
  description: yup
    ?.string()
    ?.label("Description")
    ?.optional()
    .nullable()
    .trim(),
  planStartDate: yup?.string()?.label("Plan Start Date")?.required().trim(),
});
const AddLeavePlan = ({ addLeavePlan, setAddLeavePlan }) => {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      planName: "",
      description: "",
      planStartDate: "",
    },
    resolver: yupResolver(addLeavePlanSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    trigger,
  } = methods;
  const isFetching = useSelector(
    (state) => state?.leaveConfig?.addLeavePlan?.isFetching
  );
  const submitHandler = async (data) => {
    await dispatch(addNewLeavePlan(data));
    await dispatch(getLeavePlanList());
    reset();
    setAddLeavePlan(false);
  };
  return (
    <>
      <div
        className={
          addLeavePlan ? "createLeaveDrawer active relative" : "createLeaveDrawer relative"
        }
      >
        <div className="bg-blue-500 text-white py-4 px-3 w-full">
          <div className="text-lg mb-2">
            <div className="flex items-center justify-between">
              <div>Add Leave Plan</div>
              <div
                className="headerClose cursor-pointer"
                onClick={() => {
                  setAddLeavePlan(false);
                  reset();
                }}
              >
                <FontAwesomeIcon className="fa-lg" icon={faXmark} />
              </div>
            </div>
            <div className="text-sm text-gray-200">
              Get started by filling the information below to add leave plan.
            </div>
          </div>
        </div>
        {/* {isFetching && <Loader />} */}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={addLeavePlan ? "active" : ""}>
              <div className="addLeaveForm bg-white">
                <div className="formContainer pt-4">
                  <div className="mb-3">
                    <label
                      htmlFor="planName"
                      className="block text-sm mb-1 text-gray-900"
                    >
                      Leave Plan Name<span className="text-red-500">*</span>
                    </label>
                    <TextFieldComponent
                      name="planName"
                      onChangeHandler={() => trigger("planName")}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="block text-sm mb-1 text-gray-900"
                    >
                      Description
                    </label>
                    <div className="font-sans">
                      <TextAreaComponent
                        name="description"
                        className="focus: shadow-none"
                        placeholder="Write your description here..."
                        onChangeHandler={() => trigger("description")}
                        control={control}
                        errors={errors}
                        rows={4}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="planStartDate"
                      className="block text-sm mb-1 text-gray-900"
                    >
                      Plan Start Date<span className="text-red-500">*</span>
                    </label>
                    <DatePickerComponent
                      name="planStartDate"
                      onChangeHandler={() => trigger("planStartDate")}
                      control={control}
                      errors={errors}
                      backSpaceChange={false}
                      disableMaskedInput={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end p-3">
              <button
                onClick={() => {
                  setAddLeavePlan(false);
                  reset();
                }}
                type="button"
                className="text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-600 hover:border-gray-600 py-2 px-3"
              >
                Cancel
              </button>
              <button
                type="submit"
                id={!isDirty ? "newDisable" : ""}
                className="ml-2 text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-600 text-white hover:bg-blue-700 py-2 px-3"
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          addLeavePlan ? "createLeaveOverlay active" : "createLeaveOverlay"
        }
        onClick={() => setAddLeavePlan(false)}
      ></div>
    </>
  );
};

export default AddLeavePlan;
