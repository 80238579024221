// import React from "react";
// import { Outlet } from "react-router-dom";
// import Navbar from "../Components/Navbar/Navbar";
// import TopHeader from "../Components/TopHeader/TopHeader";

// const Layout = () => {
//   return (
//     <main className="App">
//       <Navbar />
//       <section className="home-section dashboard">
//         <TopHeader />
//         <Outlet />
//       </section>
//     </main>
//   );
// };

// export default Layout;
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import TopHeader from "../Components/TopHeader/TopHeader";
const Navbar = React.lazy(() => import("../Components/Navbar/Navbar"));

const Layout = () => {
  return (
    <main className="App">
      <Suspense
        fallback={
          <div>
            {/* <Loader /> */}
          </div>
        }
      >
        <Navbar />
      </Suspense>
      <section className="home-section dashboard">
        <TopHeader />
        <Outlet />
      </section>
    </main>
  );
};

export default Layout;
