import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";

export const resetEmployeeProjects = createAction("employeeProjects");

export const fetchEmployeeDataStore = createAsyncThunk(
  "employee/all",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.EMPLOYEE_DATA_URL, {
        params: {
          filter: JSON.stringify([
            {
              filter_name: "status",
              filter_label: "Status",
              filter_condition: "=",
              filter_value: "Active",
            },
          ]),
          module: params.module,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeNameList = createAsyncThunk(
  "employee/id-list",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.EMPLOYEE_NAME_LIST, {
        params: {
          module: params.module,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchEmployeeManagementList = createAsyncThunk(
  END_POINTS.EMPLOYEE_MANAGEMENT_NAME_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.EMPLOYEE_MANAGEMENT_NAME_LIST}/${params}`);
      console.log('data:', data)
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeesUserIds = createAsyncThunk(
  END_POINTS.EMPLOYEE_USER_IDS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.EMPLOYEE_USER_IDS);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeCard = createAsyncThunk(
  END_POINTS.EMPLOYEE_CARD,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.EMPLOYEE_CARD}/${params}`);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const isTeamLead = createAsyncThunk(
  END_POINTS.IS_TEAM_LEAD,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.IS_TEAM_LEAD}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getEmployeeProjectsWithTeam = createAsyncThunk(
  END_POINTS.GET_EMPLOYEES_PROJECT_WITH_TEAM,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEES_PROJECT_WITH_TEAM}/${params.employeeId}`,
        { params: { startDate: params.startDate, endDate: params.endDate } }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
const initialState = {
  employeeList: [],
  employeeNameList: [],
  employeeManagementList: [],
  employeeUserIds: {
    isFetching: false,
    error: null,
    userIds: [],
  },
  employeeCardData: {
    isFetching: false,
    employeeCard: {},
  },
  loading: false,
  error: null,
  success: false,
  isFetching: false,
  userRole: {
    isTeamLead: false,
    isProjectManager: false,
    isFetching: false,
  },
  employeeProjects: {
    isFetching: false,
    data: [],
  },
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isFetching = true;
      })
      .addCase(fetchEmployeeDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.success = true;
        state.employeeList = payload.allEmployeeData;
        state.error = null;
      })
      .addCase(fetchEmployeeDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.error = payload;
        state.employeeList = [];
        state.success = false;
      })
      .addCase(fetchEmployeeNameList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeNameList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeNameList = payload.allEmployeeData;
        state.error = null;
      })
      .addCase(fetchEmployeeNameList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeNameList = [];
        state.success = false;
      })
      .addCase(fetchEmployeeManagementList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeManagementList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeManagementList = payload;
        state.error = null;
      })
      .addCase(fetchEmployeeManagementList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeManagementList = [];
        state.success = false;
      })

      // Fetch User Ids [emails]
      .addCase(fetchEmployeesUserIds.pending, (state) => {
        state.employeeUserIds.isFetching = true;
        state.employeeUserIds.error = null;
      })
      .addCase(fetchEmployeesUserIds.fulfilled, (state, { payload }) => {
        state.employeeUserIds.isFetching = false;
        state.employeeUserIds.userIds = payload?.allEmployeeUserNames;
        state.employeeUserIds.error = null;
      })
      .addCase(fetchEmployeesUserIds.rejected, (state, { payload }) => {
        state.employeeUserIds.isFetching = false;
        state.employeeUserIds.error = payload;
        state.employeeUserIds.userIds = [];
      })

      //Fetch Employee Card Data
      .addCase(fetchEmployeeCard.pending, (state) => {
        state.employeeCardData = {
          isFetching: true,
          employeeCard: {},
        };
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchEmployeeCard.fulfilled, (state, { payload }) => {
        state.employeeCardData = {
          isFetching: false,
          employeeCard: payload,
        };
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(fetchEmployeeCard.rejected, (state, { payload }) => {
        state.employeeCard = {
          isFetching: false,
          employeeCard: {},
        };
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      .addCase(isTeamLead.pending, (state) => {
        state.userRole.isFetching = true;
      })
      .addCase(isTeamLead.fulfilled, (state, { payload }) => {
        state.userRole.isFetching = false;
        state.userRole.isTeamLead = payload.isTeamLead;
        state.userRole.isProjectManager = payload.isProjectManager;
        state.userRole.reportingEmployeeIds = payload.reportingEmployeeIds;
      })
      .addCase(isTeamLead.rejected, (state) => {
        state.userRole.isFetching = false;
        state.userRole.isTeamLead = false;
        state.userRole.isProjectManager = false;
      })
      .addCase(getEmployeeProjectsWithTeam.pending, (state) => {
        state.employeeProjects.isFetching = true;
      })
      .addCase(getEmployeeProjectsWithTeam.fulfilled, (state, { payload }) => {
        state.employeeProjects.isFetching = false;
        state.employeeProjects.data = payload.data;
      })
      .addCase(getEmployeeProjectsWithTeam.rejected, (state) => {
        state.employeeProjects.isFetching = false;
        state.employeeProjects.data = [];
      })
      .addCase(resetEmployeeProjects, (state) => {
        state.employeeProjects.data = [];
      });
  },
});

export default employeeSlice.reducer;
