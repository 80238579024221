import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";

export const fetchConfigData = createAsyncThunk(
  "master/config",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.CONFIG_DATA}`);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  configData: [],
  loading: false,
  error: null,
  success: false,
};

export const ConfigDataSlice = createSlice({
  name: "ConfigDataSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConfigData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.configData = payload?.data;
        state.error = null;
      })
      .addCase(fetchConfigData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.configData = [];
        state.success = false;
      });
  },
});

export default ConfigDataSlice.reducer;
