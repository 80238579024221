import moment from "moment";
import CONSTANTS from "../constants/constants";
import jwt from "jwt-decode";
import { getAccessData } from "./token";

export const getFiscalYear = (selectedDate = "") => {
  let currentDate = selectedDate
    ? selectedDate
    : moment(new Date()).format("YYYY-MM-DD");
  //   currentDate = "2011-03-31";
  const currentYear = parseInt(currentDate.split("-")[0]);
  const fiscalYearStart = currentYear + "-03-31";
  const fiscalYear =
    currentDate <= fiscalYearStart
      ? currentYear - 1 + "-" + currentYear
      : currentYear + "-" + (currentYear + 1);
  return fiscalYear;
};

export const getFilterFields = (Filtertype) => {
  let newTypesFilter;
  switch (Filtertype) {
    //text values
    case "STRING":
    case "VARCHAR":
    case "TEXT":
      newTypesFilter = [
        {
          label: "Like",
          value: "like",
        },
        {
          label: "Equals",
          value: "=",
        },
        {
          label: "Not Equals",
          value: "!=",
        },
        {
          label: "In",
          value: "in",
        },
      ];
      break;
    //NUMERIC VALUES
    case "INTEGER":
    case "FLOAT":
    case "BIGINT":
    case "SMALLINT":
    case "TINYINT":
    case "DECIMAL":
    case "DOUBLE PRECISION":
      newTypesFilter = [
        {
          label: "Equals",
          value: "=",
        },
        {
          label: ">=",
          value: ">=",
        },
        {
          label: "<=",
          value: "<=",
        },
        {
          label: ">",
          value: ">",
        },
        {
          label: "<",
          value: "<",
        },
        {
          label: "Between",
          value: "between",
        },
        {
          label: "In",
          value: "in",
        },
        {
          label: "Not Equals",
          value: "!=",
        },
      ];
      break;
    //date values
    case "NOW":
    case "DATE":
    case "DATEONLY":
      newTypesFilter = [
        {
          label: "Equals",
          value: "=",
        },
        {
          label: ">=",
          value: ">=",
        },
        {
          label: "<=",
          value: "<=",
        },
        {
          label: ">",
          value: ">",
        },
        {
          label: "<",
          value: "<",
        },
        {
          label: "Between",
          value: "between",
        },
        {
          label: "Not Equals",
          value: "!=",
        },
      ];
      break;
    //boolean values
    case "BOOLEAN":
      newTypesFilter = [
        {
          label: "Equals",
          value: "=",
        },
        {
          label: "Not Equals",
          value: "!=",
        },
      ];
      break;

    case "TIME":
      newTypesFilter = [
        {
          label: "Equals",
          value: "=",
        },
      ];
      break;

    default:
      newTypesFilter = [
        {
          label: "Like",
          value: "like",
        },
        {
          label: "Equals",
          value: "=",
        },
        {
          label: ">=",
          value: ">=",
        },
        {
          label: "<=",
          value: "<=",
        },
        {
          label: ">",
          value: ">",
        },
        {
          label: "<",
          value: "<",
        },
        {
          label: "Between",
          value: "between",
        },
        {
          label: "In",
          value: "in",
        },
        {
          label: "Not Equals",
          value: "!=",
        },
      ];
  }

  return newTypesFilter;
};

/** Date Format for Display on ui
 * props : data => date value to format (String)
 *          dateFormat => format of date (String) (DefaultFormat : 01-Jan-2001)
 *  return formated Date Value (String)
 */
export const getFormatedDate = ({
  date,
  dateFormat = CONSTANTS.DD_MMM_YYYY,
}) => {
  const formatVal =
    date && date !== "Invalid Date"
      ? moment(new Date(date)).format(dateFormat)
      : null;

  return formatVal;
};
export const handleInputChange = (inputName, pattern, setIsEdited) => (e) => {
  const value = e.target.value.trim();
  const isValidInput = value.match(pattern) !== null;
  // const isValidInput =
  //   e.target.value.match(pattern) !== null && e.target.value.trim() !== "";
  setIsEdited((prev) => ({
    ...prev,
    [inputName]: !isValidInput && value !== "",
    isEdit: false,
  }));
};

// export const getPayrollStartEndDates = (payrollMonth) => {
//   const issalaryMonthValid = moment(payrollMonth, "YYYY-MM-DD", true).isValid();
//   if (!issalaryMonthValid) {
//     return { status: false, message: "Invalid Date !!!" };
//   }

//   let payrollStartDay = CONSTANTS.PAYROLL_CYCLE_DAY;
//   payrollStartDay = parseInt(payrollStartDay);
//   payrollStartDay =
//     payrollStartDay <= 9 ? "0" + payrollStartDay : payrollStartDay;

//   const payrollStartDate =
//     payrollMonth.split("-")[0] +
//     "-" +
//     payrollMonth.split("-")[1] +
//     "-" +
//     payrollStartDay;

//   const payrollEndDate = moment(payrollStartDate)
//     .add(1, "month")
//     .format("YYYY-MM-DD");

//   return { status: true, payrollStartDate, payrollEndDate };
// };

export const getPayrollStartEndDates = (payrollMonth) => {
  // const payrollMonth = "2023-02-24";
  const issalaryMonthValid = moment(payrollMonth, "YYYY-MM-DD", true).isValid();
  if (!issalaryMonthValid) {
    return { status: false, message: "Invalid Date !!!" };
  }

  let payrollStartDay = CONSTANTS.PAYROLL_CYCLE_DAY;
  payrollStartDay = parseInt(payrollStartDay);
  payrollStartDay =
    payrollStartDay <= 9 ? "0" + payrollStartDay : payrollStartDay;

  let payrollStartDate = moment(
    payrollMonth.split("-")[0] +
    "-" +
    payrollMonth.split("-")[1] +
    "-" +
    payrollStartDay
  )
    .subtract(1, "month")
    .format("YYYY-MM-DD");

  let payrollEndDate = moment(payrollStartDate)
    .add(1, "month")
    .subtract(1, "day")
    .format("YYYY-MM-DD");

  if (payrollMonth > payrollEndDate) {
    payrollStartDate = moment(payrollEndDate)
      .add(1, "day")
      .format("YYYY-MM-DD");
    payrollEndDate = moment(payrollStartDate)
      .add(1, "month")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
  }

  return { status: true, payrollStartDate, payrollEndDate };
};

export const getLoggedUserData = () => {
  let token = localStorage.getItem("accessToken");
  const state = token ? jwt(token) : {};

  return {
    id: state?.id ?? "",
    employeeId: state?.employeeId ?? "",
    employeeName: state?.employeeName ?? "",
    workMode: state?.workMode ?? "",
    gender: state?.gender ?? "",
    userRoles: state?.userRoles ?? "",
    componentAccess: state?.componentAccess ?? "",
    employeeCurrentWorkStatus: state?.employeeCurrentWorkStatus ?? "",
  };
};

export const getMaxObject = (...objects) => {
  const keys = objects
    .map(Object.keys)
    .reduce((acc, arr) => (acc.length > arr.length ? acc : arr), []);
  return keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: Math.max(...objects.map((o) => o[key] || 0)),
    }),
    {}
  );
};
export const getUserComponentAccess = (
  employeeId,
  module = "",
  component = ""
) => {
  let userAccessData = getAccessData();
  userAccessData = JSON.parse(userAccessData);
  const moduleAccess = userAccessData.roleModuleAccessData;
  const componentAccess = userAccessData.roleComponentAccessData;
  if (!employeeId) {
    return null;
  }

  let userComponentAccessData = {};

  for (const module in moduleAccess) {
    const moduleAccessData = moduleAccess[module];
    userComponentAccessData[module] = {};
    for (const component in componentAccess) {
      const componentAccessData = componentAccess[component];
      userComponentAccessData[module][component] = {};
      for (const key in moduleAccessData) {
        const finalReportingEmployees =
          moduleAccessData[key] === "Reporting-Excluding Self"
            ? userAccessData.reportingEmployees.filter(
              (emp) => emp !== `EMP/${userAccessData.employeeId}`
            )
            : userAccessData.reportingEmployees;

        userComponentAccessData[module][component][key] =
          moduleAccessData[key] === "All-Including Self" ||
            (moduleAccessData[key] === "All-Excluding Self" &&
              employeeId !== userAccessData.employeeId) ||
            (moduleAccessData[key].includes("Reporting") &&
              finalReportingEmployees.includes(`EMP/${employeeId}`)) ||
            (moduleAccessData[key] === "Self" &&
              employeeId === userAccessData.employeeId)
            ? { moduleAccess: true, componentAccess: componentAccessData }
            : {
              moduleAccess: false,
              componentAccess: {
                createAccess: 0,
                readAccess: 0,
                updateAccess: 0,
                deleteAccess: 0,
              },
            };
      }
    }
  }
  const dataToReturn =
    module && component
      ? userComponentAccessData?.[module]?.[component]
      : module
        ? userComponentAccessData?.[module]
        : userComponentAccessData;
  return dataToReturn;
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const getCurrentQuarterStartMidEndDates = () => {
  const currentMonth = parseInt(
    new Date().toISOString()?.split("T")[0]?.split("-")[1]
  );
  const currentYear = new Date().toISOString()?.split("T")[0]?.split("-")[0];
  const quarter = currentMonth / 3;
  const quarterStart =
    quarter <= 1
      ? `${currentYear}-01-01`
      : quarter > 1 && quarter <= 2
        ? `${currentYear}-04-01`
        : quarter > 2 && quarter <= 3
          ? `${currentYear}-07-01`
          : `${currentYear}-10-01`;

  const prevQuarterMid = moment(quarterStart)
    .subtract(45, "days")
    .format("YYYY-MM-DD");

  const quarterEnd = moment(quarterStart)
    .add(3, "months")
    .subtract(1, "day")
    .format("YYYY-MM-DD");
  return { status: true, quarterStart, prevQuarterMid, quarterEnd };
};

// const loggedEmployee = getLoggedUserData();
// export const getWeekOffs = async () => {
//   let array = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
//   let index = [];
//   store.dispatch(fetchWeekOffs(loggedEmployee?.employeeId))
//   // response?.data?.data?.weekly_off?.map((i) => {
//   //   index?.push(array?.findIndex((element) => element === i))
//   // })
//   // return index;
// }


export const getTwoDecimalFloatNumber = (value, elseReturnValue = '0.00') => {
  if (
    typeof parseFloat(value) === 'number' &&
    !Number.isNaN(parseFloat(value))
  ) {
    return parseFloat(value).toFixed(2)
  } else {
    return elseReturnValue;
  }
}

export const roundedAmountVal = (roundAmount) => {
  return(
    Math.round(Number(roundAmount)).toFixed(2)
    )
  }
export const toFixedAmountVal = (toFixedAmount) => {
  return(
    Number(toFixedAmount).toFixed(2)
    )
}