import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./MiscDeductionType.module.css";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import {
    fetchMiscDeductionTypeDataStore,
  fetchReimbursementTypeSliceDataStore,
} from "../../../../store/slices/masterSlice";
import { componentDisplayAccess } from "../../../../utils/userPermission";
import MiscDeductionTypeDialog from "./MiscDeductionTypeDialog";

const MiscDeductionTypeList = () => {
  const dispatch = useDispatch();
  const { miscDeductionTypeList } = useSelector((state) => state.masterList);

  const miscDeductionData = miscDeductionTypeList?.data;


  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);
  const [toggelDataUpdate, setToggelDataUpdate] = useState(false);
  const [miscDeductionTypeSelected, setMiscDeductionTypeSelected] = useState({});
  const [deletedData, setDeletedData] = useState("");
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [order, setOrder] = useState(null);
  const miscDeductionTypeDisplay = componentDisplayAccess({
    component: "HR_Branch",
  });
  const callme = () => {
    setMiscDeductionTypeSelected();
    setOpen(true);
  };

  useEffect(() => {
    dispatch(fetchMiscDeductionTypeDataStore());
  }, [fetchMiscDeductionTypeDataStore]);

//   const handleCloseDelete = () => {
//     setOpenDelete(false);
//   };

  const handleSortModelChange = React.useCallback((sortModel) => {
    if (sortModel && sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setOrder(sortModel[0].sort.toUpperCase());
    } else {
      setSortBy(null);
      setOrder(null);
    }
  }, []);
  //   useEffect(() => {
  //     fetchRecords();
  //   }, [initialIndex, pageSize, sortBy, order, toggelDataUpdate]);

//   const handleDialogDelete = async (dataDelete) => {
//     setDeletedData(dataDelete?.branch_id);
//     setOpenDelete(true);
//   };
//   const handleDelete = async () => {
//     try {
//       const data = {
//         branch_id: deletedData,
//         isDeleted: 1,
//       };
//       const deleteBranch = await http.patch(`master/update-branch`, data);
//       dispatch(fetchBranchSliceDataStore());
//       toast.success(deleteBranch.data.message);
//       //   fetchRecords();
//       setOpenDelete(false);
//     } catch (err) {
//       toast.error(err.response.data.message);
//       setOpenDelete(false);
//     }
//   };
  const [rows, setRows] = useState([]);
  const columns = useMemo(
    () => [
        // {
        //     field: "id",
        //     headerName: "ID",
        //     minWidth: 100,
        //     textAlign: "center",
        //     flex: 1,
        //     cellClassName: "All",
        //     hideable: false,
        //   },
        {
        field: "type_name",
        headerName: "Miscellaneous Deduction Type",
        minWidth: 100,
        textAlign: "center",
        flex: 1,
        cellClassName: "All",
        hideable: false,
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 150,
        flex: 1,
        cellClassName: "All",
      },
    //   {
    //     field: "edit",
    //     flex: 0.3,
    //     headerName: "Edit",
    //     minWidth: 80,
    //     type: "number",
    //     cellClassName: "All",
    //     sortable: false,
    //     renderCell: (action) => {
    //       return (
    //         <Button
    //           onClick={() => {
    //             setMiscDeductionTypeSelected({
    //               type_name: action.row.type_name,
    //               description: action.row.description,
    //             });
    //             setOpen(true);
    //           }}
    //         >
    //           <EditIcon />
    //         </Button>
    //       );
    //     },
    //   },
    //   {
    //     field: "delete",
    //     flex: 0.3,
    //     headerName: "Delete",
    //     minWidth: 80,
    //     cellClassName: "All",
    //     sortable: false,
    //     renderCell: (action) => {
    //       return (
    //         <React.Fragment>
    //           <DeleteIcon
    //             style={{ color: "gray" }}
    //             onClick={(e) => {
    //               handleDialogDelete(action.row);
    //             }}
    //           />
    //         </React.Fragment>
    //       );
    //     },
    //   },
    ],
    []
  );

  useEffect(() => {
    if (miscDeductionData) 
    setRows(
      miscDeductionData?.map((item) => ({
        id: nanoid(),
        type_name: item?.type_name,
        description: item?.description,
      }))
    );
  }, [miscDeductionData]);

  return (
    <div className="container-fluid">
      <div className="head">
        <div className={styles.leaveheading}>
          <h1>Miscellaneous Deduction Type List</h1>
        </div>
        {miscDeductionTypeDisplay?.createAccess ? (
          <div>
            <button onClick={callme} className="newbutton">
              + New
            </button>
          </div>
        ) : null}
      </div>
      <Box
        sx={{
          width: "100%",
          "& .All": {
            backgroundColor: "DFF6FF",
            color: "black",
            fontWeight: "300",
            padding: "2px 15px",
          },
        }}
      >
        {miscDeductionTypeDisplay?.readAccess ? (
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRecords}
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            page={initialIndex}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setInitialIndex(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            disableColumnFilter
            autoHeight
            columnVisibilityModel={
              !!miscDeductionTypeDisplay?.updateAccess
                ? !!miscDeductionTypeDisplay?.deleteAccess
                  ? { delete: true, edit: true }
                  : { delete: false, edit: true }
                : !!miscDeductionTypeDisplay?.deleteAccess
                ? { delete: true, edit: false }
                : { delete: false, edit: false }
            }
          />
        ) : null}
      </Box>
      {open && (
        <MiscDeductionTypeDialog
          open={open}
          setOpen={setOpen}
          defaultValue={miscDeductionTypeSelected}
          miscDeductionData={miscDeductionData}
          fetchMiscDeductionTypeDataStore={fetchMiscDeductionTypeDataStore}
          //   fetchRecords={fetchRecords}
        />
      )}
      {/* <Dialog open={openDelete} style={{ margin: "auto", width: "550px" }}>
        <DialogTitle className="leaveheading">Confirm</DialogTitle>
        <DialogContent className="text-center">
          <button onClick={handleCloseDelete} className="crossButton">
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <DialogContentText style={{ textAlign: "center", padding: "20px" }}>
            Are You Sure Want To Delete Branch?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            autoFocus
            variant="outlined"
            className="py-2"
            onClick={handleCloseDelete}
          >
            Cancel
          </Button>
          <Button className="newbutton text-white" onClick={handleDelete}>
            Ok
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};
export default MiscDeductionTypeList;
