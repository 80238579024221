export function getHeaderTitle(path, location, employeeName) {
  const HEADING = {
    Dashboard: `Welcome, ${employeeName ? employeeName : "User"}`,
    Employee: "Employee",
    AllEmployees: "Employees",
    Calendar: "Calendar",
    Attendance: "Attendance List",
    Salaryslip: "Salary Slip List",
    Policies: "Policies List",
    Holidays: "Holidays",
    TermAppraisal: "Term Appraisal List",
    EmployeeHandbook: "Employee Handbook",
    LeaveApplication: "Leave Application List",
    WFH: "Work From Home Application",
    LeaveAllocation: "Leave Allocation List",
    Regularization: "Regularization List",
    PayrollDashboard: "Payroll",
    SalarySlip: "Salary Slip List",
    "Salaryslip/": "ABCD", // Salary Edit or Read
    "Salaryslip/New": "New Salary Slip", // New Create
    "SalaryStructure/New": "Employee Salary Structure",
    HR: "Human Resources",
    SalaryStructure: "Salary Management",
    Home: "Home",
    Earnings: "Earning Type List",
    Deductions: "Deduction Type List",
    SalaryBracket: "Salary Bracket List",
    SalaryStructureList: "Salary Structure List",
    MealCharges: "Meal Charge History",
    LeaveTypeList: "Leave Type List",
    EmployeeShiftList: "Shift Types",
    HolidayListType: "Holiday List Types",
    DepartmentList: "Department List",
    DesignationList: "Designation List",
    BranchList: "Branch List",
    ClientList: "Client List",
    SkillsList: "Skill List",
    DailyMenuList: "Daily Menu List",
    ClientProjectList: "Client Projects List",
    EmploymentTypeList: "Employment Type List",
    TemporaryAccessCardList: "Temporary Access Card List",
    ReceiverGroup: "Receiver Group List",
    "HolidayListType/New": "New Holiday List Type",
    "EmployeeShiftList/New": "New Shift Type",
    "LeaveAllocation/New": "New Leave Allocation ",
    "SalaryBracket/New": "Salary Bracket Structure",
    Setup: "Setup",
    UsersList: "User List",
    RolesList: "Role List",
    ComponentsList: "Component List",
    ModulesList: "Data Access Group List",
    CronSchedulers: "Cron Schedulers",
    "ComponentsList/New": "New Component",
    "RolesList/New": "New Role",
    EmployeeDetails: "Employees",
    MyTeam: "My Team",
    ApprovalRequest: "Approvals",
    LunchRequestList: "Lunch Requests",
    Reports: "Reports",
    BonusAndRevisions: "Bonus & Revisions",
    SalaryHold: "Salary On Hold",
    NewJoineeAndExits: "New Joiners & Exits",
    // AttendanceDashboard: "Attendance And Timesheet",
    PayrollPreview: "Preview Payroll Salary Slips",
    EmailConfig: "Email Configurations",
    Configurations: "Configurations",
    EmailConfiguration: "Email Configuration",
    AttendanceDashboard: "Attendance & Time Sheet",
    // PayrollPreview: "Preview Salary & Run Payroll",
    Leaves: "Leave Application",
    Leave: "Leaves",
    EmployeeTermAppraisal: "Term Appraisal List",
    SalarySlipLandingPage: "Finance",
    HolidayListDashboard: "Holiday List Dashboard",
  };
  return HEADING[path] ? HEADING[location.pathname.replace("/", "")] : "";
}

// export default HEADING;
