import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";
export const resetTimeSheetSummary = createAction("employeeTimeSheetSummary");

export const fetchDesignationSliceDataStore = createAsyncThunk(
  "master/designation",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.DESIGNATION_DATA_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchDepartmentSliceDataStore = createAsyncThunk(
  "master/department",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.DEPARTMENT_DATA_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchBranchSliceDataStore = createAsyncThunk(
  "master/branch",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.BRANCH_DATA_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchReimbursementTypeSliceDataStore = createAsyncThunk(
  "payroll/reimbursement-type",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_REIMBURSEMENT_TYPE);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
//

export const fetchMiscDeductionTypeDataStore = createAsyncThunk(
  "payroll/misc-deduction-types",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_MISC_DEDUCTION_TYPE);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmploymentTypeSliceDataStore = createAsyncThunk(
  "master/employment-type",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.EMPLOYMENT_DATA_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchHolidaySliceDataStore = createAsyncThunk(
  END_POINTS.HOLIDAY_LIST_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.HOLIDAY_LIST_URL}/${params.EmployeeId}?fiscalYear=${params.year}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchSkillsSliceDataStore = createAsyncThunk(
  "master/skill",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.SKILL_DATA_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchClientSliceDataStore = createAsyncThunk(
  "master/clientList",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.CLIENT_LIST_URL);
      let clientNames = data?.data?.clientData?.map(
        (value) => value?.clientName
      );
      return clientNames;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchDeclarationSliceDataStore = createAsyncThunk(
  "declaration/declaration-section",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.DECLARATION_SECTION_URL, {
        setAuthHeader: true,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchDeclarationTypeSliceDataStore = createAsyncThunk(
  "declaration/declaration-type",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.DECLARATION_TYPE_URL, {
        setAuthHeader: true,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchMealDataStore = createAsyncThunk(
  END_POINTS.MEAL_LIST_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.MEAL_LIST_URL, {
        setAuthHeader: true,
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const postMealFeedBack = createAsyncThunk(
  END_POINTS.MEAL_FEEDBACK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.MEAL_FEEDBACK}`, params);
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const postTemporaryAccessCard = createAsyncThunk(
  END_POINTS.POST_TEMPORARY_ACCESS_CARD,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.POST_TEMPORARY_ACCESS_CARD}`,
        params
      );
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAttRegularizationReasons = createAsyncThunk(
  "master/attRegularizationReasons",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.Att_REGULARIZATION_REASONS}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch Department By Id And Name
export const fetchDepartmentTypeSliceDataStore = createAsyncThunk(
  END_POINTS.DEPARTMENT_TYPE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.DEPARTMENT_TYPE_DATA_URL);
      return data?.data?.departmentTypeData?.rows;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch Designation By Id And Name
export const fetchDesignationTypeSliceDataStore = createAsyncThunk(
  END_POINTS.DESIGNATION_TYPE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.DESIGNATION_TYPE_DATA_URL);
      return data?.data?.designationTypeData?.rows;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch Branch By Id And Name
export const fetchBranchTypeSliceDataStore = createAsyncThunk(
  END_POINTS.BRANCH_TYPE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.BRANCH_TYPE_DATA_URL);
      return data?.data?.branchTypeData?.rows;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch Branch By Id And Name
export const fetchEmploymentTypeListSliceDataStore = createAsyncThunk(
  END_POINTS.EMPLOYMENT_TYPE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.EMPLOYMENT_TYPE_DATA_URL);
      return data?.data?.employmentTypeData?.rows;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch Branch By Id And Name
export const fetchHolidayTypeSliceDataStore = createAsyncThunk(
  END_POINTS.HOLIDAY_TYPE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.HOLIDAY_TYPE_DATA_URL);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch Branch By Id And Name
export const fetchCompanyTypeSliceDataStore = createAsyncThunk(
  END_POINTS.COMPANY_TYPE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.COMPANY_TYPE_DATA_URL);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch Skills Set
export const fetchSkillsSetStore = createAsyncThunk(
  END_POINTS.SKILLS_SET,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.SKILLS_SET);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Client Creation , Updation and Fetching

export const fetchOrganizationClient = createAsyncThunk(
  END_POINTS.ORGANIZATION_CLIENT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.ORGANIZATION_CLIENT_LIST}?page=${
          params?.page || ""
        }&limit=${params?.limit || ""}&filterField=${
          params?.filterField || ""
        }&filterValue=${params?.filterValue || ""}&search=${
          params.search || ""
        }`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createOrganizationClientStore = createAsyncThunk(
  END_POINTS.CREATE_ORGANIZATION_CLIENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_ORGANIZATION_CLIENT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateOrganizationClientStore = createAsyncThunk(
  END_POINTS.UPDATE_ORGANIZATION_CLIENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_ORGANIZATION_CLIENT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Projeect Creation , Updation and Fetching

export const fetchClientProject = createAsyncThunk(
  END_POINTS.CLIENT_PROJECT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.CLIENT_PROJECT_LIST}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createClientProjectStore = createAsyncThunk(
  END_POINTS.CREATE_CLIENT_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_CLIENT_PROJECT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateClientProjectStore = createAsyncThunk(
  END_POINTS.UPDATE_CLIENT_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_CLIENT_PROJECT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Client Logo upload

export const uploadClientLogo = createAsyncThunk(
  END_POINTS.UPLOAD_CLIENT_LOGO,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.UPLOAD_CLIENT_LOGO,
        params?.data,
        params?.headers
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      console.log("error:", error);
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Add and Get employee in Project

export const addEmployeesInProject = createAsyncThunk(
  END_POINTS.ADD_EMPLOYEES_IN_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.ADD_EMPLOYEES_IN_PROJECT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Delete Employee From Project

export const deleteEmployeesFromProject = createAsyncThunk(
  END_POINTS.DELETE_EMPLOYEES_FROM_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.DELETE_EMPLOYEES_FROM_PROJECT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getCurrencylist = createAsyncThunk(
  END_POINTS.MASTER_CURRENCY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.MASTER_CURRENCY);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Time sheet new task create

export const createNewTimeSheetTask = createAsyncThunk(
  END_POINTS.CREATE_NEW_TIMESHEET_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_NEW_TIMESHEET_TASK,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Time Team sheet new task create

export const createNewTeamTimeSheetTask = createAsyncThunk(
  END_POINTS.CREATE_NEW_TEAM_TIMESHEET_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_NEW_TEAM_TIMESHEET_TASK,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Self timesheet task list

export const fetchSelfTimeSheetTaskList = createAsyncThunk(
  END_POINTS.SELF_TIMESHEET_TASK_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.SELF_TIMESHEET_TASK_LIST}/${params.employeeId}`,
        {
          params: {
            startDate: params.fromDate,
            endDate: params.toDate,
            filterValue: params.filterBy,
            projectId: params.filterByProject,
            limit: params.limit,
            page: params.page,
            self: params?.self,
          },
        }
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Team timesheet task list

export const fetchTeamTimeSheetTaskList = createAsyncThunk(
  END_POINTS.TEAM_TIMESHEET_TASK_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.TEAM_TIMESHEET_TASK_LIST}`,
        {
          params: {
            search: params?.search,
            page: params?.page,
            limit: params?.limit,
            startDate: params?.fromDate,
            endDate: params?.toDate,
            project_id: params?.project_id,
            filterValue: params?.filterBy,
          },
        }
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchTeamTimeSheetReport = createAsyncThunk(
  END_POINTS.TEAM_TIMESHEET_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.TEAM_TIMESHEET_REPORT}`, {
        params: {
          search: params?.search,
          page: params?.page,
          limit: params?.limit,
          projectId: params?.projectId,
          startDate: params?.fromDate,
          endDate: params?.toDate,
        },
      });
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchEmployeeTimeSheetReport = createAsyncThunk(
  END_POINTS.EMPLOYEE_TIMESHEET_TASK_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_TIMESHEET_TASK_REPORT}/${params?.employeeId}`,
        {
          params: {
            search: params?.search,
            page: params?.page,
            limit: params?.limit,
            startDate: params?.fromDate,
            endDate: params?.toDate,
          },
        }
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
// export const fetchTeamTimeSheetReport = createAsyncThunk(
//   END_POINTS.TEAM_TIMESHEET_REPORT,
//   async (params, { rejectWithValue }) => {
//     try {
//       const { data } = await http.get(
//         `${END_POINTS.TEAM_TIMESHEET_REPORT}`,
//         {
//           params: {
//             search: params?.search,
//             page: params?.page,
//             limit: params?.limit,
//             startDate: params?.fromDate,
//             endDate: params?.toDate,
//           },
//         }
//       );
//       // toast.success(data?.message);
//       return data;
//     } catch (error) {
//       toast.error(error?.response?.data?.message);
//       return rejectWithValue(error.response ? error.response.data : error);
//     }
//   }
// );
// s
// Dismiss Timesheet Task

export const fetchAllProjectList = createAsyncThunk(
  END_POINTS.GET_ALL_PROJECT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_ALL_PROJECT_LIST);
      console.log("data:", data);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response ? error?.response?.data : error);
    }
  }
);

export const dismissTimeSheetSelfTask = createAsyncThunk(
  END_POINTS.DISMISS_TIMESHEET_SELF_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.DISMISS_TIMESHEET_SELF_TASK,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Approve/Reject Timesheet Task

export const approveTimeSheetTask = createAsyncThunk(
  END_POINTS.APPROVE_TIMESHEET_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.APPROVE_TIMESHEET_TASK,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Download/Export Employee Time sheet Task List
export const downloadEmployeeTimeSheetTask = createAsyncThunk(
  END_POINTS.DOWNLOAD_EMPLOYEE_TIMESHEET_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.DOWNLOAD_EMPLOYEE_TIMESHEET_TASK}/${params?.employeeId}`,
        {
          params: {
            startDate: params.fromDate,
            endDate: params.toDate,
            filterValue: params.status,
            projectId: params.projects,
            columns: params.columns,
          },
        }
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const getCalculateOtData = createAsyncThunk(
  END_POINTS.GET_CALCULATE_OT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_CALCULATE_OT}/${params?.employeeId}?payrollMonth=${params?.payrollMonth}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// New Attendance List of particular employee

export const fetchParticularEmployeeAttendanceList = createAsyncThunk(
  END_POINTS.EMPLOYEE_ATTENDANCE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_ATTENDANCE_LIST}`,
        {
          params: {
            employeeId: params.EmployeeId,
            fromDate: params.fromDate,
            toDate: params.toDate,
            order: params.sort,
          },
        }
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Create New Attendance

export const createNewAttendance = createAsyncThunk(
  END_POINTS.CREATE_NEW_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_NEW_ATTENDANCE,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Update Attendance

export const updateAttendance = createAsyncThunk(
  END_POINTS.UPDATE_NEW_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_NEW_ATTENDANCE}/${params?.attendanceId}`,
        params?.data
      );
      console.log("data:", data);
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMailConfigList = createAsyncThunk(
  END_POINTS.GET_MAIL_CONFIG_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_MAIL_CONFIG_LIST);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmailConfigStatus = createAsyncThunk(
  END_POINTS.UPDATE_MAIL_CONFIG_STATUS,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_MAIL_CONFIG_STATUS}/${params?.configId}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmailConfig = createAsyncThunk(
  END_POINTS.CREATE_MAIL_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(`${END_POINTS.CREATE_MAIL_CONFIG}`, params);
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const createApproveOT = createAsyncThunk(
  END_POINTS.CREATE_OT_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(`${END_POINTS.CREATE_OT_CONFIG}`, params);
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const createNewConfig = createAsyncThunk(
  END_POINTS.CREATE_NEW_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(`${END_POINTS.CREATE_NEW_CONFIG}`, params);
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateConfigData = createAsyncThunk(
  END_POINTS.UPDATE_MASTER_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_MASTER_CONFIG}/${params?.Id}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createMasterApprovalConfigData = createAsyncThunk(
  END_POINTS.CREATE_MASTER_CONFIG_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_MASTER_CONFIG_DATA}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateMasterApprovalConfigData = createAsyncThunk(
  END_POINTS.UPDATE_MASTER_CONFIG_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_MASTER_CONFIG_DATA}/${params?.Id}`,
        params?.submitData
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getMasterConfigData = createAsyncThunk(
  END_POINTS.GET_MASTER_CONFIG_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.GET_MASTER_CONFIG_DATA}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchRolesAndComponent = createAsyncThunk(
  END_POINTS.MASTER_ROLES_AND_COMPONENT,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.MASTER_ROLES_AND_COMPONENT}`,
        params
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllCompanyEmployee = createAsyncThunk(
  END_POINTS.ALL_COMPANY_EMPLOYEES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.ALL_COMPANY_EMPLOYEES);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch All Leave Approvers
export const fetchAllReportingManagers = createAsyncThunk(
  END_POINTS.FETCH_ALL_REPORTING_MANAGERS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.FETCH_ALL_REPORTING_MANAGERS);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//Fetch All Head Of Department
export const fetchAllHods = createAsyncThunk(
  END_POINTS.FETCH_ALL_HODS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.FETCH_ALL_HODS);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Organization client approvers level config data

export const fetchOrgClientsApproverConfig = createAsyncThunk(
  END_POINTS.PROJECT_APPROVER_CONFIG,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.PROJECT_APPROVER_CONFIG}`, {
        params: {
          project_id: params.project_id,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Fetch employees from all the projects assigned

export const fetchEmployeesFromAllProjects = createAsyncThunk(
  END_POINTS.EMPLOYEES_FROM_ALL_PROJECTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEES_FROM_ALL_PROJECTS}`,
        params
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Get Time sheet History

export const fetchTimeSheetHistory = createAsyncThunk(
  END_POINTS.GET_TIMESHEET_HISTORY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_TIMESHEET_HISTORY}/${params}`,
        {}
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Employee Time sheet summary

export const getEmployeesTimeSheetSummary = createAsyncThunk(
  END_POINTS.EMPLOYEE_TIME_SHEET_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_TIME_SHEET_SUMMARY}/${params?.employeeId}?start_date=${params?.start_date}&end_date=${params?.end_date}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//  Create New Holiday Type Slice

export const CreateNewHolidayTypeSlice = createAsyncThunk(
  END_POINTS.CREATE_HOLIDAY_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(END_POINTS.CREATE_HOLIDAY_LIST, params);
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//  Update / Delete a Holiday Type Slice

export const UpdateNewHolidayTypeSlice = createAsyncThunk(
  END_POINTS.UPDATE_HOLIDAY_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_HOLIDAY_LIST}/${params}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//  Create / Update a New Holiday

export const CreateNewHolidaySlice = createAsyncThunk(
  "patchHoliday",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.HOLIDAY_LIST_URL}`,
        params?.data,
        params?.headers
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Get Holiday List of a Holiday Type

export const getHolidaysList = createAsyncThunk(
  "getHolidayList",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.HOLIDAY_LIST_URL}?fiscalYear=${params?.fiscalYear}&holidayListId=${params?.holidayListId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  designationData: { status: false, designationList: [] },
  departmentData: { status: false, departmentList: [] },
  branchData: { status: false, branchList: [] },
  employmentTypeData: { status: false, employmentTypeList: [] },
  holidayData: { status: false, holidayList: [] },
  skillData: { status: false, skillList: [] },
  clientData: { status: false, clientList: [] },
  declarationData: { status: false, declarationList: [] },
  declarationTypeData: { status: false, declarationTypeList: [] },
  declarationSectionData: { status: false, declarationSectionList: [] },
  todayMealData: { status: false, mealList: [], isFetching: true },
  mealFeedbackData: { status: false },
  temporaryAccessCard: { isFetching: false, status: false },
  attRegularizationReasons: { status: false, reasons: [], isFetching: true },
  designationTypeData: { isFetching: false, designationTypeList: [] },
  departmentTypeData: { isFetching: false, departmentTypeList: [] },
  branchTypeData: { isFetching: false, branchTypeList: [] },
  employmentTypeListData: { isFetching: false, employmentType: [] },
  holidayTypeData: { isFetching: false, holidayTypeList: [] },
  companyTypeData: { isFetching: false, companyTypeList: [] },
  skillsSetData: { isFetching: false, skillsSetList: [] },
  organizationClientResponse: {
    isFetching: false,
    organizationClientData: null,
  },
  createOrganizationClientResponse: {
    isFetching: false,
    response: null,
  },
  updateOrganizationClientResponse: {
    isFetching: false,
    response: null,
  },
  clientProjectResponse: {
    isFetching: false,
    clientProjectData: null,
  },
  createClientProjectResponse: {
    isFetching: false,
    response: null,
  },
  updateClientProjectResponse: {
    isFetching: false,
    response: null,
  },
  uploadClientLogoResponse: {
    isFetching: false,
    response: null,
  },
  addEmployeeInProjectResponse: {
    isFetching: false,
    response: null,
  },
  deleteEmployeeFromProjectResponse: {
    isFetching: false,
    response: null,
  },
  currencyList: {
    isFetching: false,
    data: [],
  },
  createNewTimeSheetTaskData: {
    isFetching: false,
    response: null,
  },
  createNewTeamTimeSheetTaskData: {
    isFetching: false,
    response: null,
  },
  dismissTimeSheetSelfTaskStore: {
    isFetching: false,
    response: null,
  },
  selfTimeSheetTaskList: { isFetching: false, data: [] },
  teamTimeSheetTaskList: { isFetching: false, data: [] },
  approveTimeSheetTaskStore: {
    isFetching: false,
    response: null,
  },
  employeeAttendanceListData: { isFetching: false, data: [] },
  createNewAttendanceData: {
    isFetching: false,
    response: null,
  },
  updateAttendanceData: {
    isFetching: false,
    response: null,
  },
  downloadEmployeeTimeSheet: {
    isFetching: false,
    data: [],
  },
  calculatedOtData: {
    isFetching: false,
    data: [],
  },
  mailConfigList: {
    isFetching: false,
    data: [],
  },
  updateEmailConfigStatusData: {
    isFetching: false,
    response: null,
  },
  createEmailConfigData: {
    isFetching: false,
    response: null,
  },
  createApproveOTData: {
    isFetching: false,
    response: null,
  },
  createConfigData: {
    isFetching: false,
    response: null,
  },
  updateConfigDataValue: {
    isFetching: false,
    response: null,
  },
  getConfigDataValue: {
    isFetching: false,
    response: [],
  },
  createMasterApprovalConfigDataValue: {
    isFetching: false,
  },
  updateMasterApprovalConfigDataValue: {
    isFetching: false,
  },
  rolesAndComponents: {
    isFetching: false,
    response: [],
  },
  allCompanyEmployeesData: {
    isFetching: false,
    response: [],
  },
  allReportingManagersList: {
    isFetching: false,
    data: [],
  },
  allHodsList: {
    isFetching: false,
    data: [],
  },
  OrgClientsApproverConfigData: { isFetching: false, data: [] },
  allEmployeesInProjects: { isFetching: false, data: [] },
  timeSheetHistory: { isFetching: false, data: [] },
  employeeTimeSheetSummary: { isFetching: false, data: [] },
  timeSheetReport: { isFetching: false, data: [] },
  EmployeetimeSheet: { isFetching: false, data: [] },
  AllProjectList: { isFetching: false, response: [] },
  createNewHolidayTypeData: {
    isFetching: false,
    response: null,
  },
  updateNewHolidayTypeData: {
    isFetching: false,
    response: null,
  },
  createNewHolidayData: {
    isFetching: false,
    response: null,
  },
  getHolidaysListData: {
    isFetching: false,
    response: null,
  },
  loading: false,
  error: null,
  success: false,
};

export const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: [],
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesignationSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDesignationSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.designationData = {
            status: true,
            designationList: payload.designationData,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchDesignationSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.designationData = { status: false, designationList: [] };
          state.success = false;
        }
      )
      .addCase(fetchBranchSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBranchSliceDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.branchData = { status: true, branchList: payload.branchData };
        state.error = null;
      })
      .addCase(fetchBranchSliceDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.branchData = { status: false, branchList: [] };
        state.success = false;
      })

      .addCase(fetchReimbursementTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchReimbursementTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.reimbursementTypeList = { status: true, data: payload };
          state.error = null;
        }
      )
      .addCase(
        fetchReimbursementTypeSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.reimbursementTypeList = {
            status: false,
            reimbursementTypeList: [],
          };
          state.success = false;
        }
      )

      .addCase(fetchMiscDeductionTypeDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchMiscDeductionTypeDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.miscDeductionTypeList = { status: true, data: payload };
          state.error = null;
        }
      )
      .addCase(
        fetchMiscDeductionTypeDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.miscDeductionTypeList = {
            status: false,
            miscDeductionTypeList: [],
          };
          state.success = false;
        }
      )

      .addCase(fetchDepartmentSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDepartmentSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.departmentData = {
            status: true,
            departmentList: payload.departmentData,
          };
          state.error = null;
        }
      )
      .addCase(fetchDepartmentSliceDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.departmentData = { status: false, departmentList: [] };
        state.success = false;
      })
      .addCase(fetchEmploymentTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchEmploymentTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.employmentTypeData = {
            status: true,
            employmentTypeList: payload.employmentTypeData,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmploymentTypeSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.employmentTypeData = { status: false, employmentTypeList: [] };
          state.success = false;
        }
      )
      .addCase(fetchHolidaySliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHolidaySliceDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employmentTypeData = {
          status: true,
          holidayList: payload.holidayData,
        };
        state.error = null;
      })
      .addCase(fetchHolidaySliceDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.holidayData = { status: false, holidayList: [] };
        state.success = false;
      })
      .addCase(fetchSkillsSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSkillsSliceDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.skillData = {
          status: true,
          skillList: payload.skillData,
        };
        state.error = null;
      })
      .addCase(fetchSkillsSliceDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.skillData = { status: false, skillList: [] };
        state.success = false;
      })

      .addCase(fetchClientSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClientSliceDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.clientData = {
          status: true,
          clientList: payload,
        };
        state.error = null;
      })
      .addCase(fetchClientSliceDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.clientData = { status: false, clientList: [] };
        state.success = false;
      })

      .addCase(fetchDeclarationSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDeclarationSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.declarationData = {
            status: true,
            declarationList: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchDeclarationSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.declarationData = { status: false, declarationList: [] };
          state.success = false;
        }
      )

      .addCase(fetchDeclarationTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDeclarationTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.declarationTypeData = {
            status: true,
            declarationTypeList: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchDeclarationTypeSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.declarationTypeData = {
            status: false,
            declarationTypeList: [],
          };
          state.success = false;
        }
      )
      .addCase(fetchMealDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.todayMealData = {
          status: false,
          mealList: [],
          isFetching: true,
        };
      })
      .addCase(fetchMealDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.todayMealData = {
          status: true,
          mealList: payload,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(fetchMealDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.todayMealData = {
          status: false,
          mealList: [],
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(postMealFeedBack.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.mealFeedbackData = {
          status: false,
        };
      })
      .addCase(postMealFeedBack.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.mealFeedbackData = {
          status: true,
        };
        state.error = null;
      })
      .addCase(postMealFeedBack.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.mealFeedbackData = {
          status: false,
        };
        state.success = false;
      })
      .addCase(postTemporaryAccessCard.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.temporaryAccessCard = {
          status: false,
          isFetching: true,
        };
      })
      .addCase(postTemporaryAccessCard.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.temporaryAccessCard = {
          status: true,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(postTemporaryAccessCard.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.temporaryAccessCard = {
          status: false,
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(fetchAttRegularizationReasons.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.attRegularizationReasons = {
          status: false,
          reasons: [],
          isFetching: true,
        };
      })
      .addCase(
        fetchAttRegularizationReasons.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.attRegularizationReasons = {
            status: true,
            reasons: payload?.data,
            isFetching: false,
          };
          state.error = null;
        }
      )
      .addCase(fetchAttRegularizationReasons.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.attRegularizationReasons = {
          status: false,
          reasons: [],
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(fetchDepartmentTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.departmentTypeData = { isFetching: true, departmentTypeList: [] };
      })
      .addCase(
        fetchDepartmentTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.departmentTypeData = {
            isFetching: false,
            departmentTypeList: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchDepartmentTypeSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.departmentTypeData = {
            isFetching: false,
            departmentTypeList: [],
          };
          state.success = false;
        }
      )
      .addCase(fetchDesignationTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.designationTypeData = {
          isFetching: true,
          designationTypeList: [],
        };
      })
      .addCase(
        fetchDesignationTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.designationTypeData = {
            isFetching: false,
            designationTypeList: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchDesignationTypeSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.designationTypeData = {
            isFetching: false,
            designationTypeList: [],
          };
          state.success = false;
        }
      )
      .addCase(fetchBranchTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.branchTypeData = { isFetching: true, branchTypeList: [] };
      })
      .addCase(
        fetchBranchTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.branchTypeData = {
            isFetching: false,
            branchTypeList: payload,
          };
          state.error = null;
        }
      )
      .addCase(fetchBranchTypeSliceDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.branchTypeData = { isFetching: false, branchTypeList: [] };
        state.success = false;
      })
      .addCase(fetchEmploymentTypeListSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employmentTypeListData = { isFetching: true, employmentType: [] };
      })
      .addCase(
        fetchEmploymentTypeListSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.employmentTypeListData = {
            isFetching: false,
            employmentType: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmploymentTypeListSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.employmentTypeListData = {
            isFetching: false,
            employmentType: [],
          };
          state.success = false;
        }
      )
      .addCase(fetchHolidayTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.holidayTypeData = { isFetching: true, holidayTypeList: [] };
      })
      .addCase(
        fetchHolidayTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.holidayTypeData = {
            isFetching: false,
            holidayTypeList: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchHolidayTypeSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.holidayTypeData = { isFetching: false, holidayTypeList: [] };
          state.success = false;
        }
      )
      .addCase(fetchCompanyTypeSliceDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.companyTypeData = { isFetching: true, companyTypeList: [] };
      })
      .addCase(
        fetchCompanyTypeSliceDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.companyTypeData = {
            isFetching: false,
            companyTypeList: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchCompanyTypeSliceDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.companyTypeData = { isFetching: false, companyTypeList: [] };
          state.success = false;
        }
      )
      .addCase(fetchSkillsSetStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.skillsSetData = { isFetching: true, skillsSetList: [] };
      })
      .addCase(fetchSkillsSetStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.skillsSetData = {
          isFetching: false,
          skillsSetList: payload,
        };
        state.error = null;
      })
      .addCase(fetchSkillsSetStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.skillsSetData = { isFetching: false, skillsSetList: [] };
        state.success = false;
      })
      .addCase(fetchOrganizationClient.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.organizationClientResponse = {
          isFetching: true,
          organizationClientData: null,
        };
      })
      .addCase(fetchOrganizationClient.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.organizationClientResponse = {
          isFetching: false,
          organizationClientData: payload,
        };
        state.error = null;
      })
      .addCase(fetchOrganizationClient.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.organizationClientResponse = {
          isFetching: false,
          organizationClientData: null,
        };
        state.success = false;
      })
      .addCase(createOrganizationClientStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createOrganizationClientResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createOrganizationClientStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createOrganizationClientResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(createOrganizationClientStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createOrganizationClientResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateOrganizationClientStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateOrganizationClientResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateOrganizationClientStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateOrganizationClientResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(updateOrganizationClientStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateOrganizationClientResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchClientProject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.clientProjectResponse = {
          isFetching: true,
          clientProjectData: null,
        };
      })
      .addCase(fetchClientProject.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.clientProjectResponse = {
          isFetching: false,
          clientProjectData: payload,
        };
        state.error = null;
      })
      .addCase(fetchClientProject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.clientProjectResponse = {
          isFetching: false,
          clientProjectData: null,
        };
        state.success = false;
      })
      .addCase(createClientProjectStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createClientProjectResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createClientProjectStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createClientProjectResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createClientProjectStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createClientProjectResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateClientProjectStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateClientProjectResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateClientProjectStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateClientProjectResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateClientProjectStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateClientProjectResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(uploadClientLogo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uploadClientLogoResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(uploadClientLogo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.uploadClientLogoResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(uploadClientLogo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.uploadClientLogoResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(addEmployeesInProject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addEmployeeInProjectResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(addEmployeesInProject.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.addEmployeeInProjectResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(addEmployeesInProject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.addEmployeeInProjectResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteEmployeesFromProject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteEmployeeFromProjectResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteEmployeesFromProject.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteEmployeeFromProjectResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteEmployeesFromProject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteEmployeeFromProjectResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })

      .addCase(getCurrencylist.pending, (state) => {
        state.currencyList.isFetching = true;
      })
      .addCase(getCurrencylist.fulfilled, (state, { payload }) => {
        state.currencyList.isFetching = false;
        state.currencyList.data = payload;
      })
      .addCase(getCurrencylist.rejected, (state, { payload }) => {
        state.currencyList.isFetching = true;
        state.currencyList.data = [];
      })
      .addCase(createNewTimeSheetTask.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createNewTimeSheetTaskData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createNewTimeSheetTask.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createNewTimeSheetTaskData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createNewTimeSheetTask.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createNewTimeSheetTaskData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createNewTeamTimeSheetTask.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createNewTeamTimeSheetTaskData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createNewTeamTimeSheetTask.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createNewTeamTimeSheetTaskData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createNewTeamTimeSheetTask.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createNewTeamTimeSheetTaskData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchSelfTimeSheetTaskList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.selfTimeSheetTaskList = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchSelfTimeSheetTaskList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.selfTimeSheetTaskList = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(fetchSelfTimeSheetTaskList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.selfTimeSheetTaskList = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })
      .addCase(dismissTimeSheetSelfTask.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.dismissTimeSheetSelfTaskStore = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(dismissTimeSheetSelfTask.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.dismissTimeSheetSelfTaskStore = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(dismissTimeSheetSelfTask.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.dismissTimeSheetSelfTaskStore = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchTeamTimeSheetTaskList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.teamTimeSheetTaskList = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchTeamTimeSheetTaskList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.teamTimeSheetTaskList = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(fetchTeamTimeSheetTaskList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.teamTimeSheetTaskList = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })
      .addCase(fetchTeamTimeSheetReport.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.timeSheetReport = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchTeamTimeSheetReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.timeSheetReport = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(fetchTeamTimeSheetReport.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.timeSheetReport = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeTimeSheetReport.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.EmployeetimeSheet = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchEmployeeTimeSheetReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.EmployeetimeSheet = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeTimeSheetReport.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.EmployeetimeSheet = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })
      .addCase(approveTimeSheetTask.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.approveTimeSheetTaskStore = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(approveTimeSheetTask.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.approveTimeSheetTaskStore = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(approveTimeSheetTask.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.approveTimeSheetTaskStore = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchParticularEmployeeAttendanceList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeAttendanceListData = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(
        fetchParticularEmployeeAttendanceList.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.employeeAttendanceListData = {
            isFetching: false,
            data: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchParticularEmployeeAttendanceList.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.employeeAttendanceListData = {
            isFetching: false,
            data: null,
          };
          state.success = false;
        }
      )
      .addCase(createNewAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createNewAttendanceData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createNewAttendance.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createNewAttendanceData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createNewAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createNewAttendanceData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateAttendanceData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateAttendance.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateAttendanceData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateAttendanceData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(downloadEmployeeTimeSheetTask.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.downloadEmployeeTimeSheet = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(
        downloadEmployeeTimeSheetTask.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.downloadEmployeeTimeSheet = {
            isFetching: false,
            data: payload,
          };
          state.error = null;
        }
      )
      .addCase(downloadEmployeeTimeSheetTask.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.downloadEmployeeTimeSheet = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })
      .addCase(getCalculateOtData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.calculatedOtData = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(getCalculateOtData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.calculatedOtData = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(getCalculateOtData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.calculatedOtData = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })

      .addCase(fetchMailConfigList.pending, (state) => {
        state.mailConfigList.isFetching = true;
      })
      .addCase(fetchMailConfigList.fulfilled, (state, { payload }) => {
        state.mailConfigList.isFetching = false;
        state.mailConfigList.data = payload;
      })
      .addCase(fetchMailConfigList.rejected, (state, { payload }) => {
        state.mailConfigList.isFetching = true;
        state.mailConfigList.data = [];
      })
      .addCase(updateEmailConfigStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmailConfigStatusData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateEmailConfigStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateEmailConfigStatusData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateEmailConfigStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateEmailConfigStatusData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createEmailConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmailConfigData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createEmailConfig.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createEmailConfigData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createEmailConfig.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createEmailConfigData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createApproveOT.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createApproveOTData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createApproveOT.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createApproveOTData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createApproveOT.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createApproveOTData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createNewConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createConfigData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createNewConfig.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createConfigData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createNewConfig.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createConfigData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateConfigDataValue = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateConfigData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateConfigDataValue = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateConfigData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateConfigDataValue = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateMasterApprovalConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateMasterApprovalConfigDataValue = {
          isFetching: true,
        };
      })
      .addCase(
        updateMasterApprovalConfigData.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateMasterApprovalConfigDataValue = {
            isFetching: false,
          };
          state.error = null;
        }
      )
      .addCase(
        updateMasterApprovalConfigData.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateMasterApprovalConfigDataValue = {
            isFetching: false,
          };
          state.success = false;
        }
      )
      .addCase(createMasterApprovalConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createMasterApprovalConfigDataValue = {
          isFetching: true,
        };
      })
      .addCase(
        createMasterApprovalConfigData.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createMasterApprovalConfigDataValue = {
            isFetching: false,
          };
          state.error = null;
        }
      )
      .addCase(
        createMasterApprovalConfigData.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createMasterApprovalConfigDataValue = {
            isFetching: false,
          };
          state.success = false;
        }
      )
      .addCase(getMasterConfigData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getConfigDataValue = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getMasterConfigData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getConfigDataValue = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getMasterConfigData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getConfigDataValue = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchRolesAndComponent.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.rolesAndComponents = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchRolesAndComponent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.rolesAndComponents = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchRolesAndComponent.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.rolesAndComponents = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchAllCompanyEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allCompanyEmployeesData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchAllCompanyEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.allCompanyEmployeesData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchAllCompanyEmployee.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allCompanyEmployeesData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchAllReportingManagers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allReportingManagersList = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchAllReportingManagers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.allReportingManagersList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchAllReportingManagers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allReportingManagersList = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchAllHods.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allHodsList = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchAllHods.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.allHodsList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchAllHods.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allHodsList = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchOrgClientsApproverConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.OrgClientsApproverConfigData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchOrgClientsApproverConfig.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.OrgClientsApproverConfigData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(fetchOrgClientsApproverConfig.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.OrgClientsApproverConfigData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchEmployeesFromAllProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allEmployeesInProjects = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchEmployeesFromAllProjects.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.allEmployeesInProjects = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(fetchEmployeesFromAllProjects.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allEmployeesInProjects = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchTimeSheetHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.timeSheetHistory = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchTimeSheetHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.timeSheetHistory = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchTimeSheetHistory.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.timeSheetHistory = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(getEmployeesTimeSheetSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeTimeSheetSummary = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getEmployeesTimeSheetSummary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeTimeSheetSummary = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getEmployeesTimeSheetSummary.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeTimeSheetSummary = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchAllProjectList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.AllProjectList = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchAllProjectList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.AllProjectList = {
          isFetching: false,
          AllProjectList: payload,
        };
        state.error = null;
      })
      .addCase(fetchAllProjectList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.AllProjectList = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(resetTimeSheetSummary, (state) => {
        state.employeeTimeSheetSummary = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(CreateNewHolidayTypeSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createNewHolidayTypeData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(CreateNewHolidayTypeSlice.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createNewHolidayTypeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(CreateNewHolidayTypeSlice.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createNewHolidayTypeData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(UpdateNewHolidayTypeSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateNewHolidayTypeData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(UpdateNewHolidayTypeSlice.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateNewHolidayTypeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(UpdateNewHolidayTypeSlice.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateNewHolidayTypeData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(CreateNewHolidaySlice.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createNewHolidayData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(CreateNewHolidaySlice.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createNewHolidayData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(CreateNewHolidaySlice.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createNewHolidayData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getHolidaysList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getHolidaysListData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getHolidaysList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getHolidaysListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getHolidaysList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getHolidaysListData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      });
  },
});

export default masterSlice.reducer;
