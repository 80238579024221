import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import "../LeaveConfigDashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faClone,
  faHashtag,
  faHousePersonLeave,
  faMagnifyingGlass,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeavePlanList,
  updateExistingLeavePlan,
} from "../../../store/slices/leaveConfigSlice";
import dayjs from "dayjs";
import AddLeavePlan from "./AddLeavePlan";
import UpdateLeavePlan from "./UpdateLeavePlan";
import ConfirmDeleteLeavePlanDialog from "./ConfirmDeleteLeavePlanDialog";
import { ClickAwayListener } from "@mui/material";
import UpdateLeavePlanConfig from "./UpdateLeavePlanConfig";
import AssignLeaveTypes from "./AssignLeaveTypes";
import LeaveConfiguration from "./LeaveConfigSetup/LeaveConfiguration";
const LeavePlans = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(0);
  const [activeTab, setActiveTab] = useState("configuration");
  const [addLeavePlan, setAddLeavePlan] = useState(false);
  const [updateLeavePlan, setUpdateLeavePlan] = useState(false);
  const [activeLeavePlan, setActiveLeavePlan] = useState("");
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [updateConfigData, setUpdateConfigData] = useState({});
  const [assignLeaveTypes, setAssignLeaveTypes] = useState(false);

  const handleTabChange = (e) => {
    setActiveTab(e.target.id);
  };
  useEffect(() => {
    dispatch(getLeavePlanList({ search: search }));
  }, [dispatch, search]);

  const planList = useSelector(
    (state) => state?.leaveConfig?.planList?.response?.leavePlanData
  );

  useEffect(() => {
    setActiveLeavePlan(planList?.[0]);
  }, [planList]);

  const handleSearchChange = _.debounce((event) => {
    setSearch(event.target.value);
  }, 800);

  const handleLeavePlanDelete = async () => {
    let data = {
      id: activeLeavePlan?.id,
      body: { isActive: 0 },
    };
    try {
      if (data?.id) {
        await dispatch(updateExistingLeavePlan(data));
        await dispatch(getLeavePlanList());
        setOpen(false);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  let data = [
    {
      id: 1,
      labelType: "Leave Type",
      quota: "Quota",
      accural: "Accural",
      end_of_year: "End Of Year",
      action: { setup: "Setup", delete: <FontAwesomeIcon icon={faTrash} /> },
    },
    {
      id: 2,
      labelType: "Leave Type",
      quota: "Quota",
      accural: "Accural",
      end_of_year: "End Of Year",
      action: { setup: "Setup", delete: <FontAwesomeIcon icon={faTrash} /> },
    },
    {
      id: 3,
      labelType: "Leave Type",
      quota: "Quota",
      accural: "Accural",
      end_of_year: "End Of Year",
      action: { setup: "Setup", delete: <FontAwesomeIcon icon={faTrash} /> },
    },
    {
      id: 4,
      labelType: "Leave Type",
      quota: "Quota",
      accural: "Accural",
      end_of_year: "End Of Year",
      action: { setup: "Setup", delete: <FontAwesomeIcon icon={faTrash} /> },
    },
    {
      id: 5,
      labelType: "Leave Type",
      quota: "Quota",
      accural: "Accural",
      end_of_year: "End Of Year",
      action: { setup: "Setup", delete: <FontAwesomeIcon icon={faTrash} /> },
    },
  ];
  const handleUpdateConfig = (event, list) => {
    setUpdateConfigData(list);
    setIsUpdateOpen(true);
  };

  return (
    <>
      {isUpdateOpen ? (
        <UpdateLeavePlanConfig
          isUpdateOpen={isUpdateOpen}
          setIsUpdateOpen={setIsUpdateOpen}
          updateConfigData={updateConfigData}
          setUpdateConfigData={setUpdateConfigData}
        />
      ) : (
        <div className="leavePlans p-3">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <div className="text-2xl">Leave Plans</div>
                <p className="text-sm">
                  You can now configure various leave plans for various groups
                  of employees
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-end">
                <button
                  onClick={() => setAddLeavePlan(true)}
                  className=" text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-600 py-2 px-3"
                >
                  <FontAwesomeIcon className="mr-1" icon={faPlus} />
                  Add Leave Plan
                </button>
              </div>
            </div>
          </div>
          <div className="leavePlansContainer mt-3">
            <div className="leavePlanLeft bg-white rounded-t-md">
              <div className="flex items-center rounded-t-md bg-white py-2 px-3 text-black border-b border-gray-200">
                <span
                  className="text-gray-500 cursor-text"
                  onClick={() => ref.current.focus()}
                >
                  <FontAwesomeIcon className="fa-1x" icon={faMagnifyingGlass} />
                </span>
                <input
                  ref={ref}
                  onChange={handleSearchChange}
                  type="text"
                  name="search input"
                  placeholder="Search"
                  className="text-sm w-full border-none"
                />
              </div>
              <div className="py-2 px-3 border-b border-gray-200 font-semibold bg-gray-50 text-gray-950">
                LEAVE PLANS LIST
              </div>
              <ul className="leavePlanList capitalize">
                {planList?.length > 0 &&
                  planList?.map((data, index) => {
                    return (
                      <li
                        key={index}
                        id={index}
                        onClick={() => {
                          setActive(index);
                          setActiveLeavePlan(data);
                        }}
                        className={
                          active === index
                            ? "listItem text-sm border-b border-gray-200 p-3 bg-blue-500 text-white cursor-pointer"
                            : "listItem text-sm border-b border-gray-200 p-3 cursor-pointer"
                        }
                      >
                        {data?.planName}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="leavePlanRight">
              <div className="bg-white p-3 rounded-t-md border-b border-gray-200">
                <div className="row items-center">
                  <div className="col-md-6">
                    <div className="capitalize">
                      {activeLeavePlan?.planName && (
                        <div className="">{activeLeavePlan?.planName}</div>
                      )}
                      {activeLeavePlan?.planStartDate ? (
                        <p className="text-sm mt-2">
                          <FontAwesomeIcon
                            className="pl-1 mr-2 text-purple-700"
                            icon={faCalendarDays}
                          />
                          {dayjs(activeLeavePlan?.planStartDate)?.format(
                            "DD-MMM-YYYY"
                          )}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="flex items-center justify-end">
                      <FontAwesomeIcon
                        icon={faPen}
                        className="cursor-pointer text-blue-600 px-2"
                        onClick={() => setUpdateLeavePlan(true)}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="cursor-pointer text-red-600 px-2"
                        onClick={() => setOpen(true)}
                      />
                      <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                        <div
                          className="leavePlanMenu relative"
                          onClick={() => setOpenMenu((prev) => !prev)}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer text-gray-700 px-2"
                            icon={faEllipsisVertical}
                          />
                          {openMenu && (
                            <ul className="leavePlanMunuList rounded-md border-1 border-gray-200 absolute top-6 right-0 whitespace-nowrap bg-white">
                              <li
                                onClick={() => setAssignLeaveTypes(true)}
                                className="py-2 px-3 text-sm cursor-pointer hover:bg-gray-50"
                              >
                                <FontAwesomeIcon
                                  className="text-blue-700 mr-3"
                                  icon={faHousePersonLeave}
                                />
                                Assign Leave Types
                              </li>
                              <li className="border-t border-gray-200 py-2 px-3 text-sm cursor-pointer hover:bg-gray-50">
                                <FontAwesomeIcon
                                  icon={faHashtag}
                                  className="text-blue-700 mr-3"
                                />
                                Set as Default
                              </li>
                              <li className="border-t border-gray-200 py-2 px-3 text-sm cursor-pointer hover:bg-gray-50">
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-blue-700 mr-3"
                                />
                                Clone Leave Plan
                              </li>
                            </ul>
                          )}
                        </div>
                      </ClickAwayListener>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="flex items-center bg-white text-sm">
                <li
                  className={
                    activeTab === "configuration"
                      ? "active border-b border-blue-500 py-2 px-3 cursor-pointer"
                      : "border-b border-transparent py-2 px-3 cursor-pointer"
                  }
                  id="configuration"
                  onClick={(e) => handleTabChange(e)}
                >
                  Configuration
                </li>
                <li
                  className={
                    activeTab === "employees"
                      ? "active border-b border-blue-500 py-2 px-3 cursor-pointer"
                      : "border-b border-transparent py-2 px-3 cursor-pointer"
                  }
                  id="employees"
                  onClick={(e) => handleTabChange(e)}
                >
                  Employees
                </li>
              </ul>
              <div className="bg-white rounded-t-md mt-4">
                {activeTab === "configuration" ? (
                  <LeaveConfiguration
                    id={activeLeavePlan?.id}
                    handleUpdateConfig={handleUpdateConfig}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      {addLeavePlan && (
        <AddLeavePlan
          setAddLeavePlan={setAddLeavePlan}
          addLeavePlan={addLeavePlan}
        />
      )}
      <ConfirmDeleteLeavePlanDialog
        open={open}
        setOpen={setOpen}
        handleLeavePlanDelete={handleLeavePlanDelete}
      />
      {assignLeaveTypes && (
        <AssignLeaveTypes
          assignLeaveTypes={assignLeaveTypes}
          setAssignLeaveTypes={setAssignLeaveTypes}
          activeLeavePlan={activeLeavePlan?.id}
        />
      )}
      {updateLeavePlan && (
        <UpdateLeavePlan
          setUpdateLeavePlan={setUpdateLeavePlan}
          updateLeavePlan={updateLeavePlan}
          activeLeavePlan={activeLeavePlan}
        />
      )}
    </>
  );
};

export default LeavePlans;
