// import CarouselEmployee from "./CarouselEmployee";
import React from "react";
import Navbar from "../Navbar/Navbar";

import ImageSlider from "./ImageSlider";
import { SliderData } from "./Slider";
import styles from "./EmployeeHandbook.module.css";
// import "../../styles/ImageSlider.css";

export const EmployeeHandbook = () => {
  return (
    <div>
    
      
      {/* <CarouselEmployee /> */}
      <ImageSlider slides={SliderData} />
    </div>
  );
};
