import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserAuth } from "../store/slices/authSlice";
import { userComponentAccess } from "../store/slices/componentSlice";
import { getAccessToken } from "../utils/token";

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { accessToken } = useSelector((state) => state.auth);

  useEffect(() => {
    const accessTokenLocalStorage = getAccessToken();
    if (accessTokenLocalStorage) {
      dispatch(
        setUserAuth({
          accessToken: accessTokenLocalStorage,
          isAuthenticated: true,
        })
      );
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      dispatch(userComponentAccess());
    }
  }, [dispatch, accessToken]);

  return loading ? null : children;
};

export default AuthProvider;
