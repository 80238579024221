import { getMaxObject } from "./helper";
import store from "../store";

const getPermissionData = () => {
  const state = store.getState();
  const userAccessData = state?.component?.userAccessData;
  const roleAccessData = userAccessData?.roleAccessData;
  const selfAccessData = userAccessData?.selfAccessData;
  const userId = userAccessData?.employeeId; //ex: 10633
  return { roleAccessData, selfAccessData, userId };
};

/**get logged in user component access - self, roles */
const getloggedInUserComponentAccess = ({ component = "" }) => {
  const { selfAccessData, roleAccessData, userId } = getPermissionData();
  const selfPermission = selfAccessData?.[component];
  const rolesPermission = roleAccessData?.[component];
  return { selfPermission, rolesPermission, userId };
};

/** Get merged access of a component [merge self and roles access] for logged in user */
export const componentDisplayAccess = ({ component = "" }) => {
  const { selfPermission, rolesPermission } = getloggedInUserComponentAccess({
    component,
  });
  return selfPermission && rolesPermission
    ? getMaxObject(selfPermission, rolesPermission)
    : rolesPermission
    ? rolesPermission
    : selfPermission;
};

/** Return employees list with accessType [including self access if logged in userId defined in list]*/
export const getEmployeesAccess = ({
  employees = [],
  component,
  accessType = null,
}) => {
  const userPermissions = getloggedInUserComponentAccess({ component });
  const allEmployeeAccess = {};
  for (const employee of employees) {
    if (Number(employee) !== Number(userPermissions?.userId)) {
      allEmployeeAccess[employee] = accessType
        ? userPermissions?.rolesPermission?.[accessType]
        : userPermissions?.rolesPermission;
    } else {
      allEmployeeAccess[employee] = accessType
        ? userPermissions?.selfPermission?.[accessType]
        : userPermissions?.selfPermission;
    }
  }
  return allEmployeeAccess;
};

/**Return component access for given employeeId  */
export const componentAccessByEmployeeId = ({ component = "", employeeId }) => {
  const { selfPermission, rolesPermission, userId } =
  getloggedInUserComponentAccess({ component });
  if (Number(employeeId) !== Number(userId)) {
    return rolesPermission;
  } else {
    return selfPermission;
  }
};
