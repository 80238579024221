import Popover from "@mui/material/Popover";
import Alert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import { config } from "./config";
import { getLoggedUserData } from "../../../utils/helper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TodayIcon from "@mui/icons-material/Today";
import moment from "moment";
import styles from "./Notifications.module.css";
import { faBellSlash } from "@fortawesome/free-regular-svg-icons";
import {
  FetchEmployeeNotifcations,
  UpdateEmployeeNotifcations,
} from "../../Services/NotificationService";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEmpty } from "ramda";
import { faBell } from "@fortawesome/pro-thin-svg-icons";
import { useSelector } from "react-redux";

const theme = createTheme({
  palette: {
    warning: {
      main: "#664D03",
    },
    info: {
      main: "#175E6C",
    },
  },
});

const useStyles = makeStyles(() => ({
  paper: {
    width: "300px",
    height: "380px",
    overflowX: "auto",
    borderRadius: "4px",
  },
}));

const Notifications = ({ socket }) => {
  const loggedEmployee = getLoggedUserData();
  const employeeId = loggedEmployee?.employeeId ?? null;
  const classes = useStyles();
  const [notification, setNotification] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [unRead, setUnRead] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const success = useSelector((state) => state?.component?.success);

  const notificationHandler = async (event) => {
    if (!!success) {
      setNotificationAnchorEl(event.currentTarget);
      const notificationUpdates = await UpdateEmployeeNotifcations({
        employeeId,
      });
      if (notificationUpdates?.status) {
        setUnRead(0);
      }
    }
  };

  const fetchRecords = async () => {
    if (!!success) {
      const data = await FetchEmployeeNotifcations({
        employeeId,
        params: { limit: 6, page },
      });
      const filterNotification = data?.rows
        ? data?.rows?.filter((v) => {
            const result = notification.find((val) => val.Id === v.Id);
            return isEmpty(result) ? false : true;
          })
        : [];
      setNotification((prev) => [...prev, ...filterNotification]);
      setDataLength(data?.count);
      setUnRead(data?.unReadCount);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (notification && notification.length > 0) {
      if (dataLength === notification.length) {
        setHasMoreData(false);
      }
    }
  }, [notification, dataLength]);

  useEffect(() => {
    fetchRecords();
  }, [employeeId]);

  const notificationOpen = Boolean(notificationAnchorEl);
  const id = notificationOpen ? "notification-popover" : undefined;

  useEffect(() => {
    if (socket) {
      socket.off("Notification").on("Notification", (data) => {
        if (!isEmpty(data)) {
          setNotification((prev) => [...prev, data]);
          setUnRead((prev) => prev + 1);
          setDataLength((prev) => prev + 1);
        }
      });
    }
  }, [socket]);

  return (
    <ThemeProvider theme={theme}>
      <div
        className="notificationIcon position-relative border-0"
        aria-describedby={id}
        onClick={notificationHandler}
      >
        <FontAwesomeIcon icon={faBell} className="fs-4 text-muted" />
        {unRead ? (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
            {unRead}
          </span>
        ) : (
          <></>
        )}
      </div>
      <Popover
        id={id}
        classes={{ paper: classes.paper }}
        open={notificationOpen}
        anchorEl={notificationAnchorEl}
        onClose={() => setNotificationAnchorEl(null)}
        anchorOrigin={{
          vertical: 40,
          horizontal: -150,
        }}
      >
        <h5
          className={`text-center position-sticky sticky-top ${styles.notificationHeading}`}
        >
          Notifications
        </h5>
        <div className="col">
          {notification.length ? (
            <InfiniteScroll
              dataLength={notification.length}
              next={fetchRecords}
              hasMore={hasMoreData}
              height={300}
            >
              {notification && notification.length ? (
                notification
                  .sort((a, b) => b.Id - a.Id)
                  .map((v) => {
                    return (
                      <Alert
                        key={v?.Id}
                        severity={config.severity[v?.type]}
                        color={config.notificationColor[v?.type]}
                        style={config.Notification[v?.type]}
                        iconMapping={{
                          success: (
                            <CheckCircleOutlineIcon fontSize="inherit" />
                          ),
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: v?.notification }}
                        ></div>
                        <div className={styles.notificationDate}>
                          <TodayIcon />
                          {v?.creation &&
                            moment(v?.creation).format("MMM DD, YYYY")}
                        </div>
                      </Alert>
                    );
                  })
              ) : (
                <div className={styles.disableNotificationIcon}>
                  <FontAwesomeIcon icon={faBellSlash} />
                </div>
              )}
            </InfiniteScroll>
          ) : (
            <div className={styles.defaultIcon}>
              <FontAwesomeIcon icon={faBellSlash} />
              <label className={styles.emptyCardLabel}>No Notifications</label>
            </div>
          )}
        </div>
      </Popover>
    </ThemeProvider>
  );
};

export default Notifications;
