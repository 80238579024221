import React, { useEffect } from "react";
import "../LeaveConfigDashboard.css";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import {
  updateExistingLeavePlan,
  getLeavePlanList,
} from "../../../store/slices/leaveConfigSlice";

const updateLeavePlanSchema = yup?.object({
  planName: yup?.string()?.label("Leave Plan Name")?.required().trim(),
  description: yup
    ?.string()
    ?.label("Description")
    ?.optional()
    .nullable()
    .trim(),
  planStartDate: yup?.string()?.label("Plan Start Date")?.required().trim(),
});
const UpdateLeavePlan = ({
  updateLeavePlan,
  setUpdateLeavePlan,
  activeLeavePlan,
}) => {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      planName: activeLeavePlan?.planName ?? null,
      description: activeLeavePlan?.description ?? null,
      planStartDate: activeLeavePlan?.planStartDate ?? null,
    },
    resolver: yupResolver(updateLeavePlanSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    trigger,
  } = methods;
  useEffect(() => {
    const defaultValues = {
      planName: activeLeavePlan?.planName ?? null,
      description: activeLeavePlan?.description ?? null,
      planStartDate: activeLeavePlan?.planStartDate ?? null,
    };
    methods.reset(defaultValues);
  }, [activeLeavePlan, methods]);

  const isFetching = useSelector(
    (state) => state?.leaveConfig?.updateLeavePlan?.isFetching
  );
  const submitHandler = async (data) => {
    await dispatch(
      updateExistingLeavePlan({ id: activeLeavePlan?.id, body: data })
    );
    await dispatch(getLeavePlanList());
    reset();
    setUpdateLeavePlan(false);
  };
  return (
    <>
      <div
        className={
          updateLeavePlan ? "createLeaveDrawer active" : "createLeaveDrawer"
        }
      >
        <div className="bg-blue-500 text-white py-4 px-3 w-full whitespace-nowrap">
          <div className="text-lg mb-2">
            <div className="flex items-center justify-between">
              <div>Update Leave Plan</div>
              <div
                className="headerClose cursor-pointer"
                onClick={() => {
                  setUpdateLeavePlan(false);
                  reset();
                }}
              >
                <FontAwesomeIcon className="fa-lg" icon={faXmark} />
              </div>
            </div>
            <div className="text-sm text-gray-200">
              Get started by filling the information below to update leave plan.
            </div>
          </div>
        </div>
        {/* {isFetching && <Loader />} */}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={updateLeavePlan ? "active" : ""}>
              <div className="addLeaveForm bg-white p-3">
                <div className="mb-3">
                  <label
                    htmlFor="planName"
                    className="block text-sm mb-1 text-gray-900"
                  >
                    Leave Plan Name<span className="text-red-500">*</span>
                  </label>
                  <TextFieldComponent
                    name="planName"
                    onChangeHandler={() => trigger("planName")}
                    control={control}
                    errors={errors}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="description"
                    className="block text-sm mb-1 text-gray-900"
                  >
                    Description
                  </label>
                  <div className="font-sans">
                    <TextAreaComponent
                      name="description"
                      placeholder="Write your description here..."
                      onChangeHandler={() => trigger("description")}
                      control={control}
                      errors={errors}
                      rows={4}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="planStartDate"
                    className="block text-sm mb-1 text-gray-900"
                  >
                    Plan Start Date<span className="text-red-500">*</span>
                  </label>
                  <DatePickerComponent
                    name="planStartDate"
                    onChangeHandler={() => trigger("planStartDate")}
                    control={control}
                    errors={errors}
                    backSpaceChange={false}
                    disableMaskedInput={true}
                  />
                </div>
              </div>
            </div>
            <div className="text-end p-3 whitespace-nowrap">
              <button
                onClick={() => {
                  setUpdateLeavePlan(false);
                  reset();
                }}
                type="button"
                className="text-sm border-1 border-gray-500 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-600 hover:border-gray-600 py-2 px-3"
              >
                Cancel
              </button>
              <button
                type="submit"
                id={!isDirty ? "newDisable" : ""}
                className="ml-2 text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-600 text-white hover:bg-blue-700 py-2 px-3"
              >
                Update
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          updateLeavePlan ? "createLeaveOverlay active" : "createLeaveOverlay"
        }
        onClick={() => setUpdateLeavePlan(false)}
      ></div>
    </>
  );
};

export default UpdateLeavePlan;
